import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';
import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';

export default function CollectionDatastorePathField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (THIRD_PARTY_TYPES.REGIONAL.uriTest.test(newValue)) {
      setError(false);
    } else {
      setError(`Invalid path. Must be valid linux absolute file path`);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Path'
          id='path'
          aria-describedby='uri-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='uri-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='uri-helper-text'>
            Enter the path to the folder containing the desired data in the
            datastore
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
