const THIRD_PARTY_TYPES = {
  LOCAL: {
    key: 'local',
    icon: 'computer',
    value: 'local',
    text: 'Local',
    alwaysEnabled: true,
    uriTest: /^(\/)|(~\/)|(\$)/i,
    uriExample: '~/Documents/path/to/data',
    toolTip: 'Load data to/from your local computer',
  },
  AWS: {
    key: 'aws',
    icon: 'aws',
    text: 'AWS',
    value: 'aws',
    uriTest: /^(s3):\/\//i,
    uriExample: 's3://my-aws-bucket/path/to/data',
    toolTip: 'Load data to/from an S3 bucket',
  },
  WEB: {
    key: 'web',
    icon: 'world',
    text: 'Web',
    value: 'web',
    alwaysEnabled: true,
    uriTest: /^(http(s?)|ftp(s?)):\/\//i,
    uriExample: 'https://www.example.com/path/to/data/file.tar.gz',
    toolTip: 'Load data from an HTTP/FTP server',
  },
  GCP: {
    key: 'gcp',
    icon: 'google',
    text: 'GCP',
    value: 'gcp',
    uriTest: /^(gs):\/\//i,
    uriExample: 'gs://my-gcp-bucket/path/to/data',
    toolTip: 'Load data to/from a Google Cloud Storage bucket',
  },
  AZURE: {
    key: 'azure',
    icon: 'microsoft',
    text: 'Azure',
    value: 'azure',
    uriTest: /^https:\/\//i,
    toolTip: 'Load data to/from Azure Blob Storage',
  },
  KAGGLE: {
    key: 'kaggle',
    icon: 'trophy',
    text: 'Kaggle',
    value: 'kaggle',
    uriExample: 'allen-institute-for-ai/CORD-19-research-challenge',
    uriTest: /.*/,
    toolTip: 'Load data from your Kaggle account',
  },
  GIT: {
    key: 'git',
    icon: 'git',
    text: 'Git',
    value: 'git',
    alwaysEnabled: true,
    uriExample: `https://github.com/${process.env.REACT_APP_ORG_NAME}/examples.git`,
    uriTest: /^(http(s?):\/\/|.*@.*:.*)/i,
    toolTip: 'Load data from a git repository',
  },
  TRAINML: {
    key: 'trainml',
    icon: 'logo-icon',
    text: process.env.REACT_APP_ORG_NAME,
    value: 'trainml',
    alwaysEnabled: true,
    uriExample: 'Job Name',
    uriTest: /.*/,
    toolTip: `Use/Create a ${process.env.REACT_APP_ORG_NAME} Model, Checkpoint, or Dataset`,
  },
  EVEFS: {
    key: 'evefs',
    icon: 'logo-icon',
    text: 'EverywhereFS',
    value: 'evefs',
    alwaysEnabled: true,
    uriExample: 'Job Name',
    uriTest: /.*/,
    toolTip: `Use/Create an EverywhereFS Model, Checkpoint, Dataset, or Volume`,
  },
  DOCKER: {
    key: 'docker',
    icon: 'docker',
    text: 'Docker',
    value: 'docker',
  },
  NVIDIA: {
    key: 'ngc',
    icon: 'microchip',
    text: 'NVIDIA NGC',
    value: 'ngc',
  },
  REGIONAL: {
    key: 'regional',
    text: 'Regional Datastore',
    value: 'regional',
    icon: 'building',
    uriExample: '/path/to/data',
    uriTest: /^\/$|^\/\/$|^(\/[\w-]+)+$/,
    cloudbender_required: true,
    toolTip: 'Mount a directory in a regional datastore',
  },
  WASABI: {
    key: 'wasabi',
    icon: 'won',
    text: 'Wasabi',
    value: 'wasabi',
    uriTest: /^(s3):\/\//i,
    uriExample: 's3://my-wasabi-bucket/path/to/data',
    toolTip: 'Load data to/from Wasabi Object Storage',
  },
  HUGGINGFACE: {
    key: 'huggingface',
    icon: 'smile',
    text: 'Hugging Face',
    value: 'huggingface',
    uriExample: 'stabilityai/stable-diffusion-2-1',
    uriTest: /^[a-z-A-z0-9-]+(\/[a-z-A-z0-9-]+)?$/,
    toolTip: 'Load data from a Hugging Face repository',
  },
  EMPTY: {
    key: 'empty',
    text: 'Empty',
    value: 'empty',
    toolTip: 'Create an empty volume',
    alwaysEnabled: true,
  },
};

export default THIRD_PARTY_TYPES;
