import React, { useState, useEffect } from 'react';
import { Typography, TableRow, TableCell } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  deleteRegion,
  fetchRegion,
  setActiveRegion,
} from '../../actions/regions';
import ActionsButton from './ActionsButton';
import STATUSES from '../statuses';
import useInterval from '../../util/useInterval';
import { setActiveProvider } from '../../actions/providers';

export default function Region({ provider, region }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [poll_interval, setPollInterval] = useState(null);

  const removeRegion = async () => {
    setLoading(true);
    await dispatch(
      deleteRegion(
        region.provider_uuid,
        region.provider_type,
        region.region_uuid
      )
    );
  };

  useEffect(() => {
    if (
      region &&
      [STATUSES.NEW, STATUSES.PROVISIONING, STATUSES.REMOVING].includes(
        region.status
      )
    ) {
      setPollInterval(10000);
    } else {
      setPollInterval(null);
    }
  }, [region]);

  useInterval(() => {
    dispatch(
      fetchRegion(
        region.provider_uuid,
        region.provider_type,
        region.region_uuid
      )
    );
  }, poll_interval);

  const items = [];

  // if (region.provider_type === PROVIDER_TYPES.PHYSICAL.value) {
  //   items.push({
  //     key: 'create-node',
  //     onClick: () =>
  //       history.push({
  //         pathname: `/resources/region/${region.region_uuid}/node/edit`,
  //         search: `?provider_type=${type.value}&provider_uuid=${region.provider_uuid}`,
  //       }),
  //     content: 'Add Node',
  //   });
  // }
  items.push(
    {
      key: 'list-nodes',
      onClick: () => {
        dispatch(
          setActiveProvider(provider, () => {
            dispatch(
              setActiveRegion(region, () => {
                history.push({
                  pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/dashboard`,
                });
              })
            );
          })
        );
      },
      content: 'View Region',
    },
    {
      key: 'edit-region',
      onClick: () => {
        dispatch(
          setActiveProvider(provider, () => {
            dispatch(
              setActiveRegion(region, () => {
                history.push({
                  pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/edit`,
                });
              })
            );
          })
        );
      },
      content: 'Edit Region',
    },
    {
      key: 'remove-region',
      onClick: () => removeRegion(),
      content: 'Remove Region',
    }
  );

  return (
    <TableRow sx={loading ? { backgroundColor: 'text.disabled' } : undefined}>
      <TableCell>
        <Typography variant='body1'>{region.name}</Typography>
      </TableCell>
      <TableCell>
        {region.compute ? region.compute.active : 0}/{region.compute.total}
      </TableCell>
      <TableCell>{region.status}</TableCell>
      <TableCell align='right' sx={{ maxWidth: '25px' }}>
        <ActionsButton disabled={loading} items={items} />
      </TableCell>
    </TableRow>
  );
}
