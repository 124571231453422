import React from 'react';
import { Card, Checkbox } from 'semantic-ui-react';

const GpuClass = (props) => {
  const { name, price, performance, id } = props.details;
  const disabled = false;
  return (
    <Card
      id={id}
      link={!disabled}
      onClick={!disabled ? () => props.onClick(id) : undefined}
      color={props.selected ? 'purple' : disabled ? 'grey' : 'blue'}
    >
      <Card.Content>
        <Card.Header className={disabled ? 'ui disabled' : undefined}>
          {name}
        </Card.Header>

        <Card.Meta>
          Credits Per Hour:{' '}
          {price.max === price.min ? price.max : `${price.min} - ${price.max}`}
        </Card.Meta>

        <Card.Description>
          <p className={disabled ? 'meta' : undefined}>
            {performance.memory_size} GB GPU RAM
          </p>{' '}
          <p className={disabled ? 'meta' : undefined}>
            {performance.fp32_rate} TFLOPS (fp32)
          </p>
          <p className={disabled ? 'meta' : undefined}>
            {performance.fp16_rate} TFLOPS (fp16)
          </p>
        </Card.Description>
      </Card.Content>
      <Card.Content extra textAlign='center'>
        <Checkbox radio readOnly checked={props.selected} />
      </Card.Content>
    </Card>
  );
};
export default GpuClass;
