import React, { useState } from 'react';

import {
  Container,
  Box,
  Typography,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createProvider } from '../../actions/providers';
import PROVIDER_TYPES from './providerTypes';

export default function EmptyProviders() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const enableProvider = async (type) => {
    handleClose();
    setLoading(true);
    await dispatch(createProvider({ type }));
    history.push('/resources/provider/new', { type });
  };

  return (
    <Container maxWidth='sm'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Box display='flex' flexGrow={1} justifyContent='center'>
            <img
              style={{ width: '150px' }}
              src='/img/gpu-purple-256.png'
              alt='Resources Icon'
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant='h5' paragraph gutterBottom align='center'>
            Welcome to CloudBender&#8482; by {process.env.REACT_APP_ORG_NAME}
          </Typography>
          <Typography variant='body1' paragraph align='center'>
            CloudBender lets you connect your on-prem and cloud GPUs to the
            {process.env.REACT_APP_ORG_NAME} platform and seamlessly run jobs
            across them.
          </Typography>
          <Typography variant='body1' paragraph align='center' gutterBottom>
            To get started, enable a cloud or physical resource provider.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box display='flex' flexGrow={1} justifyContent='center' mt={2}>
            <LoadingButton
              variant='contained'
              color='brand'
              onClick={handleClick}
              loading={loading}
            >
              Enable Provider
            </LoadingButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                onClick={() => enableProvider(PROVIDER_TYPES.PHYSICAL.value)}
              >
                {PROVIDER_TYPES.PHYSICAL.label}
              </MenuItem>
              <MenuItem
                onClick={() => enableProvider(PROVIDER_TYPES.GCP.value)}
              >
                {PROVIDER_TYPES.GCP.label}
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
