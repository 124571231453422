import React, { Component } from 'react';
import { Modal, Button, Message, Header } from 'semantic-ui-react';

class ConnectDialog extends Component {
  state = {
    copySuccess: false,
  };

  code = `${process.env.REACT_APP_CLI_COMMAND} ${this.props.type} connect ${this.props.id}`;

  copyCodeToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => this.setState({ copySuccess: true }));
  };

  renderCurlExample = (url, route) => {
    return (
      <>
        <span>curl -X {route.verb} -H "Content-Type: application/json" \</span>
        <br />
        {route.body.length ? (
          <>
            <span>
              -d '{'{'}
              {route.body
                .map((param) => {
                  return `"${param.name}": ${param.type}`;
                })
                .join(', ')}
              {'}'}' \
            </span>
            <br />
          </>
        ) : undefined}
        <span>
          {url}
          {route.path}
        </span>
      </>
    );
  };

  render() {
    const { open, clearModal, endpoint } = this.props;
    return (
      <Modal open={open} closeOnDimmerClick closeOnEscape onClose={clearModal}>
        <Modal.Header>Connect</Modal.Header>
        <Modal.Content>
          {endpoint ? (
            <>
              <Header as='h4'>Endpoint Address: </Header>
              <code>
                {endpoint.routes[0] ? (
                  endpoint.url
                ) : (
                  <a href={`${endpoint.url}`} target='_blank' rel='noreferrer'>
                    {endpoint.url}
                  </a>
                )}
              </code>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                icon='copy outline'
                basic
                size='small'
                onClick={() => this.copyCodeToClipboard(endpoint.url)}
              />
              &nbsp;&nbsp;
              {this.state.copySuccess ? <span>Success!</span> : null}
              {endpoint.routes[0] ? (
                <>
                  <Header as='h4'>Example Usage:</Header>
                  <Message>
                    <code>
                      {this.renderCurlExample(endpoint.url, endpoint.routes[0])}
                    </code>
                  </Message>
                </>
              ) : undefined}
            </>
          ) : (
            <ol>
              <li>
                Install and configure the{' '}
                <a href={`${process.env.REACT_APP_CLI_GITHUB}`}>
                  {process.env.REACT_APP_ORG_NAME} python module
                </a>
              </li>
              <li>
                Run the following command:
                <br />
                <br />
                <code>{this.code}</code>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  icon='copy outline'
                  basic
                  size='small'
                  onClick={() => this.copyCodeToClipboard(this.code)}
                />
                &nbsp;&nbsp;
                {this.state.copySuccess ? <span>Success!</span> : null}
              </li>
            </ol>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon='close'
            secondary
            labelPosition='right'
            onClick={clearModal}
            content='Close'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default ConnectDialog;
