import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { clearActiveProvider, fetchProviders } from '../../actions/providers';
import theme from '../../theme';
import EmptyProviders from './EmptyProviders';
import PROVIDER_TYPES from './providerTypes';
import Provider from './Provider';
import { clearErrorMessage } from '../../actions/errorMessage';
import LoadingMask from '../shared/LoadingMask';
import { clearActiveRegion } from '../../actions/regions';

export default function ResourceDashboard() {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.providers);
  const regions = useSelector((state) => state.regions);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(clearActiveProvider());
    dispatch(clearActiveRegion());
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!providers) {
        await dispatch(fetchProviders());
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, [dispatch, providers, regions]);

  if (loading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <LoadingMask />
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }

  if (!providers?.length) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <EmptyProviders />
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Typography variant='h4' paragraph gutterBottom align='center'>
            Providers
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell align='right'>Regions</TableCell>
                  <TableCell align='right'>Credits</TableCell>
                  <TableCell align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Provider
                  type={PROVIDER_TYPES.PHYSICAL}
                  provider={providers?.find(
                    (provider) =>
                      provider.type === PROVIDER_TYPES.PHYSICAL.value
                  )}
                />
                <Provider
                  type={PROVIDER_TYPES.AWS}
                  provider={providers?.find(
                    (provider) => provider.type === PROVIDER_TYPES.AWS.value
                  )}
                />
                <Provider
                  type={PROVIDER_TYPES.GCP}
                  provider={providers?.find(
                    (provider) => provider.type === PROVIDER_TYPES.GCP.value
                  )}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
