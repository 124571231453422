import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';
import AWSCredential from './AWSCredential';
import AzureCredential from './AzureCredential';
import GCPCredential from './GCPCredential';
import GitCredential from './GitCredential';
import NGCCredential from './NGCCredential';

const CREDENTIAL_TYPES = [
  {
    type: THIRD_PARTY_TYPES.AWS.value,
    component: AWSCredential,
    icon: '/img/logos/aws.svg',
  },
  {
    type: THIRD_PARTY_TYPES.AZURE.value,
    component: AzureCredential,
    icon: '/img/logos/azure.svg',
  },
  {
    type: THIRD_PARTY_TYPES.DOCKER.value,
    component: AWSCredential,
    icon: '/img/logos/docker.svg',
  },
  {
    type: THIRD_PARTY_TYPES.HUGGINGFACE.value,
    component: AWSCredential,
    icon: '/img/logos/huggingface.svg',
  },
  {
    type: THIRD_PARTY_TYPES.GCP.value,
    component: GCPCredential,
    icon: '/img/logos/gcp.svg',
  },
  {
    type: THIRD_PARTY_TYPES.KAGGLE.value,
    component: GCPCredential,
    icon: '/img/logos/kaggle.svg',
  },
  {
    type: THIRD_PARTY_TYPES.NVIDIA.value,
    component: NGCCredential,
    icon: '/img/logos/ngc.svg',
  },
  {
    type: THIRD_PARTY_TYPES.GIT.value,
    component: GitCredential,
    icon: '/img/logos/git.svg',
  },
  {
    type: THIRD_PARTY_TYPES.WASABI.value,
    component: AWSCredential,
    icon: '/img/logos/wasabi.svg',
  },
];

export default CREDENTIAL_TYPES;
