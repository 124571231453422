import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Segment,
  Form,
  Button,
  Grid,
  Message,
  Icon,
  Checkbox,
  Header,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import ENVIRONMENT_KEY_TYPES from './environmentKeyTypes';
import JOB_TYPES from '../types';
import THIRD_PARTY_TYPES from './thirdPartyTypes';

const pip_re = /^"?[a-zA-z0-9_\-<>.=:/@\n+[\]]*"?$/; //pip supports git repo installs
const apt_re = /^"?[a-zA-z0-9_\-<>.=\n]*"?$/;
const conda_re = /^"?[a-zA-z0-9_\-<>.=\n]*"?$/;

const aws_re = /^[0-9a-z-.]*\.amazonaws.com\/.*$/;
const docker_re = /^[0-9a-z]*\/[0-9a-z-_]*/;
const gcp_re = /^[a-z.]*gcr.io\/.*$/;
const gcp_new_re = /^[0-9a-zA-Z-.]*-docker.pkg.dev\/.*$/;
const ngc_re = /^[a-z.]*nvcr.io\/.*$/;
const azure_re = /^[0-9a-zA-Z]*.azurecr.io\/.*$/;
const git_re = /^ghcr.io\/.*$/;

const getImageType = (name) => {
  switch (true) {
    case aws_re.test(name):
      return THIRD_PARTY_TYPES.AWS.value;
    case gcp_re.test(name):
      return THIRD_PARTY_TYPES.GCP.value;
    case gcp_new_re.test(name):
      return THIRD_PARTY_TYPES.GCP.value;
    case ngc_re.test(name):
      return THIRD_PARTY_TYPES.NVIDIA.value;
    case docker_re.test(name):
      return THIRD_PARTY_TYPES.DOCKER.value;
    case azure_re.test(name):
      return THIRD_PARTY_TYPES.AZURE.value;
    case git_re.test(name):
      return THIRD_PARTY_TYPES.GIT.value;
    default:
      return;
  }
};

const buildKeyOptions = (options, configured_keys) => {
  configured_keys = configured_keys || [];
  return options.map((option) => {
    const { alwaysEnabled, uriTest, uriExample, ...rest } = option;
    if (
      configured_keys.some((key) => key.type === option.key) ||
      alwaysEnabled
    ) {
      return rest;
    }
    return { disabled: true, ...rest };
  });
};

class EnvironmentFields extends Component {
  getDefaultEnvironmentType = () => {
    return this.props.type === JOB_TYPES.NOTEBOOK.value
      ? this.props.user.default_notebook_environment
      : this.props.type === JOB_TYPES.TRAINING.value
      ? this.props.user.default_training_environment
      : this.props.user.default_inference_environment;
  };

  state = {
    expanded: false,
    env_count:
      this.props.initialValues && this.props.initialValues.env
        ? this.props.initialValues.env.length
        : 0,
    env_errors:
      this.props.initialValues && this.props.initialValues.env
        ? new Array(this.props.initialValues.env.length).fill({
            key: false,
            value: false,
          })
        : [],
    type_error: false,
    pip_raw:
      (this.props.initialValues &&
      this.props.initialValues.packages &&
      this.props.initialValues.packages &&
      this.props.initialValues.packages.pip
        ? this.props.initialValues.packages.pip.join('\n')
        : undefined) || '',
    apt_raw:
      (this.props.initialValues &&
      this.props.initialValues.packages &&
      this.props.initialValues.packages &&
      this.props.initialValues.packages.apt
        ? this.props.initialValues.packages.apt.join('\n')
        : undefined) || '',
    conda_raw:
      (this.props.initialValues &&
      this.props.initialValues.packages &&
      this.props.initialValues.packages &&
      this.props.initialValues.packages.conda
        ? this.props.initialValues.packages.conda.join('\n')
        : undefined) || '',
    pip_error: false,
    apt_error: false,
    conda_error: false,
    custom_environment:
      this.props.initialValues && this.props.initialValues.type === 'CUSTOM',
    custom_image_error: false,
    values: this.props.initialValues || {
      type: this.getDefaultEnvironmentType(),
      custom_image: '',
      env: [],
      worker_key_types: [],
      packages: {
        pip: [],
        apt: [],
        conda: [],
      },
      init_script: '',
    },
  };
  componentDidMount() {
    this.props.onChange('environment', this.state.values, false);
    if (this.props.environments.length) {
      this.setState({
        values: {
          ...this.state.values,
          ...this.getVersionFromEnvironmentId(this.state.values.type),
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.environments.length && this.props.environments.length) {
      this.setState({
        values: {
          ...this.state.values,
          ...this.getVersionFromEnvironmentId(this.state.values.type),
        },
      });
    }
    if (_.isEqual(prevState, this.state)) {
      return;
    }

    const env_error = new Set([
      ...this.state.env_errors.map((env) => env.key),
      ...this.state.env_errors.map((env) => env.value),
    ]);
    this.props.onChange(
      'environment',
      this.state.values,
      env_error.has(true) ||
        this.state.type_error ||
        this.state.pip_error ||
        this.state.apt_error ||
        this.state.conda_error ||
        this.state.custom_image_error
    );
  }

  handleChange = (e, { name, value }) => {
    const array_re = /(?<key>.+)\[(?<number>[0-9]+)\]\.?(?<attribute>.+)?/;
    const result = name.match(array_re);
    if (result && result.groups.attribute) {
      const array = [...this.state.values[result.groups.key]];
      const item = { ...array[result.groups.number] };
      item[result.groups.attribute] = value;
      array[result.groups.number] = item;
      this.setState({
        values: {
          ...this.state.values,
          [result.groups.key]: array,
        },
      });
    } else {
      switch (name) {
        case 'py_version': {
          let newValues = { ...this.state.values, [name]: value };
          newValues.type = undefined;
          newValues.framework = '';
          newValues.version = '';
          this.setState({
            values: newValues,
            type_error: !Boolean(newValues.type),
          });
          break;
        }
        case 'framework': {
          let newValues = { ...this.state.values, [name]: value };
          newValues.type = undefined;
          newValues.version = '';
          if (value === 'Deep Learning') {
            newValues.version = 'latest';
            newValues.type = this.getEnvironmentIdFromSelections(
              this.state.values.py_version,
              value
            );
          }
          this.setState({
            values: newValues,
            type_error: !Boolean(newValues.type),
          });
          break;
        }
        case 'version': {
          this.setState({
            values: {
              ...this.state.values,
              [name]: value,
              type: this.getEnvironmentIdFromSelections(
                this.state.values.py_version,
                this.state.values.framework,
                value
              ),
            },
            type_error: false,
          });
          break;
        }
        case 'custom_environment': {
          this.setState((prevState) => ({
            custom_environment: !prevState.custom_environment,
            values: {
              ...this.state.values,
              type: prevState.custom_environment
                ? this.getDefaultEnvironmentType()
                : 'CUSTOM',
              custom_image: '',
            },
          }));
          break;
        }
        case 'pip_raw': {
          this.setState({
            pip_raw: value,
            pip_error: !pip_re.test(value),
            values: {
              ...this.state.values,
              packages: {
                ...this.state.values.packages,
                pip: value.split('\n'),
              },
            },
          });
          break;
        }
        case 'apt_raw': {
          this.setState({
            apt_raw: value,
            apt_error: !apt_re.test(value),
            values: {
              ...this.state.values,
              packages: {
                ...this.state.values.packages,
                apt: value.split('\n'),
              },
            },
          });
          break;
        }
        case 'conda_raw': {
          this.setState({
            conda_raw: value,
            conda_error: !conda_re.test(value),
            values: {
              ...this.state.values,
              packages: {
                ...this.state.values.packages,
                conda: value.split('\n'),
              },
            },
          });
          break;
        }
        case 'custom_image': {
          const type = getImageType(value);
          const custom_image_type_error = Boolean(
            type &&
              !this.props.projectCredentials.some((key) => key.type === type)
          );
          this.setState({
            values: {
              ...this.state.values,
              [name]: value,
            },
            custom_image_error: Boolean(!type),
            custom_image_type: type,
            custom_image_type_error,
          });
          break;
        }
        default:
          this.setState({
            values: { ...this.state.values, [name]: value },
          });
      }
    }
    const env_re = /^[a-zA-Z]+[a-zA-Z0-9_]*$/;
    if (result && result.groups.attribute) {
      const array = [...this.state[`${result.groups.key}_errors`]];
      const item = { ...array[result.groups.number] };
      item[result.groups.attribute] =
        result.groups.attribute === 'key'
          ? value === '' || !env_re.test(value)
          : value === '';
      array[result.groups.number] = item;
      this.setState({
        [`${result.groups.key}_errors`]: array,
      });
    }
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  addEnv = (e) => {
    e.preventDefault();
    let env = [...this.state.values.env];
    let env_errors = [...this.state.env_errors];

    env.push({ key: '', value: '' });
    env_errors.push({ key: false, value: false });

    this.setState({
      env_count: this.state.env_count + 1,
      values: { ...this.state.values, env },
      env_errors,
      pristine: false,
    });
  };

  removeEnv = (e) => {
    e.preventDefault();
    let env = [...this.state.values.env];
    let env_errors = [...this.state.env_errors];

    env.pop();
    env_errors.pop();

    this.setState({
      env_count: this.state.env_count - 1,
      values: { ...this.state.values, env },
      env_errors,
      pristine: false,
    });
  };

  listPythonVersions = () => {
    const versions = new Set(
      this.props.environments
        .map((env) => env.py_version)
        .filter((py_version) => py_version)
    );
    const options = [];
    versions.forEach((version) => {
      options.push({
        key: version,
        value: version,
        text: version,
      });
    });
    return options;
  };

  listFrameworkTypes = (py_version) => {
    const frameworks = new Set(
      this.props.environments
        .map((env) =>
          env.py_version === py_version ? env.framework : undefined
        )
        .filter((item) => item)
    );
    const options = [];
    frameworks.forEach((framework) => {
      options.push({
        key: framework,
        value: framework,
        text: framework,
      });
    });
    return options;
  };

  listFrameworkVersions = (py_version, framework) => {
    if (framework === 'Deep Learning') {
      return [{ key: 'latest', value: 'latest', text: 'Latest' }];
    }
    const versions = new Set(
      this.props.environments
        .map((env) =>
          env.py_version === py_version && env.framework === framework
            ? env.version
            : undefined
        )
        .filter((item) => item)
    );
    const options = [];
    versions.forEach((version) => {
      options.push({
        key: version,
        value: version,
        text: version,
      });
    });
    return options;
  };

  getEnvironmentIdFromSelections = (py_version, framework, version) => {
    if (framework === 'Deep Learning') {
      const env = this.props.environments.find(
        (env) => env.py_version === py_version
      );
      return env.id;
    } else {
      const env = this.props.environments.find(
        (env) =>
          env.py_version === py_version &&
          env.framework === framework &&
          env.version === version
      );
      return env.id;
    }
  };

  getVersionFromEnvironmentId = (type) => {
    const env = this.props.environments.find((env) => env.id === type);
    if (env) {
      return {
        type,
        py_version: env.py_version,
        framework: env.framework,
        version: env.framework === 'Deep Learning' ? 'latest' : env.version,
      };
    }
    return {};
  };

  render() {
    const {
      pip_raw,
      apt_raw,
      conda_raw,
      pip_error,
      apt_error,
      conda_error,
      custom_environment,
      custom_image_error,
      custom_image_type,
      custom_image_type_error,
      values,
      env_count,
      env_errors,
      expanded,
    } = this.state;
    const { edit } = this.props;
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon name={expanded ? 'triangle down' : 'triangle right'} />
            Environment {!edit ? '(Optional)' : ''}
          </Message.Header>
        </Message>
        {expanded ? (
          <>
            <Segment attached>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <div className='field'>
                      <label>Base Environment:</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Checkbox
                      name='custom_environment'
                      checked={custom_environment}
                      label='Customer Provided'
                      onClick={this.handleChange}
                      disabled={edit}
                    />
                  </Grid.Column>
                </Grid.Row>
                {custom_environment ? (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Input
                          name='custom_image'
                          label='Image'
                          value={values.custom_image}
                          disabled={edit}
                          onChange={this.handleChange}
                          error={
                            custom_image_error
                              ? 'Unable to determine image registry type'
                              : undefined
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {custom_image_type_error ? (
                      <Grid.Row>
                        <Grid.Column>
                          <Message negative>
                            <Header as='h3'>
                              {custom_image_type ===
                              THIRD_PARTY_TYPES.DOCKER.value
                                ? 'Warning'
                                : 'Error'}
                            </Header>
                            <p>
                              A{' '}
                              <Link to='/account/settings'>
                                Third Party Key
                              </Link>{' '}
                              is not configured for image registry provider{' '}
                              <b>{custom_image_type}</b>.
                            </p>
                            {custom_image_type ===
                            THIRD_PARTY_TYPES.DOCKER.value ? (
                              <p>
                                Private repositories images will fail and pulls
                                from public repositories may be{' '}
                                <a
                                  href='https://www.docker.com/increase-rate-limits'
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  rate limited
                                </a>{' '}
                                or error.
                              </p>
                            ) : undefined}
                          </Message>
                        </Grid.Column>
                      </Grid.Row>
                    ) : undefined}
                  </>
                ) : (
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group widths='equal'>
                        <Form.Dropdown
                          name='py_version'
                          label='Python Version'
                          options={this.listPythonVersions()}
                          value={values.py_version}
                          fluid
                          inline
                          selection
                          onChange={this.handleChange}
                          disabled={edit}
                        />
                        <Form.Dropdown
                          name='framework'
                          label='Framework'
                          options={this.listFrameworkTypes(values.py_version)}
                          value={values.framework}
                          fluid
                          inline
                          selection
                          disabled={!values.py_version || edit}
                          onChange={this.handleChange}
                        />
                        <Form.Dropdown
                          key='version'
                          name='version'
                          label='Framework Version'
                          options={this.listFrameworkVersions(
                            values.py_version,
                            values.framework
                          )}
                          value={values.version}
                          fluid
                          inline
                          selection
                          disabled={!values.framework || edit}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Grid.Column>
                    <div className='field'>
                      <label>Package Dependencies:</label>
                    </div>
                    <Form.Group widths='equal'>
                      <Form.TextArea
                        name='pip_raw'
                        label='pip'
                        rows={10}
                        onChange={this.handleChange}
                        value={pip_raw}
                        disabled={edit}
                        error={
                          pip_error ? 'Invalid character specified' : undefined
                        }
                      />
                      <Form.TextArea
                        name='apt_raw'
                        label='apt'
                        rows={10}
                        onChange={this.handleChange}
                        value={apt_raw}
                        disabled={edit}
                        error={
                          apt_error ? 'Invalid character specified' : undefined
                        }
                      />
                      <Form.TextArea
                        name='conda_raw'
                        label='conda'
                        rows={10}
                        onChange={this.handleChange}
                        value={conda_raw}
                        disabled={edit}
                        error={
                          conda_error
                            ? 'Invalid characters specified'
                            : undefined
                        }
                      />
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.TextArea
                      name='init_script'
                      label='Initialization Script:'
                      rows={10}
                      onChange={this.handleChange}
                      value={values.init_script}
                      disabled={edit}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column verticalAlign='middle' width={4}>
                    <div className='field'>
                      <label>Environment Variables:</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    {env_count >= 10 ? undefined : (
                      <Button
                        icon='plus'
                        size='tiny'
                        secondary
                        disabled={edit}
                        onClick={this.addEnv}
                      />
                    )}
                    {env_count === 0 ? undefined : (
                      <Button
                        icon='minus'
                        size='tiny'
                        secondary
                        disabled={edit}
                        onClick={this.removeEnv}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                {new Array(env_count).fill().map((e, i) => {
                  return (
                    <Grid.Row key={`env_${i}`}>
                      <Grid.Column width={6}>
                        <Form.Input
                          name={`env[${i}].key`}
                          label='Key'
                          value={values.env[i].key}
                          fluid
                          onChange={this.handleChange}
                          required
                          disabled={edit}
                          error={
                            env_errors[i].key
                              ? {
                                  content: 'Please enter a valid key',
                                  pointing: 'above',
                                }
                              : undefined
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <Form.Input
                          name={`env[${i}].value`}
                          label='Value'
                          value={values.env[i].value}
                          fluid
                          onChange={this.handleChange}
                          disabled={edit}
                          error={
                            env_errors[i].value
                              ? {
                                  content: 'Please enter a value',
                                  pointing: 'above',
                                }
                              : undefined
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
                <br />

                <Grid.Row>
                  <Grid.Column>
                    <Form.Dropdown
                      name='worker_key_types'
                      label='Third-Party Access Keys'
                      options={buildKeyOptions(
                        ENVIRONMENT_KEY_TYPES,
                        this.props.projectCredentials
                      )}
                      value={values.worker_key_types}
                      fluid
                      inline
                      selection
                      multiple
                      disabled={edit}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Message attached='bottom' info>
              <Icon name='help' />
              Third-Party Key Disabled?{' '}
              <Link to='/account/settings'>Add Third Party Keys Here</Link>
            </Message>
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ user, projectCredentials, environments }) {
  return { user, projectCredentials, environments };
}

export default connect(mapStateToProps)(EnvironmentFields);
