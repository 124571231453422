import React, { useState } from 'react';
import {
  IconButton,
  Box,
  Stack,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CopyIcon from '@mui/icons-material/ContentCopy';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import toTitleCase from 'util/toTitleCase';
import { LoadingButton } from '@mui/lab';

export default function GitCredential({
  type,
  credential,
  putCredential,
  removeCredential,
  icon,
}) {
  const [loading, setLoading] = useState(false);

  const [tooltip, setTooltip] = useState('Copy');
  const setCopied = () => setTooltip('Copied!');
  const setCopyError = () => setTooltip('Copy Error!');

  const copyText = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard
        .writeText(credential?.key_id)
        .then(setCopied, setCopyError);
    } else {
      setCopyError();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await putCredential({ type });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRemove = async () => {
    if (!credential) {
      return;
    }
    setLoading(true);

    try {
      await removeCredential(credential?.type || type);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <Stack
      paddingBottom='10px'
      direction={'row'}
      spacing={1}
      alignItems={'center'}
    >
      <Tooltip title={`${toTitleCase(type)} Credential`}>
        <Icon fontSize='large' sx={{ alignContent: 'center' }}>
          <img
            src={icon}
            width={'25px'}
            height={'25px'}
            alt={`${toTitleCase(type)} Credential`}
          />
        </Icon>
      </Tooltip>
      <Typography>{credential.key_id}</Typography>
      <Tooltip title={tooltip}>
        <IconButton aria-label='close' onClick={copyText}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
      <Box sx={{ display: 'flex', flexGrow: 1 }} />
      {Boolean(credential?.key_id) ? (
        <LoadingButton
          onClick={handleRemove}
          variant='outlined'
          endIcon={<DeleteOutlinedIcon />}
          loading={loading}
        >
          Remove
        </LoadingButton>
      ) : (
        <LoadingButton
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          endIcon={<SettingsApplicationsIcon />}
          loading={loading}
        >
          Generate
        </LoadingButton>
      )}
    </Stack>
  );
}
