import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  Box,
  FormHelperText,
} from '@mui/material';

import RESOURCE_TYPE_MAPPING from './resourceTypeMapping';

export default function ComputeInstanceClassField({
  value,
  setValue,
  setChanged,
  resource_type_id,
  disabled,
}) {
  const handleChange = (event) => {
    if (event.target.value !== value) {
      setChanged();
    }
    setValue(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='instance-class-label'>Instance Class</InputLabel>
        <Select
          labelId='instance-class-label'
          id='instance_class'
          value={value}
          label='Instance Class'
          onChange={handleChange}
          disabled={disabled}
        >
          {Boolean(resource_type_id)
            ? RESOURCE_TYPE_MAPPING[resource_type_id].instance_classes
            : undefined}
        </Select>
        <FormHelperText id='instance-class-helper-text'>
          The amount of compute resources for this node
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
