import _ from 'lodash';
import {
  FETCH_RIGS,
  FETCH_RIG,
  DELETE_RIG,
  CREATE_RIG,
  CLEAR_RIGS,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import wait from '../util/wait';
import { fetchProvider } from './providers';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchRigs = (provider_uuid, region_uuid) => async (dispatch) => {
  try {
    const res = await api.get(
      `/provider/${provider_uuid}/region/${region_uuid}/node`
    );
    dispatch({
      type: FETCH_RIGS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchRig =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}`
      );
      if (_.isEmpty(res.data)) {
        dispatch({ type: DELETE_RIG, payload: region_uuid });
      } else {
        dispatch({
          type: FETCH_RIG,
          payload: res.data,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({ type: DELETE_RIG, payload: rig_uuid });
      } else {
        const message = getMessageFromApiError(error);
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  };

export const updateRig =
  (provider_uuid, region_uuid, rig_uuid, data) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}`,
        data
      );
      dispatch({
        type: FETCH_RIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const deleteRig =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      const res = await api.delete(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}`
      );
      if (res.data) {
        dispatch({
          type: FETCH_RIG,
          payload: res.data,
        });
      } else {
        dispatch({
          type: DELETE_RIG,
          payload: rig_uuid,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({ type: DELETE_RIG, payload: rig_uuid });
      } else {
        const message = getMessageFromApiError(error);
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  };

export const createRig =
  (provider_uuid, region_uuid, values) => async (dispatch) => {
    try {
      const res = await api.post(
        `/provider/${provider_uuid}/region/${region_uuid}/node`,
        values
      );
      dispatch({
        type: CREATE_RIG,
        payload: res.data,
      });
      fetchProvider(provider_uuid);
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const toggleMaintenance =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}/maintenance`
      );
      dispatch({
        type: FETCH_RIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const enableRemoteSupport =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}/support/enable`
      );
      dispatch({
        type: FETCH_RIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const disableRemoteSupport =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}/support/disable`
      );
      dispatch({
        type: FETCH_RIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const runAction =
  (provider_uuid, region_uuid, rig_uuid, command) => async (dispatch) => {
    try {
      await api.post(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}/action`,
        { command }
      );
      await wait(10000); // give the rig time to report and update
      dispatch(fetchRig(provider_uuid, region_uuid, rig_uuid));
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const deployDeviceEndpoint =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      await api.put(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}/deploy`
      );
      await wait(20000); // give the job time to start updating the rig
      await dispatch(fetchRig(provider_uuid, region_uuid, rig_uuid));
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const stopDeviceEndpoint =
  (provider_uuid, region_uuid, rig_uuid) => async (dispatch) => {
    try {
      await api.put(
        `/provider/${provider_uuid}/region/${region_uuid}/node/${rig_uuid}/stop`
      );
      await dispatch(fetchRig(provider_uuid, region_uuid, rig_uuid));
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const clearRigs = () => ({
  type: CLEAR_RIGS,
});
