import ENTITY_TYPES from '../components/shared/entityTypes';

export const SET_USER = 'set_user';
export const DELETE_USER = 'delete_user';
export const FETCH_JOBS = 'fetch_jobs';
export const FETCH_JOB = 'fetch_job';
export const CREATE_JOB = 'create_job';
export const REMOVE_JOB = 'remove_job';
export const FETCH_GPU_CLASSES = 'fetch_gpu_classes';
export const LOGOUT = 'logout';
export const SET_SESSION = 'set_session';
export const CLEAR_SESSION = 'clear_session';
export const FETCH_PAYMENT_METHODS = 'fetch_payment_methods';
export const CLEAR_PAYMENT_METHODS = 'clear_payment_methods';
export const CREATE_PAYMENT_METHOD = 'create_payment_method';
export const UPDATE_PAYMENT_METHOD = 'update_payment_method';
export const REMOVE_PAYMENT_METHOD = 'remove_payment_method';
export const CREATE_PAYMENT = 'create_payment';
export const FETCH_PAYMENTS = 'fetch_payments';
export const CLEAR_PAYMENTS = 'clear_payments';
export const SET_ERROR_MESSAGE = 'set_error_message';
export const CLEAR_ERROR_MESSAGE = 'clear_error_message';
export const FETCH_CREDENTIALS = 'fetch_credentials';
export const FETCH_SECRETS = 'fetch_secrets';
export const FETCH_ARCHIVED_JOBS = 'fetch_archived_jobs';
export const CLEAR_ARCHIVED_JOBS = 'clear_archived_jobs';
export const FETCH_DATASETS = 'fetch_datasets';
export const UPDATE_DATASET = 'update_dataset';
export const DELETE_DATASET = 'delete_dataset';
export const CREATE_DATASET = 'create_dataset';
export const FETCH_DATASET_LOGS = 'fetch_dataset_logs';
export const CLEAR_DATASET_LOGS = 'clear_dataset_logs';
export const FETCH_PUBLIC_DATASETS = 'fetch_public_datasets';
export const FETCH_ENVIRONMENTS = 'fetch_environments';
export const FETCH_MODELS = 'fetch_models';
export const UPDATE_MODEL = 'update_model';
export const DELETE_MODEL = 'delete_model';
export const CREATE_MODEL = 'create_model';
export const FETCH_PROJECTS = 'fetch_projects';
export const FETCH_PROJECT = 'fetch_project';
export const CREATE_PROJECT = 'create_project';
export const REMOVE_PROJECT = 'remove_project';
export const FETCH_USER_CREDENTIALS = 'fetch_user_credentials';
export const FETCH_PROVIDERS = 'fetch_providers';
export const FETCH_PROVIDER = 'fetch_provider';
export const DELETE_PROVIDER = 'delete_provider';
export const CREATE_PROVIDER = 'create_provider';
export const FETCH_REGIONS = 'fetch_regions';
export const FETCH_REGION = 'fetch_region';
export const DELETE_REGION = 'delete_region';
export const CREATE_REGION = 'create_region';
export const FETCH_RIGS = 'fetch_rigs';
export const FETCH_RIG = 'fetch_rig';
export const DELETE_RIG = 'delete_rig';
export const CREATE_RIG = 'create_rig';
export const FETCH_DATASTORES = 'fetch_datastores';
export const FETCH_DATASTORE = 'fetch_datastore';
export const DELETE_DATASTORE = 'delete_datastore';
export const CREATE_DATASTORE = 'create_datastore';
export const FETCH_PROJECT_DATASTORES = 'fetch_project_datastores';
export const FETCH_SERVICES = 'fetch_services';
export const FETCH_SERVICE = 'fetch_service';
export const DELETE_SERVICE = 'delete_service';
export const CREATE_SERVICE = 'create_service';
export const FETCH_PROJECT_SERVICES = 'fetch_project_services';
export const FETCH_DEVICE_CONFIGS = 'fetch_device_configs';
export const FETCH_DEVICE_CONFIG = 'fetch_device_config';
export const DELETE_DEVICE_CONFIG = 'delete_device_config';
export const CREATE_DEVICE_CONFIG = 'create_device_config';
export const FETCH_SUBSCRIPTION_PLANS = 'fetch_subscription_plans';
export const FETCH_SUBSCRIPTIONS = 'fetch_subscriptions';
export const FETCH_SUBSCRIPTION = 'fetch_subscription';
export const CREATE_SUBSCRIPTION = 'create_subscription';
export const FETCH_CHECKPOINTS = 'fetch_checkpoints';
export const UPDATE_CHECKPOINT = 'update_checkpoint';
export const DELETE_CHECKPOINT = 'delete_checkpoint';
export const CREATE_CHECKPOINT = 'create_checkpoint';
export const FETCH_PUBLIC_CHECKPOINTS = 'fetch_public_checkpoints';
export const CLEAR_RIGS = 'clear_rigs';
export const CLEAR_DATASTORES = 'clear_datastores';
export const CLEAR_SERVICES = 'clear_services';
export const CLEAR_DEVICE_CONFIGS = 'clear_device_configs';
export const SET_REGION = 'set_region';
export const CLEAR_REGION = 'clear_region';
export const SET_PROVIDER = 'set_provider';
export const CLEAR_PROVIDER = 'clear_provider';
export const FETCH_VOLUMES = 'fetch_volumes';
export const UPDATE_VOLUME = 'update_volume';
export const DELETE_VOLUME = 'delete_volume';
export const CREATE_VOLUME = 'create_volume';
export const FETCH_DATA_CONNECTOR = 'fetch_data_connector';
export const FETCH_DATA_CONNECTORS = 'fetch_data_connectors';
export const DELETE_DATA_CONNECTOR = 'delete_data_connector';
export const CREATE_DATA_CONNECTOR = 'create_data_connector';
export const FETCH_PROJECT_DATA_CONNECTORS = 'fetch_project_data_connectors';
export const CLEAR_DATA_CONNECTORS = 'clear_data_connectors';
export const COLLECTION_TO_ENTITY_TYPE_MAPPING = {
  [ENTITY_TYPES.CHECKPOINT]: {
    list: FETCH_CHECKPOINTS,
    update: UPDATE_CHECKPOINT,
    create: CREATE_CHECKPOINT,
    delete: DELETE_CHECKPOINT,
    list_public: FETCH_PUBLIC_CHECKPOINTS,
  },
  [ENTITY_TYPES.DATASET]: {
    list: FETCH_DATASETS,
    update: UPDATE_DATASET,
    create: CREATE_DATASET,
    delete: DELETE_DATASET,
    list_public: FETCH_PUBLIC_DATASETS,
  },
  [ENTITY_TYPES.MODEL]: {
    list: FETCH_MODELS,
    update: UPDATE_MODEL,
    create: CREATE_MODEL,
    delete: DELETE_MODEL,
  },
  [ENTITY_TYPES.VOLUME]: {
    list: FETCH_VOLUMES,
    update: UPDATE_VOLUME,
    create: CREATE_VOLUME,
    delete: DELETE_VOLUME,
  },
};
