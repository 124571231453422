import React, { Component } from 'react';
import {
  Button,
  Dropdown,
  Segment,
  Message,
  Grid,
  Header,
  Placeholder,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { fetchCredentials as fetchProjectSecrets } from '../../actions/projectCredentials';
import { fetchCredentials as fecthUserSecrets } from '../../actions/userCredentials';
import ThirdPartyKey from './ThirdPartyKey';
import SshKey from './SshKey';
import KEY_TYPES from './keyTypes';

class ThirdPartyKeysForm extends Component {
  state = {
    loading: false,
    credentials: [],
    ssh_key: null,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    if (this.props.entity === 'user') {
      await this.props.fecthUserSecrets();
    } else {
      await this.props.fetchProjectSecrets(this.props.project_uuid);
    }
    this.setKeysState(this.props.credentials);
    this.setState({ loading: false });
  }

  componentDidUpdate({ credentials: prevKeys }) {
    if (prevKeys && this.props.credentials.length !== prevKeys.length) {
      this.setKeysState(this.props.credentials);
    }
  }

  setKeysState(credentials) {
    let newState = { ssh_key: null };
    newState.credentials = credentials.filter((key) => key.type !== 'git');
    if (newState.credentials.length !== credentials.length) {
      newState.ssh_key = credentials.find((key) => key.type === 'git').key_id;
    }
    this.setState(newState);
  }

  handleChange = (e, { name, value }) => {
    if (!this.state.credentials.some((key) => key.type === value)) {
      this.setState({
        credentials: [...this.state.credentials, { type: value }],
      });
    }
  };

  buildOptions() {
    return KEY_TYPES.map((option) => {
      const { alwaysEnabled, uriTest, uriExample, ...rest } = option;
      if (this.state.credentials.some((key) => key.type === rest.key)) {
        return { disabled: true, ...rest };
      }
      return rest;
    });
  }

  renderKeys() {
    if (this.state.credentials.length) {
      return (
        <Segment>
          {this.state.credentials.map((key) => {
            return (
              <ThirdPartyKey
                entity={this.props.entity}
                project_uuid={this.props.project_uuid}
                {...key}
                key={key.type}
              />
            );
          })}
        </Segment>
      );
    } else {
      return (
        <Message info>
          <p>No credentials configured.</p>
        </Message>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      );
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Button.Group secondary>
              <Button>Add</Button>
              <Dropdown
                className='ui button icon primary'
                floating
                options={this.buildOptions()}
                trigger={<React.Fragment />}
                onChange={this.handleChange}
                value=''
              />
            </Button.Group>
            {this.renderKeys()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as='h3'>Git SSH Key</Header>
            <SshKey
              entity={this.props.entity}
              project_uuid={this.props.project_uuid}
              key_id={this.state.ssh_key}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps({ projectCredentials, userCredentials }, ownProps) {
  if (ownProps.entity === 'user') {
    return {
      credentials: userCredentials,
    };
  } else {
    return {
      credentials: projectCredentials,
    };
  }
}

export default connect(mapStateToProps, {
  fetchProjectSecrets,
  fecthUserSecrets,
})(ThirdPartyKeysForm);
