import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Image, Button } from 'semantic-ui-react';

import toNiceTimestamp from '../../../util/toNiceTimestamp';
import {
  approveBillingLink,
  rejectBillingLinkRequest,
  removeBillingLink,
} from '../../../actions/user';

class ConsolidatedBillingUser extends Component {
  state = {};

  approveLink = async () => {
    this.setState({ approve_submitting: true });
    await this.props.approveBillingLink(this.props.user.customer_uuid);
    this.setState({ approve_submitting: false });
  };

  rejectLink = async () => {
    this.setState({ reject_submitting: true });
    await this.props.rejectBillingLinkRequest(this.props.user.customer_uuid);
    this.setState({ reject_submitting: false });
  };

  removeLink = async () => {
    this.setState({ remove_submitting: true });
    await this.props.removeBillingLink(this.props.user.customer_uuid);
    this.setState({ remove_submitting: false });
  };

  render() {
    const { user, pending } = this.props;
    return (
      <Card>
        <Card.Content>
          <Image floated='right' size='mini' src={user.picture} />
          <Card.Header>{user.display_name}</Card.Header>
          <Card.Meta>{user.email}</Card.Meta>
          <Card.Description>
            {pending
              ? `Requested on ${toNiceTimestamp(user.date)}`
              : `Linked since ${toNiceTimestamp(user.approved_date)}`}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {pending ? (
            <div className='ui two buttons'>
              <Button
                basic
                color='green'
                onClick={this.approveLink}
                loading={this.state.approve_submitting}
                disabled={this.state.reject_submitting}
              >
                Approve
              </Button>
              <Button
                basic
                color='red'
                onClick={this.rejectLink}
                loading={this.state.reject_submitting}
                disabled={this.state.approve_submitting}
              >
                Decline
              </Button>
            </div>
          ) : (
            <div className='ui two buttons'>
              <Button
                basic
                color='red'
                onClick={this.removeLink}
                loading={this.state.remove_submitting}
              >
                Remove
              </Button>
            </div>
          )}
        </Card.Content>
      </Card>
    );
  }
}

export default connect(null, {
  approveBillingLink,
  rejectBillingLinkRequest,
  removeBillingLink,
})(ConsolidatedBillingUser);
