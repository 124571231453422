import React, { Component } from 'react';
import {
  Segment,
  Header,
  Form,
  Button,
  Grid,
  Icon,
  Checkbox,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/user';
import NOTIFICATION_SETTINGS from '../../account/settings/notificationSettings';

class TopupForm extends Component {
  state = {
    threshold: this.props.user.topup_threshold,
    amount: this.props.user.topup_amount,
    pristine: true,
    submitting: false,
    notif_enabled: true,
    topup_enabled:
      Boolean(this.props.user.topup_threshold) ||
      Boolean(this.props.user.topup_amount),
  };

  componentDidMount() {
    const top_up_setting = this.props.user.notification_settings.find(
      (setting) => setting.key === NOTIFICATION_SETTINGS.AUTO_TOP_UP_EMAIL.key
    );
    this.setState({
      notif_enabled: top_up_setting ? top_up_setting.value : true,
    });
  }

  handleSubmit = async () => {
    let notification_settings;
    if (!this.props.user.notification_settings) {
      notification_settings = [
        {
          key: NOTIFICATION_SETTINGS.AUTO_TOP_UP_EMAIL.key,
          value: this.state.notif_enabled,
        },
      ];
    } else {
      notification_settings = this.props.user.notification_settings.filter(
        (setting) => setting.key !== NOTIFICATION_SETTINGS.AUTO_TOP_UP_EMAIL.key
      );
      notification_settings.push({
        key: NOTIFICATION_SETTINGS.AUTO_TOP_UP_EMAIL.key,
        value: this.state.notif_enabled,
      });
    }
    this.setState({ submitting: true });
    this.state.topup_enabled
      ? await this.props.updateUser({
          topup_threshold: parseInt(this.state.threshold),
          topup_amount: parseInt(this.state.amount),
          notification_settings,
        })
      : await this.props.updateUser({
          topup_threshold: null,
          topup_amount: null,
          notification_settings,
        });

    this.setState({ submitting: false, pristine: true });
    if (this.props.onFinish) {
      this.props.onFinish();
    }
  };

  handleChange = (e, { name, value }) => {
    switch (name) {
      case 'threshold':
        this.setState({
          threshold: value,
          thresholdError: isNaN(value) || value < 0 || !value,
          pristine: false,
        });
        break;
      case 'amount':
        this.setState({
          amount: value,
          amountError: isNaN(value) || value < 25 || !value,
          pristine: false,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { user, primaryPaymentMethod } = this.props;
    const {
      topup_enabled,
      notif_enabled,
      threshold,
      amount,
      thresholdError,
      amountError,
      pristine,
      submitting,
    } = this.state;
    return (
      <Segment>
        <Header textAlign='center' as='h2'>
          Automatic Top-up
        </Header>
        <p>
          Running jobs will automatically stop when you run out of credits. If
          you would like {process.env.REACT_APP_ORG_NAME} to automatically
          charge your primary payment method when your credits drop below a
          threshold, enter the threshold value and the amount of credits to be
          added. The minimum credits per transaction is 25 ($25).
        </p>
        <Form onSubmit={this.handleSubmit}>
          <Checkbox
            checked={topup_enabled}
            label='Enable automatic Top-up'
            name='checkbox'
            onChange={() => {
              this.setState({
                topup_enabled: !topup_enabled,
                pristine: false,
              });
            }}
          />
          <p></p>
          <Checkbox
            disabled={!topup_enabled}
            label='Send me an email notification when a top-up occurs'
            name='notif-checkbox'
            checked={notif_enabled}
            onChange={() => {
              this.setState({
                notif_enabled: !notif_enabled,
                pristine: false,
              });
            }}
          />
          <p> </p>
          <Form.Input
            label='Top-up Credits Threshold'
            name='threshold'
            disabled={!topup_enabled}
            placeholder={
              user.topup_threshold
                ? 'Current: ' + user.topup_threshold + ' credits'
                : 'Enter amount in credits'
            }
            onChange={this.handleChange}
            error={
              thresholdError
                ? {
                    content: 'Please enter a valid number',
                    pointing: 'above',
                  }
                : undefined
            }
          ></Form.Input>
          {threshold !== user.topup_threshold ? (
            <p>
              New threshold: {this.state.threshold} credits, $
              {this.state.threshold}
            </p>
          ) : (
            <></>
          )}
          <Form.Input
            name='amount'
            label='Top-up Credits Amount'
            disabled={!topup_enabled}
            placeholder={
              user.topup_amount
                ? 'Current: ' + user.topup_amount + ' credits'
                : 'Enter amount in credits'
            }
            onChange={this.handleChange}
            error={
              amountError
                ? {
                    content: 'Please enter a valid amount (>= 25)',
                    pointing: 'above',
                  }
                : undefined
            }
          ></Form.Input>
          {amount !== user.topup_amount ? (
            <p>
              New amount: {amount} credits, ${amount}
            </p>
          ) : (
            <></>
          )}

          <Header as='h5'>Default Payment Method</Header>
          {primaryPaymentMethod ? (
            <Grid>
              <Grid.Row>
                <Grid.Column width={4} floated='left'>
                  <Icon
                    size='large'
                    name={`cc ${primaryPaymentMethod.card.brand}`}
                  />
                  Card x{primaryPaymentMethod.card.last4}
                  <p>
                    Expires: {primaryPaymentMethod.card.exp_month}/
                    {primaryPaymentMethod.card.exp_year}
                  </p>
                  <br></br>
                </Grid.Column>
                <Grid.Column
                  verticalAlign='middle'
                  width={2}
                  floated='left'
                ></Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <p>You have no primary payment method</p>
          )}
          <Button
            primary
            loading={submitting}
            disabled={
              pristine ||
              thresholdError ||
              amountError ||
              !threshold ||
              !amount ||
              !primaryPaymentMethod
            }
          >
            Update
          </Button>
        </Form>
      </Segment>
    );
  }
}

export default connect(null, {
  updateUser,
})(TopupForm);
