import { MenuItem } from '@mui/material';

import INSTANCE_CLASSES from '../../../instanceClasses';
import AWS_RESOURCE_TYPES from './awsResourceTypes';

const RESOURCE_TYPE_MAPPING = {
  [AWS_RESOURCE_TYPES.V100]: {
    label: 'V100',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(8)')}
      </MenuItem>,
    ],
  },
  [AWS_RESOURCE_TYPES.T4]: {
    label: 'T4',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(8)')}
      </MenuItem>,
    ],
  },
  [AWS_RESOURCE_TYPES.A100]: {
    label: 'A100',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(8)')}
      </MenuItem>,
    ],
  },
  [AWS_RESOURCE_TYPES.CPU]: {
    label: 'CPU Only',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(8)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(16)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(32)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(64)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.XLARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(128)')}
      </MenuItem>,
    ],
  },
};
export default RESOURCE_TYPE_MAPPING;
