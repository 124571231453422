import React, { Component } from 'react';
import { Segment, Icon, Message, Table } from 'semantic-ui-react';

class WorkerReview extends Component {
  state = {
    expanded: true,
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (!this.props.data) {
      return;
    }
    const { data: workers } = this.props;
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon
              name={this.state.expanded ? 'triangle down' : 'triangle right'}
            />
            Workers
          </Message.Header>
        </Message>
        {this.state.expanded ? (
          <>
            <Segment attached>
              {workers.length > 1 ? (
                <Table compact>
                  <Table.Header>
                    <Table.Row key='header'>
                      <Table.HeaderCell width={2}>Worker</Table.HeaderCell>
                      <Table.HeaderCell>Command</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {workers.map((worker, i) => {
                      return (
                        <Table.Row key={i + 1}>
                          <Table.Cell width={2}>Worker {i + 1}</Table.Cell>
                          <Table.Cell>{worker.command}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              ) : (
                <p>
                  Command: <b>{workers[0].command}</b>
                </p>
              )}
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

export default WorkerReview;
