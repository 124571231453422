import _ from 'lodash';
import {
  FETCH_PROVIDERS,
  FETCH_PROVIDER,
  CREATE_PROVIDER,
  DELETE_PROVIDER,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROVIDERS:
      newState = action.payload.sort((a, b) => {
        const typeA = a.type.toUpperCase();
        const typeB = b.type.toUpperCase();

        if (typeA === 'physical') {
          return -1;
        }
        if (typeA < typeB) {
          return -1;
        } else if (typeA > typeB) {
          return 1;
        }
        return 0;
      });
      return newState;
    case CREATE_PROVIDER:
      newState = [...state, action.payload].sort((a, b) => {
        const typeA = a.type.toUpperCase();
        const typeB = b.type.toUpperCase();

        if (typeA === 'physical') {
          return -1;
        }
        if (typeA < typeB) {
          return -1;
        } else if (typeA > typeB) {
          return 1;
        }
        return 0;
      });
      return newState;
    case FETCH_PROVIDER:
      newState = [...state];
      _.remove(newState, (provider) => {
        return provider.provider_uuid === action.payload.provider_uuid;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const typeA = a.type.toUpperCase();
        const typeB = b.type.toUpperCase();

        if (typeA === 'physical') {
          return -1;
        }
        if (typeA < typeB) {
          return -1;
        } else if (typeA > typeB) {
          return 1;
        }
        return 0;
      });
      return newState;
    case DELETE_PROVIDER:
      newState = [...state];
      _.remove(newState, (provider) => {
        return provider.provider_uuid === action.payload;
      });
      newState = newState.sort((a, b) => {
        const typeA = a.type.toUpperCase();
        const typeB = b.type.toUpperCase();

        if (typeA === 'physical') {
          return -1;
        }
        if (typeA < typeB) {
          return -1;
        } else if (typeA > typeB) {
          return 1;
        }
        return 0;
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
