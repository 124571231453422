import React, { Component } from 'react';
import { Segment, Icon, Message, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';

import THIRD_PARTY_TYPES from '../JobForm/thirdPartyTypes';

class ModelReview extends Component {
  state = {
    expanded: true,
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (!this.props.data) {
      return;
    }
    const { source_type, source_uri, checkpoints } = this.props.data;
    let model;
    if (source_type === THIRD_PARTY_TYPES.TRAINML.value) {
      model = this.props.models.find(
        (model) => model.model_uuid === source_uri
      );
    }
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon
              name={this.state.expanded ? 'triangle down' : 'triangle right'}
            />
            Model
          </Message.Header>
        </Message>
        {this.state.expanded ? (
          <>
            <Segment attached>
              {source_uri ? (
                <p>
                  {source_type !== THIRD_PARTY_TYPES.TRAINML.value ? (
                    <>
                      Load <b>{source_uri}</b> from <b>{source_type}</b> into{' '}
                    </>
                  ) : (
                    <>
                      Load Model: <b>{model.name}</b> into{' '}
                    </>
                  )}
                  <span
                    style={{
                      fontFamily: '"Courier New", Courier, monospace',
                    }}
                  >
                    /opt/trainml/models
                  </span>
                </p>
              ) : undefined}

              {checkpoints.length ? (
                <Grid.Column>
                  {checkpoints.length === 1 ? (
                    <p>
                      Load checkpoint <b>{checkpoints[0].name}</b> into{' '}
                      <span
                        style={{
                          fontFamily: '"Courier New", Courier, monospace',
                        }}
                      >
                        /opt/trainml/checkpoint
                      </span>
                    </p>
                  ) : (
                    checkpoints.map((checkpoint) => {
                      return (
                        <p key={checkpoint.checkpoint_uuid}>
                          Load checkpoint <b>{checkpoint.name}</b> into{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/checkpoint/
                            {checkpoint.name.toLowerCase().replaceAll(' ', '_')}
                          </span>
                        </p>
                      );
                    })
                  )}
                </Grid.Column>
              ) : undefined}
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ user, models }) {
  return {
    user,
    models: models || [],
  };
}

export default connect(mapStateToProps)(ModelReview);
