import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Segment,
  Container,
  Header,
  Table,
  Dimmer,
  Placeholder,
  Loader,
} from 'semantic-ui-react';
import statuses from './statuses';

import { fetchPayments, clearPayments } from '../../actions/payments';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

class PaymentList extends Component {
  state = {
    loaded: false,
  };
  async componentDidMount() {
    await this.props.fetchPayments();
    this.setState({ loaded: true });
  }
  componentWillUnmount() {
    this.props.clearPayments();
  }
  renderPayments() {
    return this.props.payments
      .filter((payment) => payment.status !== statuses.CANCELED)
      .map((payment) => {
        return (
          <Table.Row key={payment.payment_uuid}>
            <Table.Cell>{moment(payment.timestamp).format('lll')}</Table.Cell>
            <Table.Cell className='single line'>
              {formatter.format(payment.amount)}
            </Table.Cell>
            <Table.Cell className='single line'>{payment.credits}</Table.Cell>
            <Table.Cell className='single line'>
              Card x{payment.last4}
            </Table.Cell>
            <Table.Cell className='single line'>
              {payment.automated ? 'Automated' : 'Manual'}
            </Table.Cell>
            <Table.Cell className='single line'>
              {payment.status
                .toLowerCase()
                .replace(/\b(\w)/g, (s) => s.toUpperCase())}
            </Table.Cell>
          </Table.Row>
        );
      });
  }
  render() {
    return (
      <Container>
        <Segment>
          <Header textAlign='center' as='h2'>
            Payment History
          </Header>
          {this.state.loaded ? (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Credits</Table.HeaderCell>
                  <Table.HeaderCell>Payment Method</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderPayments()}</Table.Body>
            </Table>
          ) : (
            <Dimmer.Dimmable as={Segment} dimmed>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Dimmer.Dimmable>
          )}
        </Segment>
      </Container>
    );
  }
}
const mapStateToProps = ({ paymentMethods, payments }) => {
  return { paymentMethods, payments };
};

export default connect(mapStateToProps, { fetchPayments, clearPayments })(
  PaymentList
);
