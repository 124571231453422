import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

export default function DataConnectorProtocolField({
  value,
  error,
  setValue,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (event.target.value !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id='protocol-label'>Protocol</InputLabel>
        <Select
          labelId='protocol-label'
          id='protocol'
          value={value}
          label='Protocol'
          aria-describedby='protocol-helper-text'
          onChange={handleChange}
          error={Boolean(error)}
        >
          <MenuItem value='tcp'>TCP</MenuItem>
          <MenuItem value='udp'>UDP</MenuItem>
        </Select>
        {error ? (
          <FormHelperText id='protocol-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='protocol-helper-text'>
            Select the transport protocol the data connector uses
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
