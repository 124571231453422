import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
  CircularProgress,
  Alert,
  AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  createRegion,
  fetchRegions,
  updateRegion,
} from '../../../../actions/regions';
import api from '../../../../apis/api';
import AWSRegionField from './AWSRegionField';
// import AWSQuotas from './AWSQuotas';
import StorageInstanceClassField from '../Shared/StorageInstanceClassField';
import PROVIDER_TYPES from '../../providerTypes';
import StorageModeField from '../Shared/StorageModeField';
import PublicField from '../Shared/PublicField';
import STORAGE_MODES from '../Shared/storageModes';

const provider_type = PROVIDER_TYPES.AWS;

export default function AWSRegionForm({ provider, region }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const regions = useSelector((state) => state.regions);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState(region ? region.name : null);
  const [providerMetadata, setProviderMetadata] = useState(undefined);
  const [apiError, setApiError] = useState(undefined);
  const [is_public, setIsPublic] = useState(region ? region.public : true);
  const [storage_mode, setStorageMode] = useState(
    region?.storage?.mode || 'local'
  );
  const [instance_class, setInstanceClass] = useState(
    region?.storage?.instance_class || ''
  );

  useEffect(() => {
    if (!regions[provider.type]) {
      dispatch(fetchRegions(provider.provider_uuid, provider.type));
    }
  }, [dispatch, regions, provider]);

  useEffect(() => {
    (async () => {
      if (provider) {
        try {
          const resp = await api.get(
            `/provider/${provider.provider_uuid}/metadata`
          );
          setProviderMetadata(resp.data);
        } catch (error) {
          if (error.response.status === 403) {
            setApiError({
              title: `Error Accessing ${provider_type.label} Region Details`,
              message:
                'The service account credentials provided was not able to access the region data for your project.  Ensure that the provided service account is configured as a Compute Admin and try again.',
            });
          } else {
            setApiError({
              title: 'Error',
              message: `An unknown error occurred, please contact support at support@${process.env.REACT_APP_DOMAIN_SUFFIX}`,
            });
          }
        }
      }
    })();
  }, [provider]);

  if (!provider) {
    history.push('/resources');
    return <div>Redirecting...</div>;
  }

  const handleSubmit = async () => {
    const storage = Object.fromEntries(
      Object.entries({
        mode: storage_mode,
        instance_class,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    const payload = Object.fromEntries(
      Object.entries({
        name,
        public: is_public,
        storage,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    setLoading(true);
    if (region) {
      await dispatch(
        updateRegion(
          provider.provider_uuid,
          provider.type,
          region.region_uuid,
          payload
        )
      );
    } else {
      await dispatch(
        createRegion(provider.provider_uuid, provider.type, payload)
      );
    }

    history.push('/resources');
  };

  return (
    <Paper sx={{ display: 'flex', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => history.goBack()} disabled={loading}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>
                  {region
                    ? `Edit ${provider_type.label} : ${region.name}`
                    : `Create New ${provider_type.label} Region`}
                </Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
        {apiError ? (
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Alert severity='error'>
                <AlertTitle>{apiError.title}</AlertTitle>
                {apiError.message}
              </Alert>
            </Box>
          </Grid>
        ) : (
          <>
            {providerMetadata ? (
              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Box
                    padding='15px'
                    sx={{
                      width: '100%',
                      maxWidth: '600px',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant='h6'>General</Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <AWSRegionField
                          value={name}
                          setValue={setName}
                          setChanged={() => setPristine(false)}
                          all_regions={providerMetadata}
                          used_regions={(regions[provider.type] || []).map(
                            (region) => region.name
                          )}
                          edit={Boolean(region)}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <PublicField
                          value={is_public}
                          setValue={setIsPublic}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant='h6'>Storage</Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <StorageModeField
                          value={storage_mode}
                          setValue={setStorageMode}
                          setChanged={() => setPristine(false)}
                          disabled={Boolean(region)}
                        />
                      </Grid>
                      {storage_mode === STORAGE_MODES.CENTRAL ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <StorageInstanceClassField
                            value={instance_class}
                            setValue={setInstanceClass}
                            setChanged={() => setPristine(false)}
                            disabled={Boolean(region)}
                          />
                        </Grid>
                      ) : undefined}
                    </Grid>
                  </Box>
                  {/* <AWSQuotas
                    providerMetadata={providerMetadata}
                    selected_region={name}
                  /> */}
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  minHeight='300px'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack spacing={2} direction='column'>
                    <CircularProgress />
                    <Typography variant='body'>
                      Loading {provider_type.label} regions
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
              <Toolbar>
                <Stack spacing={2} direction='row'>
                  <LoadingButton
                    variant='contained'
                    color='brand'
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={pristine || !Boolean(name)}
                  >
                    Submit
                  </LoadingButton>

                  <Button
                    variant='outlined'
                    onClick={() => history.goBack()}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Toolbar>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}
