import _ from 'lodash';
import {
  FETCH_VOLUMES,
  UPDATE_VOLUME,
  CREATE_VOLUME,
  DELETE_VOLUME,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VOLUMES:
      newState = action.payload.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case UPDATE_VOLUME:
      newState = [...(state || [])];
      _.remove(newState, (volume) => {
        return volume.id === action.payload.id;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case CREATE_VOLUME:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case DELETE_VOLUME:
      newState = [...(state || [])];
      _.remove(newState, (volume) => {
        return volume.id === action.payload;
      });
      newState = newState.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    default:
      return state;
  }
}

export default reducer;
