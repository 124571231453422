import _ from 'lodash';
import { COLLECTION_TO_ENTITY_TYPE_MAPPING } from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import toTitleCase from '../util/toTitleCase';

export const fetchCollections = (entity_type) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;

    let res = await api.get(`/${entity_type}`, {
      params: { project_uuid },
    });
    dispatch({
      type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].list,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateCollection = (entity_type, id, name) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.patch(
      `/${entity_type}/${id}`,
      { name: name },
      { params: { project_uuid } }
    );
    dispatch({
      type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].update,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteCollection = (entity_type, id) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    await api.delete(`/${entity_type}/${id}`, {
      params: { project_uuid },
    });
    dispatch({
      type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].delete,
      payload: id,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createCollection = (entity_type, values) => async (dispatch) => {
  try {
    if (!values.project_uuid) {
      const project_uuid = store.getState().user.selected_project;
      values.project_uuid = project_uuid;
    }
    const res = await api.post(`/${entity_type}`, values);
    if (res.data.project_uuid !== store.getState().user.selected_project) {
      return;
    }
    dispatch({
      type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].create,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const exportCollection =
  (entity_type, id, values) => async (dispatch) => {
    try {
      const project_uuid = store.getState().user.selected_project;
      const res = await api.post(`/${entity_type}/${id}/export`, values, {
        params: { project_uuid },
      });
      dispatch({
        type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].update,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const abortExportCollection = (entity_type, id) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.delete(`/${entity_type}/${id}/export`, {
      params: { project_uuid },
    });
    dispatch({
      type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].update,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchCollection = (entity_type, id) => async (dispatch) => {
  try {
    const collection = await getCollection(entity_type, id);
    if (!collection || _.isEmpty(collection)) {
      dispatch({
        type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].delete,
        payload: id,
      });
    } else {
      dispatch({
        type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].update,
        payload: collection,
      });
    }
  } catch (error) {
    dispatch(setErrorMessage(error));
    throw error;
  }
};

export const getCollection = async (entity_type, id) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/${entity_type}/${id}`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return;
    }
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const getCollectionDetails = async (entity_type, id) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/${entity_type}/${id}/details`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchPublicCollections = (entity_type) => async (dispatch) => {
  try {
    let res = await api.get(`/${entity_type}/public`);
    dispatch({
      type: COLLECTION_TO_ENTITY_TYPE_MAPPING[entity_type].list_public,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const verifyPublicCollections = (entity_type) => async (dispatch) => {
  const publicCollections =
    store.getState()[`public${toTitleCase(entity_type)}`];
  if (publicCollections === null || publicCollections.length === 0) {
    dispatch(fetchPublicCollections(entity_type));
  }
};
