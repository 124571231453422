import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Segment,
  Dimmer,
  Loader,
  Button,
  Icon,
  Placeholder,
} from 'semantic-ui-react';

import PaymentMethod from './PaymentMethod';
import {
  fetchPaymentMethods,
  removePaymentMethod,
  setPrimaryPaymentMethod,
  clearPaymentMethods,
} from '../../../actions/paymentMethods';

class PaymentMethodList extends Component {
  state = { paymentValue: null, editing: false };
  componentDidMount() {
    this.props.fetchPaymentMethods();
  }
  componentWillUnmount() {
    this.props.clearPaymentMethods();
  }

  async setPrimary(id) {
    this.setState({
      editing: true,
    });
    await this.props.setPrimaryPaymentMethod(id);
    this.setState({
      editing: false,
    });
  }

  async remove(id) {
    this.setState({
      editing: true,
    });
    await this.props.removePaymentMethod(id);
    this.setState({
      editing: false,
    });
  }

  renderPaymentMethods(primary) {
    if (!this.props.paymentMethods) {
      return (
        <Dimmer.Dimmable as={Segment} dimmed>
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Dimmer.Dimmable>
      );
    }
    return this.props.paymentMethods.map((paymentMethod) => {
      return (
        <PaymentMethod
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          removePaymentMethod={() => {
            this.remove(paymentMethod.id);
          }}
          isPrimary={primary === paymentMethod.id}
          setPrimary={() => {
            this.setPrimary(paymentMethod.id);
          }}
          editing={this.state.editing}
        />
      );
    });
  }
  render() {
    return (
      <>
        <Dimmer.Dimmable dimmed={this.state.editing}>
          <Dimmer active={this.state.editing} inverted>
            <Loader>Updating</Loader>
          </Dimmer>
          {this.renderPaymentMethods(this.props.primary)}
          {this.state.editing ? (
            <Button primary disabled labelPosition='right' icon>
              <Icon name='credit card' />
              Add Payment Method
            </Button>
          ) : (
            <Link to='/account/billing/method/new'>
              <Button primary labelPosition='right' icon>
                <Icon name='credit card' />
                Add Payment Method
              </Button>
            </Link>
          )}
        </Dimmer.Dimmable>
      </>
    );
  }
}

const mapStateToProps = ({ user, paymentMethods }) => {
  return { user, paymentMethods };
};

export default connect(mapStateToProps, {
  fetchPaymentMethods,
  removePaymentMethod,
  setPrimaryPaymentMethod,
  clearPaymentMethods,
})(PaymentMethodList);
