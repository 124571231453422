import {
  FETCH_DATA_CONNECTOR,
  FETCH_DATA_CONNECTORS,
  DELETE_DATA_CONNECTOR,
  CREATE_DATA_CONNECTOR,
  CLEAR_DATA_CONNECTORS,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchDataConnectors =
  (provider_uuid, region_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/data_connector`
      );
      dispatch({
        type: FETCH_DATA_CONNECTORS,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const fetchDataConnector =
  (provider_uuid, region_uuid, connector_id) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/data_connector/${connector_id}`
      );
      dispatch({
        type: FETCH_DATA_CONNECTOR,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const updateDataConnector =
  (provider_uuid, region_uuid, connector_id, data) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/data_connector/${connector_id}`,
        data
      );
      dispatch({
        type: FETCH_DATA_CONNECTOR,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const deleteDataConnector =
  (provider_uuid, region_uuid, connector_id) => async (dispatch) => {
    try {
      await api.delete(
        `/provider/${provider_uuid}/region/${region_uuid}/data_connector/${connector_id}`
      );
      dispatch({
        type: DELETE_DATA_CONNECTOR,
        payload: connector_id,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const createDataConnector =
  (provider_uuid, region_uuid, values) => async (dispatch) => {
    try {
      const res = await api.post(
        `/provider/${provider_uuid}/region/${region_uuid}/data_connector`,
        values
      );
      dispatch({
        type: CREATE_DATA_CONNECTOR,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const clearDataConnectors = () => ({
  type: CLEAR_DATA_CONNECTORS,
});
