import React, { useState } from 'react';

import {
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createProvider, updateProvider } from '../../../../actions/providers';
import PROVIDER_TYPES from '../../providerTypes';
import PaymentModeField from '../Shared/PaymentModeField';
import AWSAccountIdField from './AWSAccountIdField';
import AWSAccountKeyField from './AWSAccountKeyField';
import AWSAccountSecretField from './AWSAccountSecretField';
import AWSRoleARNField from './AWSRoleARNField';

const provider_type = PROVIDER_TYPES.AWS;

export default function AWSProviderForm({ provider }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(false);
  const [payment_mode, setPaymentMode] = useState(
    provider?.payment_mode || 'credits'
  );
  const [account, setAccount] = useState(provider?.credentials?.account || '');
  const [account_error, setAccountError] = useState(false);
  const [key_id, setKeyId] = useState(provider?.credentials?.id || '');
  const [key_id_error, setKeyIdError] = useState(false);
  const [secret, setSecret] = useState(provider?.credentials?.secret || '');
  const [secret_error, setSecretError] = useState(false);
  const [role, setRole] = useState(provider?.credentials?.role || '');
  const [role_error, setRoleError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const credentials = Object.fromEntries(
      Object.entries({
        account,
        id: key_id,
        secret,
        role,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    const payload = Object.fromEntries(
      Object.entries({
        payment_mode,
        credentials,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    if (provider) {
      await dispatch(updateProvider(provider.provider_uuid, payload));
    } else {
      await dispatch(createProvider({ ...payload, type: provider_type.value }));
    }

    history.push('/resources');
  };

  return (
    <Paper sx={{ display: 'flex', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => history.goBack()} disabled={loading}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>{provider_type?.label}</Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box padding='15px' sx={{ maxWidth: 400 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>General</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <PaymentModeField
                  value={payment_mode}
                  setValue={setPaymentMode}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>Credentials</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AWSAccountIdField
                  value={account}
                  error={account_error}
                  setValue={setAccount}
                  setError={setAccountError}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AWSRoleARNField
                  value={role}
                  error={role_error}
                  setValue={setRole}
                  setError={setRoleError}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AWSAccountKeyField
                  value={key_id}
                  error={key_id_error}
                  setValue={setKeyId}
                  setError={setKeyIdError}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AWSAccountSecretField
                  value={secret}
                  error={secret_error}
                  setValue={setSecret}
                  setError={setSecretError}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Divider />
          <Toolbar>
            <Stack spacing={2} direction='row'>
              <LoadingButton
                variant='contained'
                color='brand'
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  pristine ||
                  !Boolean(account) ||
                  !Boolean(key_id) ||
                  !Boolean(role) ||
                  (!provider && !Boolean(secret)) ||
                  account_error ||
                  key_id_error ||
                  secret_error ||
                  role_error
                }
              >
                Submit
              </LoadingButton>

              <Button
                variant='outlined'
                onClick={() => history.goBack()}
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
      </Grid>
    </Paper>
  );
}
