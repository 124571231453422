import _ from 'lodash';
import {
  FETCH_SERVICES,
  FETCH_SERVICE,
  DELETE_SERVICE,
  CREATE_SERVICE,
  CLEAR_SERVICES,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_SERVICES:
      return initialState;
    case FETCH_SERVICES:
      newState = action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case CREATE_SERVICE:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case FETCH_SERVICE:
      newState = [...(state || [])];
      _.remove(newState, (service) => {
        return service.service_id === action.payload.service_id;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case DELETE_SERVICE:
      newState = [...(state || [])];
      _.remove(newState, (service) => {
        return service.service_id === action.payload;
      });
      newState = newState.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
