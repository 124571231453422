import React, { Component } from 'react';
import { Segment, Icon, Table, Message, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

class EnvironmentReview extends Component {
  state = {
    expanded: true,
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (!this.props.data) {
      return;
    }
    const { type, custom_image, env, worker_key_types, packages, init_script } =
      this.props.data;
    const environment = this.props.environments.find((env) => env.id === type);
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon
              name={this.state.expanded ? 'triangle down' : 'triangle right'}
            />
            Environment
          </Message.Header>
        </Message>
        {this.state.expanded ? (
          <>
            <Segment attached>
              {environment.id === 'CUSTOM' ? (
                <>
                  <p>
                    Environment: <b>{environment.name}</b>
                  </p>
                  <p>
                    Image: <b>{custom_image}</b>
                  </p>
                </>
              ) : (
                <p>
                  Environment: <b>{environment.name}</b>
                </p>
              )}

              {(packages.pip && packages.pip.length) ||
              (packages.apt && packages.apt.length) ||
              (packages.conda && packages.conda.length) ? (
                <Header as='h5'>Packages to Install:</Header>
              ) : undefined}
              {packages.pip && packages.pip.length ? (
                <p>
                  <b>pip:</b> {packages.pip.join(' ')}
                </p>
              ) : undefined}
              {packages.apt && packages.apt.length ? (
                <p>
                  <b>apt:</b> {packages.apt.join(' ')}
                </p>
              ) : undefined}
              {packages.conda && packages.conda.length ? (
                <p>
                  <b>conda:</b> {packages.conda.join(' ')}
                </p>
              ) : undefined}
              {init_script ? (
                <>
                  <Header as='h5'>Initialization Script to Execute:</Header>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{init_script}</p>
                </>
              ) : undefined}
              {env.length ? (
                <>
                  <Header as='h5'>Environment Variables:</Header>
                  <Table compact>
                    <Table.Header>
                      <Table.Row key='header'>
                        <Table.HeaderCell>Key</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {env.map((e) => {
                        return (
                          <Table.Row key={e.key}>
                            <Table.Cell>{e.key}</Table.Cell>
                            <Table.Cell>{e.value}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </>
              ) : undefined}
              {worker_key_types.length ? (
                <p>
                  Keys to attach: <b>{worker_key_types.join(', ')}</b>
                </p>
              ) : undefined}
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ environments }) {
  return {
    environments,
  };
}

export default connect(mapStateToProps)(EnvironmentReview);
