import { FETCH_PROJECT_DATA_CONNECTORS } from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchProjectDataConnectors = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/project/${project_uuid}/data_connectors`);
    dispatch({
      type: FETCH_PROJECT_DATA_CONNECTORS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const getProjectDataConnectors = async (project_uuid) => {
  try {
    const res = await api.get(`/project/${project_uuid}/data_connectors`);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const enableProjectDataConnector = async (
  project_uuid,
  connector_id
) => {
  try {
    const res = await api.patch(
      `/project/${project_uuid}/data_connectors/${connector_id}/enable`
    );
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const disableProjectDataConnector = async (
  project_uuid,
  connector_id
) => {
  try {
    const res = await api.patch(
      `/project/${project_uuid}/data_connectors/${connector_id}/disable`
    );
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
