import { FETCH_USER_CREDENTIALS } from '../actions/types';

const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_CREDENTIALS:
      return action.payload || [];
    default:
      return state;
  }
}
export default reducer;
