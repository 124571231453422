import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Header, Grid } from 'semantic-ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import PaymentMethodBillingDetailsSection from './PaymentMethodBillingDetailsSection';
import PaymentMethodCardSection from './PaymentMethodCardSection';
import { createPaymentMethod } from '../../../actions/paymentMethods';

class PaymentMethodNew extends Component {
  state = { showCardSection: false, submitting: false, billingDetails: null };

  billingDetailsSubmit = async (values) => {
    this.setState({ showCardSection: true, billingDetails: values });
  };

  onCardCancel = () => {
    this.setState({
      showCardSection: false,
    });
  };
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Container>
              <Header as='h2' textAlign='center'>
                New Payment Method
              </Header>
              {this.state.showCardSection ? (
                <Elements
                  stripe={loadStripe(
                    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                  )}
                >
                  <PaymentMethodCardSection
                    billingDetails={this.state.billingDetails}
                    submitting={this.state.submitting}
                    onCancel={this.onCardCancel}
                  />
                </Elements>
              ) : (
                <PaymentMethodBillingDetailsSection
                  onSubmit={this.billingDetailsSubmit}
                  initialValues={this.state.billingDetails}
                />
              )}
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(null, {
  createPaymentMethod,
})(PaymentMethodNew);
