import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function CollectionBranchField({
  value,
  setValue,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label='Branch (Optional)'
          id='branch'
          aria-describedby='branch-helper-text'
          variant='outlined'
          onChange={handleChange}
          value={value}
          disabled={edit}
        />

        <FormHelperText id='branch-helper-text'>
          Enter the branch to pull (if not the default branch)
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
