import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function AWSAccountIdField({
  value,
  error,
  setValue,
  setError,
  setChanged,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (!/^\d{12}$/.test(newValue)) {
      setError('Invalid Account Number.  Must be a 12 digit number.');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Account Number'
          id='account'
          aria-describedby='account-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={error}
          value={value}
        />
        {error ? (
          <FormHelperText id='account-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='account-helper-text'>
            Enter the AWS Account ID to create CloudBender resources in
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
