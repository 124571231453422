import React from 'react';

import { Stack, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EnvKeyField from './EnvKeyField';
import EnvValueField from './EnvValueField';

export default function EnvField({
  index,
  value,
  error,
  setValue,
  setError,
  remove,
}) {
  const setEnvKey = (key) => {
    value.key = key;
    setValue(index, value);
  };
  const setEnvValue = (env_value) => {
    value.value = env_value;
    setValue(index, value);
  };
  const setKeyError = (error) => {
    setError(index, error);
  };

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <EnvKeyField
        index={index}
        value={value.key}
        error={error}
        setValue={setEnvKey}
        setError={setKeyError}
      />
      <EnvValueField index={index} value={value.value} setValue={setEnvValue} />
      <IconButton
        onClick={() => {
          remove(index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
}
