import React from 'react';

import { useDispatch } from 'react-redux';
import useInterval from '../../../../util/useInterval';
import { fetchService } from '../../../../actions/services';

export default function PollService({ region, service, interval }) {
  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(
      fetchService(
        region.provider_uuid,
        service.region_uuid,
        service.service_id
      )
    );
  }, interval);

  return <div></div>;
}
