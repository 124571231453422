import { FETCH_PUBLIC_CHECKPOINTS } from './types';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchPublicCheckpoints = () => async (dispatch) => {
  try {
    let res = await api.get('/checkpoint/public');
    dispatch({
      type: FETCH_PUBLIC_CHECKPOINTS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const verifyPublicCheckpoints = () => async (dispatch) => {
  const publicCheckpoints = store.getState().publicCheckpoints;
  if (publicCheckpoints === null || publicCheckpoints.length === 0) {
    dispatch(fetchPublicCheckpoints());
  }
};
