import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  TableBody,
  Table,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/ContentCopy';

export default function ConnectDialog({
  open,
  handleClose,
  type,
  id,
  endpoint,
}) {
  const [tooltip, setTooltip] = useState('Copy');
  const setCopied = () => setTooltip('Copied!');
  const setCopyError = () => setTooltip('Copy Error!');

  const copyText = (value) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value).then(setCopied, setCopyError);
    } else {
      setCopyError();
    }
  };

  if (endpoint) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Connect
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box padding='10px'>
            <Table>
              <TableBody></TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else {
    const copy_text = `${process.env.REACT_APP_CLI_COMMAND} ${type} connect ${id}`;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Connect to {type}
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box padding='10px'>
            <Typography>
              1. Install and configure the{' '}
              <a href={`${process.env.REACT_APP_CLI_GITHUB}`}>
                {' '}
                {process.env.REACT_APP_ORG_NAME} python module
              </a>
            </Typography>
            <Typography>2. Run the following command:</Typography>
            <br />
            <Typography as='code'>{copy_text}</Typography>
            <Tooltip title={tooltip}>
              <IconButton
                aria-label='close'
                onClick={() => copyText(copy_text)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}
