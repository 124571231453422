import _ from 'lodash';
import {
  FETCH_RIGS,
  FETCH_RIG,
  DELETE_RIG,
  CREATE_RIG,
  CLEAR_RIGS,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_RIGS:
      return initialState;
    case FETCH_RIGS:
      newState = action.payload.sort((a, b) => {
        const nameA = a.friendly_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.friendly_name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case CREATE_RIG:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const nameA = a.friendly_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.friendly_name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case FETCH_RIG:
      newState = [...(state || [])];
      _.remove(newState, (rig) => {
        return rig.rig_uuid === action.payload.rig_uuid;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const nameA = a.friendly_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.friendly_name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case DELETE_RIG:
      newState = [...(state || [])];
      _.remove(newState, (rig) => {
        return rig.rig_uuid === action.payload;
      });
      newState = newState.sort((a, b) => {
        const nameA = a.friendly_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.friendly_name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
