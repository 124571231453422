import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  TableBody,
  Table,
  TableHead,
  Alert,
  Link,
  Modal,
  Stack,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  deleteProvider,
  fetchProvider,
  setActiveProvider,
} from '../../actions/providers';
import Region from './Region';
import ActionsButton from './ActionsButton';
import STATUSES from '../statuses';
import useInterval from '../../util/useInterval';
import { fetchRegions } from '../../actions/regions';
import LoadingMask from '../shared/LoadingMask';

export default function Provider({ type, provider }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.regions);
  const [loading_regions, setLoadingRegions] = useState(true);
  const [removing, setRemoving] = useState(false);
  const [open, setOpen] = useState(false);
  const [modal_open, setModalOpen] = useState(false);
  const [poll_interval, setPollInterval] = useState(null);

  const removeProvider = async () => {
    setRemoving(true);
    await dispatch(deleteProvider(provider.provider_uuid));
  };
  useEffect(() => {
    if (
      provider &&
      [STATUSES.NEW, STATUSES.PROVISIONING].includes(provider.status)
    ) {
      setPollInterval(10000);
    } else {
      setPollInterval(null);
    }
  }, [provider]);

  useEffect(() => {
    if (!provider) {
      setLoadingRegions(false);
    }
    if (provider && !regions[provider.type]) {
      dispatch(fetchRegions(provider.provider_uuid, provider.type));
    } else {
      setLoadingRegions(false);
    }
  }, [dispatch, provider, regions]);

  useInterval(() => {
    dispatch(fetchProvider(provider.provider_uuid));
  }, poll_interval);

  if (provider) {
    if (loading_regions) {
      return (
        <TableRow key={type}>
          <TableCell>
            <LoadingMask />
          </TableCell>
        </TableRow>
      );
    }

    const provider_regions = regions[provider.type] || [];

    if (provider.status === STATUSES.ERRORED) {
      return (
        <TableRow
          key={type.value}
          sx={removing ? { backgroundColor: 'text.disabled' } : undefined}
        >
          <TableCell sx={{ maxWidth: '25px' }}></TableCell>
          <TableCell>
            <Typography variant='h6'>{type.label}</Typography>
          </TableCell>
          <TableCell colSpan={2}>
            <Alert severity='error' variant='outlined'>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography variant='body2'>
                  Error initializing provider.
                </Typography>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() => setModalOpen(true)}
                >
                  View Details
                </Link>
              </Stack>
            </Alert>
            <Modal
              open={modal_open}
              onClose={() => setModalOpen(false)}
              aria-labelledby='error-modal-title'
              aria-describedby='error-modal-description'
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: 700,
                  bgcolor: 'background.paper',
                  p: 4,
                }}
              >
                <Stack direction='column' spacing={2}>
                  <Typography
                    id='error-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Provider Initialization Error
                  </Typography>

                  <Typography
                    id='error-modal-description'
                    variant='body1'
                    sx={{ mt: 2, flexWrap: true }}
                    style={{ wordBreak: 'break-all' }}
                  >
                    {provider.status_detail}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', flexGrow: 1 }}
                    justifyContent='center'
                  >
                    <Button
                      variant='outlined'
                      onClick={() => setModalOpen(false)}
                    >
                      Close
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Modal>
          </TableCell>
          <TableCell align='right' sx={{ maxWidth: '25px' }}>
            <ActionsButton
              items={[
                {
                  key: 'edit-provider',
                  onClick: () => {
                    dispatch(
                      setActiveProvider(provider, () => {
                        history.push(
                          `/resources/provider/${provider.provider_uuid}/edit`
                        );
                      })
                    );
                  },

                  content: 'Edit Provider',
                },
                {
                  key: 'remove-provider',
                  onClick: () => removeProvider(),
                  content: 'Remove Provider',
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    } else if (
      [STATUSES.PROVISIONING, STATUSES.NEW].includes(provider.status)
    ) {
      return (
        <TableRow key={type.value}>
          <TableCell sx={{ maxWidth: '25px' }}></TableCell>
          <TableCell>
            <Typography variant='h6'>{type.label}</Typography>
          </TableCell>
          <TableCell colSpan={2}>
            <Alert severity='info' variant='outlined'>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography variant='body2'>Provisioning</Typography>
                <CircularProgress size={15} />
              </Stack>
            </Alert>
          </TableCell>
          <TableCell align='right' sx={{ maxWidth: '25px' }}>
            <ActionsButton
              items={[
                {
                  key: 'edit-provider',
                  onClick: () => {
                    dispatch(
                      setActiveProvider(provider, () => {
                        history.push(
                          `/resources/provider/${provider.provider_uuid}/edit`
                        );
                      })
                    );
                  },
                  content: 'Edit Provider',
                },
                {
                  key: 'remove-provider',
                  onClick: () => removeProvider(),
                  content: 'Remove Provider',
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    } else if (provider_regions.length) {
      return (
        <>
          <TableRow key={type.value}>
            <TableCell sx={{ maxWidth: '25px' }}>
              {provider_regions.length ? (
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              ) : undefined}
            </TableCell>
            <TableCell>
              <Typography variant='h6'>{type.label}</Typography>
            </TableCell>
            <TableCell align='right'>{provider_regions.length}</TableCell>
            <TableCell align='right'>{provider.credits}</TableCell>
            <TableCell align='right' sx={{ maxWidth: '25px' }}>
              <ActionsButton
                items={[
                  {
                    key: 'create-region',
                    onClick: () => {
                      dispatch(
                        setActiveProvider(provider, () => {
                          history.push({
                            pathname: `/resources/provider/${provider.provider_uuid}/region/new`,
                          });
                        })
                      );
                    },
                    content: 'Create Region',
                  },
                  {
                    key: 'edit-provider',
                    onClick: () => {
                      dispatch(
                        setActiveProvider(provider, () => {
                          history.push(
                            `/resources/provider/${provider.provider_uuid}/edit`
                          );
                        })
                      );
                    },
                    content: 'Edit Provider',
                  },
                  {
                    key: 'remove-provider',
                    onClick: () => removeProvider(),
                    content: 'Remove Provider',
                  },
                ]}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='h6' gutterBottom component='div'>
                    Regions
                  </Typography>
                  <Table size='small' aria-label='regions'>
                    <TableHead>
                      <TableRow key='header'>
                        <TableCell>Name</TableCell>
                        <TableCell>Nodes (Active/Total)</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align='right'>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {provider_regions.map((region) => {
                        return (
                          <Region
                            key={region.region_uuid}
                            provider={provider}
                            region={region}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      );
    } else {
      return (
        <TableRow key={type.value}>
          <TableCell sx={{ maxWidth: '25px' }}></TableCell>
          <TableCell>
            <Typography variant='h6'>{type.label}</Typography>
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant='contained'
              color='brand'
              onClick={() => {
                dispatch(
                  setActiveProvider(provider, () => {
                    history.push({
                      pathname: `/resources/provider/${provider.provider_uuid}/region/new`,
                    });
                  })
                );
              }}
            >
              Create Region
            </Button>
          </TableCell>
          <TableCell align='right' sx={{ maxWidth: '25px' }}>
            <ActionsButton
              items={[
                {
                  key: 'create-region',
                  onClick: () => {
                    dispatch(
                      setActiveProvider(provider, () => {
                        history.push({
                          pathname: `/resources/provider/${provider.provider_uuid}/region/new`,
                        });
                      })
                    );
                  },
                  content: 'Create Region',
                },
                {
                  key: 'edit-provider',
                  onClick: () => {
                    dispatch(
                      setActiveProvider(provider, () => {
                        history.push(
                          `/resources/provider/${provider.provider_uuid}/edit`
                        );
                      })
                    );
                  },
                  content: 'Edit Provider',
                },
                {
                  key: 'remove-provider',
                  onClick: () => removeProvider(),
                  content: 'Remove Provider',
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    }
  } else {
    return (
      <TableRow key={type.value}>
        <TableCell sx={{ maxWidth: '25px' }}></TableCell>
        <TableCell>
          <Typography variant='h6'>{type.label}</Typography>
        </TableCell>
        <TableCell colSpan={2}></TableCell>
        <TableCell align='right' sx={{ maxWidth: '25px' }}>
          <ActionsButton
            items={[
              {
                key: 'enable-provider',
                onClick: () => {
                  history.push({
                    pathname: `/resources/provider/new`,
                    search: `?type=${type.value}`,
                  });
                },
                content: 'Enable Provider',
              },
            ]}
          />
        </TableCell>
      </TableRow>
    );
  }
}
