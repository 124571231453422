import THIRD_PARTY_TYPES from './thirdPartyTypes';

const STORAGE_TYPES = {
  input: [
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.WEB,
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.REGIONAL,
    THIRD_PARTY_TYPES.WASABI,
  ],
  output: [
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.TRAINML,
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.REGIONAL,
    THIRD_PARTY_TYPES.WASABI,
  ],
  data: [
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.WEB,
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.KAGGLE,
    THIRD_PARTY_TYPES.TRAINML,
    THIRD_PARTY_TYPES.WASABI,
  ],
  dataset: [
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.WEB,
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.KAGGLE,
    THIRD_PARTY_TYPES.TRAINML,
    THIRD_PARTY_TYPES.WASABI,
  ],
  model: [
    THIRD_PARTY_TYPES.GIT,
    THIRD_PARTY_TYPES.TRAINML,
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.WEB,
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.WASABI,
    // THIRD_PARTY_TYPES.KAGGLE,
  ],
  checkpoint: [
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.GIT,
    THIRD_PARTY_TYPES.HUGGINGFACE,
    THIRD_PARTY_TYPES.KAGGLE,
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.TRAINML,
    THIRD_PARTY_TYPES.WASABI,
    THIRD_PARTY_TYPES.WEB,
  ],
  volume: [
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.EMPTY,
    THIRD_PARTY_TYPES.EVEFS,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.GIT,
    THIRD_PARTY_TYPES.HUGGINGFACE,
    THIRD_PARTY_TYPES.KAGGLE,
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.WASABI,
    THIRD_PARTY_TYPES.WEB,
  ],
  export: [
    THIRD_PARTY_TYPES.AWS,
    THIRD_PARTY_TYPES.AZURE,
    THIRD_PARTY_TYPES.GCP,
    THIRD_PARTY_TYPES.LOCAL,
    THIRD_PARTY_TYPES.WASABI,
  ],
};

export default STORAGE_TYPES;
