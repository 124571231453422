import { FETCH_PROJECT_DATASTORES } from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchProjectDatastores = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/project/${project_uuid}/datastores`);
    dispatch({
      type: FETCH_PROJECT_DATASTORES,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const getProjectDatastores = async (project_uuid) => {
  try {
    const res = await api.get(`/project/${project_uuid}/datastores`);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const enableProjectDatastore = async (project_uuid, store_id) => {
  try {
    const res = await api.patch(
      `/project/${project_uuid}/datastores/${store_id}/enable`
    );
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const disableProjectDatastore = async (project_uuid, store_id) => {
  try {
    const res = await api.patch(
      `/project/${project_uuid}/datastores/${store_id}/disable`
    );
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
