import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Backdrop,
  CircularProgress,
  Typography,
  Toolbar,
  Stack,
  Button,
  Paper,
  Grid,
  Link,
  Breadcrumbs,
  Divider,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import DeleteIcon from '@mui/icons-material/Delete';

import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import theme from '../../theme';

import toNiceTimestamp from '../../util/toNiceTimestamp';
import { clearErrorMessage } from '../../actions/errorMessage';
import ErrorSnackBar from '../shared/ErrorSnackBar';

import { deleteProject, fetchProjects, leaveProject } from 'actions/projects';

export default function ProjectDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();

  const api_error = useSelector((state) => state.errorMessage);
  const projects = useSelector((state) => state.projects);
  const user = useSelector((state) => state.user);
  const my_projects = projects.filter(
    (project) => project.id !== user.id && project.owner
  );
  const other_projects = projects.filter(
    (project) => project.id !== user.id && !project.owner
  );
  const [selectedProjects, setSelectedProjects] = useState([]);
  // const [transitioningProjects, setTransitioningProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (projects === null) {
        dispatch(fetchProjects());
        return;
      }
      setLoading(false);
    })();
  }, [dispatch, projects]);

  const removeProject = useCallback(
    async (id) => {
      // setTransitioningProjects((prevTransitioningProjects) => [
      //   ...prevTransitioningProjects,
      //   id,
      // ]);
      await dispatch(deleteProject(id));
      // setTransitioningProjects((prevTransitioningProjects) =>
      //   prevTransitioningProjects.filter(
      //     (transitioning_id) => transitioning_id !== id
      //   )
      // );
    },
    [dispatch]
  );

  const deleteSelectedProjects = useCallback(
    async (selectedProjects) => {
      const ids = [...selectedProjects];
      setSelectedProjects([]);
      // setTransitioningProjects((prevTransitioningProjects) => [
      //   ...prevTransitioningProjects,
      //   ...ids,
      // ]);
      for (let i = 0; i < ids.length; i++) {
        // parallel actions put a lot of stress on the API
        await dispatch(deleteProject(ids[i]));
      }
      // setTransitioningProjects((prevTransitioningProjects) =>
      //   prevTransitioningProjects.filter((id) => !ids.includes(id))
      // );
    },
    [dispatch]
  );

  const renderName = useCallback((params) => {
    return (
      <Link component={RouterLink} to={`/project/details/${params.id}`}>
        {params.row.name}
      </Link>
    );
  }, []);

  const renderCreatedDate = useCallback((params) => {
    return toNiceTimestamp(params.row.createdAt, true);
  }, []);

  const my_projects_columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        renderCell: renderName,
      },

      {
        field: 'createdAt',
        headerName: 'Created',
        type: 'string',
        renderCell: renderCreatedDate,
        minWidth: 150,
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={() => removeProject(params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    [renderCreatedDate, renderName, removeProject]
  );

  const other_projects_columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
      },

      {
        field: 'owner_name',
        headerName: 'Owner',
        type: 'string',
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Leave'
              onClick={() => leaveProject(params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    []
  );

  if (loading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={`/dashboard`}
            >
              Home
            </Link>
            <Typography color='text.primary'>Projects</Typography>
          </Breadcrumbs>
          <br />

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={{ display: 'flex', width: '100%' }} elevation={1}>
                <Toolbar>
                  <Stack spacing={2} direction='row' alignItems='center'>
                    <Typography variant='h4'>My Projects</Typography>
                    <Button
                      color='brand'
                      onClick={() => history.push(`/project/edit`)}
                    >
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <AddCircleOutlineIcon />
                        <Typography variant='button' display='block'>
                          Create
                        </Typography>
                      </Stack>
                    </Button>

                    <Button
                      onClick={() =>
                        history.push({
                          pathname: `/project/edit`,
                          search: `?id=${selectedProjects[0]}`,
                        })
                      }
                      disabled={Boolean(selectedProjects.length !== 1)}
                    >
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <EditIcon />
                        <Typography variant='button' display='block'>
                          Edit
                        </Typography>
                      </Stack>
                    </Button>

                    <Button
                      onClick={() => {
                        deleteSelectedProjects(selectedProjects);
                      }}
                      disabled={!Boolean(selectedProjects.length)}
                    >
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <DeleteIcon />
                        <Typography variant='button' display='block'>
                          Delete
                        </Typography>
                      </Stack>
                    </Button>
                  </Stack>
                </Toolbar>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      autoHeight
                      checkboxSelection
                      getRowId={(row) => row.id}
                      columns={my_projects_columns}
                      rows={my_projects}
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectedProjects(newSelectionModel);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant='h5'>Projects Shared With Me</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      autoHeight
                      getRowId={(row) => row.id}
                      columns={other_projects_columns}
                      rows={other_projects}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
