import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  Backdrop,
  Typography,
  CircularProgress,
  Divider,
  Button,
  Breadcrumbs,
  Link,
  Box,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import theme from 'theme';
import ErrorSnackBar from 'components/shared/ErrorSnackBar';
import { getCollection, deleteCollection } from 'actions/collections';

import CollectionTree from './CollectionTree';
import toNiceTimestamp from 'util/toNiceTimestamp';
import readableBytes from 'util/readableBytes';
import round from 'util/round';
import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';
import toTitleCase from 'util/toTitleCase';

export default function Collection() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const entity_type = location.pathname.split('/')[1];
  const { id } = useParams();

  const api_error = useSelector((state) => state.errorMessage);
  const collections = useSelector((state) => state[`${entity_type}s`]);

  const [collection, setCollection] = useState(
    collections
      ? collections.find((collection) => collection.id === id)
      : undefined
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (!collection) {
        const collection_data = await getCollection(entity_type, id);
        if (!collection_data) {
          history.push(`/${entity_type}`);
        }
        setCollection(collection_data);
      }
      setLoading(false);
    })();
  }, [dispatch, history, collection, id, entity_type]);

  if (loading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={`/dashboard`}
            >
              Home
            </Link>
            <Link
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={`/${entity_type}`}
            >
              {toTitleCase(entity_type)}s
            </Link>
            <Typography color='text.primary'>{collection.name}</Typography>
          </Breadcrumbs>
          <br />
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <Typography variant='h5'>{collection.name}</Typography>
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: `/${entity_type}/edit`,
                            search: `?id=${id}`,
                          })
                        }
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <EditIcon />
                          <Typography variant='button' display='block'>
                            Edit
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(deleteCollection(entity_type, id));
                          history.push(`/${entity_type}`);
                        }}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <DeleteIcon />
                          <Typography variant='button' display='block'>
                            Delete
                          </Typography>
                        </Stack>
                      </Button>
                    </Stack>
                  </Toolbar>
                </Paper>
                <Box padding='15px'>
                  <Typography variant='h6'>Basic Information</Typography>
                  <Divider />
                  <br />
                  <Grid
                    container
                    component='dl' // mount a Definition List
                    spacing={0}
                    sx={{ display: 'table' }}
                  >
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '150px' }}
                      >
                        <b>ID:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {collection.id}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '150px' }}
                      >
                        <b>Created:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {toNiceTimestamp(collection.createdAt, true)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '150px' }}
                      >
                        <b>Source Type:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {collection.source_type}
                      </Typography>
                    </Grid>
                    {![
                      THIRD_PARTY_TYPES.EMPTY.value,
                      THIRD_PARTY_TYPES.EVEFS.value,
                      THIRD_PARTY_TYPES.TRAINML.value,
                    ].includes(collection.source_type) ? (
                      <Grid item>
                        <Typography
                          component='dt'
                          variant='body1'
                          sx={{ display: 'table-cell', width: '150px' }}
                        >
                          <b>Source URI:</b>
                        </Typography>
                        <Typography
                          component='dd'
                          variant='body2'
                          sx={{ display: 'table-cell' }}
                        >
                          {collection.source_uri}
                        </Typography>
                      </Grid>
                    ) : undefined}
                    {collection.capacity ? (
                      <Grid item>
                        <Typography
                          component='dt'
                          variant='body1'
                          sx={{ display: 'table-cell', width: '150px' }}
                        >
                          <b>Capacity:</b>
                        </Typography>
                        <Typography
                          component='dd'
                          variant='body2'
                          sx={{ display: 'table-cell' }}
                        >
                          {collection.capacity}
                        </Typography>
                      </Grid>
                    ) : undefined}
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '150px' }}
                      >
                        <b>Used:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {readableBytes(collection.used_size || 0)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '150px' }}
                      >
                        <b>Monthly Cost:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                          round(
                            (0.2 * collection.billed_size || 0) /
                              Math.pow(1024, 3),
                            4
                          )
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <br />
                <Grid item xs={12} md={12} lg={12}>
                  <Box padding='15px'>
                    <Typography variant='h6'>Contents</Typography>

                    <CollectionTree id={id} entity_type={entity_type} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
