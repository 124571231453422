import _ from 'lodash';
import { FETCH_JOBS, FETCH_JOB, CREATE_JOB, REMOVE_JOB } from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import history from '../history';
import getMessageFromApiError from '../util/getMessageFromApiError';
import STATUSES from '../components/statuses';

export const fetchJobs = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get('/job', { params: { project_uuid } });
    dispatch({
      type: FETCH_JOBS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchJob = (id) => async (dispatch) => {
  const project_uuid = store.getState().user.selected_project;
  try {
    const res = await api.get(`/job/${id}`, { params: { project_uuid } });
    if (_.isEmpty(res.data)) {
      dispatch({ type: REMOVE_JOB, payload: id });
    } else {
      dispatch({
        type: FETCH_JOB,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch({ type: REMOVE_JOB, payload: id });
    } else {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  }
};

export const submitJob = (data) => async (dispatch) => {
  try {
    if (!data.project_uuid) {
      const project_uuid = store.getState().user.selected_project;
      data.project_uuid = project_uuid;
    }
    const res = await api.post('/job', data);

    history.push(`/jobs/${res.data.type}`);
    if (res.data.project_uuid !== store.getState().user.selected_project) {
      return;
    }
    dispatch({
      type: CREATE_JOB,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateJob = (id, values) => async (dispatch) => {
  const project_uuid = store.getState().user.selected_project;
  try {
    const res = await api.patch(`/job/${id}`, values, {
      params: { project_uuid },
    });
    dispatch({
      type: FETCH_JOB,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const terminateJob = (id) => async (dispatch) => {
  const project_uuid = store.getState().user.selected_project;
  const job = store.getState().jobs.find((job) => job.job_uuid === id);
  try {
    if (
      ![STATUSES.STOPPED, STATUSES.FINISHED, STATUSES.CACHING].includes(
        job.status
      )
    ) {
      await api.delete(`/job/${id}`, { params: { force: true, project_uuid } });
      dispatch({ type: REMOVE_JOB, payload: id });
    } else {
      await api.delete(`/job/${id}`, { params: { project_uuid } });
      dispatch(fetchJob(id));
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch({ type: REMOVE_JOB, payload: id });
    } else {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  }
};

export const updateJobWorker =
  (job_uuid, job_worker_uuid, values) => async (dispatch) => {
    try {
      const project_uuid = store.getState().user.selected_project;
      await api.patch(`/job/${job_uuid}/worker/${job_worker_uuid}`, values, {
        params: { project_uuid },
      });
      dispatch(fetchJob(job_uuid));
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };
