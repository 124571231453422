import THIRD_PARTY_TYPES from './thirdPartyTypes';

const ENVIRONMENT_KEY_TYPES = [
  THIRD_PARTY_TYPES.AWS,
  THIRD_PARTY_TYPES.AZURE,
  THIRD_PARTY_TYPES.GCP,
  THIRD_PARTY_TYPES.HUGGINGFACE,
  THIRD_PARTY_TYPES.WASABI,
];

export default ENVIRONMENT_KEY_TYPES;
