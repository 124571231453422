import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Table, Icon, Button, Modal } from 'semantic-ui-react';
import api from '../../../apis/api';
import STATUSES from '../../statuses';
import { updateJobWorker } from '../../../actions/jobs';

class JobWorker extends Component {
  state = {
    downloading: false,
    error: false,
  };
  handleDownload = async () => {
    this.setState({ downloading: true, clicked: true });
    try {
      let response = await api.get(
        `/job/${this.props.job_uuid}/worker/${this.props.worker.job_worker_uuid}/logs`,
        { params: { project_uuid: this.props.user.selected_project } }
      );
      window.location.href = response.data;
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({
          error: true,
        });
      }
    }

    this.setState({ downloading: false });
  };

  renderStatus(status) {
    if (
      [
        STATUSES.NEW,
        STATUSES.STARTING,
        STATUSES.PROVISIONING,
        STATUSES.STOPPING,
        STATUSES.REMOVING,
        STATUSES.BUILDING,
        STATUSES.DOWNLOADING,
        STATUSES.CACHING,
      ].includes(status)
    ) {
      return <Icon name='circle notch' loading size='large' />;
    }
    if ([STATUSES.WAITING_DATA, STATUSES.WAITING_GPU].includes(status)) {
      return <Icon name='wait' size='large' />;
    }
    if (status === STATUSES.STOPPED) {
      return <Icon name='stop circle outline' color='grey' size='large' />;
    }
    if (status === STATUSES.FINISHED) {
      return <Icon name='check circle outline' color='grey' size='large' />;
    }
    if (status === STATUSES.RUNNING || status === STATUSES.READY) {
      return <Icon name='play circle' color='green' size='large' />;
    }
    if (status === STATUSES.FAILED) {
      return <Icon name='exclamation circle' color='red' size='large' />;
    }
  }
  render() {
    const { worker, job_uuid } = this.props;
    return (
      <Table.Row
        key={worker.job_worker_uuid}
        active={this.props.selected}
        onClick={() => this.props.handleSelect(worker.job_worker_uuid)}
      >
        <Table.Cell width={2}>
          <Header as='h3'>Worker {this.props.number}</Header>
          {this.props.transitioning ? (
            <Icon name='circle notch' loading size='large' />
          ) : (
            this.renderStatus(worker.status)
          )}
          <span>
            <b>{worker.status}</b>
          </span>
        </Table.Cell>
        <Table.Cell>{worker.command}</Table.Cell>

        <Table.Cell textAlign='right' width={2}>
          {[
            STATUSES.RUNNING,
            STATUSES.UPLOADING,
            STATUSES.WAITING_GPU,
          ].includes(worker.status) ? (
            <Button
              secondary
              basic
              content='Stop'
              icon='stop'
              onClick={async (e) => {
                e.preventDefault();
                this.setState({ stopping: true });
                await this.props.updateJobWorker(
                  job_uuid,
                  worker.job_worker_uuid,
                  {
                    command: 'stop',
                  }
                );
                this.setState({ stopping: false });
              }}
              loading={this.state.stopping}
            />
          ) : undefined}
          {worker.status === STATUSES.FINISHED ? (
            <Button
              secondary
              basic
              content='Download Logs'
              icon='cloud download'
              onClick={this.handleDownload}
              loading={this.state.downloading}
            />
          ) : undefined}
        </Table.Cell>

        <Modal
          key='Logs Not Available'
          open={this.state.error}
          closeOnDimmerClick
          closeOnEscape
          onClose={() => this.setState({ error: false })}
        >
          <Modal.Header>Logs Not Available</Modal.Header>
          <Modal.Content>
            <p>
              Worker {this.props.number}'s log extract is not currently
              available. If the worker recently finished, the log extract may
              still be generating. If the worker finished more than 7 days ago,
              the log extract may have been purged.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='checkmark'
              primary
              labelPosition='right'
              onClick={() => this.setState({ error: false })}
              content='Okay'
            />
          </Modal.Actions>
        </Modal>
        <Modal
          key='JobWorkerModal'
          open={this.state.canceling}
          onClose={() =>
            this.setState({ canceling: false, cancelingLoading: false })
          }
        >
          <Modal.Header>Cancel Upload</Modal.Header>
          <Modal.Content>
            If you cancel a worker's upload, the worker's output data will be
            permanently deleted. Are you sure you want to cancel?
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.closeCancelModal}>
              No
            </Button>
            <Button
              primary
              loading={this.state.cancelingLoading}
              onClick={async () => {
                this.setState({ cancelingLoading: true });
                const workers = this.state.selectedWorkers;
                await this.stopWorkers(workers);
                this.setState({ canceling: false, cancelingLoading: false });
              }}
              content='Yes'
            />
          </Modal.Actions>
        </Modal>
      </Table.Row>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps, {
  updateJobWorker,
})(JobWorker);
