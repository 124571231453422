import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Toolbar,
  Stack,
  Box,
  Tooltip,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';

import { createCollection } from '../../../actions/collections';
import CollectionNameField from '../CollectionForm/CollectionNameField';
import CollectionProjectField from './CollectionProjectField';
import CollectionCapacityField from '../CollectionForm/CollectionCapacityField';
import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';
import toTitleCase from '../../../util/toTitleCase';

export default function CollectionCopyModal({
  entity_type,
  open,
  handleClose,
  collection,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(collection ? `${collection.name} copy` : '');
  const [name_error, setNameError] = useState(false);
  const [capacity, setCapacity] = useState(collection?.capacity || '');
  const [capacity_error, setCapacityError] = useState(false);
  const user = useSelector((state) => state.user);
  const [project_uuid, setProjectUuid] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        name,
        capacity: capacity ? `${capacity}G` : undefined,
        source_type: THIRD_PARTY_TYPES.EVEFS.value,
        source_uri: collection.id,
        source_options: {
          type: entity_type,
          project_uuid: Boolean(project_uuid)
            ? user.selected_project
            : undefined,
        },
      };
      if (Boolean(project_uuid)) {
        payload.project_uuid = project_uuid;
      }
      await dispatch(createCollection(entity_type, payload));
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  if (!collection) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle></DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  const not_enough_credits = Boolean(
    !user.cloudbender_enabled &&
      user.credits <= 0 &&
      !user.billing_account_id &&
      user.id === user.selected_project
  );

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            Copy {toTitleCase(entity_type)} {collection.name}
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 400,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <CollectionNameField
                value={name}
                error={name_error}
                setValue={setName}
                setError={setNameError}
                setChanged={() => {}}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CollectionCapacityField
                value={capacity}
                error={capacity_error}
                setValue={setCapacity}
                setError={setCapacityError}
                setChanged={() => {}}
                minimum_capacity={parseInt(
                  (collection?.capacity || '').replace(/\D/g, '')
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CollectionProjectField
                value={project_uuid}
                setValue={setProjectUuid}
                setChanged={() => {}}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <Tooltip title={not_enough_credits ? 'Not Enough Credits' : ''}>
              <span>
                <LoadingButton
                  variant='contained'
                  color='brand'
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={
                    not_enough_credits ||
                    Boolean(name_error) ||
                    !Boolean(name) ||
                    Boolean(capacity_error) ||
                    !Boolean(capacity)
                  }
                >
                  Submit
                </LoadingButton>
              </span>
            </Tooltip>

            <Button variant='outlined' onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </DialogActions>
    </Dialog>
  );
}
