import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDeviceConfig } from '../../../../../../actions/deviceConfigs';

export default function DeviceConfigsModal({ open, handleClose, region }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const device_configs = useSelector((state) => state.deviceConfigs);

  if (!region) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle></DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            Region {region.name} Device Configurations
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', maxWidth: 360 }}>
          <List component='div' role='group'>
            {device_configs
              ? device_configs.map((device_config) => {
                  return (
                    <ListItem
                      key={device_config.config_id}
                      divider
                      secondaryAction={
                        <IconButton
                          onClick={() => {
                            dispatch(
                              deleteDeviceConfig(
                                region.provider_uuid,
                                region.region_uuid,
                                device_config.config_id
                              )
                            );
                            handleClose();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => {
                          handleClose();
                          history.push({
                            pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/device/config`,
                            search: `?config_id=${device_config.config_id}`,
                          });
                        }}
                      >
                        <ListItemText primary={device_config.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })
              : undefined}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            history.push({
              pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/device/config`,
            });
          }}
        >
          Add New
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
