export default function getMessageFromApiError(error) {
  if (error.response && error.response.data) {
    if (error.response.data.errorMessage) {
      // Lambda error
      if (/^\[[0-9]{3}\]*/.test(error.response.data.errorMessage)) {
        return error.response.data.errorMessage.replace(/^\[[0-9]{3}\]\s/, '');
      } else {
        return error.response.data.errorMessage;
      }
    } else if (error.response.data.message) {
      // API Gateway Response error
      if (error.response.data.validation) {
        // Body Schema validation error
        return `${error.response.data.message} - ${error.response.data.validation}`;
      } else {
        return error.response.data.message;
      }
    }
  }
  return 'An unknown error occurred';
}
