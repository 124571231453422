import { FETCH_PROJECT_SERVICES } from '../actions/types';

const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_SERVICES:
      return action.payload;
    default:
      return state;
  }
}
export default reducer;
