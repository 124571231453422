import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Segment, Message, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { fetchGpuClasses } from '../../../actions/gpuClasses';
import GpuCountField from './GpuCountField';
import GpuTypeField from './GpuTypeField';

const cpu_gpu_type_id = '78201a90-ca48-4147-b6f5-fec51b4bd7db';

class RequiredFields extends Component {
  state = {
    expanded: !this.props.edit,
    disk_size_error: false,
    job_name_error: false,
    max_price_error: false,
    cpu_only: this.props.initialValues
      ? this.props.initialValues.gpu_types[0] === cpu_gpu_type_id
      : false,
    values: this.props.initialValues || {
      job_name: '',
      gpu_types: [],
      gpu_count: 1,
      cpu_count: 4,
      disk_size: 10,
      max_price: 10,
      preemptible: false,
    },
  };

  disk_min = this.props.disk_min || 10;
  disk_max = this.props.disk_max || 1000;

  componentDidMount() {
    this.props.fetchGpuClasses();
    this.props.onChange(
      'resources',
      this.state.values,
      this.state.disk_size_error ||
        this.state.job_name_error ||
        this.state.max_price_error ||
        this.state.values.gpu_types.length === 0 ||
        this.state.values.job_name === ''
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevState, this.state)) {
      return;
    }

    this.props.onChange(
      'resources',
      {
        ...this.state.values,
        max_price: parseFloat(this.state.values.max_price),
      },
      this.state.disk_size_error ||
        this.state.job_name_error ||
        this.state.max_price_error ||
        this.state.values.gpu_types.length === 0 ||
        this.state.values.job_name === ''
    );
  }

  handleChange = (e, { name, value }) => {
    switch (name) {
      case 'job_name':
        this.setState({
          job_name_error:
            value === '' || /[#$<>+%!`&*'"|{}?/\\:=@]/.test(value),
          values: { ...this.state.values, [name]: value },
        });
        break;
      case 'disk_size':
        this.setState({
          disk_size_error:
            !(value >>> 0 === parseFloat(value)) ||
            value < this.disk_min ||
            value > this.disk_max,
          values: {
            ...this.state.values,
            [name]: isNaN(parseInt(value)) ? '' : parseInt(value),
          },
        });
        break;
      case 'max_price':
        this.setState({
          max_price_error: parseFloat(value) < 0.05 || parseFloat(value) > 1000,
          values: { ...this.state.values, [name]: value },
        });
        break;
      case 'gpu_count':
        let min_cpu_count = 4;

        this.state.values.gpu_types.forEach((gpu_type_id) => {
          const selected_gpu_class = this.props.gpuClasses.find(
            (gpu_class) => gpu_class.id === gpu_type_id
          );
          min_cpu_count = Math.max(selected_gpu_class.cpus, min_cpu_count);
        });
        this.setState({
          values: {
            ...this.state.values,
            gpu_count: value,
            cpu_count: min_cpu_count * value,
          },
        });
        break;
      default:
        this.setState({
          values: { ...this.state.values, [name]: value },
        });
    }
  };

  handleGpuSelection = (gpu_type_id) => {
    let gpu_types;
    if (typeof gpu_type_id === 'object') {
      // changing between cpu and gpu is disabled on edit form
      gpu_types = [...gpu_type_id];
    } else {
      if (gpu_type_id === cpu_gpu_type_id) {
        gpu_types = [cpu_gpu_type_id];
        this.setState({
          cpu_only: true,
          values: {
            ...this.state.values,
            gpu_types,
            cpu_count: 4,
            gpu_count: 0,
          },
        });
        return;
      } else if (this.state.values.gpu_types[0] === cpu_gpu_type_id) {
        gpu_types = [gpu_type_id];
      } else {
        gpu_types = [...this.state.values.gpu_types];
        if (this.state.values.gpu_types.includes(gpu_type_id)) {
          _.remove(gpu_types, (gpu_type) => gpu_type === gpu_type_id);
        } else {
          gpu_types.push(gpu_type_id);
        }
      }
    }

    let min_cpu_count = 4,
      max_per_worker = 16;
    gpu_types.forEach((gpu_type_id) => {
      const selected_gpu_class = this.props.gpuClasses.find(
        (gpu_class) => gpu_class.id === gpu_type_id
      );
      min_cpu_count = Math.max(selected_gpu_class.cpus, min_cpu_count);
      max_per_worker = Math.min(
        selected_gpu_class.max_per_worker,
        max_per_worker
      );
    });

    this.setState({
      cpu_only: false,
      values: {
        ...this.state.values,
        gpu_types,
        cpu_count:
          min_cpu_count *
          (Math.min(this.state.values.gpu_count, max_per_worker) || 1),
        gpu_count: Math.min(this.state.values.gpu_count, max_per_worker) || 1,
      },
    });
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    const { type, edit, small } = this.props;
    const {
      expanded,
      values,
      job_name_error,
      disk_size_error,
      max_price_error,
      cpu_only,
    } = this.state;
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon name={expanded ? 'triangle down' : 'triangle right'} />
            Resources
          </Message.Header>
        </Message>
        {expanded ? (
          <Segment attached>
            <Form.Input
              name='job_name'
              label='Job Name'
              value={values.job_name}
              required
              onChange={this.handleChange}
              disabled={edit}
              error={
                job_name_error
                  ? {
                      content:
                        'Job names cannot be blank and cannot include the following characters: #$<>+%!`&*\'"|{}?/\\:=@',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
            <GpuTypeField
              onClick={this.handleGpuSelection}
              gpu_types={values.gpu_types}
              cards={!edit && !small}
              edit={edit}
            />
            <br />
            <Form.Group widths='equal'>
              {cpu_only ? (
                <Form.Dropdown
                  name='cpu_count'
                  label={type === 'training' ? 'CPUs Per Worker' : 'CPU Count'}
                  value={values.cpu_count}
                  options={[
                    {
                      key: 4,
                      value: 4,
                      text: '4',
                    },
                    {
                      key: 8,
                      value: 8,
                      text: '8',
                    },
                    {
                      key: 12,
                      value: 12,
                      text: '12',
                    },
                    {
                      key: 16,
                      value: 16,
                      text: '16',
                    },
                  ]}
                  selection
                  fluid
                  onChange={this.handleChange}
                />
              ) : (
                <GpuCountField
                  onChange={this.handleChange}
                  gpu_types={values.gpu_types}
                  gpu_count={values.gpu_count}
                  type={type}
                />
              )}

              <Form.Input
                name='max_price'
                label='Maximum Price per GPU (credits/hr)'
                value={values.max_price}
                onChange={this.handleChange}
                disabled={edit}
                error={
                  max_price_error
                    ? {
                        content: `Please enter decimal between 0.05 and 1000`,
                        pointing: 'above',
                      }
                    : undefined
                }
              />
              <Form.Input
                name='disk_size'
                label='Disk Size (GB)'
                value={values.disk_size}
                onChange={this.handleChange}
                disabled={edit}
                error={
                  disk_size_error
                    ? {
                        content: `Please enter an integer between ${this.disk_min} and ${this.disk_max}`,
                        pointing: 'above',
                      }
                    : undefined
                }
              />
            </Form.Group>
          </Segment>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ gpuClasses }) {
  return { gpuClasses };
}

export default connect(mapStateToProps, {
  fetchGpuClasses,
})(RequiredFields);
