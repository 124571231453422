import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Dropdown,
  Container,
  Button,
  Table,
  Pagination,
  Header,
  Segment,
  Loader,
  Grid,
  Message,
} from 'semantic-ui-react';

import { fetchGpuClasses } from '../../../actions/gpuClasses';
import {
  fetchArchivedJobs,
  clearArchivedJobs,
} from '../../../actions/archivedJobs';
import ArchivedJob from './ArchivedJob';

class ArchivedJobs extends Component {
  state = {
    sortBy: 'name',
    displayOrder: true,
    displayCount: 20,
    totalPages: 1,
    currentPage: 1,
    mounted: false,
  };

  update_last_keys = (last_key_uuid, last_key_sort) => {
    this.setState({
      last_key_uuid,
      last_key_sort,
    });
  };

  async componentDidMount() {
    await Promise.all([
      this.props.fetchArchivedJobs(
        this.state.sortBy,
        this.state.displayCount,
        this.state.displayOrder,
        this.state.last_key_uuid,
        this.state.last_key_sort,
        this.update_last_keys
      ),
      this.props.fetchGpuClasses(),
    ]);
    this.setState({
      currentJobs: this.props.archivedJobs.slice(0, this.state.displayCount),
      mounted: true,
    });
  }
  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.totalPages !==
      Math.ceil((this.props.archivedJobs.length || 1) / this.state.displayCount)
    ) {
      this.setState({
        totalPages: Math.ceil(
          (this.props.archivedJobs.length || 1) / this.state.displayCount
        ),
      });
    }
  }

  sortByOptions = [
    {
      key: 'Name',
      text: 'Name',
      value: 'name',
    },
    {
      key: 'Start time',
      text: 'Start time',
      value: 'start',
    },
    {
      key: 'End time',
      text: 'End time',
      value: 'end',
    },
  ];
  displayOrderOptions = [
    {
      key: 'Ascending',
      text: 'Ascending',
      value: true,
    },
    {
      key: 'Descending',
      text: 'Descending',
      value: false,
    },
  ];
  displayCountOptions = [
    {
      key: '20',
      text: '20',
      value: 20,
    },
    {
      key: '50',
      text: '50',
      value: 50,
    },
    {
      key: '100',
      text: '100',
      value: 100,
    },
  ];

  handleChange = async (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = async () => {
    this.props.clearArchivedJobs();
    await this.props.fetchArchivedJobs(
      this.state.sortBy,
      this.state.displayCount,
      this.state.displayOrder,
      undefined,
      undefined,
      this.update_last_keys
    );
  };

  handlePageChange = async (e, { name, value, activePage }) => {
    this.setState({
      currentPage: activePage,
    });
    if (
      this.state.currentPage === this.state.totalPages - 1 &&
      this.state.last_key_uuid &&
      this.state.last_key_sort
    ) {
      this.props.fetchArchivedJobs(
        this.state.sortBy,
        this.state.displayCount,
        this.state.displayOrder,
        this.state.last_key_uuid,
        this.state.last_key_sort,
        this.update_last_keys
      );
    }
  };

  getGpuClassName = (gpu_type_id) => {
    if (this.props.gpuClasses) {
      const gpu_class = this.props.gpuClasses.find(
        (gpu_class) => gpu_class.id === gpu_type_id
      );
      if (gpu_class) {
        return gpu_class.name;
      }
    }
  };

  renderNoJobs = () => {
    return (
      <Message info>
        <Message.Header>You have no archived jobs</Message.Header>
      </Message>
    );
  };

  render() {
    return (
      <Grid>
        <Grid.Row width={16}>
          <Grid.Column>
            <Container>
              <Header as='h1'>Archived Jobs</Header>
              <Menu>
                <Menu.Item>
                  <Dropdown
                    placeholder='Sort By'
                    selection
                    name='sortBy'
                    onChange={this.handleChange}
                    options={this.sortByOptions}
                  />
                  <Dropdown
                    placeholder='Display Order'
                    name='displayOrder'
                    selection
                    onChange={this.handleChange}
                    options={this.displayOrderOptions}
                  />
                  <Dropdown
                    placeholder='Display Count'
                    name='displayCount'
                    selection
                    onChange={this.handleChange}
                    options={this.displayCountOptions}
                  />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button onClick={this.handleSubmit}>Search</Button>
                </Menu.Item>
              </Menu>
              {this.state.mounted ? (
                this.state.currentJobs.length ? (
                  <>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>GPU Type</Table.HeaderCell>
                          <Table.HeaderCell>GPU Count</Table.HeaderCell>
                          <Table.HeaderCell>Credits</Table.HeaderCell>
                          <Table.HeaderCell>Start Time</Table.HeaderCell>
                          <Table.HeaderCell>End Time</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.props.archivedJobs
                          .slice(
                            (this.state.currentPage - 1) *
                              this.state.displayCount,
                            this.state.currentPage * this.state.displayCount
                          )
                          .map((job) => {
                            return (
                              <ArchivedJob
                                key={job.job_uuid}
                                job={job}
                                gpu_type_name={this.getGpuClassName(
                                  job.resources.gpu_type_id
                                )}
                              />
                            );
                          })}
                      </Table.Body>
                    </Table>
                    <Pagination
                      onPageChange={this.handlePageChange}
                      name='pagination'
                      activePage={this.state.currentPage}
                      floated='right'
                      totalPages={this.state.totalPages}
                    />
                  </>
                ) : (
                  <>{this.renderNoJobs()}</>
                )
              ) : (
                <Segment>
                  <Loader active />
                </Segment>
              )}
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps({ gpuClasses, archivedJobs }) {
  return { gpuClasses, archivedJobs };
}

export default connect(mapStateToProps, {
  fetchGpuClasses,
  fetchArchivedJobs,
  clearArchivedJobs,
})(ArchivedJobs);
