import _ from 'lodash';
import {
  FETCH_PROJECTS,
  FETCH_PROJECT,
  CREATE_PROJECT,
  REMOVE_PROJECT,
  SET_USER,
} from './types';
import api from '../apis/api';
import store from '../store';
import { fetchModels } from './models';
import { fetchJobs } from './jobs';
import { fetchDatasets } from './datasets';
import { fetchGpuClasses } from './gpuClasses';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';
import { fetchCheckpoints } from './checkpoints';
import { fetchVolumes } from './volumes';

export const fetchProjects = () => async (dispatch) => {
  try {
    const res = await api.get('/project');
    dispatch({
      type: FETCH_PROJECTS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    if (message !== 'The incoming token has expired') {
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  }
};

export const fetchProject = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/project/${id}`);
    if (_.isEmpty(res.data)) {
      dispatch({ type: REMOVE_PROJECT, payload: id });
    } else {
      dispatch({
        type: FETCH_PROJECT,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch({ type: REMOVE_PROJECT, payload: id });
    } else {
      const message = getMessageFromApiError(error);
      console.log(message);
      if (message !== 'The incoming token has expired') {
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  }
};

export const updateProject = (id, values) => async (dispatch) => {
  try {
    const res = await api.patch(`/project/${id} `, values);
    dispatch({
      type: FETCH_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const refreshProjectGpuTypes = (id) => async (dispatch) => {
  try {
    await api.patch(`/project/${id}/gputypes`);
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteProject = (id) => async (dispatch) => {
  try {
    const user = store.getState().user;
    if (id === user.selected_project) {
      dispatch(setSelectedProject(user.id));
    }
    await api.delete(`/project/${id}`);
    dispatch({
      type: REMOVE_PROJECT,
      payload: id,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const leaveProject = (id) => async (dispatch) => {
  try {
    const user = store.getState().user;
    if (id === user.selected_project) {
      dispatch(setSelectedProject(user.id));
    }
    await api.delete(`/project/${id}/access`, { params: { leave: true } });
    dispatch({
      type: REMOVE_PROJECT,
      payload: id,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createProject = (values) => async (dispatch) => {
  try {
    const res = await api.post('/project', values);
    dispatch({
      type: CREATE_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const setSelectedProject = (selected_project) => async (dispatch) => {
  try {
    if (selected_project !== store.getState().user.selected_project) {
      try {
        const res = await api.patch('/customer', { selected_project });
        dispatch({
          type: SET_USER,
          payload: res.data,
        });
        dispatch(fetchGpuClasses());
        dispatch(fetchJobs());
        dispatch(fetchModels());
        dispatch(fetchDatasets());
        dispatch(fetchCheckpoints());
        dispatch(fetchVolumes());
      } catch (error) {
        console.log(error.res);
      }
    }
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const getProject = async (id) => {
  try {
    const res = await api.get(`/project/${id}`);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    console.log(message);
    throw new Error(`API Error - ${message}`);
  }
};
