import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  TableBody,
  Table,
  Box,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CopyableRow from './CopyableRow';

export default function TelemetryInfo({ open, handleClose, region }) {
  if (region && region.telemetry) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Region {region.name} Telemetry Information
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box padding='10px'>
            <Table>
              <TableBody>
                <CopyableRow label='URL' value={region.telemetry.url} />
                <CopyableRow
                  label='Organization'
                  value={region.telemetry.org}
                />
                <CopyableRow
                  label='Bucket Name'
                  value={region.telemetry.bucket_name}
                />
                <CopyableRow
                  label='Bucket ID'
                  value={region.telemetry.bucket_id}
                />
                <CopyableRow label='Token' value={region.telemetry.token} />
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              Region {region.name} Telemetry Information
            </Grid>
            <Grid item xs={1} sx={{ maxWidth: '25px' }}>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box padding='10px'>
            <Typography>Telemetry information not available</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}
