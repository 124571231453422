import { MenuItem } from '@mui/material';

import INSTANCE_CLASSES from '../../../instanceClasses';
import GCP_RESOURCE_TYPES from './gcpResourceTypes';

const RESOURCE_TYPE_MAPPING = {
  [GCP_RESOURCE_TYPES.V100]: {
    label: 'V100',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(2)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(8)')}
      </MenuItem>,
    ],
  },
  [GCP_RESOURCE_TYPES.P100]: {
    label: 'P100',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(2)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
    ],
  },
  [GCP_RESOURCE_TYPES.T4]: {
    label: 'T4',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(2)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
    ],
  },
  [GCP_RESOURCE_TYPES.A100]: {
    label: 'A100',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(2)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(8)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.XLARGE.value}>
        {INSTANCE_CLASSES.XLARGE.label('(16)')}
      </MenuItem>,
    ],
  },
  [GCP_RESOURCE_TYPES.A100_80GB]: {
    label: 'A100 (80GB)',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(1)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(2)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(4)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(8)')}
      </MenuItem>,
    ],
  },
  [GCP_RESOURCE_TYPES.CPU]: {
    label: 'CPU Only',
    instance_classes: [
      <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
        {INSTANCE_CLASSES.MICRO.label('(8)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
        {INSTANCE_CLASSES.SMALL.label('(16)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
        {INSTANCE_CLASSES.MEDIUM.label('(30)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(90)')}
      </MenuItem>,
      <MenuItem value={INSTANCE_CLASSES.XLARGE.value}>
        {INSTANCE_CLASSES.LARGE.label('(180)')}
      </MenuItem>,
    ],
  },
};
export default RESOURCE_TYPE_MAPPING;
