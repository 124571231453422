import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Stack,
  Icon,
  Tooltip,
} from '@mui/material';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import KeyIdField from './KeyIdField';
import SecretField from './SecretField';
import { CheckCircleOutline } from '@mui/icons-material';
import toTitleCase from 'util/toTitleCase';

export default function AWSCredential({
  type,
  credential,
  putCredential,
  removeCredential,
  icon,
}) {
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [key_id, setKeyId] = useState(credential?.key_id || '');
  const [key_id_error, setKeyIdError] = useState(false);
  const [secret, setSecret] = useState(credential?.secret || '');
  const [secret_error, setSecretError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        type: credential?.type || type,
        key_id,
        secret,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      await putCredential(payload);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setPristine(true);
  };

  const handleRemove = async () => {
    if (!credential) {
      return;
    }
    setLoading(true);

    try {
      await removeCredential(credential?.type || type);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  const submit_disabled =
    pristine ||
    key_id_error ||
    secret_error ||
    !Boolean(key_id) ||
    !Boolean(secret);

  return (
    <Stack
      paddingBottom='10px'
      direction={'row'}
      spacing={1}
      alignItems={'center'}
    >
      <Tooltip title={`${toTitleCase(type)} Credential`}>
        <Icon fontSize='large' sx={{ alignContent: 'center' }}>
          <img
            src={icon}
            width={'25px'}
            height={'25px'}
            alt={`${toTitleCase(type)} Credential`}
          />
        </Icon>
      </Tooltip>
      <KeyIdField
        value={key_id}
        setValue={setKeyId}
        setChanged={() => setPristine(false)}
        error={key_id_error}
        setError={setKeyIdError}
      />
      <SecretField
        value={secret}
        setValue={setSecret}
        setChanged={() => setPristine(false)}
        error={secret_error}
        setError={setSecretError}
      />
      <IconButton
        onClick={handleSubmit}
        disabled={submit_disabled}
        size='large'
      >
        {loading ? (
          <CircularProgress size={25} />
        ) : (
          <CheckCircleOutline color={submit_disabled ? 'inherit' : 'brand'} />
        )}
      </IconButton>
      <IconButton onClick={handleRemove} size='large'>
        {loading ? <CircularProgress size={25} /> : <DeleteOutlinedIcon />}
      </IconButton>
    </Stack>
  );
}
