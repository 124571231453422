import React, { useState } from 'react';
import {
  IconButton,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from '@mui/material';

import CopyIcon from '@mui/icons-material/ContentCopy';

export default function CopyableRow({ label, value }) {
  const [tooltip, setTooltip] = useState('Copy');
  const setCopied = () => setTooltip('Copied!');
  const setCopyError = () => setTooltip('Copy Error!');

  const copyText = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value).then(setCopied, setCopyError);
    } else {
      setCopyError();
    }
  };

  return (
    <TableRow>
      <TableCell sx={{ maxWidth: '100px' }}>
        <Typography variant='body' gutterBottom>
          {label}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '400px', wordWrap: 'break-word' }}>
        <Typography variant='body' gutterBottom>
          {value}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '25px' }}>
        <Tooltip title={tooltip}>
          <IconButton aria-label='close' onClick={copyText}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
