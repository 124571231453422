const STATUSES = {
  NEW: 'new',
  WAITING_INSTANCE: 'waiting for instance initialization',
  WAITING_DATA: 'waiting for data/model download',
  WAITING_GPU: 'waiting for resources',
  PROVISIONING: 'provisioning',
  DOWNLOADING: 'downloading',
  BUILDING: 'building',
  READY: 'ready',
  STARTING: 'starting',
  RUNNING: 'running',
  STOPPING: 'stopping',
  UPLOADING: 'uploading',
  STOPPED: 'stopped',
  REMOVING: 'removing',
  REMOVED: 'removed',
  ARCHIVED: 'archived',
  FAILED: 'failed',
  ABORTED: 'aborted',
  CACHING: 'caching',
  RESTORING: 'restoring',
  WAITING_RESTORE: 'reinitializing',
  SAVING: 'saving',
  STORED: 'stored',
  UPDATING: 'updating',
  COPYING: 'copying',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  ERRORED: 'errored',
  ACTIVE: 'active',
  OFFLINE: 'offline',
  HEALTHY: 'healthy',
  DEGRADED: 'degraded',
  MAINTENANCE: 'maintenance',
  COMPRESSING: 'compressing',
  EXPORTING: 'exporting',
  UNKNOWN: 'unknown',
  PENDING: 'pending',
  RENEWING: 'renewing',
  EXPIRED: 'expired',
};

export default STATUSES;
