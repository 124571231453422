import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Grid, Header, Container, Button } from 'semantic-ui-react';

import { cancelBillingLink } from '../../actions/user';

class Payments extends Component {
  state = {
    loading: false,
  };
  deactivateConsolidatedBilling = async () => {
    this.setState({ loading: true });
    await this.props.cancelBillingLink();
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Container>
              <Segment textAlign='center'>
                <Header as='h2'>Your Account Uses Consolidated Billing</Header>
                <Header as='h4'>Your account is linked to:</Header>
                <Header as='h3'>{this.props.user.billing_account_email}</Header>
                <br />
                <p>
                  If you would like to deactivate consolidated billing, click
                  the Deactivate button below.
                </p>
                <p>
                  This will cause all jobs to stop and your datasets and models
                  to be removed if you do not purchase credits after unlinking.
                </p>
                <Button
                  content='Deactivate'
                  negative
                  onClick={this.deactivateConsolidatedBilling}
                  loading={this.state.loading}
                />
              </Segment>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  cancelBillingLink,
})(Payments);
