import React from 'react';

import UploadButton from './UploadButton';

export default function UploadFile(props) {
  const handleChange = async (e) => {
    const filename = e.target.files[0].name;

    const file = await new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsText(e.target.files[0]);
    });

    props.setValue({ filename, contents: file });
    e.target.value = '';
  };

  return (
    <UploadButton
      name={props.name}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );
}
