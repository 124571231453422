import _ from 'lodash';
import {
  FETCH_CHECKPOINTS,
  UPDATE_CHECKPOINT,
  CREATE_CHECKPOINT,
  DELETE_CHECKPOINT,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHECKPOINTS:
      newState = action.payload.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case UPDATE_CHECKPOINT:
      newState = [...(state || [])];
      _.remove(newState, (checkpoint) => {
        return checkpoint.checkpoint_uuid === action.payload.checkpoint_uuid;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case CREATE_CHECKPOINT:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case DELETE_CHECKPOINT:
      newState = [...(state || [])];
      _.remove(newState, (checkpoint) => {
        return checkpoint.checkpoint_uuid === action.payload;
      });
      newState = newState.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    default:
      return state;
  }
}

export default reducer;
