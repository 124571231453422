import { FETCH_PUBLIC_DATASETS } from '../actions/types';

const initialState = [];
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PUBLIC_DATASETS:
      newState = action.payload.sort((a, b) => {
        const catA = a.category;
        const catB = b.category;
        if (catA < catB) {
          return 1;
        } else if (catA > catB) {
          return -1;
        } else {
          const nameA = a.name;
          const nameB = b.name;
          if (nameA < nameB) {
            return 1;
          } else if (nameA > nameB) {
            return -1;
          } else return 0;
        }
      });
      return newState;

    default:
      return state;
  }
}

export default reducer;
