import React from 'react';
import { Segment, Grid, Header } from 'semantic-ui-react';

const GpuClass = (props) => {
  const { name, price, performance } = props.details;

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} verticalAlign='middle'>
            <Header as='h2'>{name}</Header>
          </Grid.Column>
          <Grid.Column width={8} verticalAlign='middle'>
            <Header as='h5'>Performance</Header>
            <Grid columns='equal'>
              <Grid.Column>
                <p>
                  Single Prec (FP32): <b>{performance.fp32_rate} TFLOPS</b>
                </p>
                <p>
                  Half Prec (FP16): <b>{performance.fp16_rate} TFLOPS</b>
                </p>
              </Grid.Column>
              <Grid.Column>
                <p>
                  Double Prec (FP64): <b>{performance.fp64_rate} TFLOPS</b>
                </p>
                <p>
                  Integer (INT8): <b>{performance.int8_rate} TOPS</b>
                </p>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign='middle'>
            <Header as='h4'>Memory</Header>
            <p>
              Size: <b>{performance.memory_size} GB</b>
            </p>
            <p>
              Bandwidth: <b>{performance.memory_bandwidth} GB/S</b>
            </p>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign='middle'>
            <Header as='h4'>Pricing</Header>
            {}
            <p>
              Credits Per Hour: <b>{price.max}</b>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
export default GpuClass;
