import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  DialogActions,
  Radio,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { updateRig } from '../../../../../actions/rigs';
import { LoadingButton } from '@mui/lab';

export default function DeviceConfigsPicker({
  open,
  handleClose,
  region,
  rig,
}) {
  const dispatch = useDispatch();
  const device_configs = useSelector((state) => state.deviceConfigs);
  const [loading, setLoading] = useState(false);
  const [device_config_id, setDeviceConfig] = useState(
    rig ? rig.device_config_id : ''
  );

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(
      updateRig(region.provider_uuid, region.region_uuid, rig.rig_uuid, {
        device_config_id,
      })
    );
    setLoading(false);
    handleClose();
  };

  if (!region || !rig) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle></DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            Set {rig.friendly_name} Device Configuration
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <RadioGroup
            name='device_config_id'
            value={device_config_id}
            onChange={(event) => setDeviceConfig(event.target.value)}
          >
            {device_configs.map((device_config) => (
              <FormControlLabel
                value={device_config.config_id}
                key={device_config.config_id}
                control={<Radio />}
                label={device_config.name}
              />
            ))}
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant='contained'
          color='brand'
          onClick={handleSubmit}
        >
          Select
        </LoadingButton>
        <Button disabled={loading} variant='outlined' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
