const INSTANCE_CLASSES = {
  MICRO: {
    value: 'micro',
    label: (suffix) => `Micro ${suffix}`,
  },
  SMALL: {
    value: 'small',
    label: (suffix) => `Small ${suffix}`,
  },
  MEDIUM: {
    value: 'medium',
    label: (suffix) => `Medium ${suffix}`,
  },
  LARGE: {
    value: 'large',
    label: (suffix) => `Large ${suffix}`,
  },
  XLARGE: {
    value: 'xlarge',
    label: (suffix) => `X-Large ${suffix}`,
  },
};
export default INSTANCE_CLASSES;
