import {
  FETCH_PAYMENT_METHODS,
  CLEAR_PAYMENT_METHODS,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
} from './types';
import api from '../apis/api';
import { fetchUser } from './user';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchPaymentMethods = () => async (dispatch) => {
  try {
    const res = await api.get('/customer/payment/method');
    dispatch({
      type: FETCH_PAYMENT_METHODS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createPaymentMethod = (id) => async (dispatch) => {
  try {
    const res = await api.post('/customer/payment/method', { id });
    dispatch({
      type: CREATE_PAYMENT_METHOD,
      payload: res.data,
    });
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updatePaymentMethod = (id, values) => async (dispatch) => {
  try {
    const res = await api.post(`/customer/payment/method/${id}`, values);
    dispatch({
      type: UPDATE_PAYMENT_METHOD,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const clearPaymentMethods = () => ({
  type: CLEAR_PAYMENT_METHODS,
});

export const removePaymentMethod = (id) => async (dispatch) => {
  try {
    await api.delete(`/customer/payment/method/${id}`);
    dispatch({
      type: REMOVE_PAYMENT_METHOD,
      payload: id,
    });
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const setPrimaryPaymentMethod = (id) => async (dispatch) => {
  try {
    const res = await api.patch(`/customer/payment/method/${id}`, {
      invoice_settings: { default_payment_method: id },
    });
    dispatch({
      type: UPDATE_PAYMENT_METHOD,
      payload: res.data,
    });
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
