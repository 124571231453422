import React from 'react';

import { Menu, MenuItem, Box, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export default function ActionsButton({
  size = 'large',
  items,
  ...otherProps
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <IconButton
        id='actions-button'
        aria-controls='actions-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label='actions'
        size={size}
        {...otherProps}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
      >
        {items.map(({ onClick, content, ...rest }) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                onClick();
              }}
              {...rest}
            >
              {content}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
