import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Typography,
  Toolbar,
  Stack,
  IconButton,
  Paper,
  Grid,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { fetchRigs } from '../../../actions/rigs';
import { fetchActiveRegion } from '../../../actions/regions';

import theme from '../../../theme';
import Nodes from './Nodes';
import Datastores from './Datastores';
import { fetchDatastores } from '../../../actions/datastores';
import { fetchGpuClasses } from '../../../actions/gpuClasses';
import { fetchServices } from '../../../actions/services';
import Services from './Services';
import { fetchDeviceConfigs } from '../../../actions/deviceConfigs';
import { clearErrorMessage } from '../../../actions/errorMessage';
import { fetchActiveProvider } from '../../../actions/providers';
import LoadingMask from '../../shared/LoadingMask';
import { fetchDataConnectors } from '../../../actions/dataConnectors';
import DataConnectors from './DataConnectors';
import ErrorSnackBar from '../../shared/ErrorSnackBar';

export default function RegionDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { provider_uuid, region_uuid } = useParams();

  const api_error = useSelector((state) => state.errorMessage);
  const provider = useSelector((state) => state.provider);
  const region = useSelector((state) => state.region);
  const rigs = useSelector((state) => state.rigs);
  const datastores = useSelector((state) => state.datastores);
  const data_connectors = useSelector((state) => state.dataConnectors);
  const services = useSelector((state) => state.services);
  const gpu_types = useSelector((state) => state.gpuClasses);
  const device_configs = useSelector((state) => state.deviceConfigs);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!provider) {
      dispatch(fetchActiveProvider(provider_uuid));
    } else if (!region) {
      dispatch(fetchActiveRegion(provider_uuid, region_uuid));
    }
  }, [dispatch, provider, region, provider_uuid, region_uuid]);

  useEffect(() => {
    (async () => {
      if (region) {
        if (
          datastores === null ||
          (datastores.length > 0 &&
            datastores[0].region_uuid !== region.region_uuid)
        ) {
          dispatch(fetchDatastores(region.provider_uuid, region.region_uuid));
        } else {
        }
        if (
          data_connectors === null ||
          (data_connectors.length > 0 &&
            data_connectors[0].region_uuid !== region.region_uuid)
        ) {
          dispatch(
            fetchDataConnectors(region.provider_uuid, region.region_uuid)
          );
        }
        if (
          services === null ||
          (services.length > 0 &&
            services[0].region_uuid !== region.region_uuid)
        ) {
          dispatch(fetchServices(region.provider_uuid, region.region_uuid));
        }
        if (
          device_configs === null ||
          (device_configs.length > 0 &&
            device_configs[0].region_uuid !== region.region_uuid)
        ) {
          dispatch(
            fetchDeviceConfigs(region.provider_uuid, region.region_uuid)
          );
        }
        if (gpu_types === null) {
          dispatch(fetchGpuClasses());
        }
        if (
          rigs === null ||
          (rigs.length > 0 && rigs[0].region_uuid !== region.region_uuid)
        ) {
          await dispatch(fetchRigs(region.provider_uuid, region.region_uuid));
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    })();
  }, [
    dispatch,
    provider_uuid,
    history,
    region,
    rigs,
    datastores,
    data_connectors,
    services,
    gpu_types,
    device_configs,
  ]);

  if (loading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <LoadingMask />
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                <Toolbar>
                  <Stack spacing={2} direction='row' alignItems='center'>
                    <IconButton
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      variant='h4'
                      paragraph
                      gutterBottom
                      align='center'
                    >
                      Region {region.name}
                    </Typography>
                  </Stack>
                </Toolbar>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Nodes rigs={rigs} region={region} />
              <Datastores region={region} />
              <DataConnectors region={region} />
              <Services region={region} />
            </Grid>
          </Grid>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
