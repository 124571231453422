import React, { useState } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function DatastorePasswordField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Password cannot be blank');
    } else {
      setError(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel htmlFor='password'>Password</InputLabel>
        <OutlinedInput
          label='Password'
          id='password'
          type={showPassword ? 'text' : 'password'}
          aria-describedby='password-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
          multiline
          rows={1}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error ? (
          <FormHelperText id='name-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='name-helper-text'>
            Enter the datastore password
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
