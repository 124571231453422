import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const NoGpusModal = (props) => {
  return (
    <Modal
      open={props.errorMessage === 'Not Enough Credits'}
      closeOnDimmerClick
      closeOnEscape
      onClose={props.clearError}
    >
      <Modal.Header>Not Enough Credits</Modal.Header>
      <Modal.Content>
        <p>
          Your account does not have enough credits to start this job. Please
          add credits to your account, reduce the number of GPU for this job, or
          select a lower cost GPU.
        </p>
      </Modal.Content>
      <Modal.Actions>
        {/* {props.notifyLoading ? (
            <Loader size='small' active inline='centered' />
          ) : (
            <Checkbox
              label='Notify Me When Available'
              checked={props.notify}
              onClick={() => props.handleNotify(props.gpu_type_id)}
            />
          )} */}
        <Button
          icon='checkmark'
          primary
          labelPosition='right'
          onClick={props.clearError}
          content='Okay'
        />
      </Modal.Actions>
    </Modal>
  );
};
export default NoGpusModal;
