import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Message, Modal, Grid } from 'semantic-ui-react';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import { fetchPayments } from '../../actions/payments';
import { fetchUser } from '../../actions/user';
import api from '../../apis/api';
import statuses from './statuses';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

class PaymentConfirm extends Component {
  state = {
    submitting: false,
    open: this.props.open,
    payment_error: null,
  };

  componentDidUpdate() {
    if (this.state.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  getPayment() {
    setTimeout(async () => {
      const response = await api.get(
        `/billing/payment/${this.props.paymentIntent.payment_uuid}`
      );
      if (response.status === statuses.PENDING) {
        this.getPayment();
      } else {
        this.props.fetchPayments();
        this.props.fetchUser();
        this.setState({
          submitting: false,
        });
        this.props.onCancel();
      }
    }, 1000);
  }
  onSubmit = async () => {
    this.setState({ submitting: true });
    try {
      const result = await this.props.stripe.confirmCardPayment(
        this.props.paymentIntent.client_secret
      );
      if (result.error) {
        this.setState({
          payment_error: result.error.message,
          submitting: false,
        });
        window.gtag('event', 'purchase_failed', {
          currency: 'USD',
          value: this.props.amount,
          customer_uuid: this.props.user.id,
        });
      } else {
        window.gtag('event', 'purchase', {
          currency: 'USD',
          value: this.props.amount,
          customer_uuid: this.props.user.id,
          transaction_id: this.props.paymentIntent.payment_uuid,
        });

        this.getPayment();
      }
    } catch (error) {
      console.log(error);
      // Probably need to let the user know something happened
      this.setState({ submitting: false });
    }
  };

  close = async () => {
    this.props.onCancel();
    this.setState({ payment_error: null });
    const response = await api.get(
      `/billing/payment/${this.props.paymentIntent.payment_uuid}`
    );
    if (response.status !== statuses.FAILED) {
      const result = await api.delete(
        `/billing/payment/${this.props.paymentIntent.payment_uuid}`
      );
      if (result.error) {
        this.setState({
          payment_error: result.error.message,
          submitting: false,
        });
      } else {
        this.props.fetchPayments();
        this.props.fetchUser();
      }
    } else {
      this.props.fetchPayments();
      this.props.fetchUser();
    }
  };

  render() {
    return (
      <Modal
        open={this.state.open}
        onClose={this.close}
        closeOnEscape
        closeOnDimmerClick
      >
        <Modal.Header>Confirm your Purchase</Modal.Header>
        <Modal.Content>
          {this.state.payment_error ? (
            <Message
              error
              header='Charge Failed'
              content={this.state.payment_error}
            />
          ) : !this.props.paymentMethod ? (
            <Message
              error
              header='Charge Failed'
              content='You do not have a primary payment method'
            />
          ) : (
            <Message
              info
              header={`Amount to be charged: ${formatter.format(
                this.props.amount
              )}`}
              list={[
                `You will receive ${this.props.credits} credits`,
                `Your card ending in ${this.props.paymentMethod.card.last4} will be
        charged.`,
              ]}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Button
                  onClick={this.onSubmit}
                  icon
                  floated='right'
                  labelPosition='right'
                  primary
                  loading={this.state.submitting}
                  disabled={Boolean(
                    this.state.payment_error || !this.props.paymentMethod
                  )}
                >
                  Pay Now
                  <Icon name='check' />
                </Button>
                <Button
                  icon
                  floated='left'
                  labelPosition='left'
                  secondary
                  onClick={this.close}
                  disabled={this.state.submitting}
                >
                  Cancel
                  <Icon name='close' />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const paymentMethod = _.filter(state.paymentMethods, (method) => {
    return method.id === state.user.primary_pm;
  });
  return { user: state.user, paymentMethod: paymentMethod[0] };
};

const PaymentConfirmWithStripe = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <PaymentConfirm stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

export default connect(mapStateToProps, {
  fetchPayments,
  fetchUser,
})(PaymentConfirmWithStripe);
