import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from '@mui/material';
import RESOURCE_TYPE_MAPPING from './resourceTypeMapping';

export default function ResourceTypeField({
  value,
  setValue,
  setChanged,
  disabled,
}) {
  const handleChange = (event) => {
    if (event.target.value !== value) {
      setChanged();
    }
    setValue(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='resource-type-label'>Compute Resource Type</InputLabel>
        <Select
          labelId='resource-type-label'
          id='resource-type-id'
          value={value}
          label='Compute Resource Type'
          onChange={handleChange}
          disabled={disabled}
        >
          {Object.entries(RESOURCE_TYPE_MAPPING).map(([k, v]) => {
            return <MenuItem value={k}>{v.label}</MenuItem>;
          })}
        </Select>
        <FormHelperText id='resource-type-helper-text'>
          The type of compute resource to create for this node
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
