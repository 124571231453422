import { FETCH_USER_CREDENTIALS } from './types';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchCredentials = () => async (dispatch) => {
  try {
    const user = store.getState().user;
    const res = await api.get(`/project/${user.id}/credentials/`);
    dispatch({
      type: FETCH_USER_CREDENTIALS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const removeCredential = (type) => async (dispatch) => {
  try {
    const user = store.getState().user;
    await api.delete(`/project/${user.id}/credential/${type}`);
    dispatch(fetchCredentials());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const putCredential = (values) => async (dispatch) => {
  try {
    const user = store.getState().user;
    await api.put(`/project/${user.id}/credential/${values.type}`, values);
    dispatch(fetchCredentials());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
