import React, { Component } from 'react';
import { Form, Card } from 'semantic-ui-react';
import { connect } from 'react-redux';

import GpuClass from './GpuClass';

const cpu_gpu_type_id = '78201a90-ca48-4147-b6f5-fec51b4bd7db';

class GpuTypeField extends Component {
  state = {
    loaders: [],
    disabled: this.props.edit && this.props.gpu_types[0] === cpu_gpu_type_id,
  };

  render() {
    if (this.props.cards) {
      return (
        <>
          <div className='required field'>
            <label>Gpu Type</label>
          </div>

          <Card.Group itemsPerRow={4} stackable>
            {this.props.gpuClasses.map((gpuClass) => (
              <GpuClass
                key={gpuClass.id}
                details={gpuClass}
                onClick={this.props.onClick}
                selected={this.props.gpu_types.includes(gpuClass.id)}
              />
            ))}
          </Card.Group>
        </>
      );
    } else {
      return (
        <Form.Dropdown
          name='gpu_types'
          label='Gpu Type'
          value={this.props.gpu_types}
          options={this.props.gpuClasses.map((gpuClass) => {
            return {
              key: gpuClass.id,
              value: gpuClass.id,
              text: gpuClass.name,
              disabled: gpuClass.id === cpu_gpu_type_id,
            };
          })}
          selection
          fluid
          multiple
          disabled={this.state.disabled}
          onChange={(e, { name, value }) => {
            this.props.onClick(value);
          }}
        />
      );
    }
  }
}

function mapStateToProps({ gpuClasses }) {
  gpuClasses = gpuClasses || [];
  gpuClasses = gpuClasses.sort((a, b) =>
    a.id === cpu_gpu_type_id ? 1 : a.name > b.name
  );
  return { gpuClasses };
}

export default connect(mapStateToProps)(GpuTypeField);
