const getGpuTypeName = (gpu_type_id, gpu_types) => {
  if (gpu_types) {
    const gpu_type = gpu_types.find((gpu_type) => gpu_type.id === gpu_type_id);
    if (gpu_type) {
      return gpu_type.name;
    }
  }
};

export default getGpuTypeName;
