import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';

export default function CollectionOutputUriField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
  outputType,
}) {
  const selected_type = Object.values(THIRD_PARTY_TYPES).find(
    (type) => type.value === outputType
  );

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
    const uriTest = selected_type ? selected_type.uriTest : undefined;
    if (uriTest) {
      const valid = uriTest.test(newValue);
      if (!valid) {
        setError(`Invalid output path for selected type`);
      } else {
        setError(false);
      }
    }
  };

  const example = selected_type ? selected_type.uriExample : undefined;
  const local_file_error =
    outputType === THIRD_PARTY_TYPES.LOCAL.value &&
    /\/[^/]*\.[^/]*$/.test(value);

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Source Location'
          id='output_uri'
          aria-describedby='output-uri-helper-text'
          variant='outlined'
          placeholder={example}
          onChange={handleChange}
          error={Boolean(error) || local_file_error}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='output-uri-helper-text'>{error}</FormHelperText>
        ) : local_file_error ? (
          <FormHelperText id='output-uri-helper-text'>
            Local storage expects a directory path, not a file. Ensure the
            provide path is the directory.
          </FormHelperText>
        ) : (
          <FormHelperText id='output-uri-helper-text'>
            Enter the path to write the data to within the selected output type
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
