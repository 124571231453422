import React, { useState, useCallback } from 'react';
import { Button, Stack, Typography, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMaintenance } from '../../../../actions/rigs';
import RIG_TYPES from './rigTypes';

function NodeGridToolbar({ region, selected, disabled, service, type }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const rigs = useSelector((state) => state.rigs);

  const [toggling, setToggling] = useState(false);

  const handleToggleMaintenance = useCallback(async () => {
    setToggling(true);
    await Promise.all(
      [].concat(
        selected.map(async (rig_uuid) => {
          const rig = rigs.find((rig) => rig_uuid === rig.rig_uuid);
          await dispatch(
            toggleMaintenance(
              region.provider_uuid,
              rig.region_uuid,
              rig.rig_uuid
            )
          );
        })
      )
    );
    setToggling(false);
  }, [dispatch, region, rigs, selected]);

  return (
    <GridToolbarContainer>
      <Stack direction='row' spacing={1}>
        <Tooltip title={Boolean(disabled) ? disabled : ''}>
          <span>
            {' '}
            <Button
              color='brand'
              variant='outlined'
              disabled={Boolean(disabled)}
              onClick={() =>
                history.push({
                  pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/node/new`,
                  search: `?service=${service}&type=${type}`,
                })
              }
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <AddCircleOutlineIcon />
                <Typography variant='button' display='block' gutterBottom>
                  Add
                </Typography>
              </Stack>
            </Button>
            <LoadingButton
              variant='outlined'
              loading={toggling}
              onClick={handleToggleMaintenance}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <EngineeringIcon />
                <Typography variant='button' display='block' gutterBottom>
                  Toggle Maintenance
                </Typography>
              </Stack>
            </LoadingButton>
            {type === RIG_TYPES.DEVICE ? (
              <Button
                variant='outlined'
                disabled={Boolean(disabled)}
                onClick={() =>
                  history.push({
                    pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/device/config`,
                  })
                }
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <SettingsIcon />
                  <Typography variant='button' display='block' gutterBottom>
                    Set Config
                  </Typography>
                </Stack>
              </Button>
            ) : (
              <></>
            )}
          </span>
        </Tooltip>
        <GridToolbarColumnsButton variant='outlined' />
        <GridToolbarFilterButton variant='outlined' />
      </Stack>
    </GridToolbarContainer>
  );
}
export default NodeGridToolbar;
