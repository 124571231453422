import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Typography,
  Toolbar,
  Stack,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import theme from '../../theme';
import { clearErrorMessage } from '../../actions/errorMessage';
import ProfileForm from './ProfileForm';
import { updateUser } from '../../actions/user';
import TermsOfService from './TermsOfService';

export default function AccountSignup() {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [display_terms, setDisplayTerms] = useState(false);
  const [terms_accepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  const handleProfileSubmit = async () => {
    window.gtag('event', 'sign_up_profile_submitted', {
      customer_uuid: user.id,
    });
    setDisplayTerms(true);
  };

  const handleSubmitTerms = async () => {
    setLoading(true);
    window.gtag('event', 'sign_up_complete', {
      customer_uuid: user.id,
    });
    await dispatch(updateUser({ terms_accepted, terms_version: '2023-06-15' }));
    history.push('/account/login');
  };

  if (!user) {
    history.push('/');
    return <div>Redirecting...</div>;
  }
  if (display_terms) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <Typography variant='h5'>
              Terms and Conditions of Service
            </Typography>
            <br />
            <TermsOfService />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={terms_accepted}
                  onChange={(e) => {
                    setTermsAccepted(e.target.checked);
                  }}
                />
              }
              required
              label='I accept the Terms and Conditions of Service'
            />
            <Toolbar>
              <Stack spacing={2} direction='row'>
                <LoadingButton
                  variant='contained'
                  color='brand'
                  onClick={handleSubmitTerms}
                  loading={loading}
                  disabled={!terms_accepted}
                >
                  Submit
                </LoadingButton>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setTermsAccepted(false);
                    setDisplayTerms(false);
                  }}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Stack>
            </Toolbar>
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <Typography variant='h5'>New Account Signup</Typography>
            <br />
            <ProfileForm onSubmit={handleProfileSubmit} />
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }
}
