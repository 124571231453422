import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Segment,
  Form,
  Message,
  Icon,
  Grid,
  Button,
  Checkbox,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import STORAGE_TYPES from './storageTypes';
import THIRD_PARTY_TYPES from './thirdPartyTypes';
import JOB_TYPES from '../types';
import { fetchPublicCheckpoints } from '../../../actions/publicCheckpoints';
import { fetchCheckpoints } from '../../../actions/checkpoints';

const buildKeyOptions = (options, configured_keys) => {
  configured_keys = configured_keys || [];
  return options.map((option) => {
    const { alwaysEnabled, uriTest, uriExample, ...rest } = option;
    if (
      configured_keys.some((key) => key.type === option.key) ||
      alwaysEnabled
    ) {
      return rest;
    }
    return { disabled: true, ...rest };
  });
};

const buildModelList = (models) => {
  let modelList = [];
  for (let model of models) {
    if (model.status === 'ready') {
      modelList.push({
        key: model.name,
        text: model.name,
        value: model.model_uuid,
      });
    }
  }
  return modelList;
};

const buildCheckpointList = (checkpoints) => {
  let checkpointList = [];
  for (let checkpoint of checkpoints) {
    if (checkpoint.status === 'ready') {
      checkpointList.push({
        key: checkpoint.name,
        text: checkpoint.name,
        value: checkpoint.checkpoint_uuid,
      });
    }
  }
  return checkpointList;
};

class ModelFields extends Component {
  state = {
    expanded:
      !this.props.edit &&
      [JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
        this.props.type
      ),
    source_uri_error: false,
    checkpoint_errors:
      this.props.initialValues && this.props.initialValues.checkpoints
        ? new Array(this.props.initialValues.checkpoints.length).fill(false)
        : [],
    checkpoint_count:
      this.props.initialValues && this.props.initialValues.checkpoints
        ? this.props.initialValues.checkpoints.length
        : 0,
    values: this.props.initialValues
      ? {
          source_type: this.props.initialValues.source_type,
          source_uri: this.props.initialValues.source_uri,
          checkpoints: this.props.initialValues.checkpoints.map(
            (checkpoint) => {
              return {
                id: checkpoint.checkpoint_uuid,
                public: checkpoint.public,
                as: checkpoint.name,
                name: checkpoint.name,
                size: checkpoint.size || checkpoint.used_size,
              };
            }
          ),
        }
      : {
          source_type: 'git',
          source_uri: '',
          checkpoints: [],
        },
  };
  componentDidMount() {
    if (this.props.publicCheckpoints === null) {
      this.props.fetchPublicCheckpoints();
    }
    if (this.props.checkpoints === null) {
      this.props.fetchCheckpoints();
    }
    this.props.onChange(
      'model',
      this.state.values,
      this.state.source_uri_error
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevState, this.state)) {
      return;
    }
    const checkpoints = this.state.values.checkpoints
      .map((checkpoint) => (!checkpoint.id ? undefined : checkpoint))
      .filter((i) => i);

    const checkpoint_error = new Set(this.state.checkpoint_errors);

    this.props.onChange(
      'model',
      { ...this.state.values, checkpoints },
      this.state.source_uri_error || checkpoint_error.has(true)
    );
  }

  handleChange = (e, { name, value }) => {
    const array_re = /(?<key>.+)\[(?<number>[0-9]+)\]\.?(?<attribute>.+)?/;
    const result = name.match(array_re);
    if (result && result.groups.attribute) {
      const array = [...this.state.values[result.groups.key]];
      const item = { ...array[result.groups.number] };
      if (result.groups.attribute === 'public') {
        delete item.id;
        delete item.name;
        delete item.size;
        item.public = !item.public;
      } else {
        item[result.groups.attribute] = value;
        const checkpoint = this.getCheckpoint(value);
        item.name = checkpoint.name;
        item.size = checkpoint.size || checkpoint.used_size;
        item.public = checkpoint.project_uuid === 'public';
      }
      array[result.groups.number] = item;
      const checkpoint_errors = this.checkpointErrorCheck(array);
      this.setState({
        checkpoint_errors,
        values: {
          ...this.state.values,
          [result.groups.key]: array,
        },
      });
    } else {
      switch (name) {
        case 'source_type':
          this.setState({
            [name]: value,
            values: {
              ...this.state.values,
              source_uri: '',
              [name]: value,
            },
          });
          break;
        case 'source_uri':
          const uriTest = Object.values(THIRD_PARTY_TYPES).find(
            (type) => type.value === this.state.values.source_type
          ).uriTest;
          const valid = uriTest.test(value);
          this.setState({
            source_uri_error: !valid,
            values: {
              ...this.state.values,
              [name]: value,
            },
          });
          break;
        default:
          this.setState({
            values: { ...this.state.values, [name]: value },
          });
      }
    }
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  getCheckpoint = (id) => {
    let checkpoint = this.props.checkpoints.find(
      (checkpoint) => checkpoint.checkpoint_uuid === id
    );
    if (!checkpoint) {
      checkpoint = this.props.publicCheckpoints.find(
        (checkpoint) => checkpoint.checkpoint_uuid === id
      );
    }
    return checkpoint;
  };

  checkpointErrorCheck = (checkpoints) => {
    const names = checkpoints.map((checkpoint) => {
      if (checkpoint.name) {
        return checkpoint.name.toLowerCase().replace(' ', '_');
      }
      return undefined;
    });
    const dup_names = names.filter((e, i, a) => a.indexOf(e) !== i);
    const errors = checkpoints.map((checkpoint) => {
      if (checkpoint.name) {
        return dup_names.includes(
          checkpoint.name.toLowerCase().replace(' ', '_')
        );
      }
      return true;
    });
    return errors;
  };

  addCheckpoint = (e) => {
    e.preventDefault();
    this.setState({
      checkpoint_count: this.state.checkpoint_count + 1,
      checkpoint_errors: [...this.state.checkpoint_errors, false],
      values: {
        ...this.state.values,
        checkpoints: [...this.state.values.checkpoints, {}],
      },
    });
  };

  removeCheckpoint = (i) => {
    const checkpoints = [...this.state.values.checkpoints];
    checkpoints.splice(i, 1);
    const checkpoint_errors = this.checkpointErrorCheck(checkpoints);
    this.setState({
      checkpoint_errors,
      checkpoint_count: this.state.checkpoint_count - 1,
      values: {
        ...this.state.values,
        checkpoints,
      },
    });
  };

  render() {
    const { type, edit, copy } = this.props;
    const {
      expanded,
      values: { source_type, source_uri, checkpoints },
      source_uri_error,
      checkpoint_count,
      checkpoint_errors,
    } = this.state;

    const checkpointList = buildCheckpointList(this.props.checkpoints || []);
    const publicCheckpointList = buildCheckpointList(
      this.props.publicCheckpoints || []
    );

    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon name={expanded ? 'triangle down' : 'triangle right'} />
            Model{' '}
            {copy || (!edit && type === JOB_TYPES.NOTEBOOK.value)
              ? '(Optional)'
              : ''}
          </Message.Header>
        </Message>
        {expanded ? (
          <>
            <Segment attached>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Form.Dropdown
                      name='source_type'
                      label='Model Type'
                      onChange={this.handleChange}
                      selection
                      fluid
                      clearable
                      disabled={edit || copy}
                      options={buildKeyOptions(
                        STORAGE_TYPES.model,
                        this.props.projectCredentials
                      )}
                      value={source_type}
                    />
                  </Grid.Column>
                  <Grid.Column width={13}>
                    {source_type !== THIRD_PARTY_TYPES.TRAINML.value ? (
                      <Form.Input
                        name='source_uri'
                        label='Model Code Location'
                        value={source_uri}
                        onChange={this.handleChange}
                        disabled={!source_type || edit || copy}
                        placeholder={
                          source_type
                            ? Object.values(THIRD_PARTY_TYPES).find(
                                (type) => type.value === source_type
                              ).uriExample
                            : ''
                        }
                        error={
                          source_uri_error
                            ? {
                                content: 'Model Path Invalid',
                                pointing: 'above',
                              }
                            : undefined
                        }
                      />
                    ) : (
                      <Form.Dropdown
                        name='source_uri'
                        label='Model'
                        onChange={this.handleChange}
                        options={buildModelList(this.props.models)}
                        value={source_uri}
                        selection
                        fluid
                        disabled={edit || copy}
                        placeholder={'None'}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                {new Array(checkpoint_count).fill().map((e, i) => {
                  return (
                    <Grid.Row key={`checkpoint_${i}`}>
                      <Grid.Column width={9}>
                        <Form.Dropdown
                          name={`checkpoints[${i}].id`}
                          onChange={this.handleChange}
                          options={
                            checkpoints[i].public
                              ? publicCheckpointList
                              : checkpointList
                          }
                          value={checkpoints[i].id}
                          selection
                          fluid
                          placeholder={'None'}
                          label='Checkpoint'
                          error={
                            checkpoint_errors[i] && checkpoints[i].id
                              ? {
                                  content: 'Duplicate Checkpoint Name',
                                  pointing: 'above',
                                }
                              : undefined
                          }
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={3}
                        verticalAlign={
                          checkpoint_errors[i] && checkpoints[i].id
                            ? 'middle'
                            : 'bottom'
                        }
                      >
                        <Checkbox
                          name={`checkpoints[${i}].public`}
                          checked={checkpoints[i].public}
                          label='Public'
                          fluid
                          onClick={this.handleChange}
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={1}
                        verticalAlign={
                          checkpoint_errors[i] && checkpoints[i].id
                            ? 'middle'
                            : 'bottom'
                        }
                      >
                        <Button
                          icon='close'
                          basic
                          onClick={(e) => {
                            e.preventDefault();
                            this.removeCheckpoint(i);
                          }}
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      icon
                      basic
                      color='black'
                      labelPosition='right'
                      onClick={this.addCheckpoint}
                    >
                      <Icon name='plus' />
                      Add Checkpoint
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            {this.props.projectCredentials &&
            this.props.projectCredentials.filter((key) => key.type === 'git')
              .length === 0 ? (
              <Message attached='bottom' info>
                <Icon name='help' />
                Trying to access a private repo?{' '}
                <Link to='/account/settings' target='_blank'>
                  Generate a Git SSH Key Here
                </Link>
              </Message>
            ) : undefined}
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({
  projectCredentials,
  models,
  checkpoints,
  publicCheckpoints,
}) {
  return {
    projectCredentials,
    checkpoints,
    publicCheckpoints,
    models: models || [],
  };
}

export default connect(mapStateToProps, {
  fetchPublicCheckpoints,
  fetchCheckpoints,
})(ModelFields);
