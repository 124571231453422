import React, { Component } from 'react';
import { Button, Icon, Message, Grid, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { createApiKey } from '../../../actions/user';
import ApiKey from './ApiKey';

class ApiKeysForm extends Component {
  state = {
    keys: this.props.user.api_keys,
    submitting: false,
  };

  componentDidUpdate({ user: prevUser }) {
    if (prevUser && !prevUser.api_keys && this.props.user.api_keys) {
      this.setState({ keys: this.props.user.api_keys });
    } else if (
      prevUser &&
      prevUser.api_keys &&
      this.props.user.api_keys &&
      this.props.user.api_keys.length !== prevUser.api_keys.length
    ) {
      this.setState({ keys: this.props.user.api_keys });
    }
  }

  createApiKey = async () => {
    this.setState({ submitting: true });
    await this.props.createApiKey();
    this.setState({ submitting: false });
  };

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Button
              icon
              labelPosition='right'
              floated='left'
              secondary
              onClick={this.createApiKey}
              loading={this.state.submitting}
            >
              <Icon name='plus' />
              Create
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {this.props.user.api_keys && this.props.user.api_keys.length ? (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>
                      Created
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Age</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>
                      Actions
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.user.api_keys.map((key) => {
                    return <ApiKey key={key.id} api_key={key} />;
                  })}
                </Table.Body>
              </Table>
            ) : (
              <Message info>
                <p>No keys configured.</p>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps, { createApiKey })(ApiKeysForm);
