import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConsolidatedBilling from './ConsolidatedBilling';
import PersonalBilling from './PersonalBilling';
import { fetchUser } from '../../actions/user';

class Billing extends Component {
  componentDidMount() {
    if (!this.props.user) {
      this.props.fetchUser();
    }
  }

  render() {
    if (this.props.user.billing_account_id) {
      return <ConsolidatedBilling />;
    }
    return <PersonalBilling />;
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
})(Billing);
