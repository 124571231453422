import React, { Component } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
  putCredential as putProjectKey,
  removeCredential as removeProjectKey,
} from '../../actions/projectCredentials';
import {
  putCredential as putUserKey,
  removeCredential as removeUserKey,
} from '../../actions/userCredentials';

class SshKey extends Component {
  state = {
    submitting: false,
    removing: false,
  };

  handleGenerate = async () => {
    this.setState({ submitting: true });
    if (this.props.entity === 'user') {
      await this.props.putUserKey({
        type: 'git',
        key_id: 'git',
        secret: 'git',
      });
    } else {
      await this.props.putProjectKey(this.props.project_uuid, {
        type: 'git',
        key_id: 'git',
        secret: 'git',
      });
    }

    this.setState({
      submitting: false,
    });
  };

  handleRemove = async () => {
    this.setState({ removing: true });
    if (this.props.entity === 'user') {
      await this.props.removeUserKey('git');
    } else {
      await this.props.removeProjectKey(this.props.project_uuid, 'git');
    }
    this.setState({
      removing: false,
    });
  };

  render() {
    return (
      <Grid verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column floated='left' width={1}>
            <Icon name='key' size='big' />
          </Grid.Column>
          <Grid.Column floated='left' width={8}>
            {this.props.key_id ? (
              <span>
                {this.props.key_id} support@
                {process.env.REACT_APP_DOMAIN_SUFFIX}
              </span>
            ) : (
              <span>None</span>
            )}
          </Grid.Column>
          <Grid.Column floated='right' width={7}>
            {this.props.key_id ? (
              <Button
                icon
                labelPosition='right'
                floated='right'
                secondary
                onClick={this.handleRemove}
                loading={this.state.removing}
              >
                <Icon name='trash alternate' />
                Remove
              </Button>
            ) : (
              <Button
                icon
                labelPosition='right'
                floated='right'
                primary
                onClick={this.handleGenerate}
                loading={this.state.submitting}
              >
                <Icon name='cog' />
                Generate
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(null, {
  putProjectKey,
  removeProjectKey,
  putUserKey,
  removeUserKey,
})(SshKey);
