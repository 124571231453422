import React from 'react';

import {
  Box,
  TextField,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';

import UploadFile from '../../../shared/UploadFile';

const private_key_re =
  /$\s*(-----BEGIN PUBLIC KEY-----(\n|\r|\r\n)([0-9a-zA-Z+/=]{64}(\n|\r|\r\n))*([0-9a-zA-Z+/=]{1,63}(\n|\r|\r\n))?-----END PUBLIC KEY-----)|(-----BEGIN PRIVATE KEY-----(\n|\r|\r\n)([0-9a-zA-Z+/=]{64}(\n|\r|\r\n))*([0-9a-zA-Z+/=]{1,63}(\n|\r|\r\n))?-----END PRIVATE KEY-----)\s*$/;

export default function ServicePrivateKeyField({
  value,
  setValue,
  error,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    if (!edit && newValue === '') {
      setError('Private key cannot be empty');
    } else if (!private_key_re.test(newValue)) {
      setError('Invalid private key format');
    } else {
      setError(false);
    }
    setValue(newValue);
  };

  const handleUpload = (file) => {
    handleChange({ target: { value: file.contents } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant='h6'>Private Key</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Box>
          <TextField
            sx={{ width: '100%' }}
            id='private-key'
            label='Private Key'
            multiline
            rows={20}
            value={value}
            error={error}
            aria-describedby='private-key-helper-text'
            inputProps={{ style: { fontSize: 10 } }}
            onChange={handleChange}
          />
          <FormHelperText id='private-key-helper-text'>
            {error ||
              'Enter the private key for the custom_hostname certificate'}
          </FormHelperText>
        </Box>
        <UploadFile name={'Upload Private Key'} setValue={handleUpload} />
      </Grid>
    </Grid>
  );
}
