import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import theme from '../../../../../theme';
import ErrorSnackBar from '../../../../shared/ErrorSnackBar';
import MemberEmailField from './MemberEmailField';
import AccessField from './AccessField';
import {
  addProjectMember,
  getProjectMembers,
  updateProjectMember,
} from 'actions/projectMembers';
import LoadingMask from 'components/shared/LoadingMask';

export default function ProjectMemberForm() {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const api_error = useSelector((state) => state.errorMessage);

  const sp = new URLSearchParams(location.search);

  const [loading_members, setLoadingMembers] = useState(true);
  const [project_members, setProjectMembers] = useState(null);

  useEffect(() => {
    (async () => {
      if (project_members === null) {
        const res = await getProjectMembers(id);
        setProjectMembers(res.filter((member) => !member.owner));
      }
      setLoadingMembers(false);
    })();
  }, [setProjectMembers, setLoadingMembers, project_members, id]);

  let project_member;
  if (project_members && sp.get('member')) {
    project_member = project_members.find(
      (project_member) => project_member.email === sp.get('member')
    );
  }

  console.log(project_members);
  console.log(sp.get('member'));
  console.log(project_member);
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(project_member?.email || undefined);
  const [email_error, setEmailError] = useState(false);
  const [job_access, setJobAccess] = useState(project_member?.job || 'read');
  const [dataset_access, setDatasetAccess] = useState(
    project_member?.dataset || 'read'
  );
  const [model_access, setModelAccess] = useState(
    project_member?.model || 'read'
  );
  const [checkpoint_access, setCheckpointAccess] = useState(
    project_member?.checkpoint || 'read'
  );
  const [volume_access, setVolumeAccess] = useState(
    project_member?.volume || 'read'
  );

  useEffect(() => {
    (async () => {
      if (project_member) {
        setEmail(project_member.email);
        setJobAccess(project_member.job);
        setDatasetAccess(project_member.dataset);
        setModelAccess(project_member.model);
        setCheckpointAccess(project_member.checkpoint);
        setVolumeAccess(project_member.volume);
      }
    })();
  }, [project_member, setEmail, setJobAccess]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        email,
        job: job_access,
        dataset: dataset_access,
        model: model_access,
        checkpoint: checkpoint_access,
        volume: volume_access,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      if (project_member) {
        await updateProjectMember(id, payload);
      } else {
        await addProjectMember(id, payload);
      }

      history.push({
        pathname: `/project/details/${id}`,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading_members) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <LoadingMask />
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {project_member
                          ? `Edit ${project_member.email}`
                          : `Add Project Member`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <MemberEmailField
                        value={email}
                        error={email_error}
                        setValue={setEmail}
                        setError={setEmailError}
                        setChanged={() => setPristine(false)}
                        edit={Boolean(project_member)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <AccessField
                        value={job_access}
                        setValue={setJobAccess}
                        setChanged={() => setPristine(false)}
                        label={'Job Access'}
                        helperText={'Level of access to project Jobs'}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <AccessField
                        value={checkpoint_access}
                        setValue={setCheckpointAccess}
                        setChanged={() => setPristine(false)}
                        label={'Checkpoint Access'}
                        helperText={'Level of access to project Checkpoints'}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <AccessField
                        value={dataset_access}
                        setValue={setDatasetAccess}
                        setChanged={() => setPristine(false)}
                        label={'Dataset Access'}
                        helperText={'Level of access to project Datasets'}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <AccessField
                        value={model_access}
                        setValue={setModelAccess}
                        setChanged={() => setPristine(false)}
                        label={'Model Access'}
                        helperText={'Level of access to project Models'}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <AccessField
                        value={volume_access}
                        setValue={setVolumeAccess}
                        setChanged={() => setPristine(false)}
                        label={'Volume Access'}
                        helperText={'Level of access to project Volumes'}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <LoadingButton
                      variant='contained'
                      color='brand'
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={
                        pristine || Boolean(email_error) || !Boolean(email)
                      }
                    >
                      Submit
                    </LoadingButton>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
