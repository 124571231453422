import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function CollectionCapacityField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
  minimum_capacity,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);

    let new_capacity = Number(newValue);
    if (Number.isNaN(new_capacity) || new_capacity % 1 !== 0) {
      setError('Capacity must be an integer');
      return;
    }
    if (new_capacity < minimum_capacity || new_capacity > 500) {
      setError(`Capacity must be between ${minimum_capacity} and 500`);
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Capacity'
          id='capacity'
          aria-describedby='capacity-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='capacity-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='capacity-helper-text'>
            Enter the maximum capacity in GB
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
