const JOB_TYPES = {
  NOTEBOOK: {
    value: 'notebook',
    label: 'Notebooks',
    icon: 'book',
  },
  TRAINING: {
    value: 'training',
    label: 'Training Jobs',
    icon: 'server',
  },
  INFERENCE: {
    value: 'inference',
    label: 'Inference Jobs',
    icon: 'cubes',
  },
  ENDPOINT: { value: 'endpoint', label: 'Endpoints', icon: 'sitemap' },
};

export default JOB_TYPES;
