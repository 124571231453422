import React, { useState, useEffect } from 'react';

import {
  Stack,
  IconButton,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

export default function CheckpointField({
  index,
  value,
  error,
  setValue,
  setError,
  remove,
}) {
  const [isPublic, setIsPublic] = useState(value ? value.public : false);
  const [id, setId] = useState(value ? value.id : '');

  const checkpoints = useSelector((state) => state.checkpoints);
  const publicCheckpoints = useSelector((state) => state.publicCheckpoints);

  useEffect(() => {
    setId(value.id);
    setIsPublic(value.public);
  }, [value]);

  const handleChange = (event, val) => {
    const { value: newId, name: newAs } = val.props;
    setId(newId);
    setValue(index, { public: isPublic, id: newId, as: newAs });
    if (newId === '') {
      setError(index, 'Checkpoint cannot be blank');
    } else {
      setError(index, false);
    }
  };

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      spacing={1}
    >
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={isPublic}
              onChange={() => {
                setIsPublic(!isPublic);
                setId('');
                setValue(index, { public: !isPublic, id: '', as: '' });
                setError(index, 'Checkpoint cannot be blank');
              }}
            />
          }
          label='Public'
        />
      </Box>
      <Box sx={{ minWidth: '300px' }}>
        <FormControl fullWidth required>
          <InputLabel id={`checkpoint-${index}-label`}>Checkpoint</InputLabel>
          <Select
            labelId={`checkpoint-${index}-label`}
            id={`checkpoint-id-${index}`}
            value={id}
            label='Checkpoint'
            onChange={handleChange}
            aria-describedby={`checkpoint-${index}-helper-text`}
          >
            {isPublic
              ? publicCheckpoints.map((checkpoint) => {
                  return (
                    <MenuItem
                      key={checkpoint.checkpoint_uuid}
                      value={checkpoint.checkpoint_uuid}
                      name={checkpoint.name}
                    >
                      {checkpoint.name}
                    </MenuItem>
                  );
                })
              : checkpoints.map((checkpoint) => {
                  return (
                    <MenuItem
                      key={checkpoint.checkpoint_uuid}
                      value={checkpoint.checkpoint_uuid}
                      name={checkpoint.name}
                    >
                      {checkpoint.name}
                    </MenuItem>
                  );
                })}
          </Select>
          {error ? (
            <FormHelperText id={`checkpoint-${index}-helper-text`}>
              {error}
            </FormHelperText>
          ) : undefined}
        </FormControl>
      </Box>
      <IconButton
        onClick={() => {
          remove(index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
}
