import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material';

export default function CollectionKaggleTypeField({
  value,
  error,
  setValue,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (event.target.value !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <Select
          labelId='kaggle-type-label'
          id='kaggle_type'
          value={value}
          label='Type'
          onChange={handleChange}
          disabled={edit}
        >
          <MenuItem value='competition'>Competition</MenuItem>
          <MenuItem value='dataset'>Dataset</MenuItem>
          <MenuItem value='kernel'>Kernel</MenuItem>
        </Select>
        {error ? (
          <FormHelperText id='name-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='name-helper-text'>
            Select the Kaggle resource type
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
