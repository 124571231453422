import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Menu, Dropdown } from 'semantic-ui-react';
import { logout } from '../actions/session';
import { setSelectedProject } from '../actions/projects';
import { getCognitoSignInUrl } from '../services/auth';

import AutoReload from './AutoReload';

class Header extends Component {
  state = {};
  componentDidMount() {
    this.setState({ hideNav: window.innerWidth <= 760 });
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ hideNav: window.innerWidth <= 760 });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }
  renderCredits() {
    if (this.props.session.isLoggedIn && this.props.user) {
      if (
        this.props.user.status === 'approved' &&
        !this.props.user.billing_account_id
      ) {
        return (
          <Menu.Item>
            <Link to='/account/billing'>
              <span>Credits: {this.props.user.credits.toFixed(4)}</span>
            </Link>
          </Menu.Item>
        );
      }
    }
  }

  renderProfile() {
    if (this.props.session.isLoggedIn && this.props.user) {
      if (this.props.user.status === 'approved') {
        return (
          <Menu.Item>
            <Link to='/account/settings'>
              <img
                className='ui avatar image'
                src={this.props.user.picture}
                alt='profile'
              />
              {this.props.user.given_name || this.props.user.display_name}
            </Link>
          </Menu.Item>
        );
      }
    }
  }

  renderProjects() {
    if (
      this.props.session.isLoggedIn &&
      this.props.user &&
      this.props.user.status === 'approved' &&
      ((this.props.my_projects && this.props.my_projects.length) ||
        (this.props.other_projects && this.props.other_projects.length))
    ) {
      return (
        <Menu.Item>
          <Dropdown text={`Project: ${this.props.selected_project.name}`}>
            <Dropdown.Menu>
              <Dropdown.Item
                key={this.props.user.id}
                text='Personal Project'
                onClick={() =>
                  this.props.setSelectedProject(this.props.user.id)
                }
              />
              {this.props.my_projects.length ? (
                <>
                  <Dropdown.Header content='My Projects' key='my_projects' />
                  {this.props.my_projects.map((project) => {
                    return (
                      <Dropdown.Item
                        key={project.id}
                        text={project.name}
                        onClick={() =>
                          this.props.setSelectedProject(project.id)
                        }
                      />
                    );
                  })}
                </>
              ) : undefined}
              {this.props.other_projects.length ? (
                <>
                  <Dropdown.Header
                    content='Shared Projects'
                    key='shared_projects'
                  />
                  {this.props.other_projects.map((project) => {
                    return (
                      <Dropdown.Item
                        key={project.id}
                        text={project.name}
                        onClick={() =>
                          this.props.setSelectedProject(project.id)
                        }
                      />
                    );
                  })}
                </>
              ) : undefined}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      );
    }
  }

  logoLink() {
    if (this.props.session.isLoggedIn && this.props.user) {
      if (this.props.user.status === 'approved') {
        return '/dashboard';
      }
    }
    return '/';
  }

  render() {
    const { hideNav } = this.state;
    return (
      <>
        <Menu inverted fixed='top' size='large'>
          <Link to={this.logoLink()} className='header item left'>
            <img
              className={`ui ${hideNav ? '' : 'small'} image`}
              alt={`${process.env.REACT_APP_ORG_NAME}`}
              src={hideNav ? '/logo-small.png' : '/logo.png'}
              width={'30px'}
              height={'auto'}
            />
          </Link>

          {this.props.session.isLoggedIn ? (
            <>
              {this.renderProjects()}
              {this.renderProfile()}
              {this.renderCredits()}
              <Menu.Item onClick={() => this.props.logout()}>Logout</Menu.Item>
              <Menu.Item
                icon='question circle outline'
                onClick={() =>
                  window.open(process.env.REACT_APP_DOC_URL, '_blank')
                }
              ></Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item
                onClick={() => {
                  const location = new URL(window.location.href);
                  if (!['/', '/logout'].includes(location.pathname)) {
                    localStorage.setItem('redirect_path', location.pathname);
                    localStorage.setItem('redirect_search', location.search);
                  } else {
                    localStorage.removeItem('redirect_path');
                    localStorage.removeItem('redirect_search');
                  }
                  window.location.replace(getCognitoSignInUrl());
                }}
              >
                Login/Signup
              </Menu.Item>
              <a
                className='ui header item'
                href={process.env.REACT_APP_DOC_URL}
              >
                <Icon name='question circle outline' />
              </a>
            </>
          )}
        </Menu>
        <AutoReload
          tryDelay={parseInt(process.env.REACT_APP_AUTO_RELOAD_INTERVAL)}
        />
      </>
    );
  }
}

function mapStateToProps({ user, session, projects }) {
  if (!user) {
    return { session };
  }
  const non_personal_projects = projects.filter(
    (project) => project.id !== user.id
  );
  const my_projects = non_personal_projects.filter((project) => {
    return project.owner;
  });
  const other_projects = non_personal_projects.filter((project) => {
    return !project.owner;
  });
  const selected_project = projects.find(
    (project) => project.id === user.selected_project
  );
  return {
    user,
    session,
    my_projects,
    other_projects,
    selected_project,
  };
}

export default connect(mapStateToProps, { logout, setSelectedProject })(Header);
