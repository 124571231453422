import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Menu,
  Segment,
  Table,
  Button,
  Grid,
  Message,
  Header,
  Dimmer,
  Loader,
  Image,
  Icon,
  Modal,
} from 'semantic-ui-react';

import { fetchJobs } from '../../../actions/jobs';
import { fetchGpuClasses } from '../../../actions/gpuClasses';
import { clearErrorMessage } from '../../../actions/errorMessage';
import Job from './Job';
import { fetchJob, updateJob, terminateJob } from '../../../actions/jobs';
import { verifyPublicDatasets } from '../../../actions/publicDatasets';
import { fetchDatasets } from '../../../actions/datasets';
import { fetchProjectDatastores } from '../../../actions/projectDatastores';
import NoGpusModal from '../NoGpusModal';
import STATUSES from '../../statuses';
import JOB_TYPES from '../types';
import JobEditForm from '../JobEditForm';
import JobCopyForm from '../JobCopyForm';
import history from '../../../history';
import JobRerunForm from '../JobRerunForm';

class Dashboard extends Component {
  type =
    this.props.match.params.type === 'undefined'
      ? 'notebook'
      : this.props.match.params.type;
  state = {
    transitioningJobs: [],
    mounted: false,
    selectedJobs: [],
  };

  componentDidMount = async () => {
    if (this.props.match.params.type === 'undefined') {
      history.push('/dashboard');
    }
    this.props.fetchGpuClasses();
    await Promise.all([
      this.props.verifyPublicDatasets(),
      this.props.fetchDatasets(),
      this.props.fetchJobs(),
      this.props.user.cloudbender_enabled
        ? this.props.fetchProjectDatastores()
        : undefined,
    ]);
    this.setState({ mounted: true });
  };
  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handleSelect = (job_uuid) => {
    if (this.state.selectedJobs.includes(job_uuid)) {
      this.setState({
        selectedJobs: this.state.selectedJobs.filter((job) => job !== job_uuid),
      });
    } else {
      this.setState({
        selectedJobs: [...this.state.selectedJobs, job_uuid],
      });
    }
  };

  handleSelectAll = () => {
    if (this.props.jobs.length === this.state.selectedJobs.length) {
      this.setState({ selectedJobs: [] });
    } else {
      this.setState({
        selectedJobs: this.props.jobs.map((job) => job.job_uuid),
      });
    }
  };

  handleRestart = async () => {
    const jobs = this.state.selectedJobs;
    this.setState({
      transitioningJobs: [...this.state.transitioningJobs, ...jobs],
      selectedJobs: [],
    });
    for (let i = 0; i < jobs.length; i++) {
      // parallel actions put a lot of stress on the API
      try {
        await this.restartJob(jobs[i]);
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({
      transitioningJobs: this.state.transitioningJobs.filter(
        (job) => !jobs.includes(job)
      ),
    });
  };

  handleStop = async () => {
    const jobs = this.state.selectedJobs;
    const selectedStatuses = new Set(
      this.props.jobs
        .filter((job) => this.state.selectedJobs.includes(job.job_uuid))
        .map((job) => job.status)
    );
    if (selectedStatuses.has(STATUSES.UPLOADING)) {
      this.setState({
        canceling: true,
      });
    } else {
      await this.stopJobs(jobs);
    }
  };

  stopJobs = async (jobs) => {
    this.setState({
      transitioningJobs: [...this.state.transitioningJobs, ...jobs],
      selectedJobs: [],
    });
    for (let i = 0; i < jobs.length; i++) {
      // parallel actions put a lot of stress on the API
      await this.stopJob(jobs[i]);
    }

    this.setState({
      transitioningJobs: this.state.transitioningJobs.filter(
        (job) => !jobs.includes(job)
      ),
    });
  };

  handleTerminate = async () => {
    const jobs = this.state.selectedJobs;
    this.setState({
      transitioningJobs: [...this.state.transitioningJobs, ...jobs],
      selectedJobs: [],
    });
    for (let i = 0; i < jobs.length; i++) {
      // parallel actions put a lot of stress on the API
      await this.terminateJob(jobs[i]);
    }

    this.setState({
      transitioningJobs: this.state.transitioningJobs.filter(
        (job) => !jobs.includes(job)
      ),
    });
  };

  restartJob = async (job_uuid) => {
    // const credits_per_hour = this.props.jobs.find(
    //   (job) => job.job_uuid === job_uuid
    // ).credits_per_hour;
    await this.props.updateJob(job_uuid, {
      command: 'start',
    });
  };

  stopJob = async (job_uuid) => {
    await this.props.updateJob(job_uuid, {
      command: 'stop',
    });
  };

  terminateJob = async (job_uuid) => {
    await this.props.terminateJob(job_uuid);
  };

  renderNoJob = () => {
    return (
      <Message info>
        <Message.Header>
          You have no active {this.props.type.value} jobs
        </Message.Header>
        <p>To create one, click the "create" button.</p>
      </Message>
    );
  };

  closeCancelModal = () =>
    this.setState({ canceling: false, cancelingLoading: false });

  renderCancelModal = () => {
    return (
      <Modal
        key={'JobWorkerModal'}
        open={this.state.canceling}
        onClose={this.closeCancelModal}
      >
        <Modal.Header>Cancel Upload</Modal.Header>
        <Modal.Content>
          If you cancel a worker's upload, the worker's output data will be
          permanently deleted. Are you sure you want to cancel?
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={this.closeCancelModal}>
            No
          </Button>
          <Button
            primary
            loading={this.state.cancelingLoading}
            onClick={async () => {
              this.setState({ cancelingLoading: true });
              const jobs = this.state.selectedJobs;
              await this.stopJobs(jobs);
              this.closeCancelModal();
            }}
            content='Yes'
          />
        </Modal.Actions>
      </Modal>
    );
  };

  editModal = () => {
    const job_uuid = this.state.selectedJobs[0];
    const job = this.props.jobs.find((job) => job.job_uuid === job_uuid);
    return (
      <Modal
        onClose={() => this.setState({ editModal: false })}
        closeOnDimmerClick
        closeOnEscape
        open={this.state.editModal}
      >
        <Modal.Header>Edit Job</Modal.Header>
        {job ? (
          <Modal.Content>
            <JobEditForm
              job={job}
              onClose={() => this.setState({ editModal: false })}
            />
          </Modal.Content>
        ) : undefined}
      </Modal>
    );
  };

  copyModal = () => {
    const job_uuid = this.state.selectedJobs[0];
    const job = this.props.jobs.find((job) => job.job_uuid === job_uuid);
    return (
      <Modal
        onClose={() => this.setState({ copyModal: false })}
        closeOnDimmerClick
        closeOnEscape
        open={this.state.copyModal}
      >
        <Modal.Header>Copy {job ? job.name : 'Job'}</Modal.Header>
        {job ? (
          <Modal.Content>
            <JobCopyForm
              job={job}
              onClose={(redirect) => {
                if (redirect) {
                  history.push(redirect);
                  return;
                }
                this.setState({ copyModal: false, selectedJobs: [] });
              }}
            />
          </Modal.Content>
        ) : undefined}
      </Modal>
    );
  };

  rerunModal = () => {
    const job_uuid = this.state.selectedJobs[0];
    const job = this.props.jobs.find((job) => job.job_uuid === job_uuid);
    return (
      <Modal
        onClose={() => this.setState({ rerunModal: false })}
        closeOnDimmerClick
        closeOnEscape
        open={this.state.rerunModal}
      >
        <Modal.Header>Rerun {job ? job.name : 'Job'}</Modal.Header>
        {job ? (
          <Modal.Content>
            <JobRerunForm
              job={job}
              onClose={(redirect) => {
                if (redirect) {
                  history.push(redirect);
                  return;
                }
                this.setState({ rerunModal: false, selectedJobs: [] });
              }}
            />
          </Modal.Content>
        ) : undefined}
      </Modal>
    );
  };

  validMenuOptions() {
    const selectedStatuses = new Set(
      this.props.jobs
        .filter(
          (job) =>
            this.state.selectedJobs.includes(job.job_uuid) &&
            !this.state.transitioningJobs.includes(job.job_uuid)
        )
        .map((job) => job.status)
    );
    let options = [];

    if (selectedStatuses.has(STATUSES.RUNNING) && selectedStatuses.size === 1) {
      options.push('stop');
    }
    if (
      selectedStatuses.has(STATUSES.WAITING_GPU) &&
      selectedStatuses.size === 1
    ) {
      options.push('stop');
    }
    if (
      selectedStatuses.has(STATUSES.WAITING_GPU) &&
      selectedStatuses.has(STATUSES.RUNNING) &&
      selectedStatuses.size === 2
    ) {
      options.push('stop');
    }
    if (this.props.type.value === JOB_TYPES.NOTEBOOK.value) {
      if (
        selectedStatuses.has(STATUSES.STOPPED) &&
        this.state.selectedJobs.length === 1
      ) {
        options.push('edit');
        options.push('copy');
      }
      if (
        selectedStatuses.has(STATUSES.RUNNING) &&
        this.state.selectedJobs.length === 1
      ) {
        options.push('copy');
      }
    }
    if (
      [JOB_TYPES.NOTEBOOK.value, JOB_TYPES.ENDPOINT.value].includes(
        this.props.type.value
      )
    ) {
      if (
        selectedStatuses.has(STATUSES.STOPPED) &&
        selectedStatuses.size === 1
      ) {
        options.push('restart');
      }
    }
    if (
      selectedStatuses.has(STATUSES.UPLOADING) &&
      selectedStatuses.size === 1
    ) {
      options.push('stop');
    }

    if (this.state.selectedJobs.length === 1) {
      options.push('rerun');
    }
    if (selectedStatuses.size) {
      options.push('terminate');
    }

    return options;
  }

  renderMenu = () => {
    const validOptions = this.validMenuOptions();
    return (
      <Menu secondary>
        <Menu.Item floated='left' name='home'>
          <Header as='h2'>{this.props.type.label}</Header>
        </Menu.Item>
        <Menu.Item>
          <Link
            to={{
              pathname: `/jobs/${this.props.type.value}/new`,
            }}
          >
            <Button content='Create' basic color='purple' />
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={!validOptions.includes('stop')}
          onClick={() => this.handleStop()}
          name='Stop'
          icon='stop circle'
        />
        {this.props.type.value === JOB_TYPES.NOTEBOOK.value ? (
          <>
            <Menu.Item
              disabled={!validOptions.includes('edit')}
              onClick={() =>
                this.setState({
                  editModal: true,
                })
              }
              name='Edit'
              icon='edit'
            />
            <Menu.Item
              disabled={!validOptions.includes('copy')}
              onClick={() =>
                this.setState({
                  copyModal: true,
                })
              }
              name='copy'
              icon='copy outline'
            />
          </>
        ) : undefined}
        {[JOB_TYPES.NOTEBOOK.value, JOB_TYPES.ENDPOINT.value].includes(
          this.props.type.value
        ) ? (
          <Menu.Item
            disabled={!validOptions.includes('restart')}
            icon='redo'
            name='Restart'
            onClick={() => this.handleRestart()}
          />
        ) : undefined}
        {[JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
          this.props.type.value
        ) ? (
          <Menu.Item
            disabled={!validOptions.includes('rerun')}
            icon='redo'
            name='Rerun'
            onClick={() =>
              this.setState({
                rerunModal: true,
              })
            }
          />
        ) : undefined}
        <Menu.Item
          disabled={!validOptions.includes('terminate')}
          name='Terminate'
          icon='trash alternate'
          onClick={() => this.handleTerminate()}
        />
      </Menu>
    );
  };

  render() {
    const { jobs, type } = this.props;
    return (
      <>
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column>
              <Container>
                <Grid.Row>
                  {this.renderMenu()}
                  <Container fluid>
                    {this.state.mounted ? (
                      jobs.length ? (
                        <Segment.Group>
                          <Table selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>
                                  <Icon
                                    name={
                                      this.props.jobs.length ===
                                        this.state.selectedJobs.length &&
                                      this.state.selectedJobs.length > 0
                                        ? 'check square outline'
                                        : 'square outline'
                                    }
                                    size='large'
                                    onClick={this.handleSelectAll}
                                  />
                                </Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                {[
                                  JOB_TYPES.NOTEBOOK.value,
                                  JOB_TYPES.ENDPOINT.value,
                                ].includes(type.value) ? (
                                  <>
                                    <Table.HeaderCell textAlign='center'>
                                      GPU Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>
                                      GPU Count
                                    </Table.HeaderCell>
                                  </>
                                ) : undefined}
                                <Table.HeaderCell textAlign='center'>
                                  Environment
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>
                                  Model
                                </Table.HeaderCell>
                                {type.value !== JOB_TYPES.ENDPOINT.value ? (
                                  <Table.HeaderCell textAlign='center'>
                                    Data
                                  </Table.HeaderCell>
                                ) : undefined}

                                <Table.HeaderCell textAlign='right'>
                                  Actions
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {jobs.map((job, i) => {
                                return (
                                  <Job
                                    transitioning={this.state.transitioningJobs.includes(
                                      job.job_uuid
                                    )}
                                    key={job.job_uuid}
                                    job={job}
                                    handleSelect={this.handleSelect}
                                    selected={this.state.selectedJobs.includes(
                                      job.job_uuid
                                    )}
                                  />
                                );
                              })}
                            </Table.Body>
                          </Table>
                        </Segment.Group>
                      ) : (
                        <Segment.Group>{this.renderNoJob()}</Segment.Group>
                      )
                    ) : (
                      <Segment>
                        <Dimmer active inverted>
                          <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                      </Segment>
                    )}
                  </Container>
                </Grid.Row>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {[JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
          type.value
        )
          ? this.renderCancelModal()
          : undefined}
        {[JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
          type.value
        )
          ? this.rerunModal()
          : undefined}
        {type.value === JOB_TYPES.NOTEBOOK.value ? this.editModal() : undefined}
        {type.value === JOB_TYPES.NOTEBOOK.value ? this.copyModal() : undefined}
        <NoGpusModal
          errorMessage={this.props.errorMessage}
          clearError={() => this.props.clearErrorMessage()}
        />
      </>
    );
  }
}

function mapStateToProps({ user, jobs, errorMessage }, ownProps) {
  const type = Object.entries(JOB_TYPES)
    .map(([key, value]) => {
      return value.value === ownProps.match.params.type ? value : undefined;
    })
    .filter((type) => type)[0];

  const filtered_jobs = [JOB_TYPES.NOTEBOOK, JOB_TYPES.ENDPOINT].includes(type)
    ? (jobs || []).filter((job) => job.type === type.value)
    : (jobs || [])
        .filter((job) => job.type === type.value)
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
  return {
    user,
    jobs: filtered_jobs,
    errorMessage,
    type,
  };
}

export default connect(mapStateToProps, {
  fetchJobs,
  fetchGpuClasses,
  clearErrorMessage,
  fetchJob,
  updateJob,
  terminateJob,
  verifyPublicDatasets,
  fetchDatasets,
  fetchProjectDatastores,
})(Dashboard);
