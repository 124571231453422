import axios from 'axios';
import store from '../store';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    if (
      config.baseURL === process.env.REACT_APP_API_URL &&
      !config.headers.Authorization
    ) {
      if (store.getState().session.isLoggedIn) {
        config.headers.Authorization = `${
          store.getState().session.credentials.idToken
        }`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
