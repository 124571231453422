import _ from 'lodash';
import {
  FETCH_REGIONS,
  FETCH_REGION,
  CREATE_REGION,
  DELETE_REGION,
} from '../actions/types';
import PROVIDER_TYPES from '../components/resources/providerTypes';

const initialState = {
  [PROVIDER_TYPES.PHYSICAL.value]: null,
  [PROVIDER_TYPES.GCP.value]: null,
  [PROVIDER_TYPES.AWS.value]: null,
  [PROVIDER_TYPES.AZURE.value]: null,
};
let newState, newRegions;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REGIONS:
      newState = { ...state };
      newState[action.provider_type] = [...(action.payload || [])].sort(
        (a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      );

      return newState;
    case FETCH_REGION: {
      newState = { ...state };
      newRegions = newState[action.provider_type];
      _.remove(newRegions, (region) => {
        return region.region_uuid === action.payload.region_uuid;
      });
      newState[action.provider_type] = [...newRegions, action.payload].sort(
        (a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      );
      return newState;
    }
    case CREATE_REGION: {
      newState = { ...state };
      newState[action.provider_type] = [
        ...newState[action.provider_type],
        action.payload,
      ].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return newState;
    }
    case DELETE_REGION: {
      newState = { ...state };
      newRegions = newState[action.provider_type];
      _.remove(newRegions, (region) => {
        return region.region_uuid === action.payload;
      });
      newState[action.provider_type] = newRegions;
      return newState;
    }
    default:
      return state;
  }
}
export default reducer;
