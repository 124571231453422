import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { submitJob } from '../../actions/jobs';

import WorkerFields from './JobForm/WorkerFields';
import RequiredFields from './JobForm/RequiredFields';
import DataFields from './JobForm/DataFields';
import EnvironmentFields from './JobForm/EnvironmentFields';
import { fetchEnvironments } from '../../actions/environments';
import { fetchCredentials } from '../../actions/projectCredentials';
import ModelFields from './JobForm/ModelFields';

class JobRerunForm extends Component {
  state = {
    pristine: true,
    submitting: false,
    name_error: false,
    name: this.props.job.name,
    resources: this.props.job.resources,
    resources_error: false,
    data: this.props.job.data,
    data_error: false,
    model: this.props.job.model,
    model_error: false,
    workers: this.props.job.workers.map((worker) => worker.command),
    workers_error: false,
    environment: this.props.job.environment,
    environment_error: false,
    form_error: true,
    trainml_type: 'model',
    save_model: 'model',
  };

  componentDidMount() {
    this.props.fetchEnvironments();
    this.props.fetchCredentials();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      resources_error,
      workers_error,
      data_error,
      model_error,
      environment_error,
    } = this.state;
    const form_error =
      resources_error ||
      workers_error ||
      data_error ||
      model_error ||
      environment_error;
    if (prevState.form_error !== form_error) {
      this.setState({ form_error });
    }
  }

  handleChange = (e, { name, value }) => {
    switch (name) {
      case 'name':
        this.setState({
          [name]: value,
          name_error: value === '' || /[#$<>+%!`&*'"|{}?/\\:=@]/.test(value),
          pristine: false,
        });
        break;
      default:
        this.setState({
          [name]: value,
          pristine: false,
        });
    }
  };

  handleFormChange = (type, values, error) => {
    if (type === 'resources') {
    }
    this.setState({
      pristine: false,
      [type]: values,
      [`${type}_error`]: error,
      name: type === 'resources' ? values.job_name : this.state.name,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      submitting: true,
    });

    let new_job = {
      name: this.state.name,
      type: this.props.job.type,
      resources: { ...this.state.resources },
      data: { ...this.state.data },
      model: { ...this.state.model },
      workers: [...this.state.workers],
      environment: { ...this.state.environment },
    };
    await this.props.submitJob(new_job);

    this.props.onClose();
  };

  render() {
    const { pristine, submitting, name, name_error, form_error } = this.state;
    const { job } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <RequiredFields
          onChange={this.handleFormChange}
          initialValues={{ ...job.resources, job_name: name }}
          small
        />
        <DataFields
          type={job.type}
          initialValues={job.data}
          onChange={this.handleFormChange}
        />
        <ModelFields
          type={job.type}
          initialValues={job.model}
          onChange={this.handleFormChange}
        />
        <WorkerFields
          type={job.type}
          initialValues={job.workers}
          onChange={this.handleFormChange}
        />
        <EnvironmentFields
          type={job.type}
          initialValues={job.environment}
          onChange={this.handleFormChange}
        />

        <br />
        <Button
          floated='left'
          secondary
          onClick={(e) => {
            e.preventDefault();
            this.props.onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          floated='right'
          primary
          disabled={pristine || name_error || !name || form_error}
          loading={submitting}
          onClick={this.handleSubmit}
        >
          Create
        </Button>
        <br />
        <br />
      </Form>
    );
  }
}

export default connect(null, {
  submitJob,
  fetchEnvironments,
  fetchCredentials,
})(JobRerunForm);
