import React from 'react';

import { Box, Typography, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import renderProgress from '../../../shared/renderProgress';

export default function GCPQuotas({ providerMetadata, selected_region }) {
  const region = providerMetadata.find((region) => {
    return region.name === selected_region;
  });

  if (!region) {
    return <Box padding='15px' sx={{ display: 'flex', width: '100%' }}></Box>;
  } else {
    const rows = Object.entries(region.quotas)
      .map(([quota, values]) => {
        if (quota === 'a2_cpus') {
          return {
            id: quota,
            quota,
            on_demand: {
              total: values.on_demand.limit,
              used: values.on_demand.usage,
            },
            preemptible: {
              total: values.preemptible.limit,
              used: values.preemptible.usage,
            },
            committed: {
              total: values.committed.limit,
              used: values.committed.usage,
            },
          };
        } else {
          return {
            id: quota,
            quota,
            on_demand: {
              total: values.on_demand.limit,
              used: values.on_demand.usage,
            },
            preemptible: {
              total: values.preemptible.limit,
              used: values.preemptible.usage,
            },
            committed: {
              total: values.committed.limit,
              used: values.committed.usage,
            },
          };
        }
      })
      .sort((a, b) => {
        return a.id > b.id;
      });

    const columns = [
      { field: 'quota', headerName: 'Quota', width: 150 },
      {
        field: 'on_demand',
        headerName: 'On-Demand',
        renderCell: renderProgress,
      },
      {
        field: 'preemptible',
        headerName: 'Preemptible',
        renderCell: renderProgress,
      },
      {
        field: 'committed',
        headerName: 'Committed',
        renderCell: renderProgress,
      },
    ];

    return (
      <Box
        padding='15px'
        sx={{ display: 'flex', flexGrow: 1, width: '100%', height: '560px' }}
      >
        <Stack
          spacing={2}
          direction='column'
          sx={{ display: 'flex', flexGrow: 1, width: '100%' }}
        >
          <Typography variant='h6'>Region {region.name} quotas </Typography>
          <DataGrid rows={rows} columns={columns} hideFooterPagination />
        </Stack>
      </Box>
    );
  }
}
