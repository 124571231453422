import React, { Component } from 'react';
import { Grid, Header, Segment, Icon, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import THIRD_PARTY_TYPES from '../JobForm/thirdPartyTypes';

class DataReview extends Component {
  state = {
    expanded: true,
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (!this.props.data) {
      return;
    }
    const {
      datasets,
      input_type,
      input_uri,
      input_options,
      output_type,
      output_uri,
      output_options,
    } = this.props.data;

    let input_datastore_name, output_datastore_name;

    if (input_type === THIRD_PARTY_TYPES.REGIONAL.value) {
      const datastore = this.props.projectDatastores.find(
        (datastore) => datastore.id === input_uri
      );
      if (datastore) {
        input_datastore_name = datastore.name;
      }
    }

    if (output_type === THIRD_PARTY_TYPES.REGIONAL.value) {
      const datastore = this.props.projectDatastores.find(
        (datastore) => datastore.id === output_uri
      );
      if (datastore) {
        output_datastore_name = datastore.name;
      }
    }

    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon
              name={this.state.expanded ? 'triangle down' : 'triangle right'}
            />
            Data
          </Message.Header>
        </Message>
        {this.state.expanded ? (
          <>
            <Segment attached>
              <Grid divided columns='equal'>
                <Grid.Row>
                  {datasets.length ? (
                    <Grid.Column>
                      <Header as='h4'>Input</Header>
                      {datasets.length === 1 ? (
                        <p>
                          Load dataset <b>{datasets[0].name}</b> into{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/input
                          </span>
                        </p>
                      ) : (
                        datasets.map((dataset) => {
                          return (
                            <p key={dataset.dataset_uuid}>
                              Load dataset <b>{dataset.name}</b> into{' '}
                              <span
                                style={{
                                  fontFamily:
                                    '"Courier New", Courier, monospace',
                                }}
                              >
                                /opt/trainml/input/
                                {dataset.name
                                  .toLowerCase()
                                  .replaceAll(' ', '_')}
                              </span>
                            </p>
                          );
                        })
                      )}
                    </Grid.Column>
                  ) : undefined}
                  {input_type ? (
                    <Grid.Column>
                      <Header as='h4'>Input</Header>
                      {input_type === THIRD_PARTY_TYPES.REGIONAL.value ? (
                        <p>
                          Map{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            {input_options.path}
                          </span>{' '}
                          from regional datastore <b>{input_datastore_name}</b>{' '}
                          to{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/input/
                          </span>
                        </p>
                      ) : (
                        <p>
                          Load <b>{input_uri}</b> from <b>{input_type}</b> into{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/input/
                          </span>
                        </p>
                      )}
                    </Grid.Column>
                  ) : undefined}
                  {output_type ? (
                    <Grid.Column>
                      <Header as='h4'>Output</Header>
                      {output_type === 'trainml' ? (
                        <p>
                          Create{' '}
                          <b>
                            {process.env.REACT_APP_ORG_NAME}{' '}
                            {output_uri ? output_uri : 'model'}
                          </b>{' '}
                          from contents of{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/
                            {output_uri && output_uri !== 'model'
                              ? 'output'
                              : 'models'}
                          </span>
                          {output_options && Boolean(output_options.interval)
                            ? ` every ${output_options.interval} minutes.`
                            : undefined}
                        </p>
                      ) : output_type === THIRD_PARTY_TYPES.REGIONAL.value ? (
                        <p>
                          Save job results from{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/output
                          </span>{' '}
                          to{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            {output_options.path}
                          </span>{' '}
                          in regional datastore <b>{output_datastore_name}</b>{' '}
                        </p>
                      ) : (
                        <p>
                          Upload results in{' '}
                          <span
                            style={{
                              fontFamily: '"Courier New", Courier, monospace',
                            }}
                          >
                            /opt/trainml/output
                          </span>{' '}
                          to <b>{output_uri}</b> on <b>{output_type}</b>
                        </p>
                      )}
                    </Grid.Column>
                  ) : undefined}
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ projectDatastores }) {
  return { projectDatastores };
}

export default connect(mapStateToProps, null)(DataReview);
