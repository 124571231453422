import React from 'react';

import { FormControl, Box, MenuItem, InputLabel, Select } from '@mui/material';

import CREDENTIAL_TYPES from './credentialTypes';
import THIRD_PARTY_TYPES from 'components/jobs/JobForm/thirdPartyTypes';

export default function AddCredentialButton({ credentials, setSelected }) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;

    setSelected(newValue);
  };
  const current_types = (credentials || []).map(
    (credential) => credential.type
  );
  const third_party_types = Object.values(THIRD_PARTY_TYPES);

  return (
    <FormControl fullWidth>
      <InputLabel id='add-credential-label'>Add Credential</InputLabel>
      <Select
        labelId='add-credential-label'
        id='credential_type'
        label='Add Credential'
        aria-describedby='add-credential-helper-text'
        onChange={handleChange}
      >
        {CREDENTIAL_TYPES.map((credential_type) => {
          const third_party_type = third_party_types.find(
            (type) => type.value === credential_type.type
          );
          return (
            <MenuItem
              key={third_party_type.key}
              disabled={current_types.includes(credential_type.type)}
              value={third_party_type.value}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                {third_party_type.text}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
