import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const mock = [
  {
    title: '1. Definitions',
    description: (
      <>
        <p>
          As used in this Agreement and in any Order Form now or hereafter
          associated herewith:
        </p>
        <p>
          “Agreement” means these online terms of use, any online Order Form,
          and any materials available on the {process.env.REACT_APP_ORG_NAME}{' '}
          website specifically incorporated by reference herein, as such
          materials, including the terms of this Agreement, may be updated by{' '}
          {process.env.REACT_APP_ORG_NAME} from time to time in its sole
          discretion;
        </p>
        <p>
          “Authorized User(s)” mean you and anyone you authorize to use the
          Services when you supply user identifications to{' '}
          {process.env.REACT_APP_ORG_NAME};
        </p>
        <p>
          “Customer Data” means any data, information or material provided or
          submitted by you to the Services in the course of using the Services,
          including, but not limited to, Personal Data;
        </p>
        <p>
          “Personal Data” means all information relating to a person that
          identifies such person or could reasonably be used to identify such
          person, including but not limited to, as first and last name, home
          address, billing address, or other physical address, email address,
          telephone number and Sensitive Data, if any;
        </p>
        <p>
          “Sensitive Data” means any information that: (a) requires a high
          degree of protection by law and where loss or unauthorized disclosure
          would require notification by Customer to government agencies,
          individuals or law enforcement, (b) any information that, if made
          public, could expose individuals to a risk of physical harm, fraud, or
          identity theft; including, but not limited to, social security numbers
          or other government-issued identification numbers, financial account
          numbers, credit card or debit card numbers, CVVs, credit report
          information or other personal financial information, health or medical
          information or other information that is subject to international,
          federal, state, or local laws or ordinances now or hereafter enacted
          regarding data protection or privacy, including, but not limited to,
          the Health Insurance Portability and Accountability Act, the Health
          Information Technology for Economic and Clinical Health Act, the Fair
          Credit Reporting Act, the Children&apos;s Online Privacy Protection
          Act and the Gramm-Leach-Bliley Act;
        </p>
        <p>
          “Effective Date” means the date this Agreement is accepted by click
          the “Submit” button presented on the screen this Agreement is
          displayed;
        </p>
        <p>
          “Intellectual Property Rights” means unpatented inventions, patent
          applications, patents, design rights, copyrights, trademarks, service
          marks, trade names, domain name rights, mask work rights, know-how and
          other trade secret rights, and all other intellectual property rights,
          derivatives thereof, and forms of protection of a similar nature
          anywhere in the world;
        </p>
        <p>
          “Order Form(s)” means the form evidencing the initial purchase of the
          Services and any subsequent order forms submitted online specifying,
          among other things, the number of Credits (“Credits”), the frequency
          of automated Credit purchases, if any, the service offering selected,
          if any, the amount of additional storage, if any, and other services
          contracted for, the applicable fees, the billing period, and other
          charges as agreed to between the parties, each such Order Form to be
          incorporated into and to become a part of this Agreement (in the event
          of any conflict between the terms of this Agreement and the terms of
          any such Order Form, the terms of this Agreement shall prevail);
        </p>
        <p>
          “{process.env.REACT_APP_ORG_NAME}” means{' '}
          {process.env.REACT_APP_ORG_NAME},{' '}
          {process.env.REACT_APP_ORG_NAME === 'trainML'
            ? 'LLC., an Illinois Limited Liability Company'
            : 'Inc., a Delaware Corporation'}
          , having its principal place of business at 813 Forest Ave., Oak Park,
          IL 60302;
        </p>
        <p>
          “Services” mean the online computing and storage infrastructure rental
          capabilities including all of {process.env.REACT_APP_ORG_NAME}&apos;s
          proprietary technology (including software, hardware, products,
          processes, algorithms, user interfaces, know-how, techniques, designs
          and other tangible or intangible technical material or information)
          made available to you by
          {process.env.REACT_APP_ORG_NAME} in providing the Services and any
          audio and visual information, documents, software, products and
          services contained or made available to you in the course of using the
          Services.
        </p>
      </>
    ),
  },
  {
    title: '2. Services and Restrictions',
    description: (
      <>
        <p>
          Subject to the terms and conditions of this Agreement,{' '}
          {process.env.REACT_APP_ORG_NAME} grants to you a non-exclusive,
          non-transferable, right to use the Services including any enhanced
          functionality or additional services and selected on the Order Form
          (collectively referred to as “Selected Services”) as you may update
          from time to time by executing a new Order Form. All rights not
          expressly granted to you are reserved by{' '}
          {process.env.REACT_APP_ORG_NAME}. You acknowledge that{' '}
          {process.env.REACT_APP_ORG_NAME}&apos;s provision of the Services is
          dependent in part on each Authorized User&apos;s compliance with the
          terms of this Agreement.
        </p>
        <p>
          You shall not directly or indirectly, (i) license, sublicense, sell,
          resell, transfer, assign, distribute or otherwise commercially exploit
          the Services in any way; (ii) reverse engineer, decompile, disassemble
          or otherwise attempt to discover the source code, object code, or any
          software, documentation or data related to the Services, (iii) modify
          or make derivative works based upon the Services; (iv) copy or create
          Internet “links” to the Services or “frame” or “mirror” any of the
          Services; or (v) access the Services in order to (a) build a
          competitive product or service, (b) build a product using similar
          ideas, features, functions or graphics of the Services, or (c) copy
          any ideas, features, functions or graphics of the Services.
        </p>
      </>
    ),
  },
  {
    title: '3. Your Responsibilities',
    description: (
      <>
        <p>
          You are responsible for all activity occurring under your User
          account(s), including but not limited to uploading any of your data
          onto the Services. You shall: (i) use the Services in compliance with
          all applicable local, state, national and foreign laws, treaties and
          regulations in connection with your use of the Services, including
          those related to data privacy, international communications, export
          laws and the transmission of technical or personal data laws.
        </p>
        <p>
          Except as permitted under this Agreement or as required by law,
          Customer will not, and will not permit or encourage anyone else, to:
          (i) license, sublicense, sell, resell, transfer, assign, distribute,
          use as a service bureau or timeshare, or otherwise commercially
          exploit or make the Services available to any third party in any way;
          (ii) modify, disassemble or make derivative works based upon the
          Services or otherwise attempt to derive source code or other trade
          secrets from the Services; (iii) reverse engineer or access the
          Services in order to (a) build a competitive product or service, (b)
          build a product using similar ideas, features, functions or graphics
          of the Services, or (c) copy any ideas, features, functions or
          graphics of the Services; (iv) modify, remove or obstruct any
          proprietary rights statement or notice contained in the Services; (v)
          send spam or otherwise duplicative or unsolicited messages in
          violation of applicable laws; (vi) send or store (a) infringing,
          obscene, threatening, libelous, or otherwise unlawful or tortious
          material, including material harmful to children or which violates
          third party privacy rights; or (b) material containing software
          viruses, worms, Trojan horses or other harmful computer code, files,
          scripts, agents or programs; (vii) attempt to gain unauthorized access
          to the Services or its related systems or networks; (viii) access the
          Services if you are a direct competitor of{' '}
          {process.env.REACT_APP_ORG_NAME}, except if{' '}
          {process.env.REACT_APP_ORG_NAME}
          agrees in writing before you access the Services; (ix) use the
          Services in excess of the number of sessions selected in the
          applicable Order; or (x) provide false identity information to access
          or use the Services.
        </p>
        <p>
          {process.env.REACT_APP_ORG_NAME} is not responsible to you for
          unauthorized access to your data or the unauthorized use of the
          Services. You are responsible for the use of the Services by any
          person to whom you have given access to the Services, and any person
          who gains access to your data, even if you did not authorize such use.
        </p>
        <p>
          You shall be responsible for obtaining and maintaining any equipment
          and ancillary services needed to connect to, access or otherwise use
          the Services, including, without limitation, modems, hardware, server,
          software, operating system, networking, web servers, long distance and
          local telephone service (collectively, “Equipment”). You shall be
          responsible for ensuring that such Equipment is compatible with the
          Services. You shall also be responsible for maintaining the security
          of the Equipment, your User account, Users passwords and files, and
          for all uses of your User account or the Equipment with or without
          your knowledge or consent. {process.env.REACT_APP_ORG_NAME} reserves
          the right to refuse registration of, or cancel accounts it deems
          inappropriate.
        </p>
        <p>
          You must cooperate with {process.env.REACT_APP_ORG_NAME}&apos;s
          reasonable investigation of Services&apos; outages, security problems,
          and any suspected breach of the Agreement. You shall: (i) notify{' '}
          {process.env.REACT_APP_ORG_NAME} immediately of any unauthorized use
          of any password or account or any other known or suspected breach of
          security; (ii) report to {process.env.REACT_APP_ORG_NAME} immediately
          and use reasonable efforts to stop immediately any copying or
          distribution of the Services that is known or suspected by you or your
          Users; and (iii) not impersonate another{' '}
          {process.env.REACT_APP_ORG_NAME} user or provide false identity
          information to gain access to or use the Services.
        </p>
        <p>
          You shall indemnify and hold {process.env.REACT_APP_ORG_NAME}, its
          licensors and each such party&apos;s parent organizations,
          subsidiaries, affiliates, officers, directors, employees, attorneys
          and agents harmless from and against any and all claims, costs,
          damages, losses, liabilities and expenses (including attorneys&apos;
          fees and costs) arising out of or in connection with: (i) a claim
          alleging that use of the Customer Data infringes the rights of, or has
          caused harm to, a third party; (ii) a claim, which if true, would
          constitute a violation by you of your representations and warranties;
          or (iii) a claim arising from the breach by you or your Users of this
          Agreement, provided in any such case that{' '}
          {process.env.REACT_APP_ORG_NAME} (a) gives written notice of the claim
          promptly to you; (b) gives you sole control of the defense and
          settlement of the claim (provided that you may not settle or defend
          any claim unless you unconditionally release{' '}
          {process.env.REACT_APP_ORG_NAME} of all liability and such settlement
          does not affect {process.env.REACT_APP_ORG_NAME}&apos;s business or
          Services); (c) provides to you all available information and
          assistance; and (d) has not compromised or settled such claim.
        </p>
      </>
    ),
  },
  {
    title: '4. Your Account Information and Data',
    description: (
      <>
        <p>
          {process.env.REACT_APP_ORG_NAME} does not own any data, information or
          material that you submit to the Services in the course of using the
          Services (“Customer Data”). You, not, {process.env.REACT_APP_ORG_NAME}
          , shall have sole responsibility for the accuracy, quality, integrity,
          legality, reliability, appropriateness, and intellectual property
          ownership or right to use of all Customer Data, and{' '}
          {process.env.REACT_APP_ORG_NAME} shall not be responsible or liable
          for the deletion, correction, destruction, damage, loss or failure to
          store any Customer Data.
        </p>
        <p>
          Customer grants to {process.env.REACT_APP_ORG_NAME} a non-exclusive
          royalty-free license to access and use Customer Data in order to
          provide the Services to Customer and as necessary to monitor and
          improve the Services. {process.env.REACT_APP_ORG_NAME} will not (a)
          disclose Customer Data except as compelled by law or as expressly
          permitted in writing by you, or (b) access Customer Data except to
          provide the Services or prevent or address service or technical
          problems, or at your request in connection with customer support
          matters. For the avoidance of doubt, {process.env.REACT_APP_ORG_NAME}{' '}
          may use, reproduce and disclose Customer Data that is anonymized,
          de-identified, or is otherwise not reasonably associated or linked to
          Customer (or any other identifiable individual person or entity)
          (“Anonymized Data”) for product improvement and other purposes
          consistent with {process.env.REACT_APP_ORG_NAME}&apos;s Privacy
          Policy. This right to use Anonymized Data will survive termination of
          this Agreement. Customer, not {process.env.REACT_APP_ORG_NAME}, will
          have sole responsibility for the accuracy, quality, integrity,
          legality, reliability, appropriateness, and intellectual property
          ownership or right to use of all Customer Data.
        </p>
        <p>
          Customer agrees that it will not provide any Sensitive Data to{' '}
          {process.env.REACT_APP_ORG_NAME}. If Customer discovers that due to
          human error or otherwise, Customer Data does include Sensitive Data,
          Customer will promptly notify {process.env.REACT_APP_ORG_NAME} and
          provide sufficient information to {process.env.REACT_APP_ORG_NAME} to
          locate such Sensitive Data and {process.env.REACT_APP_ORG_NAME} will
          scrub its systems and the Sensitive Data in its control or possession.{' '}
          {process.env.REACT_APP_ORG_NAME} will maintain appropriate
          administrative, physical, and technical safeguards for protection of
          the security, confidentiality and integrity of Customer Data and will
          comply with the {process.env.REACT_APP_ORG_NAME} Privacy Policy.
        </p>
      </>
    ),
  },
  {
    title: '5. Support',
    description: (
      <p>
        Subject to the terms of this Agreement, {process.env.REACT_APP_ORG_NAME}{' '}
        will provide Customer with reasonable technical support services in
        accordance with {process.env.REACT_APP_ORG_NAME}&apos;s standard support
        offering. Our servers may be unavailable as the result of planned or
        unplanned downtime, for reasons including technical issues, legal
        compliance, security actions, business decisions, or any other cause. We
        may attempt to inform you if such downtime is planned, but we are under
        no obligation to do so. You agree that we are not liable for the
        unavailability of our Service.
      </p>
    ),
  },
  {
    title: '6. Privacy',
    description: (
      <p>
        Customer agrees to comply with {process.env.REACT_APP_ORG_NAME}&apos;s
        Privacy Policy. {process.env.REACT_APP_ORG_NAME} reserves the right to
        modify the {process.env.REACT_APP_ORG_NAME} Privacy Policy in its
        reasonable discretion from time to time.
      </p>
    ),
  },
  {
    title: `7. ${process.env.REACT_APP_ORG_NAME}'s Intellectual Property Ownership`,
    description: (
      <p>
        {process.env.REACT_APP_ORG_NAME} alone (and its licensors, where
        applicable) shall own all right, title and interest, including all
        related Intellectual Property Rights, in and to the Services (or any
        underlying technology or content within the Services) and any
        suggestions, ideas, enhancement requests, feedback, recommendations or
        other information provided by you or any other party relating to the
        Services. This Agreement is not a sale and does not convey to you any
        rights of ownership in or related to the Services, the technology used
        by the Services, or the Intellectual Property Rights owned by{' '}
        {process.env.REACT_APP_ORG_NAME}. The {process.env.REACT_APP_ORG_NAME}{' '}
        name, the {process.env.REACT_APP_ORG_NAME} logo, and the product names
        associated with the Services are trademarks of{' '}
        {process.env.REACT_APP_ORG_NAME} or third parties, and no right or
        license is granted to use them.
      </p>
    ),
  },
  {
    title: '8. Credits',
    description: (
      <p>
        All transactions for compute services on the{' '}
        {process.env.REACT_APP_ORG_NAME} platform take place in Credits.{' '}
        {process.env.REACT_APP_ORG_NAME} Credits can only be redeemed for USD by
        Customers with a valid Supplier account. Credits are non-transferrable.{' '}
        {process.env.REACT_APP_ORG_NAME}
        may, at its sole discretion, issue Credit coupons to Customer.{' '}
        <b>
          CREDITS ISSUED AS COUPONS MAY NOT BE USED FOR CRYPTOCURRENCY OR
          BLOCKCHAIN PURPOSES
        </b>
        . Paid coupons may be used for any purpose.
      </p>
    ),
  },
  {
    title: '9. Charges and Payment of Fees',
    description: (
      <>
        <p>
          When applicable, you shall pay {process.env.REACT_APP_ORG_NAME} the
          then applicable fees for the Services as noted on your most recent
          Order Form. You are responsible for keeping your billing and other
          account information up to date. You must pay when due the fees for the
          Services stated in the Order Form or other agreement between us. The
          initial charges will be equal to the current number of total Selected
          Services on the Order Form. All payment obligations are non-cancelable
          and all amounts paid are nonrefundable. You must provide{' '}
          {process.env.REACT_APP_ORG_NAME} with valid credit card as a condition
          to use the Services. You may add or delete Selected Services by
          executing an additional online Order Form. Added Selected Services
          will be subject to the following: (i) added Selected Services will be
          coterminous with the preexisting Order Form; (ii) the applicable
          license fee for the added Selected Services will be the then current,
          generally applicable license fee; and (iii) Selected Services added in
          the middle of a billing month may be charged for partial use for that
          billing month. If selected on the Order Form, you agree that{' '}
          {process.env.REACT_APP_ORG_NAME} will automatically charge your
          preferred billing method for the “top up amount” if your account
          “credits” are below your “top up threshold”.{' '}
          {process.env.REACT_APP_ORG_NAME} reserves the right to modify its fees
          and charges and to introduce new charges at any time, upon at least 30
          days prior notice to you, which notice may be provided by e-mail.
        </p>
        <p>
          The Fees do not include any taxes, levies, duties or similar
          governmental assessments of any nature, including, for example,
          value-added, sales, use or withholding taxes, assessable by any
          jurisdiction whatsoever (collectively, “Taxes”). Customer is
          responsible for paying all Taxes associated with its purchases under
          this Agreement and any Order Form. If {process.env.REACT_APP_ORG_NAME}{' '}
          has the legal obligation to pay or collect Taxes for which Customer is
          responsible under this Section 9, {process.env.REACT_APP_ORG_NAME}{' '}
          will invoice Customer and Customer will pay that amount unless
          Customer provides {process.env.REACT_APP_ORG_NAME} with a valid tax
          exemption certificate authorized by the appropriate taxing authority.
        </p>
        <p>
          If any amount owing by Customer under this or any other Agreement with{' '}
          {process.env.REACT_APP_ORG_NAME} delinquent,{' '}
          {process.env.REACT_APP_ORG_NAME} may, without limiting its other
          rights and remedies, accelerate Customer&apos;s unpaid fee obligations
          under such Order Form so that all such obligations become immediately
          due and payable, and suspend any and all services until such amounts
          are paid in full. Customer will continue to be charged fees during any
          period of suspension. If Customer or {process.env.REACT_APP_ORG_NAME}{' '}
          initiates termination of this Agreement, Customer will be obligated to
          pay the balance due on Customer&apos;s account. Any use of the
          Services in violation of the Agreement by Customer that in{' '}
          {process.env.REACT_APP_ORG_NAME}&apos;s reasonable judgment threatens
          the security, integrity or availability of{' '}
          {process.env.REACT_APP_ORG_NAME}&apos;s services or that of its other
          customers, may result in {process.env.REACT_APP_ORG_NAME}&apos;s
          immediately suspending the Services.
        </p>
      </>
    ),
  },
  {
    title: '10. Term and Termination',
    description: (
      <>
        <p>
          This Agreement will commence upon the initial purchase of Credits and
          will remain in effect until Customer&apos;s Credit balance is
          exhausted and Customer provides written notice to{' '}
          {process.env.REACT_APP_ORG_NAME} to terminate the agreement. The
          Agreement will automatically renew for each new purchase of Credits.
        </p>
        <p>
          Either party may terminate this Agreement or any Order Form at any
          time by giving written notice to the other party in the event that the
          other party is in breach of any of its obligations under this
          Agreement or any Order Form and fails to remedy such breach within
          thirty (30) days after written notice from the other party.
        </p>
        <p>
          Customer may terminate an Order Form for convenience but all payment
          obligations are non-cancelable and all amounts paid are
          non-refundable, except in the event of a termination by Customer for
          breach of the Agreement as set forth above.
        </p>
        <p>
          Upon expiration or termination of this Agreement for any reason,{' '}
          {process.env.REACT_APP_ORG_NAME} will promptly terminate the Services.{' '}
          {process.env.REACT_APP_ORG_NAME} will have no obligation to maintain
          or provide any Customer Data and may thereafter, unless legally
          prohibited, delete all Customer Data in its systems or otherwise in
          its possession or under its control. Sections 1, 7 through 16 will
          survive any termination or expiration of this Agreement. All other
          rights and obligations will be of no further force or effect.
          Termination will not relieve either party from any liability arising
          from any breach of this Agreement. Neither party will be liable to the
          other for damages of any kind solely as a result of terminating this
          Agreement in accordance with its terms. Furthermore, termination of
          this Agreement by a party will be without prejudice to any other right
          or remedy of a party under this Agreement or applicable law.
        </p>
      </>
    ),
  },
  {
    title: `11. Representations & Warranties`,
    description: (
      <p>
        Each party represents and warrants that it has the legal power and
        authority to enter into this Agreement. You represent and warrant that
        you have not falsely identified yourself nor provided any false
        information to gain access to the Services and that your billing
        information is correct.
      </p>
    ),
  },
  {
    title: '12. Disclaimer of Warranties',
    description: (
      <>
        <p>
          {process.env.REACT_APP_ORG_NAME.toUpperCase()} AND ITS LICENSORS MAKE
          NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY,
          TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR
          COMPLETENESS OF THE SERVICES.{' '}
          {process.env.REACT_APP_ORG_NAME.toUpperCase()}
          AND ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE
          SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR
          OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR
          DATA, (B) THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,
          (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF
          ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
          OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR REQUIREMENTS OR
          EXPECTATIONS, (E) ERRORS OR DEFECTS WILL BE CORRECTED, OR (F) THE
          SERVICES OR THE SERVER(S) THAT MAKE THE SERVICES AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICES IS PROVIDED TO YOU
          STRICTLY ON AN “AS IS” BASIS. ALL CONDITIONS, REPRESENTATIONS AND
          WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT
          OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW BY{' '}
          {process.env.REACT_APP_ORG_NAME.toUpperCase()} AND ITS LICENSORS.
        </p>
        <p>
          {process.env.REACT_APP_ORG_NAME.toUpperCase()} SERVICES MAY BE SUBJECT
          TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE
          INTERNET AND ELECTRONIC COMMUNICATIONS.{' '}
          {process.env.REACT_APP_ORG_NAME.toUpperCase()} IS NOT RESPONSIBLE FOR
          ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH
          PROBLEMS.
        </p>
      </>
    ),
  },
  {
    title: '13. Limitation of Liability',
    description: (
      <>
        <p>
          IN NO EVENT SHALL EITHER PARTY&apos;S AGGREGATE LIABILITY EXCEED THE
          AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM YOU IN THE TWELVE (12) MONTH
          PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. IN
          NO EVENT SHALL EITHER PARTY AND/OR ITS LICENSORS BE LIABLE TO ANYONE
          FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL,
          CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF
          DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT
          OF, OR IN ANY WAY CONNECTED WITH THE SERVICES, INCLUDING BUT NOT
          LIMITED TO THE USE OR INABILITY TO USE THE SERVICES, ANY INTERRUPTION,
          INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE, EVEN IF THE PARTY
          FROM WHICH DAMAGES ARE BEING SOUGHT OR SUCH PARTY&apos;S LICENSORS
          HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          Certain states and/or jurisdictions do not allow the exclusion of
          implied warranties or limitation of liability for incidental,
          consequential or certain other types of damages, so the exclusions set
          forth above may not apply to you.
        </p>
      </>
    ),
  },
  {
    title: '14. Export Control',
    description: (
      <>
        <p>
          You acknowledge and agree that the {process.env.REACT_APP_ORG_NAME}
          &apos;s Services shall not be used, and none of the underlying
          information, software, or technology may be transferred or otherwise
          exported or re-exported to countries as to which the United States
          maintains an embargo, or to or by a national or resident thereof, or
          any person or entity on the U.S. Department of Treasury&apos;s List of
          Specially Designated Nationals or the U.S. Department of
          Commerce&apos;s Table of Denial Orders By using the Services, you
          represent and warrant that you are not located in, under the control
          of, or a national or resident of an embargoed country or designated
          national. You agree to comply strictly with all U.S. export laws and
          assume sole responsibility for obtaining licenses to export or
          re-export as may be required. This site may use encryption technology
          that is subject to licensing requirements under the U.S. Export
          Administration Regulations, 15 C.F.R. Parts 730-774.
          {process.env.REACT_APP_ORG_NAME} and its licensors make no
          representation that the Services is appropriate or available for use
          in other locations. If you use the Services from outside the United
          States of America you are solely responsible for compliance with all
          applicable laws, including without limitation export and import
          regulations of other countries. Any diversion of the Services contrary
          to United States law is prohibited.
        </p>
      </>
    ),
  },
  {
    title: '15. Notice',
    description: (
      <>
        <p>
          {process.env.REACT_APP_ORG_NAME} may give notice by means of a general
          notice on the Services, electronic mail to your e-mail address on
          record in {process.env.REACT_APP_ORG_NAME}&apos;s account information,
          or by written communication sent by first class mail or pre-paid post
          to your address on record in {process.env.REACT_APP_ORG_NAME}&apos;s
          account information. Such notice shall be deemed to have been given
          upon the expiration of 48 hours after mailing or posting (if sent by
          first class mail or pre-paid post) or 12 hours after sending (if sent
          by email). You may give notice to {process.env.REACT_APP_ORG_NAME}{' '}
          (such notice shall be deemed given when received by{' '}
          {process.env.REACT_APP_ORG_NAME}) at any time by any of the following:
          letter delivered by nationally recognized overnight delivery service
          or first class postage prepaid mail to{' '}
          {process.env.REACT_APP_ORG_NAME} at the following address:
        </p>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
          Customer Service{'\n'}
          {process.env.REACT_APP_ORG_NAME},{' '}
          {process.env.REACT_APP_ORG_NAME === 'trainML' ? 'LLC' : 'Inc'}
          {'\n'}PO Box 64477{'\n'}Chicago, IL 60664{'\n'}
          <Link
            href={`mailto:support@${process.env.REACT_APP_ORG_NAME.toLowerCase()}.ai`}
          >
            support@{process.env.REACT_APP_ORG_NAME.toLowerCase()}.ai
          </Link>
        </Typography>
      </>
    ),
  },
  {
    title: '16. General',
    description: (
      <>
        <p>
          {process.env.REACT_APP_ORG_NAME} reserves the right to modify the
          terms and conditions of this Agreement or its policies relating to the
          Services at any time, effective upon posting of an updated version of
          this Agreement on the Services. You are responsible for regularly
          reviewing this Agreement. Continued use of the Services after any such
          changes shall constitute your consent to such changes. If any
          provision of this Agreement is found to be unenforceable or invalid,
          that provision will be limited or eliminated to the minimum extent
          necessary so that this Agreement will otherwise remain in full force
          and effect and enforceable. This Agreement is not assignable,
          transferable or sublicensable by you except with{' '}
          {process.env.REACT_APP_ORG_NAME}&apos;s prior written consent.{' '}
          {process.env.REACT_APP_ORG_NAME} may transfer and assign any of its
          rights and obligations under this Agreement without consent. Both
          parties agree that this Agreement is the complete and exclusive
          statement of the mutual understanding of the parties and supersedes
          and cancels all previous written and oral agreements, communications
          and other understandings relating to the subject matter of this
          Agreement, and that all waivers and modifications must be in a writing
          signed by both parties, except as otherwise provided herein. No
          agency, partnership, joint venture, or employment is created as a
          result of this Agreement and you do not have any authority of any kind
          to bind {process.env.REACT_APP_ORG_NAME} in any respect whatsoever. In
          any action or proceeding to enforce rights under this Agreement, the
          prevailing party will be entitled to recover costs and attorneys&apos;
          fees. All notices under this Agreement will be in writing and will be
          deemed to have been duly given when received, if personally delivered;
          when receipt is electronically confirmed, if transmitted by facsimile
          or e-mail; the day after it is sent, if sent for next day delivery by
          recognized overnight delivery service; and upon receipt, if sent by
          certified or registered mail, return receipt requested. This Agreement
          shall be governed by the laws of the State of{' '}
          {process.env.REACT_APP_ORG_NAME === 'trainML'
            ? 'Illinois'
            : 'Delaware'}{' '}
          without regard to its conflict of laws provisions.
        </p>
      </>
    ),
  },
];

const TermsSection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography variant={'body'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

TermsSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const TermsOfService = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography variant={'body'} color={'text.secondary'}>
          Your registration for, or use of, the Services shall be deemed to be
          your agreement to abide by this Agreement including any materials
          available on the {process.env.REACT_APP_ORG_NAME} website incorporated
          by reference herein, including but not limited to{' '}
          {process.env.REACT_APP_ORG_NAME}&apos;s registration and order
          process, privacy and security policies.
        </Typography>
        <br />
        <Typography variant={'body'} color={'text.secondary'}>
          MAKE SURE YOU READ THIS AGREEMENT, THE ORDER FORM AND THE PRIVACY
          POLICY. IT CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS TO USE{' '}
          {process.env.REACT_APP_ORG_NAME.toUpperCase()}. The Order Form
          provides the specific terms and conditions regarding Customer&apos;s
          subscription for the Services, such as User limitations, pricing
          methodologies and the length of the term{' '}
          {process.env.REACT_APP_ORG_NAME}
          will provide the Services (“Subscription Term”).{' '}
          {process.env.REACT_APP_ORG_NAME}&apos;s privacy policies may be viewed
          at <Link href='/privacy-policy'>here</Link>.{' '}
          {process.env.REACT_APP_ORG_NAME} reserves the right to modify its
          privacy policies in its reasonable discretion from time to time. Note
          that because the Services are a hosted, online application,{' '}
          {process.env.REACT_APP_ORG_NAME} occasionally may need to notify all
          users of the Services of important announcements regarding the
          operation of the Services.
        </Typography>
      </Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <TermsSection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default TermsOfService;
