import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function ServiceCustomHostnameField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (!Boolean(newValue)) {
      setError(false);
    } else if (
      /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(
        newValue
      )
    ) {
      setError(false);
    } else {
      setError(`Invalid hostname`);
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          label='Custom Hostname'
          id='custom_hostname'
          aria-describedby='custom-hostname-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='custom-hostname-helper-text'>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id='custom-hostname-helper-text'>
            Enter the customer-managed hostname to use for the service
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
