import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useHistory } from 'react-router-dom';

import { getProjectMembers } from '../../../../actions/projectMembers';

import { removeProjectMember } from 'actions/projectMembers';
import ProjectMembersGridToolbar from './ProjectMembersGridToolbar';

export default function ProjectMembers({ project }) {
  const history = useHistory();
  const [project_members, setProjectMembers] = useState(null);
  // const [transitioningProjectMembers, setTransitioningProjectMembers] =
  //   useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    connector_id: false,
  });

  useEffect(() => {
    (async () => {
      if (project_members === null) {
        const res = await getProjectMembers(project.id);
        setProjectMembers(res.filter((member) => !member.owner));
      }
    })();
  }, [setProjectMembers, project_members, project.id]);

  const editProjectMember = useCallback(
    (project_member) => () => {
      history.push({
        pathname: `/project/details/${project.id}/member/edit`,
        search: `?member=${project_member.email}`,
      });
    },
    [history, project]
  );
  const deleteProjectMember = useCallback(
    (project_member) => async () => {
      // setTransitioningProjectMembers((prevTransitioningProjectMembers) => [
      //   ...prevTransitioningProjectMembers,
      //   project_member.customer_uuid,
      // ]);
      await removeProjectMember(project.id, project_member.email);
      setProjectMembers((prevState) => {
        return [...prevState].filter(
          (member) => member.customer_uuid !== project_member.customer_uuid
        );
      });
      // setTransitioningProjectMembers((prevTransitioningProjectMembers) =>
      //   prevTransitioningProjectMembers.filter(
      //     (id) => id !== project_member.customer_uuid
      //   )
      // );
    },
    [project]
  );

  const renderJobAccess = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.job === 'all' ? 'All' : 'Read Only'}
      </Typography>
    );
  }, []);

  const renderCheckpointAccess = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.checkpoint === 'all' ? 'All' : 'Read Only'}
      </Typography>
    );
  }, []);

  const renderDatasetAccess = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.dataset === 'all' ? 'All' : 'Read Only'}
      </Typography>
    );
  }, []);

  const renderModelAccess = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.model === 'all' ? 'All' : 'Read Only'}
      </Typography>
    );
  }, []);

  const renderVolumeAccess = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.volume === 'all' ? 'All' : 'Read Only'}
      </Typography>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        flex: 1,
      },
      {
        field: 'job_all',
        headerName: 'Jobs',
        type: 'string',
        renderCell: renderJobAccess,
        flex: 0.5,
      },
      {
        field: 'checkpoint_all',
        headerName: 'Checkpoints',
        type: 'string',
        renderCell: renderCheckpointAccess,
        flex: 0.5,
      },
      {
        field: 'dataset_all',
        headerName: 'Datasets',
        type: 'string',
        renderCell: renderDatasetAccess,
        flex: 0.5,
      },
      {
        field: 'model_all',
        headerName: 'Models',
        type: 'string',
        renderCell: renderModelAccess,
        flex: 0.5,
      },
      {
        field: 'volume_all',
        headerName: 'Volumes',
        type: 'string',
        renderCell: renderVolumeAccess,
        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            onClick={editProjectMember(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Remove'
            onClick={deleteProjectMember(params.row)}
            showInMenu
          />,
        ],
      },
    ],
    [
      editProjectMember,
      deleteProjectMember,
      renderJobAccess,
      renderCheckpointAccess,
      renderDatasetAccess,
      renderModelAccess,
      renderVolumeAccess,
    ]
  );

  const Toolbar = () => <ProjectMembersGridToolbar project={project} />;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Project Members
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.email}
                columns={columns}
                rows={project_members || []}
                components={{
                  Toolbar: Toolbar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
