import React, { useEffect } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDatastores } from '../../../../../../actions/datastores';

export default function DeviceConfigDatastoreIdField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  region,
}) {
  const dispatch = useDispatch();
  const datastores = useSelector((state) => state.datastores);

  useEffect(() => {
    (async () => {
      if (datastores === null) {
        dispatch(fetchDatastores(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, datastores, region]);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='datastore-id-label'>Datastore</InputLabel>
        <Select
          labelId='datastore-id-label'
          id='datastore_id'
          value={value}
          label='Datastore'
          onChange={handleChange}
        >
          {datastores
            ? datastores.map((datastore) => {
                return (
                  <MenuItem key={datastore.store_id} value={datastore.store_id}>
                    {datastore.name}
                  </MenuItem>
                );
              })
            : undefined}
        </Select>
        <FormHelperText id='datastore-id-helper-text'>
          The regional datastore for the device to mount to the
          TRAINML_OUTPUT_PATH location.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
