import _ from 'lodash';
import {
  FETCH_DATA_CONNECTORS,
  FETCH_DATA_CONNECTOR,
  DELETE_DATA_CONNECTOR,
  CREATE_DATA_CONNECTOR,
  CLEAR_DATA_CONNECTORS,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_DATA_CONNECTORS:
      return initialState;
    case FETCH_DATA_CONNECTORS:
      newState = action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case CREATE_DATA_CONNECTOR:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case FETCH_DATA_CONNECTOR:
      newState = [...(state || [])];
      _.remove(newState, (data_connector) => {
        return data_connector.connector_id === action.payload.connector_id;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case DELETE_DATA_CONNECTOR:
      newState = [...(state || [])];
      _.remove(newState, (data_connector) => {
        return data_connector.connector_id === action.payload;
      });
      newState = newState.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
