import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Button, Form, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const countryOptions = [
  {
    name: 'Afghanistan',
    key: 'AF',
    value: 'AF',
    flag: 'af',
    text: 'Afghanistan',
  },
  {
    name: 'Aland Islands',
    key: 'AX',
    value: 'AX',
    flag: 'ax',
    text: 'Aland Islands',
  },
  { name: 'Albania', key: 'AL', value: 'AL', flag: 'al', text: 'Albania' },
  { name: 'Algeria', key: 'DZ', value: 'DZ', flag: 'dz', text: 'Algeria' },
  {
    name: 'American Samoa',
    key: 'AS',
    value: 'AS',
    flag: 'as',
    text: 'American Samoa',
  },
  { name: 'Andorra', key: 'AD', value: 'AD', flag: 'ad', text: 'Andorra' },
  { name: 'Angola', key: 'AO', value: 'AO', flag: 'ao', text: 'Angola' },
  { name: 'Anguilla', key: 'AI', value: 'AI', flag: 'ai', text: 'Anguilla' },
  { name: 'Antigua', key: 'AG', value: 'AG', flag: 'ag', text: 'Antigua' },
  { name: 'Argentina', key: 'AR', value: 'AR', flag: 'ar', text: 'Argentina' },
  { name: 'Armenia', key: 'AM', value: 'AM', flag: 'am', text: 'Armenia' },
  { name: 'Aruba', key: 'AW', value: 'AW', flag: 'aw', text: 'Aruba' },
  { name: 'Australia', key: 'AU', value: 'AU', flag: 'au', text: 'Australia' },
  { name: 'Austria', key: 'AT', value: 'AT', flag: 'at', text: 'Austria' },
  {
    name: 'Azerbaijan',
    key: 'AZ',
    value: 'AZ',
    flag: 'az',
    text: 'Azerbaijan',
  },
  { name: 'Bahamas', key: 'BS', value: 'BS', flag: 'bs', text: 'Bahamas' },
  { name: 'Bahrain', key: 'BH', value: 'BH', flag: 'bh', text: 'Bahrain' },
  {
    name: 'Bangladesh',
    key: 'BD',
    value: 'BD',
    flag: 'bd',
    text: 'Bangladesh',
  },
  { name: 'Barbados', key: 'BB', value: 'BB', flag: 'bb', text: 'Barbados' },
  { name: 'Belarus', key: 'BY', value: 'BY', flag: 'by', text: 'Belarus' },
  { name: 'Belgium', key: 'BE', value: 'BE', flag: 'be', text: 'Belgium' },
  { name: 'Belize', key: 'BZ', value: 'BZ', flag: 'bz', text: 'Belize' },
  { name: 'Benin', key: 'BJ', value: 'BJ', flag: 'bj', text: 'Benin' },
  { name: 'Bermuda', key: 'BM', value: 'BM', flag: 'bm', text: 'Bermuda' },
  { name: 'Bhutan', key: 'BT', value: 'BT', flag: 'bt', text: 'Bhutan' },
  { name: 'Bolivia', key: 'BO', value: 'BO', flag: 'bo', text: 'Bolivia' },
  { name: 'Bosnia', key: 'BA', value: 'BA', flag: 'ba', text: 'Bosnia' },
  { name: 'Botswana', key: 'BW', value: 'BW', flag: 'bw', text: 'Botswana' },
  {
    name: 'Bouvet Island',
    key: 'BV',
    value: 'BV',
    flag: 'bv',
    text: 'Bouvet Island',
  },
  { name: 'Brazil', key: 'BR', value: 'BR', flag: 'br', text: 'Brazil' },
  {
    name: 'British Virgin Islands',
    key: 'VG',
    value: 'VG',
    flag: 'vg',
    text: 'British Virgin Islands',
  },
  { name: 'Brunei', key: 'BN', value: 'BN', flag: 'bn', text: 'Brunei' },
  { name: 'Bulgaria', key: 'BG', value: 'BG', flag: 'bg', text: 'Bulgaria' },
  {
    name: 'Burkina Faso',
    key: 'BF',
    value: 'BF',
    flag: 'bf',
    text: 'Burkina Faso',
  },
  { name: 'Burma', key: 'MM', value: 'MM', flag: 'mm', text: 'Burma' },
  { name: 'Burundi', key: 'BI', value: 'BI', flag: 'bi', text: 'Burundi' },
  {
    name: 'Caicos Islands',
    key: 'TC',
    value: 'TC',
    flag: 'tc',
    text: 'Caicos Islands',
  },
  { name: 'Cambodia', key: 'KH', value: 'KH', flag: 'kh', text: 'Cambodia' },
  { name: 'Cameroon', key: 'CM', value: 'CM', flag: 'cm', text: 'Cameroon' },
  { name: 'Canada', key: 'CA', value: 'CA', flag: 'ca', text: 'Canada' },
  {
    name: 'Cape Verde',
    key: 'CV',
    value: 'CV',
    flag: 'cv',
    text: 'Cape Verde',
  },
  {
    name: 'Cayman Islands',
    key: 'KY',
    value: 'KY',
    flag: 'ky',
    text: 'Cayman Islands',
  },
  {
    name: 'Central African Republic',
    key: 'CF',
    value: 'CF',
    flag: 'cf',
    text: 'Central African Republic',
  },
  { name: 'Chad', key: 'TD', value: 'TD', flag: 'td', text: 'Chad' },
  { name: 'Chile', key: 'CL', value: 'CL', flag: 'cl', text: 'Chile' },
  { name: 'China', key: 'CN', value: 'CN', flag: 'cn', text: 'China' },
  {
    name: 'Christmas Island',
    key: 'CX',
    value: 'CX',
    flag: 'cx',
    text: 'Christmas Island',
  },
  {
    name: 'Cocos Islands',
    key: 'CC',
    value: 'CC',
    flag: 'cc',
    text: 'Cocos Islands',
  },
  { name: 'Colombia', key: 'CO', value: 'CO', flag: 'co', text: 'Colombia' },
  { name: 'Comoros', key: 'KM', value: 'KM', flag: 'km', text: 'Comoros' },
  { name: 'Congo', key: 'CD', value: 'CD', flag: 'cd', text: 'Congo' },
  {
    name: 'Congo Brazzaville',
    key: 'CG',
    value: 'CG',
    flag: 'cg',
    text: 'Congo Brazzaville',
  },
  {
    name: 'Cook Islands',
    key: 'CK',
    value: 'CK',
    flag: 'ck',
    text: 'Cook Islands',
  },
  {
    name: 'Costa Rica',
    key: 'CR',
    value: 'CR',
    flag: 'cr',
    text: 'Costa Rica',
  },
  {
    name: 'Cote Divoire',
    key: 'CI',
    value: 'CI',
    flag: 'ci',
    text: 'Cote Divoire',
  },
  { name: 'Croatia', key: 'HR', value: 'HR', flag: 'hr', text: 'Croatia' },
  { name: 'Cuba', key: 'CU', value: 'CU', flag: 'cu', text: 'Cuba' },
  { name: 'Cyprus', key: 'CY', value: 'CY', flag: 'cy', text: 'Cyprus' },
  {
    name: 'Czech Republic',
    key: 'CZ',
    value: 'CZ',
    flag: 'cz',
    text: 'Czech Republic',
  },
  { name: 'Denmark', key: 'DK', value: 'DK', flag: 'dk', text: 'Denmark' },
  { name: 'Djibouti', key: 'DJ', value: 'DJ', flag: 'dj', text: 'Djibouti' },
  { name: 'Dominica', key: 'DM', value: 'DM', flag: 'dm', text: 'Dominica' },
  {
    name: 'Dominican Republic',
    key: 'DO',
    value: 'DO',
    flag: 'do',
    text: 'Dominican Republic',
  },
  { name: 'Ecuador', key: 'EC', value: 'EC', flag: 'ec', text: 'Ecuador' },
  { name: 'Egypt', key: 'EG', value: 'EG', flag: 'eg', text: 'Egypt' },
  {
    name: 'El Salvador',
    key: 'SV',
    value: 'SV',
    flag: 'sv',
    text: 'El Salvador',
  },
  {
    name: 'Equatorial Guinea',
    key: 'GQ',
    value: 'GQ',
    flag: 'gq',
    text: 'Equatorial Guinea',
  },
  { name: 'Eritrea', key: 'ER', value: 'ER', flag: 'er', text: 'Eritrea' },
  { name: 'Estonia', key: 'EE', value: 'EE', flag: 'ee', text: 'Estonia' },
  { name: 'Ethiopia', key: 'ET', value: 'ET', flag: 'et', text: 'Ethiopia' },
  {
    name: 'Falkland Islands',
    key: 'FK',
    value: 'FK',
    flag: 'fk',
    text: 'Falkland Islands',
  },
  {
    name: 'Faroe Islands',
    key: 'FO',
    value: 'FO',
    flag: 'fo',
    text: 'Faroe Islands',
  },
  { name: 'Fiji', key: 'FJ', value: 'FJ', flag: 'fj', text: 'Fiji' },
  { name: 'Finland', key: 'FI', value: 'FI', flag: 'fi', text: 'Finland' },
  { name: 'France', key: 'FR', value: 'FR', flag: 'fr', text: 'France' },
  {
    name: 'French Guiana',
    key: 'GF',
    value: 'GF',
    flag: 'gf',
    text: 'French Guiana',
  },
  {
    name: 'French Polynesia',
    key: 'PF',
    value: 'PF',
    flag: 'pf',
    text: 'French Polynesia',
  },
  {
    name: 'French Territories',
    key: 'TF',
    value: 'TF',
    flag: 'tf',
    text: 'French Territories',
  },
  { name: 'Gabon', key: 'GA', value: 'GA', flag: 'ga', text: 'Gabon' },
  { name: 'Gambia', key: 'GM', value: 'GM', flag: 'gm', text: 'Gambia' },
  { name: 'Georgia', key: 'GE', value: 'GE', flag: 'ge', text: 'Georgia' },
  { name: 'Germany', key: 'DE', value: 'DE', flag: 'de', text: 'Germany' },
  { name: 'Ghana', key: 'GH', value: 'GH', flag: 'gh', text: 'Ghana' },
  { name: 'Gibraltar', key: 'GI', value: 'GI', flag: 'gi', text: 'Gibraltar' },
  { name: 'Greece', key: 'GR', value: 'GR', flag: 'gr', text: 'Greece' },
  { name: 'Greenland', key: 'GL', value: 'GL', flag: 'gl', text: 'Greenland' },
  { name: 'Grenada', key: 'GD', value: 'GD', flag: 'gd', text: 'Grenada' },
  {
    name: 'Guadeloupe',
    key: 'GP',
    value: 'GP',
    flag: 'gp',
    text: 'Guadeloupe',
  },
  { name: 'Guam', key: 'GU', value: 'GU', flag: 'gu', text: 'Guam' },
  { name: 'Guatemala', key: 'GT', value: 'GT', flag: 'gt', text: 'Guatemala' },
  { name: 'Guinea', key: 'GN', value: 'GN', flag: 'gn', text: 'Guinea' },
  {
    name: 'Guinea-Bissau',
    key: 'GW',
    value: 'GW',
    flag: 'gw',
    text: 'Guinea-Bissau',
  },
  { name: 'Guyana', key: 'GY', value: 'GY', flag: 'gy', text: 'Guyana' },
  { name: 'Haiti', key: 'HT', value: 'HT', flag: 'ht', text: 'Haiti' },
  {
    name: 'Heard Island',
    key: 'HM',
    value: 'HM',
    flag: 'hm',
    text: 'Heard Island',
  },
  { name: 'Honduras', key: 'HN', value: 'HN', flag: 'hn', text: 'Honduras' },
  { name: 'Hong Kong', key: 'HK', value: 'HK', flag: 'hk', text: 'Hong Kong' },
  { name: 'Hungary', key: 'HU', value: 'HU', flag: 'hu', text: 'Hungary' },
  { name: 'Iceland', key: 'IS', value: 'IS', flag: 'is', text: 'Iceland' },
  { name: 'India', key: 'IN', value: 'IN', flag: 'in', text: 'India' },
  {
    name: 'Indian Ocean Territory',
    key: 'IO',
    value: 'IO',
    flag: 'io',
    text: 'Indian Ocean Territory',
  },
  { name: 'Indonesia', key: 'ID', value: 'ID', flag: 'id', text: 'Indonesia' },
  { name: 'Iran', key: 'IR', value: 'IR', flag: 'ir', text: 'Iran' },
  { name: 'Iraq', key: 'IQ', value: 'IQ', flag: 'iq', text: 'Iraq' },
  { name: 'Ireland', key: 'IE', value: 'IE', flag: 'ie', text: 'Ireland' },
  { name: 'Israel', key: 'IL', value: 'IL', flag: 'il', text: 'Israel' },
  { name: 'Italy', key: 'IT', value: 'IT', flag: 'it', text: 'Italy' },
  { name: 'Jamaica', key: 'JM', value: 'JM', flag: 'jm', text: 'Jamaica' },
  { name: 'Jan Mayen', key: 'SJ', value: 'SJ', flag: 'sj', text: 'Jan Mayen' },
  { name: 'Japan', key: 'JP', value: 'JP', flag: 'jp', text: 'Japan' },
  { name: 'Jordan', key: 'JO', value: 'JO', flag: 'jo', text: 'Jordan' },
  {
    name: 'Kazakhstan',
    key: 'KZ',
    value: 'KZ',
    flag: 'kz',
    text: 'Kazakhstan',
  },
  { name: 'Kenya', key: 'KE', value: 'KE', flag: 'ke', text: 'Kenya' },
  { name: 'Kiribati', key: 'KI', value: 'KI', flag: 'ki', text: 'Kiribati' },
  { name: 'Kuwait', key: 'KW', value: 'KW', flag: 'kw', text: 'Kuwait' },
  {
    name: 'Kyrgyzstan',
    key: 'KG',
    value: 'KG',
    flag: 'kg',
    text: 'Kyrgyzstan',
  },
  { name: 'Laos', key: 'LA', value: 'LA', flag: 'la', text: 'Laos' },
  { name: 'Latvia', key: 'LV', value: 'LV', flag: 'lv', text: 'Latvia' },
  { name: 'Lebanon', key: 'LB', value: 'LB', flag: 'lb', text: 'Lebanon' },
  { name: 'Lesotho', key: 'LS', value: 'LS', flag: 'ls', text: 'Lesotho' },
  { name: 'Liberia', key: 'LR', value: 'LR', flag: 'lr', text: 'Liberia' },
  { name: 'Libya', key: 'LY', value: 'LY', flag: 'ly', text: 'Libya' },
  {
    name: 'Liechtenstein',
    key: 'LI',
    value: 'LI',
    flag: 'li',
    text: 'Liechtenstein',
  },
  { name: 'Lithuania', key: 'LT', value: 'LT', flag: 'lt', text: 'Lithuania' },
  {
    name: 'Luxembourg',
    key: 'LU',
    value: 'LU',
    flag: 'lu',
    text: 'Luxembourg',
  },
  { name: 'Macau', key: 'MO', value: 'MO', flag: 'mo', text: 'Macau' },
  { name: 'Macedonia', key: 'MK', value: 'MK', flag: 'mk', text: 'Macedonia' },
  {
    name: 'Madagascar',
    key: 'MG',
    value: 'MG',
    flag: 'mg',
    text: 'Madagascar',
  },
  { name: 'Malawi', key: 'MW', value: 'MW', flag: 'mw', text: 'Malawi' },
  { name: 'Malaysia', key: 'MY', value: 'MY', flag: 'my', text: 'Malaysia' },
  { name: 'Maldives', key: 'MV', value: 'MV', flag: 'mv', text: 'Maldives' },
  { name: 'Mali', key: 'ML', value: 'ML', flag: 'ml', text: 'Mali' },
  { name: 'Malta', key: 'MT', value: 'MT', flag: 'mt', text: 'Malta' },
  {
    name: 'Marshall Islands',
    key: 'MH',
    value: 'MH',
    flag: 'mh',
    text: 'Marshall Islands',
  },
  {
    name: 'Martinique',
    key: 'MQ',
    value: 'MQ',
    flag: 'mq',
    text: 'Martinique',
  },
  {
    name: 'Mauritania',
    key: 'MR',
    value: 'MR',
    flag: 'mr',
    text: 'Mauritania',
  },
  { name: 'Mauritius', key: 'MU', value: 'MU', flag: 'mu', text: 'Mauritius' },
  { name: 'Mayotte', key: 'YT', value: 'YT', flag: 'yt', text: 'Mayotte' },
  { name: 'Mexico', key: 'MX', value: 'MX', flag: 'mx', text: 'Mexico' },
  {
    name: 'Micronesia',
    key: 'FM',
    value: 'FM',
    flag: 'fm',
    text: 'Micronesia',
  },
  { name: 'Moldova', key: 'MD', value: 'MD', flag: 'md', text: 'Moldova' },
  { name: 'Monaco', key: 'MC', value: 'MC', flag: 'mc', text: 'Monaco' },
  { name: 'Mongolia', key: 'MN', value: 'MN', flag: 'mn', text: 'Mongolia' },
  {
    name: 'Montenegro',
    key: 'ME',
    value: 'ME',
    flag: 'me',
    text: 'Montenegro',
  },
  {
    name: 'Montserrat',
    key: 'MS',
    value: 'MS',
    flag: 'ms',
    text: 'Montserrat',
  },
  { name: 'Morocco', key: 'MA', value: 'MA', flag: 'ma', text: 'Morocco' },
  {
    name: 'Mozambique',
    key: 'MZ',
    value: 'MZ',
    flag: 'mz',
    text: 'Mozambique',
  },
  { name: 'Namibia', key: 'NA', value: 'NA', flag: 'na', text: 'Namibia' },
  { name: 'Nauru', key: 'NR', value: 'NR', flag: 'nr', text: 'Nauru' },
  { name: 'Nepal', key: 'NP', value: 'NP', flag: 'np', text: 'Nepal' },
  {
    name: 'Netherlands',
    key: 'NL',
    value: 'NL',
    flag: 'nl',
    text: 'Netherlands',
  },
  {
    name: 'Netherlandsantilles',
    key: 'AN',
    value: 'AN',
    flag: 'an',
    text: 'Netherlandsantilles',
  },
  {
    name: 'New Caledonia',
    key: 'NC',
    value: 'NC',
    flag: 'nc',
    text: 'New Caledonia',
  },
  {
    name: 'New Guinea',
    key: 'PG',
    value: 'PG',
    flag: 'pg',
    text: 'New Guinea',
  },
  {
    name: 'New Zealand',
    key: 'NZ',
    value: 'NZ',
    flag: 'nz',
    text: 'New Zealand',
  },
  { name: 'Nicaragua', key: 'NI', value: 'NI', flag: 'ni', text: 'Nicaragua' },
  { name: 'Niger', key: 'NE', value: 'NE', flag: 'ne', text: 'Niger' },
  { name: 'Nigeria', key: 'NG', value: 'NG', flag: 'ng', text: 'Nigeria' },
  { name: 'Niue', key: 'NU', value: 'NU', flag: 'nu', text: 'Niue' },
  {
    name: 'Norfolk Island',
    key: 'NF',
    value: 'NF',
    flag: 'nf',
    text: 'Norfolk Island',
  },
  {
    name: 'North Korea',
    key: 'KP',
    value: 'KP',
    flag: 'kp',
    text: 'North Korea',
  },
  {
    name: 'Northern Mariana Islands',
    key: 'MP',
    value: 'MP',
    flag: 'mp',
    text: 'Northern Mariana Islands',
  },
  { name: 'Norway', key: 'NO', value: 'NO', flag: 'no', text: 'Norway' },
  { name: 'Oman', key: 'OM', value: 'OM', flag: 'om', text: 'Oman' },
  { name: 'Pakistan', key: 'PK', value: 'PK', flag: 'pk', text: 'Pakistan' },
  { name: 'Palau', key: 'PW', value: 'PW', flag: 'pw', text: 'Palau' },
  { name: 'Palestine', key: 'PS', value: 'PS', flag: 'ps', text: 'Palestine' },
  { name: 'Panama', key: 'PA', value: 'PA', flag: 'pa', text: 'Panama' },
  { name: 'Paraguay', key: 'PY', value: 'PY', flag: 'py', text: 'Paraguay' },
  { name: 'Peru', key: 'PE', value: 'PE', flag: 'pe', text: 'Peru' },
  {
    name: 'Philippines',
    key: 'PH',
    value: 'PH',
    flag: 'ph',
    text: 'Philippines',
  },
  {
    name: 'Pitcairn Islands',
    key: 'PN',
    value: 'PN',
    flag: 'pn',
    text: 'Pitcairn Islands',
  },
  { name: 'Poland', key: 'PL', value: 'PL', flag: 'pl', text: 'Poland' },
  { name: 'Portugal', key: 'PT', value: 'PT', flag: 'pt', text: 'Portugal' },
  {
    name: 'Puerto Rico',
    key: 'PR',
    value: 'PR',
    flag: 'pr',
    text: 'Puerto Rico',
  },
  { name: 'Qatar', key: 'QA', value: 'QA', flag: 'qa', text: 'Qatar' },
  { name: 'Reunion', key: 'RE', value: 'RE', flag: 're', text: 'Reunion' },
  { name: 'Romania', key: 'RO', value: 'RO', flag: 'ro', text: 'Romania' },
  { name: 'Russia', key: 'RU', value: 'RU', flag: 'ru', text: 'Russia' },
  { name: 'Rwanda', key: 'RW', value: 'RW', flag: 'rw', text: 'Rwanda' },
  {
    name: 'Saint Helena',
    key: 'SH',
    value: 'SH',
    flag: 'sh',
    text: 'Saint Helena',
  },
  {
    name: 'Saint Kitts and Nevis',
    key: 'KN',
    value: 'KN',
    flag: 'kn',
    text: 'Saint Kitts and Nevis',
  },
  {
    name: 'Saint Lucia',
    key: 'LC',
    value: 'LC',
    flag: 'lc',
    text: 'Saint Lucia',
  },
  {
    name: 'Saint Pierre',
    key: 'PM',
    value: 'PM',
    flag: 'pm',
    text: 'Saint Pierre',
  },
  {
    name: 'Saint Vincent',
    key: 'VC',
    value: 'VC',
    flag: 'vc',
    text: 'Saint Vincent',
  },
  { name: 'Samoa', key: 'WS', value: 'WS', flag: 'ws', text: 'Samoa' },
  {
    name: 'San Marino',
    key: 'SM',
    value: 'SM',
    flag: 'sm',
    text: 'San Marino',
  },
  {
    name: 'Sandwich Islands',
    key: 'GS',
    value: 'GS',
    flag: 'gs',
    text: 'Sandwich Islands',
  },
  { name: 'Sao Tome', key: 'ST', value: 'ST', flag: 'st', text: 'Sao Tome' },
  {
    name: 'Saudi Arabia',
    key: 'SA',
    value: 'SA',
    flag: 'sa',
    text: 'Saudi Arabia',
  },
  { name: 'Senegal', key: 'SN', value: 'SN', flag: 'sn', text: 'Senegal' },
  { name: 'Serbia', key: 'CS', value: 'CS', flag: 'cs', text: 'Serbia' },
  { name: 'Serbia', key: 'RS', value: 'RS', flag: 'rs', text: 'Serbia' },
  {
    name: 'Seychelles',
    key: 'SC',
    value: 'SC',
    flag: 'sc',
    text: 'Seychelles',
  },
  {
    name: 'Sierra Leone',
    key: 'SL',
    value: 'SL',
    flag: 'sl',
    text: 'Sierra Leone',
  },
  { name: 'Singapore', key: 'SG', value: 'SG', flag: 'sg', text: 'Singapore' },
  { name: 'Slovakia', key: 'SK', value: 'SK', flag: 'sk', text: 'Slovakia' },
  { name: 'Slovenia', key: 'SI', value: 'SI', flag: 'si', text: 'Slovenia' },
  {
    name: 'Solomon Islands',
    key: 'SB',
    value: 'SB',
    flag: 'sb',
    text: 'Solomon Islands',
  },
  { name: 'Somalia', key: 'SO', value: 'SO', flag: 'so', text: 'Somalia' },
  {
    name: 'South Africa',
    key: 'ZA',
    value: 'ZA',
    flag: 'za',
    text: 'South Africa',
  },
  {
    name: 'South Korea',
    key: 'KR',
    value: 'KR',
    flag: 'kr',
    text: 'South Korea',
  },
  { name: 'Spain', key: 'ES', value: 'ES', flag: 'es', text: 'Spain' },
  { name: 'Sri Lanka', key: 'LK', value: 'LK', flag: 'lk', text: 'Sri Lanka' },
  { name: 'Sudan', key: 'SD', value: 'SD', flag: 'sd', text: 'Sudan' },
  { name: 'Suriname', key: 'SR', value: 'SR', flag: 'sr', text: 'Suriname' },
  { name: 'Swaziland', key: 'SZ', value: 'SZ', flag: 'sz', text: 'Swaziland' },
  { name: 'Sweden', key: 'SE', value: 'SE', flag: 'se', text: 'Sweden' },
  {
    name: 'Switzerland',
    key: 'CH',
    value: 'CH',
    flag: 'ch',
    text: 'Switzerland',
  },
  { name: 'Syria', key: 'SY', value: 'SY', flag: 'sy', text: 'Syria' },
  { name: 'Taiwan', key: 'TW', value: 'TW', flag: 'tw', text: 'Taiwan' },
  {
    name: 'Tajikistan',
    key: 'TJ',
    value: 'TJ',
    flag: 'tj',
    text: 'Tajikistan',
  },
  { name: 'Tanzania', key: 'TZ', value: 'TZ', flag: 'tz', text: 'Tanzania' },
  { name: 'Thailand', key: 'TH', value: 'TH', flag: 'th', text: 'Thailand' },
  {
    name: 'Timorleste',
    key: 'TL',
    value: 'TL',
    flag: 'tl',
    text: 'Timorleste',
  },
  { name: 'Togo', key: 'TG', value: 'TG', flag: 'tg', text: 'Togo' },
  { name: 'Tokelau', key: 'TK', value: 'TK', flag: 'tk', text: 'Tokelau' },
  { name: 'Tonga', key: 'TO', value: 'TO', flag: 'to', text: 'Tonga' },
  { name: 'Trinidad', key: 'TT', value: 'TT', flag: 'tt', text: 'Trinidad' },
  { name: 'Tunisia', key: 'TN', value: 'TN', flag: 'tn', text: 'Tunisia' },
  { name: 'Turkey', key: 'TR', value: 'TR', flag: 'tr', text: 'Turkey' },
  {
    name: 'Turkmenistan',
    key: 'TM',
    value: 'TM',
    flag: 'tm',
    text: 'Turkmenistan',
  },
  { name: 'Tuvalu', key: 'TV', value: 'TV', flag: 'tv', text: 'Tuvalu' },
  { name: 'U.A.E.', key: 'AE', value: 'AE', flag: 'ae', text: 'U.A.E.' },
  { name: 'Uganda', key: 'UG', value: 'UG', flag: 'ug', text: 'Uganda' },
  { name: 'Ukraine', key: 'UA', value: 'UA', flag: 'ua', text: 'Ukraine' },
  {
    name: 'United Kingdom',
    key: 'GB',
    value: 'GB',
    flag: 'gb',
    text: 'United Kingdom',
  },
  {
    name: 'United States',
    key: 'US',
    value: 'US',
    flag: 'us',
    text: 'United States',
  },
  { name: 'Uruguay', key: 'UY', value: 'UY', flag: 'uy', text: 'Uruguay' },
  {
    name: 'US Minor Islands',
    key: 'UM',
    value: 'UM',
    flag: 'um',
    text: 'US Minor Islands',
  },
  {
    name: 'US Virgin Islands',
    key: 'VI',
    value: 'VI',
    flag: 'vi',
    text: 'US Virgin Islands',
  },
  {
    name: 'Uzbekistan',
    key: 'UZ',
    value: 'UZ',
    flag: 'uz',
    text: 'Uzbekistan',
  },
  { name: 'Vanuatu', key: 'VU', value: 'VU', flag: 'vu', text: 'Vanuatu' },
  {
    name: 'Vatican City',
    key: 'VA',
    value: 'VA',
    flag: 'va',
    text: 'Vatican City',
  },
  { name: 'Venezuela', key: 'VE', value: 'VE', flag: 've', text: 'Venezuela' },
  { name: 'Vietnam', key: 'VN', value: 'VN', flag: 'vn', text: 'Vietnam' },
  {
    name: 'Wallis and Futuna',
    key: 'WF',
    value: 'WF',
    flag: 'wf',
    text: 'Wallis and Futuna',
  },
  {
    name: 'Western Sahara',
    key: 'EH',
    value: 'EH',
    flag: 'eh',
    text: 'Western Sahara',
  },
  { name: 'Yemen', key: 'YE', value: 'YE', flag: 'ye', text: 'Yemen' },
  { name: 'Zambia', key: 'ZM', value: 'ZM', flag: 'zm', text: 'Zambia' },
  { name: 'Zimbabwe', key: 'ZW', value: 'ZW', flag: 'zw', text: 'Zimbabwe' },
];

class PaymentMethodBillingDetailsSection extends Component {
  state = {
    address_country: 'US',
    email: this.props.user.email,
    name: this.props.user.display_name,
    address_line1: '',
    address_line2: '',
    address_city: '',
    address_state: '',
    address_postal_code: '',
    phone: '',
    pristine: true,
    emailError: false,
    nameError: false,
  };

  componentDidMount() {
    this.setState(this.props.initialValues);
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, pristine: false });
    switch (name) {
      case 'email':
        const valid = email_re.test(value);
        this.setState({ emailError: !valid });
        break;
      case 'name':
        this.setState({ nameError: value === '' });
        break;
      default:
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };

  render() {
    const {
      address_country,
      email,
      name,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_postal_code,
      phone,
      pristine,
      emailError,
      nameError,
    } = this.state;
    return (
      <Segment>
        <Header as='h3'>Credit Card Billing Details</Header>
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            name='name'
            label='Full Name'
            value={name}
            required
            onChange={this.handleChange}
            error={
              nameError
                ? {
                    content: 'Please enter a value',
                    pointing: 'below',
                  }
                : undefined
            }
          />
          <Form.Input
            name='address_line1'
            label='Address Line 1'
            required
            value={address_line1}
            onChange={this.handleChange}
          />
          <Form.Input
            name='address_line2'
            label='Address Line 2'
            value={address_line2}
            onChange={this.handleChange}
          />
          <Form.Input
            name='address_city'
            label='City / Town'
            required
            value={address_city}
            onChange={this.handleChange}
          />
          <Form.Input
            name='address_state'
            label='State / Province / Region'
            required
            value={address_state}
            onChange={this.handleChange}
          />
          <Form.Input
            name='address_postal_code'
            label='ZIP / Postal Code'
            required
            value={address_postal_code}
            onChange={this.handleChange}
          />
          <Form.Dropdown
            name='address_country'
            label='Country'
            value={address_country}
            options={countryOptions}
            selection
            fluid
            required
            search
            onChange={this.handleChange}
          />
          <Form.Input
            name='phone'
            label='Phone Number'
            required
            value={phone}
            onChange={this.handleChange}
          />
          <Form.Input
            name='email'
            label='Email'
            value={email}
            onChange={this.handleChange}
            error={
              emailError
                ? {
                    content: 'Please enter a valid email address',
                    pointing: 'below',
                  }
                : undefined
            }
          />
          <Link to='/account/billing'>
            <Button content='Cancel' secondary />
          </Link>
          {pristine ? (
            <Button content='Next' primary disabled floated='right' />
          ) : (
            <Button
              content='Next'
              primary
              floated='right'
              disabled={emailError || nameError}
            />
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(PaymentMethodBillingDetailsSection);
