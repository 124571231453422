import _ from 'lodash';
import {
  FETCH_REGIONS,
  FETCH_REGION,
  CREATE_REGION,
  DELETE_REGION,
  SET_REGION,
  CLEAR_REGION,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchRegions =
  (provider_uuid, provider_type) => async (dispatch) => {
    try {
      const res = await api.get(`/provider/${provider_uuid}/region`);
      dispatch({
        type: FETCH_REGIONS,
        provider_type,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const fetchRegion =
  (provider_uuid, provider_type, region_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}`
      );
      dispatch({
        type: FETCH_REGION,
        provider_type,
        payload: res.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({ type: DELETE_REGION, provider_type, payload: region_uuid });
      } else {
        const message = getMessageFromApiError(error);
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  };

export const fetchActiveRegion =
  (provider_uuid, region_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}`
      );
      if (_.isEmpty(res.data)) {
        throw new Error('Region Not Found');
      } else {
        dispatch({
          type: SET_REGION,
          payload: res.data,
        });
      }
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const updateRegion =
  (provider_uuid, provider_type, region_uuid, data) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}`,
        data
      );
      dispatch({
        type: FETCH_REGION,
        provider_type,
        payload: res.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({ type: DELETE_REGION, provider_type, payload: region_uuid });
      } else {
        const message = getMessageFromApiError(error);
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  };

export const deleteRegion =
  (provider_uuid, provider_type, region_uuid) => async (dispatch) => {
    try {
      const res = await api.delete(
        `/provider/${provider_uuid}/region/${region_uuid}`
      );
      if (res.data) {
        dispatch({
          type: FETCH_REGION,
          provider_type,
          payload: res.data,
        });
      } else {
        dispatch({
          type: DELETE_REGION,
          provider_type,
          payload: region_uuid,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({ type: DELETE_REGION, provider_type, payload: region_uuid });
      } else {
        const message = getMessageFromApiError(error);
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  };

export const createRegion =
  (provider_uuid, provider_type, values) => async (dispatch) => {
    try {
      const res = await api.post(`/provider/${provider_uuid}/region`, values);
      dispatch({
        type: CREATE_REGION,
        provider_type,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const setActiveRegion = (region, post_action) => (dispatch) => {
  dispatch({
    type: SET_REGION,
    payload: region,
  });
  if (post_action) {
    post_action();
  }
};
export const clearActiveRegion = (post_action) => (dispatch) => {
  dispatch({
    type: CLEAR_REGION,
  });
  if (post_action) {
    post_action();
  }
};
