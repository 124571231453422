import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

function ArchivedJobBody({ job, gpu_type_name }) {
  return (
    <Table.Row key={job.job_uuid}>
      <Table.Cell>{job.name}</Table.Cell>
      <Table.Cell>
        {job.type.replace(/\b(\w)/g, (s) => s.toUpperCase())}
      </Table.Cell>
      <Table.Cell>{gpu_type_name}</Table.Cell>
      <Table.Cell>{job.resources.gpu_count}</Table.Cell>
      <Table.Cell>{job.credits}</Table.Cell>
      <Table.Cell>{moment(job.start).format('lll')}</Table.Cell>
      <Table.Cell>{moment(job.end).format('lll')}</Table.Cell>
    </Table.Row>
  );
}

export default ArchivedJobBody;
