import React from 'react';

import {
  Box,
  TextField,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';

import UploadFile from '../../../shared/UploadFile';

const cert_re =
  /$\s*(-----BEGIN CERTIFICATE-----(\n|\r|\r\n)([0-9a-zA-Z+/=]{64}(\n|\r|\r\n))*([0-9a-zA-Z+/=]{1,63}(\n|\r|\r\n))?-----END CERTIFICATE-----)|(-----BEGIN CERTIFICATE-----(\n|\r|\r\n)([0-9a-zA-Z+/=]{64}(\n|\r|\r\n))*([0-9a-zA-Z+/=]{1,63}(\n|\r|\r\n))?-----END CERTIFICATE-----)\s*$/;

export default function ServiceCertField({
  value,
  setValue,
  error,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    if (!edit && newValue === '') {
      setError('Certificate cannot be empty');
    } else if (!cert_re.test(newValue)) {
      setError('Invalid certificate format');
    } else {
      setError(false);
    }
    setValue(newValue);
  };

  const handleUpload = (file) => {
    handleChange({ target: { value: file.contents } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant='h6'>Certificate</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Box>
          <TextField
            sx={{ width: '100%' }}
            id='cert'
            label='Certificate'
            multiline
            rows={20}
            value={value}
            error={error}
            aria-describedby='cert-helper-text'
            inputProps={{ style: { fontSize: 10 } }}
            onChange={handleChange}
          />
          <FormHelperText id='cert-helper-text'>
            {error || 'Enter the certificate for the the custom hostname'}
          </FormHelperText>
        </Box>
        <UploadFile name={'Upload Certificate'} setValue={handleUpload} />
      </Grid>
    </Grid>
  );
}
