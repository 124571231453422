import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  Typography,
  Divider,
  Button,
  Breadcrumbs,
  Link,
  Box,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import theme from 'theme';
import ErrorSnackBar from 'components/shared/ErrorSnackBar';

import toNiceTimestamp from 'util/toNiceTimestamp';
import { deleteProject, getProject } from 'actions/projects';
import CredentialsForm from 'components/credentials/CredentialsForm';
import ProjectMembers from './ProjectMembers';
import LoadingMask from 'components/shared/LoadingMask';
import ProjectDatastores from './ProjectDatastores';
import ProjectDataConnectors from './ProjectDataConnectors';
import ProjectServices from './ProjectServices';

export default function Project() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const api_error = useSelector((state) => state.errorMessage);
  const projects = useSelector((state) => state.projects);

  const [project, setProject] = useState(
    projects ? projects.find((project) => project.id === id) : undefined
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (!project) {
        const project_data = await getProject(id);
        if (!project_data) {
          history.push(`/projects`);
        }
        setProject(project_data);
      }
      setLoading(false);
    })();
  }, [dispatch, history, project, id]);

  if (loading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <LoadingMask />
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={`/dashboard`}
            >
              Home
            </Link>
            <Link
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={`/projects`}
            >
              Projects
            </Link>
            <Typography color='text.primary'>{project.name}</Typography>
          </Breadcrumbs>
          <br />
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <Typography variant='h5'>{project.name}</Typography>
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: `/project/edit`,
                            search: `?id=${id}`,
                          })
                        }
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <EditIcon />
                          <Typography variant='button' display='block'>
                            Edit
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(deleteProject(id));
                          history.push(`/projects`);
                        }}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <DeleteIcon />
                          <Typography variant='button' display='block'>
                            Delete
                          </Typography>
                        </Stack>
                      </Button>
                    </Stack>
                  </Toolbar>
                </Paper>
                <Box padding='15px'>
                  <Typography variant='h6'>Basic Information</Typography>
                  <Divider />
                  <br />
                  <Grid
                    container
                    component='dl' // mount a Definition List
                    spacing={0}
                    sx={{ display: 'table' }}
                  >
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '200px' }}
                      >
                        <b>ID:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {project.id}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '200px' }}
                      >
                        <b>Created:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {toNiceTimestamp(project.createdAt, true)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component='dt'
                        variant='body1'
                        sx={{ display: 'table-cell', width: '200px' }}
                      >
                        <b>Owned Resources Only:</b>
                      </Typography>
                      <Typography
                        component='dd'
                        variant='body2'
                        sx={{ display: 'table-cell' }}
                      >
                        {project.owned_resources_only ? 'True' : 'False'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <br />
                <Grid item xs={12} md={12} lg={12}>
                  <CredentialsForm
                    key={`credentials-${id}`}
                    project_uuid={id}
                  />
                </Grid>
                <Divider />
                <br />
                <Grid item xs={12} md={12} lg={12}>
                  <ProjectMembers key={`members-${id}`} project={project} />
                </Grid>
                <Divider />
                <br />
                <Grid item xs={12} md={12} lg={12}>
                  <ProjectDatastores
                    key={`datastores-${id}`}
                    project={project}
                  />
                </Grid>
                <Divider />
                <br />
                <Grid item xs={12} md={12} lg={12}>
                  <ProjectDataConnectors
                    key={`data-connectors-${id}`}
                    project={project}
                  />
                </Grid>
                <Divider />
                <br />
                <Grid item xs={12} md={12} lg={12}>
                  <ProjectServices key={`services-${id}`} project={project} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
