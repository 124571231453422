import _ from 'lodash';
import React, { Component } from 'react';
import {
  Grid,
  Container,
  Header,
  Segment,
  Button,
  Modal,
  Message,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import ProfileForm from './ProfileFormOld';
import TopupForm from '../billing/method/TopupForm';
import { deleteUser, updateUser } from '../../actions/user';
import { fetchJobs } from '../../actions/jobs';
import { fetchPaymentMethods } from '../../actions/paymentMethods';
import ThirdPartyKeysForm from '../secrets/ThirdPartyKeysFormOld';
import DefaultSettingsForm from './settings/DefaultSettingsForm';
import ApiKeysForm from './settings/ApiKeysForm';

class AccountProfile extends Component {
  componentDidMount() {
    this.props.fetchJobs();
    if (!this.props.user.billing_account_id) {
      this.props.fetchPaymentMethods();
    }
  }
  state = {
    pressedDelete: false,
    submittingProfile: false,
    profileError: false,
    profilePristine: true,
    loading: false,
  };

  updateProfile = async (values) => {
    this.setState({ submittingProfile: true });
    await this.props.updateUser(values);
    this.setState({ submittingProfile: false, profilePristine: true });
  };
  handleProfileChange = ({ error, pristine }) => {
    this.setState({
      profileError: error,
      profilePristine: pristine,
    });
  };
  showModal = () => {
    return (
      <Modal open={this.state.pressedDelete}>
        <Modal.Header>Warning</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete your account? This action cannot be
            undone. If you have a credit balance, all credits will be forfeited
            upon account deletion.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ pressedDelete: false })}>
            No
          </Button>
          <Button onClick={this.props.deleteUser} negative content='Yes' />
        </Modal.Actions>
      </Modal>
    );
  };
  close = () => {
    this.setState({ pressedDelete: false });
  };
  render() {
    return (
      <Grid>
        <Grid.Row width={16}>
          <Grid.Column>
            <Container>
              <Grid.Row>
                <Segment>
                  <Header as='h2' textAlign='center'>
                    Profile Information
                  </Header>
                  <ProfileForm
                    onSubmit={this.updateProfile}
                    handleChange={this.handleProfileChange}
                    buttons={
                      <Button
                        content='Update'
                        primary
                        disabled={
                          this.state.profileError || this.state.profilePristine
                        }
                        loading={this.state.submittingProfile}
                      />
                    }
                  />
                </Segment>
              </Grid.Row>

              <br />
              <Grid.Row>
                <Segment>
                  <Header as='h2' textAlign='center'>
                    Third-Party Credentials
                  </Header>
                  <ThirdPartyKeysForm entity='user' />
                </Segment>
              </Grid.Row>
              <br />
              <Grid.Row>
                <Segment>
                  <Header as='h2' textAlign='center'>
                    API Keys
                  </Header>
                  <ApiKeysForm />
                </Segment>
              </Grid.Row>
              <br />
              {!this.props.user.billing_account_id ? (
                <>
                  <Grid.Row>
                    <TopupForm
                      primaryPaymentMethod={this.props.primaryPaymentMethod}
                      user={this.props.user}
                    />
                  </Grid.Row>
                  <br />
                </>
              ) : undefined}
              <Grid.Row>
                <Segment>
                  <Header as='h2' textAlign='center'>
                    Default Job Settings
                  </Header>
                  <DefaultSettingsForm />
                </Segment>
              </Grid.Row>
              <br />
              <Grid.Row>
                <Segment textAlign='center'>
                  <Button
                    disabled={this.props.jobs.length !== 0}
                    onClick={() => this.setState({ pressedDelete: true })}
                    negative
                  >
                    Delete User
                  </Button>
                  <br />
                  {this.props.jobs.length !== 0 ? (
                    <Message error>
                      You must terminate all jobs to enable this function.
                    </Message>
                  ) : (
                    <></>
                  )}
                </Segment>
                <Modal open={this.state.pressedDelete}>
                  <Modal.Header>Delete Your Account</Modal.Header>
                  <Modal.Content>
                    <p>
                      Are you sure you want to delete your account? This action
                      cannot be undone. If you have a credit balance, all
                      credits will be forfeited upon account deletion.
                    </p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={this.close} disabled={this.state.loading}>
                      No
                    </Button>
                    <Button
                      negative
                      onClick={() => {
                        this.setState({ loading: true });
                        this.props.deleteUser();
                      }}
                      loading={this.state.loading}
                      content='Yes'
                    />
                  </Modal.Actions>
                </Modal>
              </Grid.Row>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const paymentMethod = _.filter(state.paymentMethods, (method) => {
    return method.id === state.user.primary_pm;
  });
  return {
    user: state.user,
    jobs: state.jobs || [],
    primaryPaymentMethod: paymentMethod[0],
  };
};

export default connect(mapStateToProps, {
  deleteUser,
  fetchJobs,
  updateUser,
  fetchPaymentMethods,
})(AccountProfile);
