import React, { useState, useEffect } from 'react';

import {
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  createRegion,
  fetchRegions,
  updateRegion,
} from '../../../../actions/regions';
import RegionNameField from './RegionNameField';
import StorageModeField from '../Shared/StorageModeField';
import PROVIDER_TYPES from '../../providerTypes';
import PublicField from '../Shared/PublicField';

const provider_type = PROVIDER_TYPES.PHYSICAL;

export default function PhysicalRegionForm({ provider, region }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const regions = useSelector((state) => state.regions);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState(region ? region.name : null);
  const [is_public, setIsPublic] = useState(region ? region.public : true);
  const [nameError, setNameError] = useState(false);
  const [storage_mode, setStorageMode] = useState(
    region ? region.storage.mode : 'local'
  );

  useEffect(() => {
    if (!regions[provider.type]) {
      dispatch(fetchRegions(provider.provider_uuid, provider.type));
    }
  }, [dispatch, regions, provider]);

  const handleSubmit = async () => {
    setLoading(true);
    const storage = Object.fromEntries(
      Object.entries({
        mode: storage_mode,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    const payload = Object.fromEntries(
      Object.entries({
        name,
        public: is_public,
        storage,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    if (region) {
      await dispatch(
        updateRegion(
          region.provider_uuid,
          region.provider_type,
          region.region_uuid,
          payload
        )
      );
    } else {
      await dispatch(
        createRegion(provider.provider_uuid, provider.type, payload)
      );
    }

    history.push('/resources');
  };

  return (
    <Paper sx={{ display: 'flex', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => history.goBack()} disabled={loading}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>
                  {region
                    ? `Edit ${provider_type.label} : ${region.name}`
                    : `Create New ${provider_type.label} Region`}
                </Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              width: '100%',
              maxWidth: '600px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>General</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <RegionNameField
                  value={name}
                  error={nameError}
                  setValue={setName}
                  setError={setNameError}
                  setChanged={() => setPristine(false)}
                  used_names={(regions[provider.type] || [])
                    .map((region) => region.name)
                    .filter((name) => (region ? name !== region.name : true))}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <PublicField
                  value={is_public}
                  setValue={setIsPublic}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>Storage</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <StorageModeField
                  value={storage_mode}
                  setValue={setStorageMode}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Divider />
          <Toolbar>
            <Stack spacing={2} direction='row'>
              <LoadingButton
                variant='contained'
                color='brand'
                onClick={handleSubmit}
                loading={loading}
                disabled={pristine || Boolean(nameError) || !Boolean(name)}
              >
                Submit
              </LoadingButton>

              <Button
                variant='outlined'
                onClick={() => history.goBack()}
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
      </Grid>
    </Paper>
  );
}
