import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  CircularProgress,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
// import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import UpdateIcon from '@mui/icons-material/Update';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  deleteRig,
  runAction,
  deployDeviceEndpoint,
  toggleMaintenance,
  stopDeviceEndpoint,
} from '../../../../../actions/rigs';
import { fetchModels } from '../../../../../actions/models';
import GridToolbar from './GridToolbar';
import STATUSES from '../../../../statuses';
import PollNode from '../PollNode';
import RIG_SERVICES from '../rigServices';
import RIG_TYPES from '../rigTypes';
import renderProgress from '../../../../shared/renderProgress';
import getRigName from '../getRigName';
import getHotStorage from '../getHotStorage';
import DeviceConfigsPicker from './DeviceConfigsPicker';

export default function Devices({ region, rigs }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const models = useSelector((state) => state.models);
  const user = useSelector((state) => state.user);
  const device_configs = useSelector((state) => state.deviceConfigs);
  const [selectedRigs, setSelectedRigs] = useState([]);
  const [transitioningRigs, setTransitioningRigs] = useState([]);
  const [deployingRigs, setDeployingRigs] = useState([]);
  const [openDeviceConfigPicker, setOpenDeviceConfigPicker] = useState(false);
  const [deviceConfigPickerRig, setDeviceConfigPickerRig] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (models === null) {
        dispatch(fetchModels());
        return;
      }
    })();
  }, [dispatch, models]);

  //   const viewDetails = useCallback(
  //     (rig) => () => {
  //       history.push({
  //         pathname: `/resources/region/${region.region_uuid}/node/details/${rig.rig_uuid}`,
  //         search: `?provider_type=${region.provider_type}`,
  //       });
  //     },
  //     [history, region]
  //   );

  useEffect(() => {
    (async () => {
      if (deployingRigs.length) {
        deployingRigs.forEach((deploying_rig_uuid) => {
          const rig = rigs.find((rig) => deploying_rig_uuid === rig.rig_uuid);
          if (
            !rig ||
            [STATUSES.RUNNING, STATUSES.FAILED].includes(rig.job_status)
          ) {
            setDeployingRigs((prevDeployingRigs) =>
              prevDeployingRigs.filter(
                (rig_uuid) => rig_uuid !== deploying_rig_uuid
              )
            );
          }
        });
      }
    })();
  }, [dispatch, deployingRigs, rigs]);

  const deployConfigToSelectedRigs = useCallback(async () => {
    const rig_uuids = [...selectedRigs];
    setDeployingRigs((prevDeployingRigs) => [
      ...prevDeployingRigs,
      ...rig_uuids,
    ]);
    setTransitioningRigs((prevTransitioningRigs) => [
      ...prevTransitioningRigs,
      ...rig_uuids,
    ]);
    await Promise.all(
      [].concat(
        rig_uuids.map(async (rig_uuid) => {
          await dispatch(
            deployDeviceEndpoint(
              region.provider_uuid,
              region.region_uuid,
              rig_uuid
            )
          );
        })
      )
    );

    setTransitioningRigs((prevTransitioningRigs) =>
      prevTransitioningRigs.filter((rig_uuid) => !rig_uuids.includes(rig_uuid))
    );
  }, [dispatch, selectedRigs, region]);

  const deployLatestConfig = useCallback(
    (rig) => async () => {
      setDeployingRigs((prevDeployingRigs) => [
        ...prevDeployingRigs,
        rig.rig_uuid,
      ]);
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        deployDeviceEndpoint(
          region.provider_uuid,
          rig.region_uuid,
          rig.rig_uuid
        )
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const stopEndpoint = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        stopDeviceEndpoint(region.provider_uuid, rig.region_uuid, rig.rig_uuid)
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const setDeviceConfig = useCallback(
    (rig) => async () => {
      setDeviceConfigPickerRig(rig);
      setOpenDeviceConfigPicker(true);
    },
    []
  );

  const toggleNodeMaintenace = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        toggleMaintenance(region.provider_uuid, rig.region_uuid, rig.rig_uuid)
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const updateNodeStatistics = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        runAction(region.provider_uuid, rig.region_uuid, rig.rig_uuid, 'report')
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );
  const editNode = useCallback(
    (rig) => () => {
      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/node/${rig.rig_uuid}/edit`,
      });
    },
    [history, region]
  );
  const removeNode = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        deleteRig(region.provider_uuid, rig.region_uuid, rig.rig_uuid)
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const renderStatus = useCallback(
    (params) => {
      if (transitioningRigs.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      switch (params.row.status) {
        case STATUSES.ACTIVE:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <CheckCircleIcon color='success' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.OFFLINE:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <StopCircleIcon />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.ERRORED:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <ErrorOutlineIcon color='error' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.MAINTENANCE:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <WarningIcon color='warning' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        default:
          return (
            <>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CircularProgress size={20} />
                <Typography variant='body2'>{params.row.status}</Typography>
              </Stack>
              <PollNode region={region} rig={params.row} interval={10000} />
            </>
          );
      }
    },
    [region, transitioningRigs]
  );

  const renderConfigStatus = useCallback(
    (params) => {
      if (transitioningRigs.includes(params.id)) {
        return <CircularProgress size={20} />;
      }

      if (!params.row.device_config_id) {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <ErrorOutlineIcon color='error' />
            <Typography variant='body2'>not set</Typography>
          </Stack>
        );
      }
      const device_config = device_configs
        ? device_configs.find(
            (device_config) =>
              device_config.config_id === params.row.device_config_id
          )
        : undefined;

      if (!device_config) {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <ErrorOutlineIcon color='error' />
            <Typography variant='body2'>unknown</Typography>
          </Stack>
        );
      }

      if (
        params.row.job_config_id === device_config.config_id &&
        params.row.job_config_revision ===
          new Date(device_config.updatedAt).valueOf().toString()
      ) {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <CheckCircleIcon color='success' />
            <Typography variant='body2'>
              <b>{device_config.name}</b> last deployed{' '}
              {new Date(params.row.job_last_deployed).toLocaleDateString()}
            </Typography>
          </Stack>
        );
      } else {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <WarningIcon color='warning' />
            <Typography variant='body2'>
              {device_config.name} - OUT OF DATE
            </Typography>
          </Stack>
        );
      }
    },
    [transitioningRigs, device_configs]
  );

  const renderJobStatus = useCallback(
    (params) => {
      if (transitioningRigs.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      if (deployingRigs.includes(params.id)) {
        return (
          <>
            <Stack direction='row' spacing={1} alignItems='center'>
              <CircularProgress size={20} />
              <Typography variant='body2'>{params.row.job_status}</Typography>
            </Stack>
            <PollNode region={region} rig={params.row} interval={10000} />
          </>
        );
      }

      if (!params.row.job_status) {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <ErrorOutlineIcon color='error' />
            <Typography variant='body2'>unknown</Typography>
          </Stack>
        );
      }
      switch (params.row.job_status) {
        case STATUSES.RUNNING:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <CheckCircleIcon color='success' />
              <Typography variant='body2'>{params.row.job_status}</Typography>
            </Stack>
          );

        case STATUSES.FAILED:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <ErrorOutlineIcon color='error' />
              <Typography variant='body2'>{params.row.job_status}</Typography>
            </Stack>
          );
        case STATUSES.STOPPED:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <ErrorOutlineIcon color='error' />
              <Typography variant='body2'>{params.row.job_status}</Typography>
            </Stack>
          );
        case STATUSES.UNKNOWN:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <ErrorOutlineIcon color='error' />
              <Typography variant='body2'>{params.row.job_status}</Typography>
            </Stack>
          );
        default:
          return (
            <>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CircularProgress size={20} />
                <Typography variant='body2'>{params.row.job_status}</Typography>
              </Stack>
              <PollNode region={region} rig={params.row} interval={10000} />
            </>
          );
      }
    },
    [region, transitioningRigs, deployingRigs]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 0.5,
        valueGetter: getRigName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'config_status',
        headerName: 'Config Status',
        type: 'string',
        renderCell: renderConfigStatus,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'job_status',
        headerName: 'Inference Status',
        type: 'string',
        renderCell: renderJobStatus,
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'hot_storage',
        headerName: 'Storage',
        valueGetter: getHotStorage,
        renderCell: renderProgress,
        flex: 0.25,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          //   <GridActionsCellItem
          //     icon={<InfoIcon />}
          //     label='View Details'
          //     onClick={viewDetails(params.row)}
          //     showInMenu
          //   />,
          <GridActionsCellItem
            icon={<SystemUpdateAltIcon />}
            label='Deploy Latest Config'
            onClick={deployLatestConfig(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<StopCircleOutlinedIcon />}
            label='Stop Device Inference'
            onClick={stopEndpoint(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<SettingsOutlinedIcon />}
            label='Set Device Config'
            onClick={setDeviceConfig(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EngineeringIcon />}
            label='Toggle Maintenance'
            onClick={toggleNodeMaintenace(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<UpdateIcon />}
            label='Update Stats'
            onClick={updateNodeStatistics(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            onClick={editNode(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Remove'
            onClick={removeNode(params.row)}
            showInMenu
          />,
        ],
      },
    ],
    [
      renderStatus,
      //   viewDetails,
      renderConfigStatus,
      renderJobStatus,
      deployLatestConfig,
      stopEndpoint,
      setDeviceConfig,
      toggleNodeMaintenace,
      updateNodeStatistics,
      editNode,
      removeNode,
    ]
  );

  const Toolbar = () => (
    <GridToolbar
      region={region}
      selected={selectedRigs}
      editable
      service={RIG_SERVICES.COMPUTE}
      type={RIG_TYPES.DEVICE}
      deployConfigToSelected={deployConfigToSelectedRigs}
      disabled={
        !user.cloudbender_enabled
          ? 'Enable CloudBender to add Devices'
          : undefined
      }
    />
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Devices
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.rig_uuid}
                columns={columns}
                rows={rigs}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedRigs(newSelectionModel);
                }}
                // onRowSelectionModelChange={(newSelectionModel) => {
                //   setSelectedRigs(newSelectionModel);
                // }}
                // rowSelectionModel={selectedRigs}
                components={{
                  Toolbar,
                }}
              />
            </div>
          </div>
        </div>
        <DeviceConfigsPicker
          region={region}
          open={openDeviceConfigPicker}
          handleClose={() => {
            setOpenDeviceConfigPicker(false);
            setDeviceConfigPickerRig(undefined);
          }}
          rig={deviceConfigPickerRig}
        />
      </AccordionDetails>
    </Accordion>
  );
}
