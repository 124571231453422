import {
  FETCH_PAYMENTS,
  CLEAR_PAYMENTS,
  CREATE_PAYMENT,
} from '../actions/types';

const initialState = [];

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAYMENTS:
      const newState = action.payload.sort((a, b) => {
        const timeA = a.timestamp;
        const timeB = b.timestamp;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else {
          return 0;
        }
      });
      return newState;
    case CREATE_PAYMENT:
      return [...state, action.payload];
    case CLEAR_PAYMENTS:
      return initialState;
    default:
      return state;
  }
}
export default reducer;
