import React, { Component } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { updateUser } from '../../../actions/user';
import { fetchEnvironments } from '../../../actions/environments';

const buildEnvironmentsList = (environments) => {
  const options = environments.map((env) => {
    return { value: env.id, key: env.id, text: env.name };
  });
  return options;
};
class DefaultSettingsForm extends Component {
  state = {
    default_notebook_environment: this.props.user.default_notebook_environment,
    default_training_environment: this.props.user.default_training_environment,
    default_inference_environment:
      this.props.user.default_inference_environment,
    default_endpoint_environment: this.props.user.default_endpoint_environment,
    pristine: true,
    submitting: false,
  };

  componentDidMount() {
    this.props.fetchEnvironments();
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, pristine: false });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    await this.props.updateUser(this.state);
    this.setState({ submitting: false, pristine: true });
  };

  render() {
    const {
      default_notebook_environment,
      default_training_environment,
      default_inference_environment,
      default_endpoint_environment,
      pristine,
      submitting,
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Dropdown
          name='default_notebook_environment'
          label='Notebook Environment'
          placeholder='Choose Environment'
          value={default_notebook_environment}
          options={buildEnvironmentsList(this.props.environments)}
          selection
          fluid
          onChange={this.handleChange}
        />
        <Form.Dropdown
          name='default_training_environment'
          label='Training Environment'
          placeholder='Choose Environment'
          value={default_training_environment}
          options={buildEnvironmentsList(this.props.environments)}
          selection
          fluid
          onChange={this.handleChange}
        />
        <Form.Dropdown
          name='default_inference_environment'
          label='Inference Environment'
          placeholder='Choose Environment'
          value={default_inference_environment}
          options={buildEnvironmentsList(this.props.environments)}
          selection
          fluid
          onChange={this.handleChange}
        />
        <Form.Dropdown
          name='default_endpoint_environment'
          label='Endpoint Environment'
          placeholder='Choose Environment'
          value={default_endpoint_environment}
          options={buildEnvironmentsList(this.props.environments)}
          selection
          fluid
          onChange={this.handleChange}
        />
        {pristine ? (
          <Button content='Update' primary disabled />
        ) : submitting ? (
          <Button content='Update' primary loading />
        ) : (
          <Button
            content='Update'
            primary
            disabled={this.state.emailError || this.state.nameError}
          />
        )}
      </Form>
    );
  }
}

function mapStateToProps({ user, environments }) {
  return {
    user,
    environments,
  };
}

export default connect(mapStateToProps, { updateUser, fetchEnvironments })(
  DefaultSettingsForm
);
