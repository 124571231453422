import React from 'react';

import { useDispatch } from 'react-redux';

import { fetchRig } from '../../../../actions/rigs';

import useInterval from '../../../../util/useInterval';

export default function PollNode({ region, rig, interval }) {
  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(fetchRig(region.provider_uuid, rig.region_uuid, rig.rig_uuid));
  }, interval);

  return <div></div>;
}
