import _ from 'lodash';
import {
  FETCH_MODELS,
  UPDATE_MODEL,
  CREATE_MODEL,
  DELETE_MODEL,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MODELS:
      newState = [...action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case UPDATE_MODEL:
      newState = state ? [...state] : [];
      _.remove(newState, (model) => {
        return model.model_uuid === action.payload.model_uuid;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case CREATE_MODEL:
      newState = state ? [...state] : [];
      newState = [...newState, action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case DELETE_MODEL:
      newState = state ? [...state] : [];
      _.remove(newState, (model) => {
        return model.model_uuid === action.payload;
      });
      newState = newState.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
