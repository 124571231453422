import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Menu,
  Table,
  Grid,
  Header,
  Container,
  Breadcrumb,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import {
  updateJob,
  fetchJob,
  terminateJob,
  fetchJobs,
} from '../../../actions/jobs';
import { fetchGpuClasses } from '../../../actions/gpuClasses';
import getGpuTypeName from '../getGpuTypeName';
import JobWorker from './JobWorker';
import STATUSES from '../../statuses';
import JOB_TYPES from '../types';
import api from '../../../apis/api';
import NoLogsModal from '../NoLogsModal';
import history from '../../../history';

class JobDetails extends Component {
  timer_interval = null;
  timer_id = null;
  state = {};

  async componentDidMount() {
    if (!this.props.job) {
      this.props.fetchJobs();
    }
    if (!this.props.gpuClasses || !this.props.gpuClasses.length) {
      this.props.fetchGpuClasses();
    }
    this.ensureTimer();
  }

  componentDidUpdate() {
    this.ensureTimer();
  }

  ensureTimer() {
    if (!this.props.job) {
      return;
    }
    const { status, job_uuid, workers } = this.props.job;

    if (status === STATUSES.FAILED) {
      if (this.timer_id) {
        clearInterval(this.timer_id);
        this.timer_interval = null;
        this.timer_id = null;
      }
    } else if (
      [
        STATUSES.NEW,
        STATUSES.STOPPING,
        STATUSES.STARTING,
        STATUSES.PROVISIONING,
        STATUSES.WAITING_DATA,
        STATUSES.WAITING_GPU,
        STATUSES.UPLOADING,
        STATUSES.REMOVING,
        STATUSES.WAITING_RESTORE,
        STATUSES.RESTORING,
        STATUSES.SAVING,
        STATUSES.UPDATING,
        STATUSES.COPYING,
        STATUSES.COMPRESSING,
      ].includes(status)
    ) {
      // Job is in a transition state
      if (!this.timer_id) {
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      } else if (this.timer_interval !== 2000) {
        clearInterval(this.timer_id);
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      }
    } else if (
      workers
        .map((worker) => {
          return [
            STATUSES.NEW,
            STATUSES.STOPPING,
            STATUSES.STARTING,
            STATUSES.PROVISIONING,
            STATUSES.REMOVING,
            STATUSES.WAITING_RESTORE,
            STATUSES.RESTORING,
            STATUSES.SAVING,
            STATUSES.UPDATING,
            STATUSES.COPYING,
            STATUSES.COMPRESSING,
          ].includes(worker.status);
        })
        .includes(true)
    ) {
      if (!this.timer_id) {
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      } else if (this.timer_interval !== 2000) {
        clearInterval(this.timer_id);
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      }
    } else if (status === STATUSES.RUNNING) {
      // job is running, so we need to keep tabs on it slowly
      if (!this.timer_id) {
        this.timer_interval = 30000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      } else if (this.timer_interval !== 30000) {
        clearInterval(this.timer_id);
        this.timer_interval = 30000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      }
    } else if (this.timer_id) {
      clearInterval(this.timer_id);
      this.timer_id = null;
      this.timer_interval = null;
    }
  }

  componentWillUnmount() {
    if (this.timer_id) {
      clearInterval(this.timer_id);
    }
  }

  handleDownload = async () => {
    const { job, user } = this.props;
    this.setState({ downloading: true });
    try {
      let response = await api.get(
        `/job/${job.job_uuid}/worker/${job.workers[0].job_worker_uuid}/logs`,
        { params: { project_uuid: user.selected_project } }
      );
      window.location.href = response.data;
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({
          log_error: true,
        });
      }
    }

    this.setState({ downloading: false });
  };

  handleStop = async () => {
    const { job } = this.props;
    this.setState({ stopping: true });
    await this.props.updateJob(job.job_uuid, { command: 'stop' });
    this.setState({ stopping: false });
  };

  handleCompress = async () => {
    const { job } = this.props;
    this.setState({ compressing: true });
    await this.props.updateJob(job.job_uuid, { command: 'compress' });
    this.setState({ compressing: false });
  };

  handleRestart = async () => {
    const { job } = this.props;
    this.setState({ starting: true });
    await this.props.updateJob(job.job_uuid, { command: 'start' });
    this.setState({ starting: false });
  };

  handleTerminate = async () => {
    const { job } = this.props;
    this.setState({ terminating: true });
    await this.props.terminateJob(job.job_uuid);
    history.push(`/jobs/${job.type}`);
  };

  handleOpen = async () => {
    const win = window.open(
      `${this.props.job.endpoint.url}/?token=${this.props.job.endpoint.token}`,
      '_blank'
    );
    if (win != null) {
      win.focus();
    }
  };

  copyCodeToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => this.setState({ copySuccess: true }));
  };

  render() {
    const { job, type } = this.props;
    return job ? (
      <Container>
        <Grid>
          <Grid.Row>
            <Breadcrumb>
              <Breadcrumb.Section
                link
                onClick={() => history.push('/dashboard')}
              >
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section
                link
                onClick={() => history.push(`/jobs/${type.value}`)}
              >
                {type.label}
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>{job.name}</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Row>
          <Grid.Row>
            <Menu secondary>
              <Menu.Item floated='left' name='home'>
                <Header as='h2'>{job.name}</Header>
              </Menu.Item>
              {job.type === JOB_TYPES.NOTEBOOK.value ? (
                <Menu.Item
                  disabled={job.status !== STATUSES.RUNNING}
                  onClick={this.handleOpen}
                  name='Open'
                  icon='external alternate'
                />
              ) : undefined}
              <Menu.Item
                disabled={
                  ![
                    STATUSES.RUNNING,
                    STATUSES.UPLOADING,
                    STATUSES.WAITING_GPU,
                  ].includes(job.status) || this.state.stopping
                }
                onClick={this.handleStop}
                name='Stop'
                icon='stop circle'
              />
              {job.type === JOB_TYPES.NOTEBOOK.value ? (
                <Menu.Item
                  disabled={
                    job.status !== STATUSES.STOPPED ||
                    this.state.starting ||
                    this.state.terminating
                  }
                  onClick={this.handleRestart}
                  name='Restart'
                  icon='redo'
                />
              ) : undefined}
              {job.type === JOB_TYPES.NOTEBOOK.value ? (
                <Menu.Item
                  disabled={
                    job.status !== STATUSES.STOPPED ||
                    this.state.compressing ||
                    this.state.terminating
                  }
                  onClick={this.handleCompress}
                  name='Compress'
                  icon='compress'
                />
              ) : undefined}
              <Menu.Item
                disabled={
                  ![
                    STATUSES.FAILED,
                    STATUSES.STOPPED,
                    STATUSES.FINISHED,
                    STATUSES.CANCELED,
                    STATUSES.WAITING_DATA,
                    STATUSES.WAITING_RESTORE,
                  ].includes(job.status) || this.state.terminating
                }
                onClick={this.handleTerminate}
                name='Terminate'
                icon='trash alternate'
              />
              {job.type !== JOB_TYPES.NOTEBOOK.value ? (
                <Menu.Item
                  as={Link}
                  to={{
                    pathname: `/jobs/${type.value}/logs/${job.job_uuid}`,
                    state: {
                      job_uuid: job.job_uuid,
                      job_name: job.name,
                    },
                  }}
                  name='View Logs'
                  icon='bars'
                />
              ) : undefined}

              {job.workers.length === 1 &&
              job.type !== JOB_TYPES.NOTEBOOK.value ? (
                <Menu.Item
                  disabled={
                    job.status !== STATUSES.FINISHED || this.state.downloading
                  }
                  onClick={this.handleDownload}
                  name='Download Logs'
                  icon='cloud download'
                />
              ) : undefined}
            </Menu>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p>
                <b>Started:</b> {moment(job.start).format('lll')}
              </p>
              {job.status === STATUSES.STOPPED ? (
                <>
                  <p>
                    <b>Stopped:</b> {moment(job.stop).format('lll')}
                  </p>
                </>
              ) : undefined}
              {job.status === STATUSES.FINISHED ? (
                <>
                  <p>
                    <b>Ended:</b> {moment(job.end).format('lll')}
                  </p>
                </>
              ) : undefined}
              <p>
                <b>Status:</b> {job.status}
              </p>
              <p>
                <b>GPU Type:</b>{' '}
                {getGpuTypeName(
                  job.resources.gpu_type_id,
                  this.props.gpuClasses
                )}
              </p>
              <p>
                <b>GPU Count:</b> {job.resources.gpu_count}
              </p>
              <p>
                <b>Credits:</b> {job.credits}
              </p>
            </Grid.Column>
          </Grid.Row>
          {[JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
            job.type
          ) ? (
            <Grid.Row>
              <Grid.Column>
                {job.workers.length > 1 ? (
                  <Table selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Command</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {job.workers.map((worker, i) => {
                        return (
                          <JobWorker
                            number={i + 1}
                            key={worker.job_worker_uuid}
                            job_uuid={job.job_uuid}
                            worker={worker}
                          />
                        );
                      })}
                    </Table.Body>
                  </Table>
                ) : (
                  <p>
                    <b>Command:</b> {job.workers[0].command}
                  </p>
                )}
              </Grid.Column>
            </Grid.Row>
          ) : job.type === JOB_TYPES.ENDPOINT.value &&
            job.status === STATUSES.RUNNING ? (
            <Grid.Row>
              <Grid.Column>
                <Header as='h4'>Endpoint Address</Header>
                <code>{job.endpoint.url}</code>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  icon='copy outline'
                  basic
                  size='small'
                  onClick={() => this.copyCodeToClipboard(job.endpoint.url)}
                />
                &nbsp;&nbsp;
                {this.state.copySuccess ? <span>Success!</span> : null}
              </Grid.Column>
            </Grid.Row>
          ) : undefined}

          <NoLogsModal
            open={this.state.log_error}
            clearModal={() => this.setState({ log_error: false })}
          />
        </Grid>
      </Container>
    ) : null;
  }
}

function mapStateToProps({ gpuClasses, jobs, user }, ownProps) {
  const job = (jobs || []).find(
    (job) => job.job_uuid === ownProps.match.params.job_uuid
  );
  const type = Object.entries(JOB_TYPES)
    .map(([key, value]) => {
      return value.value === ownProps.match.params.type ? value : undefined;
    })
    .filter((type) => type)[0];

  return {
    gpuClasses,
    job,
    type,
    user,
  };
}

export default connect(mapStateToProps, {
  updateJob,
  fetchJob,
  terminateJob,
  fetchJobs,
  fetchGpuClasses,
})(JobDetails);
