import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchCredentials } from '../../actions/projectCredentials';
import { submitJob } from '../../actions/jobs';
import { fetchUser } from '../../actions/user';
import { fetchDatasets } from '../../actions/datasets';
import { fetchCheckpoints } from '../../actions/checkpoints';
import { fetchModels } from '../../actions/models';
import { fetchPublicDatasets } from '../../actions/publicDatasets';
import { fetchPublicCheckpoints } from '../../actions/publicCheckpoints';
import { fetchEnvironments } from '../../actions/environments';
import { fetchProjectDatastores } from '../../actions/projectDatastores';
import { fetchProjectServices } from '../../actions/projectServices';
import JobForm from './JobForm';
import JobFormReview from './JobFormReview';
import round from '../../util/round';

const cpu_gpu_type_id = '78201a90-ca48-4147-b6f5-fec51b4bd7db';

class JobNew extends Component {
  state = {
    reviewing: false,
    jobRequest: {},
    submitting: false,
  };

  componentDidMount() {
    window.gtag('event', 'job_form_open', {
      customer_uuid: this.props.user.id,
      job_type: this.props.match.params.type || 'notebook',
    });
    this.props.fetchPublicDatasets();
    this.props.fetchPublicCheckpoints();
    this.props.fetchCredentials();
    this.props.fetchDatasets();
    this.props.fetchCheckpoints();
    this.props.fetchEnvironments();
    this.props.fetchModels();
    this.props.fetchUser();
    this.props.fetchProjectDatastores();
    this.props.fetchProjectServices();
  }

  handleFormSubmit = (jobRequest) => {
    let min_price = 10000,
      max_price = 0,
      use_price = 10000;
    jobRequest.resources.gpu_types.forEach((gpu_type_id) => {
      const selected_gpu_class = this.props.gpuClasses.find(
        (gpu_class) => gpu_class.id === gpu_type_id
      );
      min_price = Math.min(selected_gpu_class.price.min, min_price);
      max_price = Math.max(selected_gpu_class.price.max, max_price);
      use_price = Math.min(selected_gpu_class.price.use, use_price);
    });

    const cpu_only = jobRequest.resources.gpu_types[0] === cpu_gpu_type_id;

    const credits_per_hour_min = round(
      min_price *
        (cpu_only
          ? jobRequest.resources.cpu_count
          : jobRequest.resources.gpu_count) *
        (jobRequest.workers.length || 1),
      4
    );
    const credits_per_hour_max = round(
      max_price *
        (cpu_only
          ? jobRequest.resources.cpu_count
          : jobRequest.resources.gpu_count) *
        (jobRequest.workers.length || 1),
      4
    );
    const max_price_too_low = jobRequest.resources.max_price < use_price;
    this.props.fetchUser();

    this.setState({
      jobRequest: { ...jobRequest },
      credits_per_hour_min,
      credits_per_hour_max,
      max_price_too_low,
      reviewing: true,
    });
  };

  handleReviewCancel = () => {
    this.setState({ reviewing: false });
  };

  handleReviewSubmit = async () => {
    this.setState({ submitting: true });
    window.gtag('event', 'job_submitted', {
      customer_uuid: this.props.user.id,
      job_type: this.state.jobRequest.type,
    });
    try {
      await this.props.submitJob(this.state.jobRequest);
      window.gtag('event', 'job_created', {
        customer_uuid: this.props.user.id,
        job_type: this.state.jobRequest.type,
      });
    } catch (error) {
      this.setState({ submitting: false });
      window.gtag('event', 'job_create_failed', {
        customer_uuid: this.props.user.id,
        job_type: this.state.jobRequest.type,
      });
      throw error;
    }
  };

  render() {
    const {
      reviewing,
      submitting,
      jobRequest,
      credits_per_hour_min,
      credits_per_hour_max,
      max_price_too_low,
    } = this.state;
    if (reviewing) {
      return (
        <JobFormReview
          jobRequest={jobRequest}
          submitting={submitting}
          credits_per_hour_min={credits_per_hour_min}
          credits_per_hour_max={credits_per_hour_max}
          max_price_too_low={max_price_too_low}
          onSubmit={this.handleReviewSubmit}
          onCancel={this.handleReviewCancel}
        />
      );
    } else {
      return (
        <JobForm
          jobRequest={jobRequest}
          onSubmit={this.handleFormSubmit}
          type={this.props.match.params.type || 'notebook'}
        />
      );
    }
  }
}

function mapStateToProps({ user, gpuClasses }) {
  return { user, gpuClasses };
}

export default connect(mapStateToProps, {
  fetchCredentials,
  submitJob,
  fetchUser,
  fetchDatasets,
  fetchModels,
  fetchPublicDatasets,
  fetchEnvironments,
  fetchProjectDatastores,
  fetchProjectServices,
  fetchCheckpoints,
  fetchPublicCheckpoints,
})(JobNew);
