import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ScopedCssBaseline, Container, Typography } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';

import theme from '../../../../theme';
import PROVIDER_TYPES from '../../providerTypes';
import PhysicalNodeForm from './PhysicalNodeForm';
import GcpNodeForm from './GcpNodeForm';
import ErrorSnackBar from '../../../shared/ErrorSnackBar';
import { clearErrorMessage } from '../../../../actions/errorMessage';
import LoadingMask from '../../../shared/LoadingMask';
import {
  fetchActiveProvider,
  fetchProviders,
} from '../../../../actions/providers';
import { fetchActiveRegion, fetchRegions } from '../../../../actions/regions';
import AwsNodeForm from './AwsNodeForm';

export default function NodeForm() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider_uuid, region_uuid, rig_uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [loading_error, setLoadingError] = useState(false);

  const provider = useSelector((state) => state.provider);
  const providers = useSelector((state) => state.providers);
  const region = useSelector((state) => state.region);
  const regions = useSelector((state) => state.regions);
  const rigs = useSelector((state) => state.rigs);
  const api_error = useSelector((state) => state.errorMessage);
  const [physical_provider, setPhysicalProvider] = useState(
    provider?.type === PROVIDER_TYPES.PHYSICAL.value ? provider : null
  );

  const sp = new URLSearchParams(location.search);

  let rig, service, type, minion_id;
  service = sp.get('service');
  type = sp.get('type');
  minion_id = sp.get('minion_id');
  rig = rigs?.find((rig) => rig.rig_uuid === rig_uuid);
  if (rig) {
    service = rig.service;
    type = rig.type;
  }

  useEffect(() => {
    if (!loading_error && provider_uuid && !provider) {
      try {
        dispatch(fetchActiveProvider(provider_uuid));
      } catch (error) {
        console.log(error);
        setLoadingError(true);
      }
    }
  }, [dispatch, provider_uuid, provider, loading_error, setLoadingError]);

  useEffect(() => {
    if (!physical_provider && providers) {
      setPhysicalProvider(
        providers?.find(
          (provider) => provider.type === PROVIDER_TYPES.PHYSICAL.value
        )
      );
    }
  }, [physical_provider, setPhysicalProvider, providers]);

  useEffect(() => {
    if (!loading_error && provider_uuid && region_uuid && !region) {
      try {
        dispatch(fetchActiveRegion(provider_uuid, region));
      } catch (error) {
        console.log(error);
        setLoadingError(true);
      }
    }
  }, [
    dispatch,
    region_uuid,
    provider_uuid,
    provider,
    region,
    loading_error,
    setLoadingError,
  ]);

  useEffect(() => {
    (async () => {
      if (minion_id && !providers) {
        dispatch(fetchProviders());
      } else if (
        !loading_error &&
        minion_id &&
        physical_provider &&
        !regions[PROVIDER_TYPES.PHYSICAL.value]
      ) {
        try {
          dispatch(
            fetchRegions(
              physical_provider.provider_uuid,
              physical_provider.type
            )
          );
        } catch (error) {
          console.log(error);
          setLoadingError(true);
        }
      }
    })();
  }, [
    dispatch,
    minion_id,
    physical_provider,
    providers,
    regions,
    loading_error,
    setLoadingError,
  ]);

  useEffect(() => {
    if (minion_id) {
      if (providers && physical_provider === undefined) {
        // hasn't created the physical provider yet
        setLoading(false);
      } else if (
        physical_provider &&
        (regions[PROVIDER_TYPES.PHYSICAL.value] || loading_error)
      ) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [
    minion_id,
    regions,
    providers,
    physical_provider,
    loading_error,
    setLoading,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  if (!region_uuid && !minion_id) {
    history.push('/resources');
    return <div>Redirecting...</div>;
  }

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <LoadingMask />
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  if (region_uuid && !region) {
    history.push('/resources');
    return <div>Redirecting...</div>;
  }
  if (providers && physical_provider === undefined) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <Typography>
              Enable the Physical provider in{' '}
              <Link to='/resources'>CloudBender&#8482;</Link> to add nodes.
            </Typography>
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }
  if (
    physical_provider &&
    !regions[PROVIDER_TYPES.PHYSICAL.value] &&
    loading_error
  ) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <Typography>
              <Link
                to={`/resources/provider/${physical_provider.provider_uuid}/region/new`}
              >
                Add a region
              </Link>{' '}
              to the Physical provider to add nodes.
            </Typography>
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }
  if (
    minion_id &&
    physical_provider &&
    regions[PROVIDER_TYPES.PHYSICAL.value]
  ) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <PhysicalNodeForm
              service={service}
              type={type}
              minion_id={minion_id}
            />
            <ErrorSnackBar message={api_error} />
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  switch (region?.provider_type) {
    case PROVIDER_TYPES.PHYSICAL.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <PhysicalNodeForm
                key={rig?.rig_uuid}
                region={region}
                rig={rig}
                service={service}
                type={type}
              />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case PROVIDER_TYPES.GCP.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <GcpNodeForm
                key={rig?.rig_uuid}
                region={region}
                rig={rig}
                service={service}
                type={type}
              />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case PROVIDER_TYPES.AWS.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <AwsNodeForm
                key={rig?.rig_uuid}
                region={region}
                rig={rig}
                service={service}
                type={type}
              />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    default:
      history.push('/resources');
      return <div>Redirecting...</div>;
  }
}
