import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

export default function AccessField({
  value,
  setValue,
  setChanged,
  label,
  helperText,
}) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  const helper_id = label.replaceAll(' ', '-');
  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id={`${helper_id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${helper_id}-label`}
          id={`${helper_id}`}
          value={value}
          label={label}
          aria-describedby={`${helper_id}-helper-text`}
          onChange={handleChange}
        >
          <MenuItem value={'read'}>Read Only</MenuItem>
          <MenuItem value={'all'}>All (Read/Write)</MenuItem>
        </Select>
        {
          <FormHelperText id={`${helper_id}-helper-text`}>
            {helperText}
          </FormHelperText>
        }
      </FormControl>
    </Box>
  );
}
