import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Header, Icon, Table, Button, Transition } from 'semantic-ui-react';
import { fetchJob } from '../../../actions/jobs';
import STATUSES from '../../statuses';
import getGpuTypeName from '../getGpuTypeName';
import ConnectDialog from '../../ConnectDialog';
import JOB_TYPES from '../types';
import THIRD_PARTY_TYPES from '../JobForm/thirdPartyTypes';

class Job extends Component {
  timer_interval = null;
  timer_id = null;

  state = {
    animate: true,
    clicked: false,
    connect_modal: false,
  };
  animate_timer_id = null;

  componentDidMount() {
    this.ensureTimer();
  }

  componentDidUpdate() {
    this.ensureTimer();
  }

  ensureTimer() {
    const { status, job_uuid, workers, model, data, vpn } = this.props.job;

    if (status === STATUSES.FAILED) {
      if (this.timer_id) {
        clearInterval(this.timer_id);
        this.timer_interval = null;
        this.timer_id = null;
      }
    } else if (
      [
        STATUSES.NEW,
        STATUSES.WAITING_INSTANCE,
        STATUSES.STOPPING,
        STATUSES.STARTING,
        STATUSES.PROVISIONING,
        STATUSES.WAITING_DATA,
        STATUSES.WAITING_GPU,
        STATUSES.UPLOADING,
        STATUSES.REMOVING,
        STATUSES.WAITING_RESTORE,
        STATUSES.RESTORING,
        STATUSES.SAVING,
        STATUSES.UPDATING,
        STATUSES.COPYING,
        STATUSES.COMPRESSING,
      ].includes(status)
    ) {
      // Job is in a transition state
      if (!this.timer_id) {
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      } else if (this.timer_interval !== 2000) {
        clearInterval(this.timer_id);
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      }
    } else if (
      workers
        .map((worker) => {
          return [
            STATUSES.NEW,
            STATUSES.STOPPING,
            STATUSES.STARTING,
            STATUSES.PROVISIONING,
            STATUSES.REMOVING,
            STATUSES.WAITING_RESTORE,
            STATUSES.WAITING_INSTANCE,
            STATUSES.RESTORING,
            STATUSES.SAVING,
            STATUSES.UPDATING,
            STATUSES.COPYING,
            STATUSES.COMPRESSING,
          ].includes(worker.status);
        })
        .includes(true)
    ) {
      if (!this.timer_id) {
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      } else if (this.timer_interval !== 2000) {
        clearInterval(this.timer_id);
        this.timer_interval = 2000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      }
    } else if (status === STATUSES.RUNNING) {
      // job is running, so we need to keep tabs on it slowly
      if (!this.timer_id) {
        this.timer_interval = 30000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      } else if (this.timer_interval !== 30000) {
        clearInterval(this.timer_id);
        this.timer_interval = 30000;
        this.timer_id = setInterval(
          () => this.props.fetchJob(job_uuid),
          this.timer_interval
        );
      }
    } else if (this.timer_id) {
      clearInterval(this.timer_id);
      this.timer_id = null;
      this.timer_interval = null;
    }
    if (
      vpn.status === STATUSES.RUNNING &&
      ((data.output_type === 'local' && status === STATUSES.UPLOADING) ||
        (data.input_type === 'local' && status === STATUSES.WAITING_DATA) ||
        (model.source_type === 'local' && status === STATUSES.WAITING_DATA)) &&
      !vpn.client.connected &&
      !this.state.clicked
    ) {
      if (!this.animate_timer_id) {
        this.animate_timer_id = setInterval(() => {
          this.setState({ animate: !this.state.animate });
        }, 1000);
      }
    } else if (this.animate_timer_id) {
      clearInterval(this.animate_timer_id);
      this.animate_timer_id = null;
    }
  }

  componentWillUnmount() {
    if (this.timer_id) {
      clearInterval(this.timer_id);
    }
  }

  handleWorkerChange = () => {
    this.ensureTimer();
  };

  handleConnect = () => {
    this.setState({ clicked: true, connect_modal: true });
  };

  handleOpen = async () => {
    const win = window.open(
      `${this.props.job.endpoint.url}/?token=${this.props.job.endpoint.token}`,
      '_blank'
    );
    if (win != null) {
      win.focus();
    }
  };

  renderStatus(status) {
    if (
      [
        STATUSES.NEW,
        STATUSES.STARTING,
        STATUSES.PROVISIONING,
        STATUSES.STOPPING,
        STATUSES.REMOVING,
        STATUSES.BUILDING,
        STATUSES.DOWNLOADING,
        STATUSES.CACHING,
        STATUSES.RESTORING,
        STATUSES.SAVING,
        STATUSES.UPDATING,
        STATUSES.COPYING,
        STATUSES.COMPRESSING,
      ].includes(status)
    ) {
      return <Icon name='circle notch' loading size='large' />;
    }
    if (
      [
        STATUSES.WAITING_DATA,
        STATUSES.WAITING_RESTORE,
        STATUSES.WAITING_INSTANCE,
        STATUSES.WAITING_GPU,
      ].includes(status)
    ) {
      return <Icon name='wait' size='large' />;
    }
    if (status === STATUSES.STOPPED) {
      return <Icon name='stop circle outline' color='grey' size='large' />;
    }
    if (status === STATUSES.FINISHED) {
      return <Icon name='check circle outline' color='grey' size='large' />;
    }
    if (status === STATUSES.CANCELED) {
      return <Icon name='times circle outline' color='grey' size='large' />;
    }
    if (status === STATUSES.RUNNING || status === STATUSES.READY) {
      return <Icon name='play circle' color='green' size='large' />;
    }
    if (status === STATUSES.FAILED) {
      return <Icon name='exclamation circle' color='red' size='large' />;
    }
  }

  renderModel(job) {
    if (job.model.source_type) {
      if (
        ![
          THIRD_PARTY_TYPES.TRAINML.value,
          THIRD_PARTY_TYPES.EVEFS.value,
        ].includes(job.model.source_type)
      ) {
        return job.model.source_uri;
      } else {
        const model = this.props.models.find(
          (model) => model.model_uuid === job.model.source_uri
        );
        if (model) {
          return model.name;
        }
        return 'Unknown';
      }
    }
    return 'None';
  }

  render() {
    const { job } = this.props;

    return (
      <Table.Row
        key={job.job_uuid}
        active={this.props.selected}
        onClick={() => this.props.handleSelect(job.job_uuid)}
        warning={
          job.vpn.status === STATUSES.RUNNING &&
          ((job.data.output_type === 'local' &&
            job.status === STATUSES.UPLOADING) ||
            (job.data.input_type === 'local' &&
              job.status === STATUSES.WAITING_DATA) ||
            (job.model.source_type === 'local' &&
              job.status === STATUSES.WAITING_DATA)) &&
          !job.vpn.client.connected
        }
      >
        <Table.Cell>
          {this.props.selected ? (
            <Icon name='check square outline' size='large' />
          ) : (
            <Icon name='square outline' size='large' />
          )}
        </Table.Cell>
        <Table.Cell>
          <Link
            to={{
              pathname: `/jobs/${job.type}/details/${job.job_uuid}`,
              job_uuid: job.job_uuid,
              state: {
                job_uuid: job.job_uuid,
              },
            }}
          >
            <Header
              as='h3'
              style={{ textDecoration: 'underline' }}
              color='blue'
            >
              {job.name}
            </Header>
          </Link>
          {this.props.transitioning ? (
            <Icon name='circle notch' loading size='large' />
          ) : (
            this.renderStatus(job.status)
          )}
          <span>
            <b>{job.status}</b>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>Created: {moment(job.start).format('ll')}</span>
        </Table.Cell>
        {[JOB_TYPES.NOTEBOOK.value, JOB_TYPES.ENDPOINT.value].includes(
          job.type
        ) ? (
          <>
            <Table.Cell textAlign='center'>
              <p>
                {job.resources.gpu_types
                  ? getGpuTypeName(
                      job.resources.gpu_types[0],
                      this.props.gpuClasses
                    )
                  : 'Unknown'}
              </p>
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <p>{job.resources.gpu_count || 0}</p>
            </Table.Cell>
          </>
        ) : undefined}
        <Table.Cell textAlign='center'>
          {job.environment.status === STATUSES.READY ? (
            <p>
              {job.environment.type === 'CUSTOM'
                ? job.environment.custom_image
                : job.environment.type}
            </p>
          ) : (
            <p>
              {this.renderStatus(job.environment.status)}
              <span>{job.environment.status}</span>
            </p>
          )}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {job.model.status === STATUSES.READY ? (
            <p>{this.renderModel(job)}</p>
          ) : (
            <p>
              {this.renderStatus(job.model.status)}
              <span>{job.model.status}</span>
            </p>
          )}
        </Table.Cell>
        {job.type === JOB_TYPES.INFERENCE.value ? (
          <Table.Cell textAlign='center'>
            {job.data.status === STATUSES.READY ? (
              job.data.input_uri ? (
                <p>
                  {job.data.input_type === THIRD_PARTY_TYPES.REGIONAL.value
                    ? job.data.input_options.path
                    : job.data.input_uri}
                </p>
              ) : (
                <p>None</p>
              )
            ) : (
              <p>
                {this.renderStatus(job.data.status)}
                <span>{job.data.status}</span>
              </p>
            )}
          </Table.Cell>
        ) : undefined}
        {[JOB_TYPES.NOTEBOOK.value, JOB_TYPES.TRAINING.value].includes(
          job.type
        ) ? (
          <Table.Cell textAlign='center'>
            {job.data.status === STATUSES.READY ? (
              job.data.datasets.length ? (
                job.data.datasets.map((dataset) => {
                  return <p key={dataset.dataset_uuid}>{dataset.name}</p>;
                })
              ) : (
                <p>None</p>
              )
            ) : (
              <p>
                {this.renderStatus(job.data.status)}
                <span>{job.data.status}</span>
              </p>
            )}
          </Table.Cell>
        ) : undefined}
        <Table.Cell textAlign='right'>
          {([JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
            job.type
          ) &&
            job.vpn.status === STATUSES.RUNNING &&
            !this.props.transitioning) ||
          ([JOB_TYPES.NOTEBOOK.value, JOB_TYPES.ENDPOINT.value].includes(
            job.type
          ) &&
            job.vpn.status === STATUSES.RUNNING &&
            job.model.source_type === 'local' &&
            job.status === STATUSES.WAITING_DATA &&
            !this.props.transitioning) ||
          (job.status === STATUSES.RUNNING &&
            job.type === JOB_TYPES.ENDPOINT.value) ? (
            ((job.data.output_type === 'local' &&
              job.status === STATUSES.UPLOADING) ||
              (job.data.input_type === 'local' &&
                job.status === STATUSES.WAITING_DATA) ||
              (job.model.source_type === 'local' &&
                job.status === STATUSES.WAITING_DATA)) &&
            !job.vpn.client.connected ? (
              <Transition
                animation='pulse'
                duration='500'
                visible={this.state.animate}
              >
                <Button
                  secondary
                  basic
                  content='Connect'
                  icon='linkify'
                  onClick={this.handleConnect}
                />
              </Transition>
            ) : (
              <Button
                secondary
                basic
                content='Connect'
                icon='linkify'
                onClick={this.handleConnect}
              />
            )
          ) : undefined}
          {job.type === JOB_TYPES.NOTEBOOK.value &&
          job.status === STATUSES.RUNNING &&
          !this.props.transitioning ? (
            <Button
              secondary
              basic
              icon='external alternate'
              content='Open'
              onClick={this.handleOpen}
            />
          ) : undefined}
          {job.type !== JOB_TYPES.NOTEBOOK.value &&
          job.status !== STATUSES.NEW ? (
            <Link
              to={{
                pathname: `/jobs/${job.type}/logs/${job.job_uuid}`,
                state: {
                  job_uuid: job.job_uuid,
                  job_name: job.name,
                },
              }}
            >
              <Button content='View' secondary basic icon='bars' />
            </Link>
          ) : undefined}
        </Table.Cell>
        <ConnectDialog
          open={this.state.connect_modal}
          clearModal={() => this.setState({ connect_modal: false })}
          id={job.job_uuid}
          type='job'
          endpoint={
            job.type === JOB_TYPES.ENDPOINT.value &&
            job.status === STATUSES.RUNNING
              ? job.endpoint
              : undefined
          }
        />
      </Table.Row>
    );
  }
}

function mapStateToProps({ gpuClasses, models }) {
  return {
    models: models || [],
    gpuClasses,
  };
}
export default connect(mapStateToProps, {
  fetchJob,
})(Job);
