import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header } from 'semantic-ui-react';

import ConsolidatedBillingUser from './ConsolidatedBillingUser';

class ConsolidatedBillingAdmin extends Component {
  state = {
    email: '',
    error: false,
    pristine: true,
    submitting: false,
  };

  render() {
    return (
      <>
        {this.props.user.billing_link_requests ? (
          <>
            {' '}
            <Header as='h3'>Pending Consolidated Billing Requests</Header>
            {this.props.user.billing_link_requests.map((link_request) => {
              return (
                <ConsolidatedBillingUser
                  key={link_request.email}
                  user={link_request}
                  pending
                />
              );
            })}
          </>
        ) : undefined}
        {this.props.user.billing_linked_accounts ? (
          <>
            <Header as='h3'>Active Consolidated Billing Links</Header>
            {this.props.user.billing_linked_accounts.map((link_request) => {
              return (
                <ConsolidatedBillingUser
                  key={link_request.email}
                  user={link_request}
                />
              );
            })}
          </>
        ) : undefined}
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(ConsolidatedBillingAdmin);
