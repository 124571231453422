import _ from 'lodash';
import {
  FETCH_CHECKPOINTS,
  UPDATE_CHECKPOINT,
  DELETE_CHECKPOINT,
  CREATE_CHECKPOINT,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchCheckpoints = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;

    let res = await api.get('/checkpoint', { params: { project_uuid } });
    dispatch({
      type: FETCH_CHECKPOINTS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateCheckpoint = (checkpoint_uuid, name) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.patch(
      `/checkpoint/${checkpoint_uuid}`,
      { name: name },
      { params: { project_uuid } }
    );
    dispatch({
      type: UPDATE_CHECKPOINT,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteCheckpoint = (checkpoint_uuid) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    await api.delete(`/checkpoint/${checkpoint_uuid}`, {
      params: { project_uuid },
    });
    dispatch({
      type: DELETE_CHECKPOINT,
      payload: checkpoint_uuid,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createCheckpoint = (values) => async (dispatch) => {
  try {
    if (!values.project_uuid) {
      const project_uuid = store.getState().user.selected_project;
      values.project_uuid = project_uuid;
    }
    const res = await api.post('/checkpoint', values);
    if (res.data.project_uuid !== store.getState().user.selected_project) {
      return;
    }
    dispatch({
      type: CREATE_CHECKPOINT,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const exportCheckpoint =
  (checkpoint_uuid, values) => async (dispatch) => {
    try {
      const project_uuid = store.getState().user.selected_project;
      const res = await api.post(
        `/checkpoint/${checkpoint_uuid}/export`,
        values,
        {
          params: { project_uuid },
        }
      );
      dispatch({
        type: UPDATE_CHECKPOINT,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const abortExportCheckpoint = (checkpoint_uuid) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.delete(`/checkpoint/${checkpoint_uuid}/export`, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_CHECKPOINT,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchCheckpoint = (checkpoint_uuid) => async (dispatch) => {
  try {
    const checkpoint = await getCheckpoint(checkpoint_uuid);
    if (!checkpoint || _.isEmpty(checkpoint)) {
      dispatch({ type: DELETE_CHECKPOINT, payload: checkpoint_uuid });
    } else {
      dispatch({
        type: UPDATE_CHECKPOINT,
        payload: checkpoint,
      });
    }
  } catch (error) {
    dispatch(setErrorMessage(error));
    throw error;
  }
};

export const getCheckpoint = async (checkpoint_uuid) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/checkpoint/${checkpoint_uuid}`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return;
    }
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const getCheckpointDetails = async (checkpoint_uuid) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/checkpoint/${checkpoint_uuid}/details`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
