import { Amplify } from 'aws-amplify';

// your Cognito Hosted UI configuratio

export const getCognitoSignInUrl = () => {
  const signinUrl = `https://${process.env.REACT_APP_AUTH_DOMAIN}/login?response_type=code&client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_SIGNIN}`;
  return signinUrl;
};

export const extractSession = (user) => {
  return {
    expires: user.signInUserSession.idToken.payload.exp,
    credentials: {
      accessToken: user.signInUserSession.accessToken.jwtToken,
      idToken: user.signInUserSession.idToken.jwtToken,
      refreshToken: user.signInUserSession.refreshToken.token,
    },
    user: {
      customer_uuid: user.signInUserSession.idToken.payload.sub,
      userName: user.signInUserSession.idToken.payload['cognito:username'],
      email: user.signInUserSession.idToken.payload.email,
      groups: user.signInUserSession.idToken.payload['cognito:groups'],
      given_name: user.signInUserSession.idToken.payload.given_name,
      family_name: user.signInUserSession.idToken.payload.family_name,
      name: user.signInUserSession.idToken.payload.name,
      locale: user.signInUserSession.idToken.payload.locale,
    },
  };
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
});
