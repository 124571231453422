import React from 'react';

import { FormControl, FormHelperText, TextField } from '@mui/material';

export default function TenantField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Directory (tenant) ID cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <FormControl fullWidth required>
      <TextField
        label='Directory (tenant) ID'
        id='tenant'
        aria-describedby='tenant-helper-text'
        variant='outlined'
        onChange={handleChange}
        error={Boolean(error)}
        value={value}
        disabled={edit}
        placeholder='00000000-0000-0000-0000-000000000000'
      />
      {error ? (
        <FormHelperText id='name-helper-text'>{error}</FormHelperText>
      ) : undefined}
    </FormControl>
  );
}
