import React, { useEffect } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCredentials } from '../../../../../../actions/projectCredentials';
import ENVIRONMENT_KEY_TYPES from '../../../../../jobs/JobForm/environmentKeyTypes';
import renderThirdPartyMenuOption from '../../../../../shared/renderThirdPartyMenuOption';

export default function DeviceConfigWorkerKeysField({
  value,
  setValue,
  setChanged,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const project_credentials = useSelector((state) => state.projectCredentials);

  useEffect(() => {
    (async () => {
      if (project_credentials === null) {
        dispatch(fetchCredentials());
        return;
      }
    })();
  }, [dispatch, project_credentials]);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(typeof newValue === 'string' ? newValue.split(',') : newValue);
  };

  const project_key_types = project_credentials
    ? project_credentials.map((key) => key.type)
    : [];
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='worker-key-types-label'>Attached Keys</InputLabel>
        <Select
          labelId='worker-key-types-label'
          id='worker_key_types'
          value={value}
          multiple
          label='Attached Keys'
          input={
            <OutlinedInput id='select-multiple-chip' label='Attached Keys' />
          }
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const type = ENVIRONMENT_KEY_TYPES.find(
                  (type) => type.value === value
                );
                return <Chip key={value} label={type.text} />;
              })}
            </Box>
          )}
        >
          {ENVIRONMENT_KEY_TYPES.map((type) => {
            return renderThirdPartyMenuOption(
              type,
              project_key_types,
              user.cloudbender_enabled
            );
          })}
        </Select>
        <FormHelperText id='worker-key-types-helper-text'>
          The third-party credentials to attach to the device endpoint.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
