const DATASTORE_TYPES = {
  NFS: 'nfs',
  SMB: 'smb',
  AWS_S3: 'aws_s3',
  GCP_CS: 'gcp_cs',
  AWS_EFS: 'aws_efs',
  GCP_FS: 'gcp_fs',
  AWS_EBS: 'aws_ebs',
  GCP_PD: 'gcp_pd',
};
export default DATASTORE_TYPES;
