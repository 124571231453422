import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { ScopedCssBaseline, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import theme from '../../theme';
import AccountSignup from './AccountSignup';

export default function AccountLogin() {
  const history = useHistory();

  const user = useSelector((state) => state.user);

  if (!user) {
    Auth.signOut();
  }
  switch (user.status) {
    case 'new':
      return <AccountSignup />;
    case 'pending':
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <Typography variant='h3' align={'center'}>
                Thank you for signing up!
              </Typography>
              <Typography variant='h6' align={'center'}>
                Your account is pending approval by the site admins.
              </Typography>
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case 'banned':
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <Typography variant='h3' align={'center'}>
                Your account has been disabled.
              </Typography>
              <Typography variant='h6' align={'center'}>
                Please contact us at support@
                {`${process.env.REACT_APP_DOMAIN_SUFFIX}`}
              </Typography>
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case 'approved':
      const redirect_path = localStorage.getItem('redirect_path');
      const redirect_search = localStorage.getItem('redirect_search');
      if (redirect_path) {
        localStorage.removeItem('redirect_path');
        localStorage.removeItem('redirect_search');
        history.push({
          pathname: redirect_path,
          search: redirect_search,
        });
      } else {
        history.push('/dashboard');
      }

      return <div>Redirecting...</div>;
    default:
      return <div>Unknown</div>;
  }
}
