import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducers from './reducers';

let store;
if (process.env.NODE_ENV !== 'production') {
  store = configureStore({
    reducer: reducers,
    middleware: [thunk],
    devTools: {
      trace: true,
      traceLimit: 25,
    },
  });
} else {
  store = configureStore({ reducer: reducers, middleware: [thunk] });
}

export default store;
