import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const NoLogsModal = (props) => {
  return (
    <Modal
      open={props.open}
      closeOnDimmerClick
      closeOnEscape
      onClose={props.clearModal}
    >
      <Modal.Header>Logs Not Available</Modal.Header>
      <Modal.Content>
        <p>
          Log extract is not currently available. If the worker recently
          finished, the log extract may still be generating. If the worker
          finished more than 7 days ago, the log extract may have been purged.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon='checkmark'
          primary
          labelPosition='right'
          onClick={props.clearModal}
          content='Okay'
        />
      </Modal.Actions>
    </Modal>
  );
};
export default NoLogsModal;
