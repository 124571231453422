import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import theme from '../../theme';
import { fetchUser, createUser } from '../../actions/user';
import { setSession } from '../../actions/session';
import { extractSession } from '../../services/auth';

export default function AuthCallback() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);
  const user = useSelector((state) => state.user);

  const sp = useMemo(() => new URLSearchParams(location.search), [location]);

  const auth_type = sp.get('session') ? 'external' : 'hosted';

  useEffect(() => {
    async function anaylzeResult() {
      if (user && user.id) {
        history.push('/account/login');
      } else if (user && _.isEmpty(user)) {
        if (auth_type === 'hosted') {
          dispatch(createUser());
        } else {
          history.push('/logout');
        }
      } else if (session && session.isLoggedIn) {
        dispatch(fetchUser());
      } else {
        if (auth_type === 'external') {
          const manual_session = atob(sp.get('session'));
          dispatch(setSession(JSON.parse(manual_session)));
        } else {
          const user = await Auth.currentAuthenticatedUser();
          dispatch(setSession(extractSession(user)));
        }
      }
    }
    anaylzeResult();
  }, [session, user, auth_type, dispatch, sp, history]);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Stack alignItems={'center'} spacing={2}>
              <CircularProgress color='inherit' />
              <Typography variant='h6'>Analyzing</Typography>
            </Stack>
          </Backdrop>
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
