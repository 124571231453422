import { FETCH_ENVIRONMENTS } from './types';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchEnvironments = () => async (dispatch) => {
  try {
    const res = await api.get('/job/environments');
    dispatch({
      type: FETCH_ENVIRONMENTS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
