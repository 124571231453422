import React from 'react';

import {
  FormControl,
  FormControlLabel,
  Box,
  FormHelperText,
  Switch,
} from '@mui/material';

export default function CopyCredentialsField({ value, setValue, setChanged }) {
  return (
    <Box>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={() => {
                setChanged();
                setValue(!value);
              }}
            />
          }
          label='Copy Credentials'
        />
        <FormHelperText id='copy-credentials-helper-text'>
          Copy all configured credentials from my personal project to the new
          project.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
