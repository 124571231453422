import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from '@mui/material';

export default function PaymentModeField(props) {
  const handleChange = (event) => {
    if (event.target.value !== props.value) {
      props.setChanged();
    }
    props.setValue(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='payment-mode-label'>Payment Mode</InputLabel>
        <Select
          labelId='payment-mode-label'
          id='payment-mode'
          value={props.value}
          label='Payment Mode'
          onChange={handleChange}
        >
          <MenuItem value='credits'>Credits</MenuItem>
          <MenuItem value='stripe' disabled>
            Stripe
          </MenuItem>
        </Select>
        <FormHelperText id='payment-mode-helper-text'>
          The method to redeem accumulated credits.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
