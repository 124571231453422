import _ from 'lodash';
import React, { Component } from 'react';

import { Segment, Form, Message, Icon, Radio } from 'semantic-ui-react';
import JOB_TYPES from '../types';

class WorkerFields extends Component {
  state = {
    expanded: !this.props.edit,
    worker_command_error: false,
    workers_errors: this.props.initialValues
      ? new Array(this.props.initialValues.length).fill(false)
      : [],
    worker_command_type:
      this.props.type === JOB_TYPES.TRAINING.value ? 'different' : 'same',
    worker_command:
      this.props.type === JOB_TYPES.INFERENCE.value &&
      this.props.initialValues &&
      this.props.initialValues[0]
        ? this.props.initialValues[0].command
        : '',
    worker_count: this.props.initialValues
      ? Math.max(this.props.initialValues.length, 1)
      : 1,
    workers:
      this.props.initialValues && this.props.initialValues.length
        ? this.props.initialValues.map((worker) => {
            return {
              command: worker.command,
            };
          })
        : [{ command: '' }],
  };

  componentDidMount() {
    const blank_commands = this.state.workers.filter(
      (worker) => worker.command === ''
    );
    this.props.onChange(
      'workers',
      this.state.workers,
      Boolean(blank_commands.length)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevState, this.state)) {
      return;
    }
    let workers;
    let command_blank;
    if (this.state.worker_command_type === 'same') {
      workers = new Array(this.state.worker_count).fill({
        command: this.state.worker_command,
      });
      command_blank = this.state.worker_command === '';
    } else {
      workers = this.state.workers;
      const blank_commands = this.state.workers.filter(
        (worker) => worker.command === ''
      );
      command_blank = Boolean(blank_commands.length);
    }
    const worker_errors = new Set([this.state.worker_commands_errors]);
    this.props.onChange(
      'workers',
      workers,
      this.state.worker_command_error ||
        worker_errors.has(true) ||
        command_blank
    );
  }

  handleChange = (e, { name, value }) => {
    const array_re = /(?<key>.+)\[(?<number>[0-9]+)\]/;
    const result = name.match(array_re);
    if (result && result.groups.key === 'workers') {
      const workers = [...this.state.workers];
      workers[result.groups.number].command = value.replace(/ \\ /g, ' ');

      const workers_errors = [...this.state.workers_errors];
      workers_errors[result.groups.number] = value === '';

      this.setState({
        workers_errors,
        workers,
        pristine: false,
      });
    } else {
      switch (name) {
        case 'worker_count':
          let workers = this.state.workers;
          let workers_errors = this.state.workers_errors;
          while (workers.length < value) {
            workers.push({ command: '' });
            workers_errors.push(true);
          }
          while (workers.length > value) {
            workers.pop();
            workers_errors.pop();
          }
          this.setState({
            workers,
            [name]: value,
            workers_errors,
            pristine: false,
          });
          break;
        case 'worker_command':
          this.setState({
            [name]: value.replace(/ \\ /g, ' '),
            pristine: false,
            worker_command_error: value === '',
          });
          break;
        case 'worker_command_type':
          this.setState({
            [name]: value,
            pristine: false,
          });
          break;
        default:
          this.setState({
            values: { ...this.state.values, [name]: value },
            pristine: false,
          });
      }
    }
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    const { edit, type } = this.props;
    const {
      expanded,
      workers,
      worker_count,
      worker_command,
      worker_command_error,
      workers_errors,
      worker_command_type,
    } = this.state;
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon name={expanded ? 'triangle down' : 'triangle right'} />
            Workers
          </Message.Header>
        </Message>
        {expanded ? (
          <>
            <Segment attached>
              {type === JOB_TYPES.TRAINING.value ? (
                <>
                  <Form.Dropdown
                    name='worker_count'
                    label='Number of Workers'
                    value={worker_count}
                    options={new Array(20).fill().map((e, i) => {
                      return { value: i + 1, text: i + 1, key: i + 1 };
                    })}
                    selection
                    fluid
                    disabled={edit}
                    onChange={this.handleChange}
                  />
                  <Form.Field>
                    <Radio
                      label='Different Command For Each Worker'
                      name='worker_command_type'
                      value='different'
                      checked={worker_command_type === 'different'}
                      disabled={edit}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Same Command For All Workers'
                      name='worker_command_type'
                      value='same'
                      checked={worker_command_type === 'same'}
                      disabled={edit}
                      onChange={this.handleChange}
                    />
                  </Form.Field>{' '}
                </>
              ) : undefined}

              {worker_command_type === 'same' ? (
                <Form.Input
                  key='worker_command'
                  name='worker_command'
                  label={`Command for Worker${
                    type === JOB_TYPES.TRAINING.value ? 's' : ''
                  }`}
                  value={worker_command}
                  fluid
                  required
                  onChange={this.handleChange}
                  disabled={edit}
                  placeholder={
                    type === JOB_TYPES.TRAINING.value
                      ? 'python train.py'
                      : 'python predict.py'
                  }
                  error={
                    worker_command_error
                      ? {
                          content: 'Please enter a value',
                          pointing: 'above',
                        }
                      : undefined
                  }
                />
              ) : (
                new Array(worker_count).fill().map((e, i) => {
                  return (
                    <Form.Input
                      key={`workers[${i}]`}
                      name={`workers[${i}]`}
                      label={`Command for Worker ${i + 1}`}
                      value={workers[i].command}
                      fluid
                      required
                      onChange={this.handleChange}
                      disabled={edit}
                      placeholder='python train.py'
                      error={
                        workers_errors[i]
                          ? {
                              content: 'Please enter a value',
                              pointing: 'above',
                            }
                          : undefined
                      }
                    />
                  );
                })
              )}
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

export default WorkerFields;
