import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DATASTORE_TYPES from 'components/shared/datastoreTypes';
import PROVIDER_TYPES from '../../providerTypes';

export default function DatastoreTypeField({
  provider_type,
  value,
  error,
  setValue,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (event.target.value !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id='type-label'>Type</InputLabel>
        <Select
          labelId='type-label'
          id='type'
          value={value}
          label='Type'
          aria-describedby='type-helper-text'
          onChange={handleChange}
          disabled={edit}
          error={Boolean(error)}
        >
          <MenuItem value={DATASTORE_TYPES.NFS}>NFS</MenuItem>
          <MenuItem value={DATASTORE_TYPES.SMB}>SMB/CIFS/Windows</MenuItem>
          {provider_type === PROVIDER_TYPES.GCP.value
            ? [
                <MenuItem value={DATASTORE_TYPES.GCP_PD}>
                  Google Persistent Disk
                </MenuItem>,
              ]
            : undefined}
          {provider_type === PROVIDER_TYPES.AWS.value
            ? [
                <MenuItem value={DATASTORE_TYPES.AWS_EBS}>
                  Elastic Block Store (EBS)
                </MenuItem>,
              ]
            : undefined}
        </Select>
        {error ? (
          <FormHelperText id='type-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='type-helper-text'>
            Select the type of regional datastore
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
