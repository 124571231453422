import React from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

const GpuCountField = (props) => {
  let max_per_worker = 8;
  props.gpu_types.forEach((gpu_type_id) => {
    const selected_gpu_class = props.gpuClasses.find(
      (gpu_class) => gpu_class.id === gpu_type_id
    );
    max_per_worker = Math.min(
      selected_gpu_class.max_per_worker,
      max_per_worker
    );
  });

  return (
    <Form.Dropdown
      name='gpu_count'
      label={props.type === 'training' ? 'GPUs Per Worker' : 'GPU Count'}
      value={props.gpu_count}
      options={[
        {
          key: 1,
          value: 1,
          text: '1',
          disabled: max_per_worker < 1,
        },
        {
          key: 2,
          value: 2,
          text: '2',
          disabled: max_per_worker < 2,
        },
        {
          key: 4,
          value: 4,
          text: '4',
          disabled: max_per_worker < 4,
        },
        {
          key: 8,
          value: 8,
          text: '8',
          disabled: max_per_worker < 8,
        },
      ]}
      selection
      fluid
      onChange={props.onChange}
    />
  );
};

function mapStateToProps({ gpuClasses }) {
  return { gpuClasses: gpuClasses || [] };
}

export default connect(mapStateToProps, {})(GpuCountField);
