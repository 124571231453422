import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from '@mui/material';

import INSTANCE_CLASSES from '../../instanceClasses';

export default function StorageInstanceClassField({
  value,
  setValue,
  setChanged,
  disabled,
}) {
  const handleChange = (event) => {
    if (event.target.value !== value) {
      setChanged();
    }
    setValue(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='storage-class-label'>Storage Class</InputLabel>
        <Select
          labelId='storage-class-label'
          id='storage-class'
          value={value}
          label='Storage Class'
          onChange={handleChange}
          disabled={disabled}
        >
          <MenuItem value={INSTANCE_CLASSES.MICRO.value}>
            {INSTANCE_CLASSES.MICRO.label('(125 GB)')}
          </MenuItem>
          <MenuItem value={INSTANCE_CLASSES.SMALL.value}>
            {INSTANCE_CLASSES.SMALL.label('(500 GB)')}
          </MenuItem>
          <MenuItem value={INSTANCE_CLASSES.MEDIUM.value}>
            {INSTANCE_CLASSES.MEDIUM.label('(1250 GB)')}
          </MenuItem>
          <MenuItem value={INSTANCE_CLASSES.LARGE.value}>
            {INSTANCE_CLASSES.LARGE.label('(2750 GB)')}
          </MenuItem>
          <MenuItem value={INSTANCE_CLASSES.XLARGE.value}>
            {INSTANCE_CLASSES.XLARGE.label('(4250 GB)')}
          </MenuItem>
        </Select>
        <FormHelperText id='storage-class-helper-text'>
          The space required for the regions data cache
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
