import React, { useEffect } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProjectDatastores } from '../../../actions/projectDatastores';

export default function CollectionStoreIdField({
  value,
  setValue,
  setChanged,
  edit,
}) {
  const dispatch = useDispatch();
  const project_datastores = useSelector((state) => state.projectDatastores);

  useEffect(() => {
    (async () => {
      if (project_datastores === null) {
        dispatch(fetchProjectDatastores());
        return;
      }
    })();
  }, [dispatch, project_datastores]);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id='store-id-helper-text'>Datastore</InputLabel>
        <Select
          labelId='store-id-label'
          id='store_id'
          value={value}
          label='Datastore'
          aria-describedby='store-id-helper-text'
          onChange={handleChange}
          disabled={edit}
        >
          {(project_datastores || []).map((datastore) => (
            <MenuItem key={datastore.id} value={datastore.id}>
              {datastore.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText id='store-id-helper-text'>
          Select the regional datastore to use
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
