import api from '../apis/api';
import { FETCH_GPU_CLASSES } from './types';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchGpuClasses = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/project/${project_uuid}/gputypes`);
    dispatch({
      type: FETCH_GPU_CLASSES,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
