import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { getSession } from '../actions/session';
import { fetchUser } from '../actions/user';
import { fetchProjects } from '../actions/projects';
import history from '../history';

import Header from './Header';
import Landing from './Landing';
import JobNew from './jobs/JobNew';
import ArchivedJobs from './jobs/archived/ArchivedJobs';
import AccountLogin from './account/AccountLogin';
import ProtectedRoute from './ProtectedRoute';
import AuthCallback from './account/AuthCallback';
import Billing from './billing/Billing';
import PaymentMethodNew from './billing/method/PaymentMethodNew';
import PaymentHistory from './billing/PaymentHistory';
import AccountSettings from './account/AccountSettings';
import Dashboard from './Dashboard';
import JobDashboard from './jobs/Dashboard';
import JobDetails from './jobs/JobDetails';
import ExecutionLogs from './ExecutionLogs';
import ResourceDashboard from './resources/ResourceDashboard';
import RegionDashboard from './resources/RegionDashboard';
import ProviderForm from './resources/ProviderForm';
import RegionForm from './resources/RegionForm';
import NodeForm from './resources/RegionDashboard/NodeForm';
import DatastoreForm from './resources/RegionDashboard/DatastoreForm';
import DataConnectorForm from './resources/RegionDashboard/DataConnectorForm';
import ServiceForm from './resources/RegionDashboard/ServiceForm';
import DeviceConfigForm from './resources/RegionDashboard/Nodes/Devices/DeviceConfigForm';
import CollectionsDashboard from './collections/CollectionDashboard';
import CollectionForm from './collections/CollectionForm';
import Collection from './collections/Collection';
import CollectionExecutionLogs from './collections/ExecutionLogs';
import ProjectDashboard from './projects/ProjectDashboard';
import Project from './projects/Project';
import ProjectForm from './projects/ProjectForm';
import ProjectMemberForm from './projects/Project/ProjectMembers/ProjectMemberForm';

class App extends Component {
  componentDidMount() {
    if (!this.props.session.isLoggedIn) {
      this.props.getSession();
    }
    this.timerId = undefined;
  }
  componentDidUpdate() {
    this.props.fetchUser();
    this.props.fetchProjects();
    if (!this.timerId && this.props.session.isLoggedIn) {
      this.timerId = setInterval(
        this.updateSession.bind(this),
        (this.props.session.expires - Date.now() / 1000) * 1000
      );
    }
  }
  updateSession() {
    clearInterval(this.timerId);
    this.timerId = undefined;
    this.props.getSession();
  }
  render() {
    return (
      <div>
        <Router history={history}>
          <Route
            path='/'
            render={({ location }) => {
              window.gtag('config', process.env.REACT_APP_GTAG, {
                page_path: location.pathname + location.search,
              });
            }}
          />
          <Route component={Header} />
          <div style={{ paddingTop: '80px' }}>
            <Route exact path='/' component={Landing} />
            <Route exact path='/logout' component={Landing} />
            <Route exact path='/account/login' component={AccountLogin} />
            <Route exact path='/auth/callback' component={AuthCallback} />
            <ProtectedRoute
              exact
              path='/archive/jobs'
              component={ArchivedJobs}
            />
            <ProtectedRoute exact path='/jobs/:type/new' component={JobNew} />

            <ProtectedRoute
              exact={false}
              path='/jobs/:type/details/:job_uuid'
              component={JobDetails}
            />
            <ProtectedRoute
              exact={false}
              path='/jobs/:type/logs/:job_uuid'
              component={ExecutionLogs}
            />

            <ProtectedRoute exact path='/jobs/:type' component={JobDashboard} />

            <ProtectedRoute exact path='/dashboard' component={Dashboard} />

            <ProtectedRoute
              exact
              path='/dataset'
              component={CollectionsDashboard}
            />
            <ProtectedRoute
              exact
              path='/dataset/edit'
              component={CollectionForm}
            />
            <ProtectedRoute
              path='/dataset/logs/:id'
              component={CollectionExecutionLogs}
            />
            <ProtectedRoute
              exact={false}
              path='/dataset/details/:id'
              component={Collection}
            />
            <ProtectedRoute
              exact
              path='/model'
              component={CollectionsDashboard}
            />
            <ProtectedRoute
              exact
              path='/model/edit'
              component={CollectionForm}
            />
            <ProtectedRoute
              path='/model/logs/:id'
              component={CollectionExecutionLogs}
            />
            <ProtectedRoute
              exact={false}
              path='/model/details/:id'
              component={Collection}
            />
            <ProtectedRoute
              exact
              path='/checkpoint'
              component={CollectionsDashboard}
            />
            <ProtectedRoute
              exact
              path='/checkpoint/edit'
              component={CollectionForm}
            />
            <ProtectedRoute
              exact
              path='/checkpoint/details/:id'
              component={Collection}
            />
            <ProtectedRoute
              exact
              path='/checkpoint/logs/:id'
              component={CollectionExecutionLogs}
            />
            <ProtectedRoute
              exact
              path='/volume'
              component={CollectionsDashboard}
            />
            <ProtectedRoute
              exact
              path='/volume/edit'
              component={CollectionForm}
            />
            <ProtectedRoute
              exact
              path='/volume/details/:id'
              component={Collection}
            />
            <ProtectedRoute
              exact
              path='/volume/logs/:id'
              component={CollectionExecutionLogs}
            />
            <ProtectedRoute
              exact
              path='/projects'
              component={ProjectDashboard}
            />
            <ProtectedRoute
              exact
              path='/project/edit'
              component={ProjectForm}
            />
            <ProtectedRoute
              exact
              path='/project/details/:id'
              component={Project}
            />
            <ProtectedRoute
              exact
              path='/project/details/:id/member/edit'
              component={ProjectMemberForm}
            />

            <ProtectedRoute exact path='/account/billing' component={Billing} />
            <ProtectedRoute
              exact
              path='/account/billing/history'
              component={PaymentHistory}
            />
            <ProtectedRoute
              exact
              path='/account/billing/method/new'
              component={PaymentMethodNew}
            />
            <ProtectedRoute
              exact
              path='/account/settings'
              component={AccountSettings}
            />
            <ProtectedRoute
              exact
              path='/resources'
              component={ResourceDashboard}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/new'
              component={ProviderForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/edit'
              component={ProviderForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/new'
              component={RegionForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/edit'
              component={RegionForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/dashboard'
              component={RegionDashboard}
            />

            <ProtectedRoute
              exact
              path='/resources/node/new'
              component={NodeForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/node/new'
              component={NodeForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/node/:rig_uuid/edit'
              component={NodeForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/device/config'
              component={DeviceConfigForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/datastore/edit'
              component={DatastoreForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/data_connector/edit'
              component={DataConnectorForm}
            />
            <ProtectedRoute
              exact
              path='/resources/provider/:provider_uuid/region/:region_uuid/service/edit'
              component={ServiceForm}
            />
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps({ session }) {
  return {
    session,
  };
}

export default connect(mapStateToProps, {
  getSession,
  fetchUser,
  fetchProjects,
})(App);
