import BytesToMinimumGB from '../../../../util/BytesToMinimumGB';

export default function getHotStorage(params) {
  if (params.row.state && params.row.state.storage) {
    return {
      used:
        BytesToMinimumGB(
          params.row.state.storage.hot_total -
            params.row.state.storage.hot_available
        ) || 0,
      total: BytesToMinimumGB(params.row.state.storage.hot_total) || 0,
    };
  } else {
    return {
      used: 0,
      total: 0,
    };
  }
}
