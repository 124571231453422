import _ from 'lodash';
import {
  FETCH_DEVICE_CONFIGS,
  FETCH_DEVICE_CONFIG,
  DELETE_DEVICE_CONFIG,
  CREATE_DEVICE_CONFIG,
  CLEAR_DEVICE_CONFIGS,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_DEVICE_CONFIGS:
      return initialState;
    case FETCH_DEVICE_CONFIGS:
      newState = action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case CREATE_DEVICE_CONFIG:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case FETCH_DEVICE_CONFIG:
      newState = [...(state || [])];
      _.remove(newState, (device_config) => {
        return device_config.config_id === action.payload.config_id;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case DELETE_DEVICE_CONFIG:
      newState = [...(state || [])];
      _.remove(newState, (device_config) => {
        return device_config.config_id === action.payload;
      });
      newState = newState.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
