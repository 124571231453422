import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Header,
  Table,
  Grid,
  Segment,
  Placeholder,
  Visibility,
  Loader,
  Message,
  Breadcrumb,
  Dropdown,
} from 'semantic-ui-react';

import Sockette from 'sockette';
import { fetchJob } from '../actions/jobs';
import { fetchUser } from '../actions/user';
import history from '../history';
import JOB_TYPES from './jobs/types';

class ExecutionLogsPage extends Component {
  job_uuid = this.props.match.params.job_uuid;
  ws = null;
  worker_number = {};
  worker_filter = false;

  state = {
    messages: [],
    tokens: {},
    loading: true,
    querying: false,
    stream: undefined,
  };

  async componentDidMount() {
    if (!this.props.user) {
      setTimeout(() => this.componentDidMount(), 300);
    } else {
      await this.props.fetchJob(this.job_uuid);
      this.worker_filter = this.props.job.type === JOB_TYPES.TRAINING.value;
      this.worker_number = this.props.job.workers.reduce(
        (ac, a, i) => ({ ...ac, [a.job_worker_uuid]: i + 1 }),
        {}
      );

      this.setState({
        tokens: this.props.job.workers.reduce(
          (ac, a) => ({ ...ac, [a.job_worker_uuid]: null }),
          {}
        ),
        stream:
          this.worker_filter && this.props.job.workers.length === 1
            ? this.props.job.workers[0].job_worker_uuid
            : null,
      });
      this.connect();
    }
  }

  connect = () => {
    this.ws = new Sockette(
      `${process.env.REACT_APP_WS_API_URL}?Authorization=${this.props.session.credentials.idToken}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => {
          this.ws.json({
            action: 'subscribe',
            data: {
              type: 'logs',
              entity: 'job',
              id: this.job_uuid,
              project_uuid: this.props.user.selected_project,
            },
          });
          this.ws.json({
            action: 'getlogs',
            data: {
              type: 'init',
              entity: 'job',
              id: this.job_uuid,
              project_uuid: this.props.user.selected_project,
            },
          });
          this.heartbeat = setInterval(() => {
            this.ws.json({
              action: 'heartbeat',
            });
          }, 9 * 60 * 1000);
        },
        onmessage: (e) => {
          const { type, msg, time, stream } = JSON.parse(e.data);
          switch (type) {
            case 'next_log':
              this.setState({
                tokens: { ...this.state.tokens, [stream]: msg },
                querying: false,
                loading: false,
              });
              break;
            case 'subscription':
              const messages = [...this.state.messages];
              messages.push({
                msg,
                time,
                id: `${stream}:${time}`,
                stream,
                worker_num: this.worker_number[stream],
              });
              this.setState({
                messages: this.dedupAndSortMessages(messages),
              });
              break;
            case 'end':
              // this isn't needed for the web version and will prevent the infinite scroll from working

              // setTimeout(() => {
              //   this.ws.close();
              // }, 30000);
              break;
            default:
              console.log('invalid message type', type);
          }
        },
        onclose: (e) => {
          console.log('close', e);
          if (this.heartbeat) {
            clearInterval(this.heartbeat);
          }
        },
        onerror: (e) => {
          console.log('error', e);
          if (this.heartbeat) {
            clearInterval(this.heartbeat);
          }
        },
        onreconnect: (e) => {
          console.log('reconnect', e);
        },
      }
    );
  };

  dedupAndSortMessages = (messages) => {
    const new_messages = messages.filter(function (a) {
      return !this[a.time] && (this[a.time] = true);
    }, Object.create(null));
    new_messages.sort((a, b) => b.time - a.time);
    return new_messages;
  };

  handleUpdate = (e, { calculations }) => {
    if (calculations.bottomVisible && !this.state.querying) {
      if (this.worker_filter) {
        if (this.state.stream && this.state.tokens[this.state.stream]) {
          this.infiniteQuery(this.state.stream);
          this.setState({ querying: true });
        }
      } else {
        Object.entries(this.state.tokens).forEach(([stream, token]) => {
          if (token) {
            this.infiniteQuery(stream);
            this.setState({ querying: true });
          }
        });
      }
    }
  };

  infiniteQuery = (stream) => {
    this.ws.json({
      action: 'getlogs',
      data: {
        type: 'next',
        entity: 'job',
        id: this.job_uuid,
        project_uuid: this.props.user.selected_project,
        stream,
        token: this.state.tokens[stream],
      },
    });
  };

  componentWillUnmount() {
    this.ws.close();
  }

  populateOptions = () => {
    let { job } = this.props;
    if (job) {
      let workers = [];
      if (job) {
        workers = job.workers;
      }
      let options = [
        {
          key: 'all',
          text: 'All',
          value: null,
        },
      ];
      let number = 1;
      for (let worker of workers) {
        options.push({
          key: 'Worker' + number,
          text: 'Worker ' + number,
          value: worker.job_worker_uuid,
        });
        number++;
      }
      return options;
    } else {
      return [
        {
          key: 'all',
          text: 'All',
          value: null,
        },
      ];
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ stream: value });
  };

  renderDropdown = () => (
    <Dropdown
      placeholder='All'
      fluid
      onChange={this.handleChange}
      selection
      options={this.populateOptions()}
    />
  );

  render() {
    const { worker_filter } = this;
    const { job, type } = this.props;
    const { stream, loading, messages } = this.state;
    const selected_messages =
      stream && this.worker_filter
        ? messages.filter((message) => message.stream === stream)
        : messages;

    return (
      <Visibility onUpdate={this.handleUpdate}>
        <Container>
          <Grid columns={16}>
            <Grid.Row>
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => history.push('/dashboard')}
                >
                  Home
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  link
                  onClick={() => history.push(`/jobs/${type.value}`)}
                >
                  {type.label}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>Execution Logs</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Row>
            <Grid.Row>
              <Header as='h1'>
                Execution Logs for {job ? job.name : undefined}
              </Header>
            </Grid.Row>
            {worker_filter ? (
              <Grid.Row textAlign='center' verticalAlign='middle' columns={16}>
                <Grid.Column width={4}>
                  <p>Select to view logs for worker: </p>
                </Grid.Column>
                <Grid.Column width={3}>{this.renderDropdown()}</Grid.Column>
              </Grid.Row>
            ) : undefined}
            <Grid.Row>
              {loading ? (
                <Container textAlign='center'>
                  <Segment textAlign='center'>
                    <Placeholder>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder>
                  </Segment>
                </Container>
              ) : (
                <div>
                  <Table celled columns={16}>
                    <Table.Header>
                      <Table.Row>
                        {worker_filter && !stream ? (
                          <Table.HeaderCell width={2}>Worker</Table.HeaderCell>
                        ) : undefined}
                        <Table.HeaderCell width={stream ? 11 : 12}>
                          Message
                        </Table.HeaderCell>
                        <Table.HeaderCell width={stream ? 3 : 4}>
                          Time
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    {selected_messages.length > 0 ? (
                      <Table.Body>
                        {selected_messages.map(function (message, i) {
                          return (
                            <Table.Row key={i}>
                              {worker_filter && !stream ? (
                                <Table.Cell width={2}>
                                  Worker {message.worker_num}
                                </Table.Cell>
                              ) : undefined}
                              <Table.Cell
                                width={stream ? 11 : 12}
                                style={{ wordBreak: 'break-all' }}
                              >
                                {message.msg}
                              </Table.Cell>
                              <Table.Cell width={stream ? 4 : 3}>
                                {new Intl.DateTimeFormat(
                                  Intl.DateTimeFormat().resolvedOptions().locale,
                                  {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    fractionalSecondDigits: 3,
                                    timeZone:
                                      Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                                  }
                                ).format(message.time)}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    ) : undefined}
                  </Table>
                  {worker_filter && !stream ? (
                    <Message info hidden={messages.length === 0}>
                      Select a worker to view older logs
                    </Message>
                  ) : (
                    <Container textAlign='center'>
                      <Loader active={this.state.querying} inline='centered'>
                        Loading
                      </Loader>
                      {((!worker_filter &&
                        Object.values(this.state.tokens).filter(
                          (token) => token
                        ).length === 0) ||
                        (worker_filter && !this.state.tokens[stream])) &&
                      messages.length !== 0 ? (
                        <Message info>There are no older results</Message>
                      ) : null}
                    </Container>
                  )}

                  <Message
                    hidden={messages.length !== 0}
                    icon='inbox'
                    info
                    header='There are currently no execution logs for this job.'
                    content='As your job runs, execution logs will update here in real time.  If your job is over a week old, logs are automatically purged.'
                  />
                </div>
              )}
            </Grid.Row>
          </Grid>
        </Container>
      </Visibility>
    );
  }
}

function mapStateToProps({ session, user, jobs }, ownProps) {
  const job = (jobs || []).find(
    (job) => job.job_uuid === ownProps.match.params.job_uuid
  );
  const type = Object.entries(JOB_TYPES)
    .map(([key, value]) => {
      return value.value === ownProps.match.params.type ? value : undefined;
    })
    .filter((type) => type)[0];

  return {
    session,
    user,
    job,
    type,
  };
}
export default connect(mapStateToProps, {
  fetchJob,
  fetchUser,
})(ExecutionLogsPage);
