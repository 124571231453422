import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Icon, Form } from 'semantic-ui-react';
import { createBillingLinkRequest } from '../../../actions/user';

const email_re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ConsolidatedBillingForm extends Component {
  state = {
    email: '',
    error: false,
    pristine: true,
    submitting: false,
  };

  sendRequest = async () => {
    const valid = email_re.test(this.state.email);
    if (!valid) {
      this.setState({ error: true });
      return;
    }
    this.setState({ submitting: true });
    await this.props.createBillingLinkRequest(this.state.email);
    this.setState({ submitting: false });
  };

  handleChange = (e, { name, value }) => {
    switch (name) {
      case 'email':
        this.setState({ [name]: value, pristine: false, error: false });
        break;
      default:
        this.setState({ [name]: value, pristine: false });
    }
  };

  render() {
    return (
      <>
        <p>
          To submit a request to another user to link your account to their
          credit pool, enter the email address of their{' '}
          {process.env.REACT_APP_ORG_NAME} account below:
        </p>
        <Form>
          <Form.Input
            name='email'
            label='Consolidated Billing Account Email'
            value={this.state.email}
            required
            onChange={this.handleChange}
            placeholder='cto@mydomain.com'
            error={
              this.state.error
                ? {
                    content: 'Please enter a email address',
                    pointing: 'below',
                  }
                : undefined
            }
          />

          <Button
            primary
            icon
            labelPosition='right'
            type='submit'
            onClick={this.sendRequest}
            disabled={this.state.pristine || this.state.error}
            loading={this.state.submitting}
          >
            <Icon name='envelope' />
            Send Request
          </Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  createBillingLinkRequest,
})(ConsolidatedBillingForm);
