import { FETCH_CREDENTIALS } from './types';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchCredentials = (id) => async (dispatch) => {
  try {
    if (!id) {
      const user = store.getState().user;
      id = user.selected_project;
    }
    const res = await api.get(`/project/${id}/credentials/`);
    dispatch({
      type: FETCH_CREDENTIALS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const removeCredential = (id, type) => async (dispatch) => {
  try {
    await api.delete(`/project/${id}/credential/${type}`);
    dispatch(fetchCredentials(id));
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const putCredential = (id, values) => async (dispatch) => {
  try {
    await api.put(`/project/${id}/credential/${values.type}`, values);
    dispatch(fetchCredentials(id));
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const getCredentials = async (id) => {
  try {
    const res = await api.get(`/project/${id}/credentials/`);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
