import api from 'apis/api';
import getMessageFromApiError from 'util/getMessageFromApiError';

export const getProjectMembers = async (id) => {
  try {
    const res = await api.get(`/project/${id}/access`);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    console.log(message);
    throw new Error(`API Error - ${message}`);
  }
};

export const removeProjectMember = async (id, email) => {
  try {
    const res = await api.delete(`/project/${id}/access`, {
      params: {
        email,
      },
    });
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    console.log(message);
    throw new Error(`API Error - ${message}`);
  }
};

export const addProjectMember = async (id, values) => {
  try {
    const res = await api.post(`/project/${id}/access`, values);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    console.log(message);
    throw new Error(`API Error - ${message}`);
  }
};

export const updateProjectMember = async (id, values) => {
  try {
    const res = await api.put(`/project/${id}/access`, values);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    console.log(message);
    throw new Error(`API Error - ${message}`);
  }
};
