import React, { useState, useEffect } from 'react';

import {
  FormControl,
  Box,
  TextField,
  FormHelperText,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import UploadFile from './UploadFile';

const project_re = /^[a-z][a-z0-9-]{4,28}[a-z0-9]$/;
const email_re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function CredentialsField({
  value,
  setValue,
  setError,
  setChanged,
}) {
  const [uploadFile, setUploadFile] = useState(true);
  const [projectError, setProjectError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [credentialsError, setCredentialsError] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(undefined);

  const processKeyFile = (contents) => {
    let credentials;
    let updates = {};
    setCredentialsError(false);
    try {
      credentials = JSON.parse(contents);
      if (credentials.client_email) {
        setEmailError(!email_re.test(credentials.client_email));
        updates.id = credentials.client_email;
      } else {
        setEmailError(true);
      }
      if (credentials.project_id) {
        setProjectError(!project_re.test(credentials.project_id));
        updates.project = credentials.project_id;
      } else {
        setProjectError(true);
      }
    } catch (error) {
      console.log(error);
      setCredentialsError(true);
    }

    updates.secret = contents;
    setValue({ ...value, ...updates });
  };

  const handleChange = (event) => {
    const { id, value: newValue } = event.target;
    setChanged();
    switch (id) {
      case 'project':
        setProjectError(!project_re.test(newValue));
        break;
      case 'id':
        setEmailError(!email_re.test(newValue));
        break;
      case 'secret':
        processKeyFile(newValue);
        return;
      default:
        return;
    }
    setValue({ ...value, [id]: newValue });
  };

  const handleUpload = (file) => {
    setChanged();
    processKeyFile(file.contents);
    setUploadedFileName(file.filename);
  };

  useEffect(() => {
    setError(projectError || emailError || credentialsError);
  }, [setError, projectError, emailError, credentialsError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant='h6'>Credentials</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Stack spacing={2} direction='row'>
          <FormControlLabel
            control={
              <Switch
                checked={uploadFile}
                onChange={() => setUploadFile(!uploadFile)}
              />
            }
            label='Upload JSON File'
          />
          {uploadFile ? (
            <UploadFile name={'Credentials'} setValue={handleUpload} />
          ) : undefined}
        </Stack>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Box>
          {uploadedFileName ? (
            <Stack spacing={2} direction='row'>
              <CheckCircleOutline color='success' />
              <Typography variant='caption'>{uploadedFileName}</Typography>
            </Stack>
          ) : undefined}
        </Box>
      </Grid>
      {!uploadFile ? (
        <Grid item xs={12} md={12} lg={12}>
          <Box>
            <TextField
              sx={{ width: '100%' }}
              id='secret'
              label='Credentials File Text'
              multiline
              rows={20}
              value={value.secret}
              error={credentialsError}
              aria-describedby='secret-helper-text'
              inputProps={{ style: { fontSize: 10 } }}
              onChange={handleChange}
            />
            <FormHelperText id='secret-helper-text'>
              {credentialsError
                ? 'Unable to process the key file.  Ensure key file is valid JSON'
                : "Enter the contents of the service account's credentials.json key file"}
            </FormHelperText>
          </Box>
        </Grid>
      ) : undefined}
      <Grid item xs={12} md={12} lg={12}>
        <Box>
          <FormControl fullWidth required>
            <TextField
              label='Project ID'
              id='project'
              aria-describedby='project-id-helper-text'
              variant='outlined'
              onChange={handleChange}
              error={projectError}
              value={value.project}
              required
              InputLabelProps={{
                shrink: Boolean(value.project),
              }}
            />
            {projectError ? (
              <FormHelperText id='project-id-helper-text'>
                'Invalid project ID. Project IDs must be 6 to 30 characters in
                length, can only contain lowercase letters, numbers, and
                hyphens, must start with a letter, and cannot end with a hyphen'
              </FormHelperText>
            ) : undefined}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Box>
          <FormControl fullWidth required>
            <TextField
              label='Service Account Email Address (from file)'
              id='id'
              aria-describedby='email-helper-text'
              variant='outlined'
              onChange={handleChange}
              value={value.id}
              required
              InputLabelProps={{
                shrink: Boolean(value.id),
              }}
              InputProps={{
                readOnly: true,
              }}
            />
            {emailError ? (
              <FormHelperText id='email-helper-text'>
                Unable to detect a valid service account email. Ensure
                credentials file format is correct.
              </FormHelperText>
            ) : undefined}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}
