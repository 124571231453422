import React, { useState } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function SecretField({
  label,
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Secret cannot be blank');
    } else {
      setError(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl fullWidth required>
      <InputLabel htmlFor='password'>{label || 'Key Secret'}</InputLabel>
      <OutlinedInput
        label={label || 'Key Secret'}
        id='secret'
        type={showPassword ? 'text' : 'password'}
        aria-describedby='secret-helper-text'
        variant='outlined'
        onChange={handleChange}
        error={Boolean(error)}
        value={value}
        disabled={edit}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle secret visibility'
              onClick={handleClickShowPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error ? (
        <FormHelperText id='secret-helper-text'>{error}</FormHelperText>
      ) : undefined}
    </FormControl>
  );
}
