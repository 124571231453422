import {
  FETCH_SERVICE,
  FETCH_SERVICES,
  DELETE_SERVICE,
  CREATE_SERVICE,
  CLEAR_SERVICES,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchServices =
  (provider_uuid, region_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/service`
      );
      dispatch({
        type: FETCH_SERVICES,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const fetchService =
  (provider_uuid, region_uuid, service_id) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/service/${service_id}`
      );
      dispatch({
        type: FETCH_SERVICE,
        payload: res.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: DELETE_SERVICE,
          payload: service_id,
        });
      } else {
        const message = getMessageFromApiError(error);
        dispatch(setErrorMessage(message));
        throw new Error(`API Error - ${message}`);
      }
    }
  };

export const updateService =
  (provider_uuid, region_uuid, service_id, data) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/service/${service_id}`,
        data
      );
      dispatch({
        type: FETCH_SERVICE,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const deleteService =
  (provider_uuid, region_uuid, service_id) => async (dispatch) => {
    try {
      await api.delete(
        `/provider/${provider_uuid}/region/${region_uuid}/service/${service_id}`
      );
      dispatch({
        type: DELETE_SERVICE,
        payload: service_id,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const createService =
  (provider_uuid, region_uuid, values) => async (dispatch) => {
    try {
      const res = await api.post(
        `/provider/${provider_uuid}/region/${region_uuid}/service`,
        values
      );
      dispatch({
        type: CREATE_SERVICE,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const clearServices = () => ({
  type: CLEAR_SERVICES,
});
