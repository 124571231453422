import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

const portRegex = /^(?:(?:\d{1,5})|(?:\d{1,5}:\d{1,5}))$/;

export default function DataConnectorPortRangeField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);

    if (portRegex.test(newValue)) {
      setError(false);
    } else {
      setError(
        `Invalid port_range.  Must be in format <start_port>:<end_port>`
      );
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Port Range'
          id='port_range'
          aria-describedby='port-range-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
        />
        {error ? (
          <FormHelperText id='port-range-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='port-range-helper-text'>
            Enter the port or port range the target service runs on.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
