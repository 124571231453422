import React, { useCallback, useEffect, useState } from 'react';

import {
  Grid,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  fetchCredentials as fetchUserCredentials,
  putCredential as putUserCredential,
  removeCredential as removeUserCredential,
} from 'actions/userCredentials';
import {
  getCredentials,
  putCredential as putProjectCredential,
  removeCredential as removeProjectCredential,
} from 'actions/projectCredentials';
import CREDENTIAL_TYPES from './credentialTypes';
import AddCredentialButton from './AddCredentialButton';

export default function CredentialsForm({ project_uuid }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const user_keys = project_uuid === user.id;
  const user_credentials = useSelector((state) => state.userCredentials);

  const [credentials, setCredentials] = useState(null);
  const [revision, setRevision] = useState(0);

  const [loading_credentials, setLoadingCredentials] = useState(true);
  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [project_uuid]);

  useEffect(() => {
    (async () => {
      if (credentials === null) {
        if (user_keys) {
          if (user_credentials === null) {
            await dispatch(fetchUserCredentials());
          } else {
            setCredentials(user_credentials);
          }
        } else {
          const project_credentials = await getCredentials(project_uuid);
          setCredentials(project_credentials);
        }
        setLoadingCredentials(false);
      }
    })();
  }, [
    dispatch,
    setLoadingCredentials,
    user_credentials,
    credentials,
    user_keys,
    project_uuid,
  ]);

  const handlePutCredential = useCallback(
    async (payload) => {
      if (user_keys) {
        await dispatch(putUserCredential(payload));
      } else {
        await dispatch(putProjectCredential(project_uuid, payload));
      }
    },
    [dispatch, user_keys, project_uuid]
  );

  const handleRemoveCredential = useCallback(
    async (type) => {
      const credential = credentials.find(
        (credential) => credential.type === type
      );
      if (credential?.key_id) {
        if (user_keys) {
          await dispatch(removeUserCredential(type));
        } else {
          await dispatch(removeProjectCredential(project_uuid, type));
        }
      } else {
        setCredentials((prevState) => {
          return [...prevState].filter(
            (credential) => credential.type !== type
          );
        });
      }
    },
    [dispatch, setCredentials, credentials, user_keys, project_uuid]
  );

  return (
    <Accordion defaultExpanded={user_keys}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Third-Party Credentials
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading_credentials ? (
          <Skeleton />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} lg={4}>
              <AddCredentialButton
                credentials={credentials}
                setSelected={(type) => {
                  setCredentials((prevState) => {
                    return [...prevState, { type }];
                  });
                }}
              />
            </Grid>
            {credentials?.length ? (
              <Grid item xs={12} md={12} lg={12}>
                <Divider />
              </Grid>
            ) : undefined}
            {(credentials || []).map((credential) => {
              const credential_type = CREDENTIAL_TYPES.find(
                (type) => type.type === credential.type
              );

              if (credential_type) {
                const Component = credential_type.component;
                return (
                  <Grid item xs={12} md={12} lg={12} key={credential.type}>
                    <Component
                      key={`${credential.type}-${revision}`}
                      type={credential.type}
                      credential={credential}
                      putCredential={handlePutCredential}
                      removeCredential={handleRemoveCredential}
                      icon={credential_type.icon}
                    />
                  </Grid>
                );
              } else {
                return <></>;
              }
            })}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
