import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import theme from '../../../theme';
import ErrorSnackBar from '../../shared/ErrorSnackBar';
import {
  fetchCollections,
  createCollection,
  updateCollection,
} from '../../../actions/collections';
import CollectionNameField from './CollectionNameField';
import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';
import CollectionSourceTypeField from './CollectionSourceTypeField';

import CollectionSourceUri from './CollectionSourceUri';
import CollectionKaggleTypeField from './CollectionKaggleType';
import CollectionDatastorePathField from './CollectionDatastorePathField';
import CollectionEndpointUrlField from './CollectionEndpointUrlField';
import { clearErrorMessage } from '../../../actions/errorMessage';
import CollectionBranchField from './CollectionBranchField';
import CollectionCapacityField from './CollectionCapacityField';
import toTitleCase from '../../../util/toTitleCase';
import CollectionTypeField from './CollectionTypeField';
import ENTITY_TYPES from '../../shared/entityTypes';
import COLLECTION_TYPES from './collectionTypes';
import CollectionStoreIdField from './CollectionStoreIdField';
import DIRECT_MOUNT_DATASTORES from 'components/shared/directMountDatastores';
import DISK_DATASTORES from 'components/shared/diskDatastores';

export default function CollectionForm() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const entity_type = location.pathname.split('/')[1];

  const api_error = useSelector((state) => state.errorMessage);
  const collections = useSelector((state) => state[`${entity_type}s`]);
  const user = useSelector((state) => state.user);
  const project_datastores = useSelector((state) => state.projectDatastores);

  const sp = new URLSearchParams(location.search);

  useEffect(() => {
    window.gtag('event', `${entity_type}_form_open`, {
      customer_uuid: user.id,
    });
  }, [user, entity_type]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (collections === null) {
        dispatch(fetchCollections(entity_type));
        return;
      }
    })();
  }, [dispatch, collections, entity_type]);

  let collection;
  if (collections && sp.get('id')) {
    collection = collections.find(
      (collection) => collection.id === sp.get('id')
    );
  }
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(collection?.name || '');
  const [type, setType] = useState(collection?.type || COLLECTION_TYPES.EVEFS);
  const [name_error, setNameError] = useState(false);
  const [store_id, setStoreId] = useState(collection?.store_id || '');
  const [capacity, setCapacity] = useState(collection?.capacity || '');
  const [capacity_error, setCapacityError] = useState(false);
  const [source_type, setSourceType] = useState(collection?.source_type || '');
  const [source_uri, setSourceUri] = useState(collection?.source_uri || '');
  const [source_uri_error, setSourceUriError] = useState(false);
  const [kaggle_type, setKaggleType] = useState(
    collection?.source_options?.type || ''
  );
  const [path, setPath] = useState(collection?.source_options?.path || '');
  const [path_error, setPathError] = useState(false);
  const [endpoint_url, setEndpointUrl] = useState(
    collection?.source_options?.endpoint_url || ''
  );
  const [endpoint_url_error, setEndpointUrlError] = useState(false);
  const [branch, setBranch] = useState(
    collection?.source_options?.branch || ''
  );
  const project_datastore = (project_datastores || []).find(
    (datastore) => datastore.id === store_id
  );
  console.log(project_datastore);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (collection) {
        window.gtag('event', `${entity_type}_edited`, {
          customer_uuid: user.id,
          id: collection.id,
        });
        await dispatch(updateCollection(entity_type, collection.id, name));
      } else {
        window.gtag('event', `${entity_type}_submitted`, {
          customer_uuid: user.id,
          capacity: capacity ? `${capacity}G` : undefined,
          source_type,
        });
        const source_options = {};
        Object.entries({
          type: kaggle_type,
          endpoint_url,
          branch,
        }).forEach(([k, v]) => {
          if (v !== '') {
            source_options[k] = v;
          }
        });
        const store_options = {};
        Object.entries({
          path,
        }).forEach(([k, v]) => {
          if (v !== '') {
            store_options[k] = v;
          }
        });
        try {
          await dispatch(
            createCollection(entity_type, {
              name,
              type,
              store_id,
              store_options,
              capacity: capacity ? `${capacity}G` : undefined,
              source_type,
              source_uri,
              source_options,
            })
          );
          window.gtag('event', `${entity_type}_created`, {
            customer_uuid: user.id,
            capacity: capacity ? `${capacity}G` : undefined,
            source_type,
          });
        } catch (error) {
          window.gtag('event', `${entity_type}_errored`, {
            customer_uuid: user.id,
            capacity: capacity ? `${capacity}G` : undefined,
            source_type,
          });
          throw error;
        }
      }

      history.push(`/${entity_type}`);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      DIRECT_MOUNT_DATASTORES.includes(project_datastore?.type) &&
      source_type !== THIRD_PARTY_TYPES.EMPTY.value
    ) {
      setSourceType(THIRD_PARTY_TYPES.EMPTY.value);
    }
  }, [project_datastore?.type, source_type, setSourceType]);

  const need_capacity =
    (entity_type === ENTITY_TYPES.VOLUME &&
      !DIRECT_MOUNT_DATASTORES.includes(project_datastore?.type)) ||
    DISK_DATASTORES.includes(project_datastore?.type);

  const not_enough_credits = Boolean(
    !user.cloudbender_enabled &&
      user.credits <= 0 &&
      !user.billing_account_id &&
      user.id === user.selected_project
  );

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {collection
                          ? `Edit ${collection.name}`
                          : `Create New ${toTitleCase(entity_type)}`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <CollectionNameField
                        value={name}
                        error={name_error}
                        edit={Boolean(collection)}
                        setValue={setName}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <CollectionTypeField
                        value={type}
                        entity_type={entity_type}
                        edit={Boolean(collection)}
                        setValue={setType}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    {type === COLLECTION_TYPES.REGIONAL ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionStoreIdField
                          entity_type={entity_type}
                          value={store_id}
                          edit={Boolean(collection)}
                          setValue={(value) => {
                            if (Boolean(path)) {
                              setPath('');
                              setPathError(false);
                            }
                            setStoreId(value);
                          }}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                    {DIRECT_MOUNT_DATASTORES.includes(
                      project_datastore?.type
                    ) ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionDatastorePathField
                          value={path}
                          error={path_error}
                          edit={Boolean(collection)}
                          setValue={setPath}
                          setError={setPathError}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionSourceTypeField
                          entity_type={entity_type}
                          value={source_type}
                          user={user}
                          edit={Boolean(collection)}
                          error={
                            !pristine && !source_type
                              ? 'Source Type Required'
                              : undefined
                          }
                          setValue={(value) => {
                            setSourceUri('');
                            setSourceUriError(false);

                            if (Boolean(kaggle_type)) {
                              setKaggleType('');
                            }
                            if (Boolean(endpoint_url)) {
                              setEndpointUrl('');
                              setEndpointUrlError(false);
                            }
                            setSourceType(value);
                          }}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    )}

                    {source_type === THIRD_PARTY_TYPES.KAGGLE.value ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionKaggleTypeField
                          value={kaggle_type}
                          edit={Boolean(collection)}
                          setValue={setKaggleType}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                    {source_type !== THIRD_PARTY_TYPES.EMPTY.value &&
                    !DIRECT_MOUNT_DATASTORES.includes(
                      project_datastore?.type
                    ) ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionSourceUri
                          value={source_uri}
                          sourceType={source_type}
                          edit={Boolean(collection)}
                          error={source_uri_error}
                          setValue={setSourceUri}
                          setError={setSourceUriError}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}

                    {source_type === THIRD_PARTY_TYPES.WASABI.value ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionEndpointUrlField
                          value={endpoint_url}
                          error={endpoint_url_error}
                          edit={Boolean(collection)}
                          setValue={setEndpointUrl}
                          setError={setEndpointUrlError}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                    {[
                      THIRD_PARTY_TYPES.GIT.value,
                      THIRD_PARTY_TYPES.HUGGINGFACE.value,
                    ].includes(source_type) ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionBranchField
                          value={branch}
                          edit={Boolean(collection)}
                          setValue={setBranch}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                    {need_capacity ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CollectionCapacityField
                          value={capacity}
                          error={capacity_error}
                          edit={Boolean(collection)}
                          setValue={setCapacity}
                          setError={setCapacityError}
                          setChanged={() => setPristine(false)}
                          minimum_capacity={10}
                        />
                      </Grid>
                    ) : undefined}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <Tooltip
                      title={not_enough_credits ? 'Not Enough Credits' : ''}
                    >
                      <span>
                        <LoadingButton
                          variant='contained'
                          color='brand'
                          onClick={handleSubmit}
                          loading={loading}
                          disabled={
                            pristine ||
                            not_enough_credits ||
                            Boolean(name_error) ||
                            Boolean(capacity_error) ||
                            Boolean(source_uri_error) ||
                            Boolean(path_error) ||
                            Boolean(endpoint_url_error) ||
                            !Boolean(name) ||
                            (need_capacity && !Boolean(capacity)) ||
                            !Boolean(source_type) ||
                            (source_type !== THIRD_PARTY_TYPES.EMPTY.value &&
                              !Boolean(source_uri)) ||
                            (source_type === THIRD_PARTY_TYPES.KAGGLE.value &&
                              !kaggle_type) ||
                            (DIRECT_MOUNT_DATASTORES.includes(
                              project_datastore?.type
                            ) &&
                              !path) ||
                            (source_type === THIRD_PARTY_TYPES.WASABI.value &&
                              !endpoint_url)
                          }
                        >
                          Submit
                        </LoadingButton>
                      </span>
                    </Tooltip>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
