import { FETCH_GPU_CLASSES } from '../actions/types';

const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GPU_CLASSES:
      return action.payload;
    default:
      return state;
  }
}
export default reducer;
