import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function EnvValueField({ index, value, setValue }) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label={`Value ${index + 1}`}
          id={`value-${index + 1}`}
          aria-describedby={`value-${index + 1}-helper-text`}
          variant='outlined'
          onChange={handleChange}
          value={value}
        />
        <FormHelperText id={`value-${index + 1}-helper-text`}>
          Enter the environment variable value.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
