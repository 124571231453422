import { SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE } from '../actions/types';

const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      if (typeof action.payload === 'string') {
        return action.payload;
      } else {
        return 'An unexpected error occured';
      }
    case CLEAR_ERROR_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
export default reducer;
