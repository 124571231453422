import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import Sockette from 'sockette';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  Backdrop,
  Typography,
  CircularProgress,
  Divider,
  Box,
  Breadcrumbs,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  LinearProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';

import theme from '../../../theme';
import ErrorSnackBar from '../../shared/ErrorSnackBar';
import { getCollection } from '../../../actions/collections';
import { clearErrorMessage } from '../../../actions/errorMessage';

import toNiceTimestamp from '../../../util/toNiceTimestamp';
import toTitleCase from '../../../util/toTitleCase';

const dedupAndSortMessages = (messages) => {
  const new_messages = messages.filter(function (a) {
    return !this[a.time] && (this[a.time] = true);
  }, Object.create(null));
  new_messages.sort((a, b) => b.time - a.time);
  return new_messages;
};

export default function CollectionExecutionLogs() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const location = useLocation();
  const entity_type = location.pathname.split('/')[1];

  const tableEl = useRef();
  const api_error = useSelector((state) => state.errorMessage);
  const collections = useSelector((state) => state[`${entity_type}s`]);
  const session = useSelector((state) => state.session);
  const user = useSelector((state) => state.user);
  const [rows, setRows] = useState([]);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [atBottom, setAtBottom] = useState(true);

  const [collection, setCollection] = useState(
    collections
      ? collections.find((collection) => collection.id === id)
      : undefined
  );
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [logsLoading, setLogsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [stream, setStream] = useState(null);
  const ws = useRef(null);
  const heartbeat = useRef(null);

  useEffect(() => {
    ws.current = new Sockette(
      `${process.env.REACT_APP_WS_API_URL}?Authorization=${session.credentials.idToken}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => {
          ws.current.json({
            action: 'subscribe',
            data: {
              type: 'logs',
              entity: entity_type,
              id: id,
              project_uuid: user.selected_project,
            },
          });
          ws.current.json({
            action: 'getlogs',
            data: {
              type: 'init',
              entity: entity_type,
              id: id,
              project_uuid: user.selected_project,
            },
          });
          heartbeat.current = setInterval(() => {
            ws.current.json({
              action: 'heartbeat',
            });
          }, 9 * 60 * 1000);
        },
        onmessage: (e) => {
          const { type, msg, time, stream } = JSON.parse(e.data);
          switch (type) {
            case 'next_log':
              setToken(msg);
              setStream(stream);
              setLogsLoading(false);
              break;
            case 'subscription':
              setRows((prev) =>
                dedupAndSortMessages([
                  ...prev,
                  {
                    msg,
                    time,
                    id: time,
                    stream,
                  },
                ])
              );
              break;
            case 'end':
              // this isn't needed for the web version and will prevent the infinite scroll from working

              break;
            default:
              console.log('invalid message type', type);
          }
        },
        onclose: (e) => {
          console.log('close', e);
          if (heartbeat.current) {
            clearInterval(heartbeat.current);
          }
        },
        onerror: (e) => {
          console.log('error', e);
          if (heartbeat.current) {
            clearInterval(heartbeat.current);
          }
        },
      }
    );
    return () => {
      dispatch(clearErrorMessage());
      ws.current.close();
      if (heartbeat.current) {
        clearInterval(heartbeat.current);
      }
    };
  }, [dispatch, session, user, id, entity_type]);

  useEffect(() => {
    (async () => {
      if (!collection) {
        const collection_data = await getCollection(entity_type, id);
        if (!collection_data) {
          history.push(`/${entity_type}`);
        }
        setCollection(collection_data);
      }
      setCollectionLoading(false);
    })();
  }, [dispatch, history, collection, id, entity_type]);

  const loadMore = useCallback(() => {
    setLogsLoading(true);
    ws.current.json({
      action: 'getlogs',
      data: {
        type: 'next',
        entity: entity_type,
        id: id,
        project_uuid: user.selected_project,
        stream: stream,
        token: token,
      },
    });

    if (!token && rows.length !== 0) {
      setHasMore(false);
    }
  }, [rows, id, user, stream, token, entity_type]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (tableEl.current.scrollTop > bottom - distanceBottom) {
      setAtBottom(true);
      if (hasMore && !logsLoading) {
        loadMore();
      }
    } else {
      setAtBottom(false);
    }
  }, [hasMore, loadMore, logsLoading, distanceBottom]);

  useLayoutEffect(() => {
    if (!collectionLoading) {
      const tableRef = tableEl.current;
      tableRef.addEventListener('scroll', scrollListener);
      return () => {
        tableRef.removeEventListener('scroll', scrollListener);
      };
    }
  }, [scrollListener, collectionLoading]);

  if (collectionLoading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <Backdrop open={collectionLoading}>
            <CircularProgress />
          </Backdrop>
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Breadcrumbs aria-label='breadcrumb'>
                <Link
                  underline='hover'
                  color='inherit'
                  component={RouterLink}
                  to={`/dashboard`}
                >
                  Home
                </Link>
                <Link
                  underline='hover'
                  color='inherit'
                  component={RouterLink}
                  to={`/${entity_type}`}
                >
                  {toTitleCase(`${entity_type}s`)}
                </Link>
                <Link
                  underline='hover'
                  color='inherit'
                  component={RouterLink}
                  to={`/${entity_type}/details/${id}`}
                >
                  {collection.name}
                </Link>
                <Typography color='text.primary'>Execution Logs</Typography>
              </Breadcrumbs>{' '}
            </Grid>
            <br />
            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={{ display: 'flex', width: '100%' }}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={1}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <Typography variant='h5'>
                        Execution Logs for <b>{collection.name}</b>
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Paper>
            </Grid>
            <Divider />
            <Grid item xs={12} md={12} lg={12}>
              <TableContainer
                style={{
                  margin: 'auto',
                  maxHeight: '800px',
                }}
                ref={tableEl}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Message</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(({ msg, time }) => (
                      <TableRow key={time + '' + msg}>
                        <TableCell>{msg}</TableCell>
                        <TableCell>{toNiceTimestamp(time, true)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {logsLoading ? (
              <Grid item xs={12} md={12} lg={12}>
                <Alert severity='info'>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography>Loading...</Typography>
                    <Box sx={{ minWidth: '200px', width: '100%' }}>
                      <LinearProgress color='primary' />
                    </Box>
                  </Stack>
                </Alert>
              </Grid>
            ) : undefined}
            {!hasMore && atBottom ? (
              <Grid item xs={12} md={12} lg={12}>
                <Alert severity='info'>
                  <Typography>There are no older results</Typography>
                </Alert>
              </Grid>
            ) : undefined}
          </Grid>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
