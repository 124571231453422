import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ScopedCssBaseline, Container } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import theme from '../../../theme';
import PROVIDER_TYPES from '../providerTypes';
import PhysicalRegionForm from './PhysicalRegionForm';
import GCPRegionForm from './GcpRegionForm';
import { clearErrorMessage } from '../../../actions/errorMessage';
import { fetchActiveProvider } from '../../../actions/providers';
import { fetchActiveRegion } from '../../../actions/regions';
import ErrorSnackBar from '../../shared/ErrorSnackBar';
import AWSRegionForm from './AwsRegionForm';

export default function RegionForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { provider_uuid, region_uuid } = useParams();
  const provider = useSelector((state) => state.provider);
  const region = useSelector((state) => state.region);
  const api_error = useSelector((state) => state.errorMessage);

  useEffect(() => {
    if (!provider) {
      dispatch(fetchActiveProvider(provider_uuid));
    } else if (region_uuid && !region) {
      dispatch(fetchActiveRegion(provider_uuid, region_uuid));
    }
  }, [dispatch, provider, region, provider_uuid, region_uuid]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  if (!provider) {
    history.push('/resources');
    return <div>Redirecting...</div>;
  }

  switch (provider?.type) {
    case PROVIDER_TYPES.PHYSICAL.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <PhysicalRegionForm provider={provider} region={region} />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case PROVIDER_TYPES.GCP.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <GCPRegionForm provider={provider} region={region} />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case PROVIDER_TYPES.AWS.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <AWSRegionForm provider={provider} region={region} />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    default:
      history.push('/resources');
      return <div>Redirecting...</div>;
  }
}
