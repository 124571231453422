import React, { useEffect } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';
import { fetchProjectDatastores } from '../../../actions/projectDatastores';
import { fetchJobs } from '../../../actions/jobs';
import JOB_TYPES from '../../jobs/types';

export default function CollectionSourceUri({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
  sourceType,
}) {
  const dispatch = useDispatch();
  const project_datastores = useSelector((state) => state.projectDatastores);
  const jobs = useSelector((state) => state.jobs);

  useEffect(() => {
    (async () => {
      if (
        sourceType === THIRD_PARTY_TYPES.REGIONAL.value &&
        project_datastores === null
      ) {
        dispatch(fetchProjectDatastores());
        return;
      }
    })();
  }, [dispatch, project_datastores, sourceType]);

  useEffect(() => {
    (async () => {
      if (sourceType === THIRD_PARTY_TYPES.EVEFS.value && jobs === null) {
        dispatch(fetchJobs());
        return;
      }
    })();
  }, [dispatch, sourceType, jobs]);

  const selected_type = Object.values(THIRD_PARTY_TYPES).find(
    (type) => type.value === sourceType
  );

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
    if (
      ![
        THIRD_PARTY_TYPES.REGIONAL.value,
        THIRD_PARTY_TYPES.EVEFS.value,
      ].includes(sourceType)
    ) {
      const uriTest = selected_type ? selected_type.uriTest : undefined;
      if (uriTest) {
        const valid = uriTest.test(newValue);
        if (!valid) {
          setError(`Invalid source path for selected type`);
        } else {
          setError(false);
        }
      }
    }
  };

  const example = selected_type ? selected_type.uriExample : undefined;
  const local_file_error =
    sourceType === THIRD_PARTY_TYPES.LOCAL.value &&
    /\/[^/]*\.[^/]*$/.test(value);
  if (sourceType === THIRD_PARTY_TYPES.EVEFS.value && edit) {
    return <></>;
  } else {
    return (
      <Box>
        <FormControl fullWidth required>
          {sourceType === THIRD_PARTY_TYPES.REGIONAL.value ? (
            <>
              <InputLabel id='source-uri-helper-text'>Datastore</InputLabel>
              <Select
                labelId='source-type-label'
                id='source_uri'
                value={value}
                label='Datastore'
                aria-describedby='source-uri-helper-text'
                onChange={handleChange}
                disabled={edit}
                error={Boolean(error)}
              >
                {(project_datastores || []).map((datastore) => (
                  <MenuItem key={datastore.id} value={datastore.id}>
                    {datastore.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : sourceType === THIRD_PARTY_TYPES.EVEFS.value ? (
            <>
              <InputLabel id='source-uri-helper-text'>Notebook</InputLabel>
              <Select
                labelId='source-type-label'
                id='source_uri'
                value={value}
                label='Notebook'
                aria-describedby='source-uri-helper-text'
                onChange={handleChange}
                disabled={edit}
                error={Boolean(error)}
              >
                {(jobs || [])
                  .filter((job) => job.type === JOB_TYPES.NOTEBOOK.value)
                  .map((job) => (
                    <MenuItem key={job.job_uuid} value={job.job_uuid}>
                      {job.name}
                    </MenuItem>
                  ))}
              </Select>
            </>
          ) : (
            <TextField
              label='Source Location'
              id='source_uri'
              aria-describedby='source-uri-helper-text'
              variant='outlined'
              placeholder={example}
              onChange={handleChange}
              error={Boolean(error) || local_file_error}
              value={value}
              disabled={edit}
            />
          )}
          {error ? (
            <FormHelperText id='source-uri-helper-text'>{error}</FormHelperText>
          ) : local_file_error ? (
            <FormHelperText id='source-uri-helper-text'>
              Local storage expects a directory path, not a file. Ensure the
              provide path is the directory containing the files needed.
            </FormHelperText>
          ) : (
            <FormHelperText id='source-uri-helper-text'>
              Enter the path of the data within the selected source type
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  }
}
