import React, { Component } from 'react';
import {
  Grid,
  Header,
  Segment,
  Icon,
  Message,
  Table,
  Checkbox,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

class EndpointReview extends Component {
  state = {
    expanded: true,
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (!this.props.data) {
      return;
    }
    const { routes, start_command, service_id } = this.props.data;

    let service_name, service_port, service_hostname;
    if (service_id) {
      const service = this.props.projectServices.find(
        (service) => service.id === service_id
      );
      service_name = service.name;
      service_port = service.resource;
      service_hostname = service.hostname;
    }

    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon
              name={this.state.expanded ? 'triangle down' : 'triangle right'}
            />
            Endpoint
          </Message.Header>
        </Message>
        {this.state.expanded ? (
          <>
            <Segment attached>
              <Grid divided columns='equal'>
                <Grid.Row>
                  <Grid.Column>
                    {routes.length ? (
                      <>
                        <Header as='h4'>Routes</Header>
                        {routes.map((route) => {
                          return (
                            <Segment
                              key={`${route.verb}_${route.path}`}
                              attached
                            >
                              <Header as='h4'>
                                Route: {route.path} : {route.verb}
                              </Header>
                              <p>
                                <b>{route.verb}</b> to <b>{route.path}</b> calls
                                function <b>{route.function}</b> in file{' '}
                                <b>{route.file}</b> using{' '}
                                <b>
                                  {route.positional ? 'positional' : 'keyword'}{' '}
                                </b>
                                arguments
                              </p>
                              {['POST', 'PUT', 'PATCH'].includes(route.verb) ? (
                                <>
                                  <Header as='h5'>Required Request Body</Header>
                                  <Table collapsing>
                                    <Table.Header>
                                      <Table.Row key='header'>
                                        <Table.HeaderCell>
                                          Name
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Type
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Optional
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Default Value
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      {route.body.map((param) => {
                                        return (
                                          <Table.Row>
                                            <Table.Cell>
                                              <b>{param.name}</b>
                                            </Table.Cell>
                                            <Table.Cell>
                                              {param.type}
                                            </Table.Cell>
                                            <Table.Cell>
                                              <Checkbox
                                                checked={param.optional}
                                              />
                                            </Table.Cell>
                                            <Table.Cell>
                                              {param.default_value}
                                            </Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                    </Table.Body>
                                  </Table>
                                </>
                              ) : undefined}
                            </Segment>
                          );
                        })}
                      </>
                    ) : (
                      <p>
                        Start server with command: <b>{start_command}</b>
                      </p>
                    )}
                    {service_id ? (
                      <>
                        <p>
                          Attach to regional service <b>{service_name}</b>
                        </p>
                        <p>
                          Endpoint will listen on port <b>{service_port}</b> at
                          hostname <b>{service_hostname}</b>
                        </p>
                      </>
                    ) : undefined}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ projectServices }) {
  return { projectServices };
}

export default connect(mapStateToProps)(EndpointReview);
