import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function DeviceConfigCommandField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Command cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Start Command'
          id='command'
          aria-describedby='command-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='command-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='command-helper-text'>
            Enter the command to start the inference service
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
