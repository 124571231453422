import _ from 'lodash';
import React, { Component } from 'react';

import { Header, Segment, Form, Grid, Icon, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import arrayMove from '../../../util/arrayMove';

const path_re = /^\/[a-zA-z0-9_/\-.{}]*/;
const file_re = /^[a-zA-z0-9][a-zA-z0-9_/\-.]*[a-zA-z0-9_\-.]$/;
const python_re = /^[A-Za-z_][A-Za-z_0-9]*$/;

class EndpointRoute extends Component {
  state = {
    path_error: false,
    file_error: false,
    function_error: false,
    body_count: this.props.initialValues
      ? Object.keys(this.props.initialValues.body).length
      : 0,
    body_errors: this.props.initialValues
      ? new Array(Object.keys(this.props.initialValues.body).length).fill({
          name_error: false,
          dup_error: false,
          default_value_error: false,
        })
      : [],
    values: this.props.initialValues || {
      path: '',
      verb: 'POST',
      file: '',
      function: '',
      body: [],
      positional: true,
    },
  };
  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevState, this.state)) {
      return;
    }
    const body_error = new Set(
      _.flatten(this.state.body_errors.map((error) => Object.values(error)))
    );

    this.props.onChange(
      this.props.index,
      this.state.values,
      this.state.path_error ||
        this.state.file_error ||
        this.state.function_error ||
        body_error.has(true)
    );
  }

  handleChange = (e, { name, value }) => {
    const array_re = /(?<key>.+)\[(?<number>[0-9]+)\]\.?(?<attribute>.+)?/;
    const result = name.match(array_re);
    if (result && result.groups.attribute) {
      const array = [...this.state.values[result.groups.key]];
      const item = { ...array[result.groups.number] };
      if (result.groups.attribute === 'optional') {
        item[result.groups.attribute] = !item[result.groups.attribute];
      } else {
        item[result.groups.attribute] = value;
      }
      array[result.groups.number] = item;
      const body_errors = this.bodyErrorCheck(array);
      this.setState({
        body_errors,
        values: {
          ...this.state.values,
          [result.groups.key]: array,
        },
      });
    } else {
      switch (name) {
        case 'path':
          this.setState({
            path_error: !path_re.test(value) || value === '',
            values: {
              ...this.state.values,
              [name]: value,
            },
          });
          break;
        case 'file':
          this.setState({
            file_error: !file_re.test(value) || value === '',
            values: {
              ...this.state.values,
              [name]: value,
            },
          });
          break;
        case 'function':
          this.setState({
            function_error: !python_re.test(value) || value === '',
            values: {
              ...this.state.values,
              [name]: value,
            },
          });
          break;
        case 'positional':
          this.setState((prevState) => ({
            values: {
              ...this.state.values,
              positional: !prevState.values.positional,
            },
          }));
          break;
        default:
          this.setState({
            ...this.state.values,
            [name]: value,
          });
      }
    }
  };

  handleBodyChange = (values, index) => {
    const body = [...this.state.values.body];
    body[index] = values;
    const body_errors = this.bodyErrorCheck(body);
    this.setState({
      body_errors,
      values: {
        ...this.state.values,
        body,
      },
    });
  };

  bodyErrorCheck = (body) => {
    const params = body.map((param) => {
      if (param.name) {
        return param.name;
      }
      return undefined;
    });
    const dup_param = params.filter((e, i, a) => a.indexOf(e) !== i);
    const errors = body.map((param) => {
      const error = {
        name_error: false,
        dup_error: false,
        default_value_error: false,
      };
      if (param.name) {
        error.dup_error = dup_param.includes(param.name);
        error.name_error = !python_re.test(param.name);
      }
      if (param.optional) {
        error.default_value_error = param.default_value === '';
      }
      return error;
    });
    return errors;
  };

  addBodyParameter = (e) => {
    e.preventDefault();
    this.setState({
      body_count: this.state.body_count + 1,
      body_errors: [
        ...this.state.body_errors,
        { name_error: false, dup_error: false, default_value_error: false },
      ],
      values: {
        ...this.state.values,
        body: [
          ...this.state.values.body,
          {
            name: '',
            type: '',
            optional: false,
            default_value: '',
          },
        ],
      },
    });
  };

  removeBodyParameter = (i) => {
    const body = [...this.state.values.body];
    body.splice(i, 1);
    const body_errors = this.bodyErrorCheck(body);
    this.setState({
      body_errors,
      body_count: this.state.body_count - 1,
      values: {
        ...this.state.values,
        body,
      },
    });
  };

  moveBodyParameterUp = (index) => {
    if (index === 0) {
      return;
    }
    const body = arrayMove(this.state.values.body, index, index - 1);
    this.setState({
      values: {
        ...this.state.values,
        body,
      },
    });
  };

  moveBodyParameterDown = (index) => {
    if (index === this.state.values.body.length - 1) {
      return;
    }
    const body = arrayMove(this.state.values.body, index, index + 1);
    this.setState({
      values: {
        ...this.state.values,
        body,
      },
    });
  };

  render() {
    const { index, duplicate } = this.props;
    const {
      path_error,
      file_error,
      function_error,
      body_count,
      body_errors,
      values,
    } = this.state;
    return (
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Form.Dropdown
                    label='HTTP Verb'
                    name='verb'
                    onChange={this.handleChange}
                    options={[{ value: 'POST', text: 'POST' }]}
                    value={values.verb}
                    selection
                    fluid
                    disabled
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Input
                    name='path'
                    onChange={this.handleChange}
                    label='Path'
                    fluid
                    placeholder={'/predict'}
                    error={
                      path_error
                        ? 'Invalid Route'
                        : duplicate
                        ? 'Duplicate Route'
                        : undefined
                    }
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Input
                    name='file'
                    onChange={this.handleChange}
                    label='File Name'
                    fluid
                    placeholder={'predict.py'}
                    error={file_error ? 'Invalid File Path' : undefined}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Form.Input
                    name='function'
                    onChange={this.handleChange}
                    label='Function Name'
                    fluid
                    placeholder={'get_prediction'}
                    error={function_error ? 'Invalid Function Name' : undefined}
                  />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign='bottom'>
                  <Button
                    icon='close'
                    basic
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.removeRoute(index);
                    }}
                  ></Button>
                </Grid.Column>
              </Grid.Row>
              {['POST', 'PUT', 'PATCH'].includes(values.verb) ? (
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <Header as='h5'>Request Body Template</Header>
                          </Grid.Column>
                        </Grid.Row>
                        {new Array(body_count).fill().map((e, i) => {
                          return (
                            <Grid.Row key={`route_${index}_param_${i}`}>
                              <Grid.Column width={4}>
                                <Form.Input
                                  name={`body[${i}].name`}
                                  label='Name'
                                  onChange={this.handleChange}
                                  fluid
                                  placeholder={'image'}
                                  value={values.body[i].name}
                                  error={
                                    body_errors[i].name_error
                                      ? 'Please Enter Valid Name'
                                      : body_errors[i].dup_error
                                      ? 'Duplicate Name'
                                      : undefined
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column width={3}>
                                <Form.Dropdown
                                  name={`body[${i}].type`}
                                  label='Data Type'
                                  onChange={this.handleChange}
                                  selection
                                  fluid
                                  value={values.body[i].type}
                                  options={[
                                    { value: 'str', text: 'String' },
                                    { value: 'int', text: 'Integer' },
                                    { value: 'float', text: 'Float' },
                                    { value: 'bool', text: 'Boolean' },
                                    { value: 'dict', text: 'Object' },
                                    { value: 'list', text: 'List' },
                                  ]}
                                />
                              </Grid.Column>
                              <Grid.Column width={2} verticalAlign='middle'>
                                <Form.Checkbox
                                  name={`body[${i}].optional`}
                                  label='Optional'
                                  onChange={this.handleChange}
                                  checked={values.body[i].optional}
                                />
                              </Grid.Column>
                              {values.body[i].optional ? (
                                <Grid.Column width={4}>
                                  <Form.Input
                                    name={`body[${i}].default_value`}
                                    label='Default Value'
                                    onChange={this.handleChange}
                                    fluid
                                    placeholder={'None'}
                                    value={values.body[i].default_value}
                                    error={
                                      body_errors[i].default_value_error
                                        ? 'Please Enter a Value'
                                        : undefined
                                    }
                                  />
                                </Grid.Column>
                              ) : (
                                <Grid.Column width={4}></Grid.Column>
                              )}
                              {i !== 0 && values.positional ? (
                                <Grid.Column
                                  width={1}
                                  verticalAlign='bottom'
                                  floated='right'
                                >
                                  <Button
                                    icon='angle up'
                                    basic
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.moveBodyParameterUp(i);
                                    }}
                                  ></Button>
                                </Grid.Column>
                              ) : (
                                <Grid.Column width={1}></Grid.Column>
                              )}
                              {i !== body_count - 1 && values.positional ? (
                                <Grid.Column
                                  width={1}
                                  verticalAlign='bottom'
                                  floated='right'
                                >
                                  <Button
                                    icon='angle down'
                                    basic
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.moveBodyParameterDown(i);
                                    }}
                                  ></Button>
                                </Grid.Column>
                              ) : (
                                <Grid.Column width={1}></Grid.Column>
                              )}

                              <Grid.Column
                                width={1}
                                verticalAlign='bottom'
                                floated='right'
                              >
                                <Button
                                  icon='close'
                                  basic
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.removeBodyParameter(i);
                                  }}
                                ></Button>
                              </Grid.Column>
                            </Grid.Row>
                          );
                        })}
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <Button
                              icon
                              basic
                              color='black'
                              labelPosition='right'
                              onClick={this.addBodyParameter}
                            >
                              <Icon name='plus' />
                              Add Parameter
                            </Button>
                          </Grid.Column>
                          <Grid.Column width={12} verticalAlign='bottom'>
                            <Form.Checkbox
                              name={`positional`}
                              label='Function Uses Positional Arguments'
                              onChange={this.handleChange}
                              checked={values.positional}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              ) : undefined}
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(EndpointRoute);
