import React, { Component, Fragment } from 'react';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import THIRD_PARTY_TYPES from '../jobs/JobForm/thirdPartyTypes';

import {
  putCredential as putProjectKey,
  removeCredential as removeProjectKey,
} from '../../actions/projectCredentials';
import {
  putCredential as putUserKey,
  removeCredential as removeUserKey,
} from '../../actions/userCredentials';
import UploadButton from './UploadButton';

class ThirdPartyKey extends Component {
  state = {
    key_id_orig: this.props.key_id || '',
    key_id: this.props.key_id || '',
    tenant: this.props.tenant || '',
    secret: '',
    editing: this.props.key_id ? false : true,
    pristine: true,
    submitting: false,
    removing: false,
    keyError: false,
    secretError: false,
    tenantError: false,
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, pristine: false });
    switch (name) {
      case 'key_id':
        this.setState({ keyError: value === '' });
        break;
      case 'secret':
        if (this.props.type === THIRD_PARTY_TYPES.NVIDIA.value) {
          this.setState({ key_id: '$oauthtoken' });
        }
        this.setState({ secretError: value === '' });
        break;
      case 'tenant':
        this.setState({ tenantError: value === '' });
        break;
      default:
    }
  };

  handleUpload = async (e) => {
    e.preventDefault();
    const file_name = e.target.files[0].name;
    this.setState({
      key_id: file_name,
      keyError: file_name === '',
    });
    const file = await new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsDataURL(e.target.files[0]);
    });
    const base64result = file.split(',')[1];
    this.setState({
      secret: base64result,
      secretError: base64result === '',
      pristine: false,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    if (this.props.entity === 'user') {
      await this.props.putUserKey({
        type: this.props.type,
        key_id: this.state.key_id,
        secret: this.state.secret,
        tenant: this.state.tenant,
      });
    } else {
      await this.props.putProjectKey(this.props.project_uuid, {
        type: this.props.type,
        key_id: this.state.key_id,
        secret: this.state.secret,
        tenant: this.state.tenant,
      });
    }
    this.setState({
      key_id_orig: this.state.key_id,
      submitting: false,
      editing: false,
    });
  };

  handleDelete = async (e) => {
    e.preventDefault();
    this.setState({ removing: true });
    if (this.props.entity === 'user') {
      await this.props.removeUserKey(this.props.type);
    } else {
      await this.props.removeProjectKey(
        this.props.project_uuid,
        this.props.type
      );
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({ key_id: this.state.key_id_orig, editing: false });
  };

  handleEdit = (e) => {
    e.preventDefault();
    this.setState({ editing: true });
  };

  renderButtons() {
    const {
      pristine,
      submitting,
      editing,
      removing,
      keyError,
      secretError,
      tenantError,
    } = this.state;
    if (editing) {
      if (pristine || keyError || secretError || tenantError) {
        return <Button icon='check circle' disabled primary />;
      }
      if (submitting) {
        return <Button icon='check circle' primary loading />;
      }
      return (
        <Fragment>
          <Button icon='check circle' primary />
          <Button icon='undo' secondary onClick={this.handleCancel} />
        </Fragment>
      );
    }
    if (removing) {
      return <Button icon='close' basic loading />;
    } else {
      return (
        <Fragment>
          <Button icon='edit' basic onClick={this.handleEdit} />
          <Button icon='close' basic onClick={this.handleDelete} />
        </Fragment>
      );
    }
  }

  renderInputFields(type, state) {
    const {
      key_id,
      secret,
      tenant,
      editing,
      keyError,
      secretError,
      tenantError,
    } = state;
    switch (type) {
      case THIRD_PARTY_TYPES.GCP.value:
        if (editing) {
          return (
            <Fragment>
              <UploadButton
                name='Upload Json File'
                onChange={this.handleUpload}
                disabled={!editing}
              />{' '}
              {this.state.key_id}
            </Fragment>
          );
        } else {
          return <span>Credentials File: {this.state.key_id}</span>;
        }
      case THIRD_PARTY_TYPES.KAGGLE.value:
        if (editing) {
          return (
            <Fragment>
              <UploadButton
                name='Upload Json File'
                onChange={this.handleUpload}
                disabled={!editing}
              />{' '}
              {this.state.key_id}
            </Fragment>
          );
        } else {
          return <span>Credentials File: {this.state.key_id}</span>;
        }
      case THIRD_PARTY_TYPES.AZURE.value:
        return (
          <Form.Group widths='equal'>
            <Form.Input
              name='key_id'
              label='Application (client) ID'
              placeholder='00000000-0000-0000-0000-000000000000'
              value={key_id}
              fluid
              onChange={this.handleChange}
              disabled={!editing}
              error={
                keyError
                  ? {
                      content: 'Please enter a value',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
            <Form.Input
              name='tenant'
              label='Directory (tenant) ID'
              placeholder='00000000-0000-0000-0000-000000000000'
              value={tenant}
              fluid
              onChange={this.handleChange}
              disabled={!editing}
              error={
                tenantError
                  ? {
                      content: 'Please enter a value',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
            <Form.Input
              name='secret'
              label='Client Secret'
              placeholder='Client Secret'
              value={secret}
              type='password'
              fluid
              onChange={this.handleChange}
              disabled={!editing}
              error={
                secretError
                  ? {
                      content: 'Please enter a value',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
          </Form.Group>
        );
      case THIRD_PARTY_TYPES.NVIDIA.value:
        return (
          <Form.Group widths='equal'>
            <Form.Input
              name='secret'
              label='NGC API Key'
              placeholder='API key'
              value={secret}
              type='password'
              fluid
              onChange={this.handleChange}
              disabled={!editing}
              error={
                secretError
                  ? {
                      content: 'Please enter a value',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
          </Form.Group>
        );
      default:
        return (
          <Form.Group widths='equal'>
            <Form.Input
              name='key_id'
              label='Key ID'
              placeholder='key-identifier'
              value={key_id}
              fluid
              onChange={this.handleChange}
              disabled={!editing}
              error={
                keyError
                  ? {
                      content: 'Please enter a value',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
            <Form.Input
              name='secret'
              label='Key Secret'
              placeholder='secret-value'
              value={secret}
              type='password'
              fluid
              onChange={this.handleChange}
              disabled={!editing}
              error={
                secretError
                  ? {
                      content: 'Please enter a value',
                      pointing: 'above',
                    }
                  : undefined
              }
            />
          </Form.Group>
        );
    }
  }

  render() {
    const icon = Object.values(THIRD_PARTY_TYPES).find(
      (type) => type.value === this.props.type
    ).icon;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Grid verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column floated='left' width={1}>
              <Icon name={icon} size='big' />
            </Grid.Column>
            <Grid.Column floated='left' width={12}>
              {this.renderInputFields(this.props.type, this.state)}
            </Grid.Column>
            <Grid.Column floated='right' width={3}>
              {this.renderButtons()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default connect(null, {
  putProjectKey,
  removeProjectKey,
  putUserKey,
  removeUserKey,
})(ThirdPartyKey);
