import React, { useEffect, useState } from 'react';

import { Box, Skeleton, Typography, List } from '@mui/material';

import { getCollectionDetails } from '../../../actions/collections';
import CollectionTreeLevel from './CollectionTreeLevel';

export default function CollectionTree({ entity_type, id }) {
  const [dir_tree, setDirTree] = useState(null);

  useEffect(() => {
    (async () => {
      if (dir_tree === null) {
        try {
          const details = await getCollectionDetails(entity_type, id);
          setDirTree(details);
        } catch (error) {
          setDirTree('unavailable');
        }
      }
    })();
  }, [id, dir_tree, entity_type]);

  if (dir_tree === null) {
    return (
      <Box>
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
      </Box>
    );
  }

  if (dir_tree === 'unavailable') {
    return (
      <Box>
        <Typography>Details Unavailable</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <List sx={{ width: '100%' }} component='nav'>
        <CollectionTreeLevel
          key={dir_tree.name}
          name={dir_tree.name}
          count={dir_tree.count}
          size={dir_tree.size}
          contents={dir_tree.contents}
          pl={0}
        />
      </List>
    </Box>
  );
}
