import React, { Component } from 'react';
import {
  Button,
  Icon,
  Message,
  Form,
  Segment,
  Grid,
  Header,
  Divider,
  Image,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import {
  clearErrorMessage,
  setErrorMessage,
} from '../../../actions/errorMessage';
import { fetchUser } from '../../../actions/user';
import history from '../../../history';
import api from '../../../apis/api';
import getMessageFromApiError from '../../../util/getMessageFromApiError';

class PaymentMethodCardSection extends Component {
  state = {
    stripeErrorMessage: '',
    complete: false,
    submitting: false,
  };

  componentWillUnmount() {
    this.props.clearErrorMessage();
  }
  createOptions = () => {
    return {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          fontFamily: 'Open Sans, sans-serif',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#c23d4b',
        },
      },
    };
  };

  createErrorMessages = (stripeMessage, reduxMessage) => {
    if (stripeMessage) {
      return (
        <Message negative>
          <p>{this.state.stripeErrorMessage}</p>
        </Message>
      );
    } else if (reduxMessage) {
      return (
        <Message negative>
          <Message.Header>Add Payment Method Failed</Message.Header>
          <p>{this.props.apiErrorMessage}</p>
        </Message>
      );
    } else {
      return <></>;
    }
  };
  handleChange = ({ complete, error }) => {
    if (error) {
      this.setState({ complete, stripeErrorMessage: error.message });
    } else {
      this.setState({ complete, stripeErrorMessage: '' });
    }
    this.props.clearErrorMessage();
  };
  cardSubmit = async (id) => {
    try {
      const res = await api.post('/customer/payment/method', { id });
      // const method = await this.props.createPaymentMethod(values);
      await this.props.stripe.confirmCardSetup(res.data.client_secret, {
        payment_method: res.data.payment_method,
      });
      history.push('/account/billing');
      this.props.fetchUser();
    } catch (error) {
      const message = getMessageFromApiError(error);
      this.props.setErrorMessage(message);
      this.setState({ submitting: false });
    }
  };
  handleSubmit = async (evt) => {
    this.setState({ submitting: true });
    evt.preventDefault();
    if (this.props.stripe) {
      const cardElement = this.props.elements.getElement('card');
      const paymentMethod = await this.props.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: this.props.billingDetails.name,
          address: {
            line1: this.props.billingDetails.address_line1,
            line2: this.props.billingDetails.address_line2,
            city: this.props.billingDetails.address_city,
            postal_code: this.props.billingDetails.address_postal_code,
            state: this.props.billingDetails.address_state,
            country: this.props.billingDetails.address_country,
          },
          phone: this.props.billingDetails.phone,
          email: this.props.billingDetails.email,
        },
      });
      if (paymentMethod.paymentMethod) {
        this.cardSubmit(paymentMethod.paymentMethod.id);
      }
      if (paymentMethod.error) {
        this.setState({ stripeErrorMessage: paymentMethod.error.message });
        this.setState({ submitting: false });
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  render() {
    return (
      <Segment>
        <Header as='h3'>Credit Card Details</Header>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <CardElement
                  onChange={this.handleChange}
                  {...this.createOptions()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  icon
                  floated='left'
                  secondary
                  labelPosition='left'
                  onClick={this.props.onCancel}
                >
                  <Icon name='close' />
                  Cancel
                </Button>
                <Button
                  disabled={
                    Boolean(this.props.apiErrorMessage) ||
                    !this.state.complete ||
                    Boolean(this.state.stripeErrorMessage)
                  }
                  loading={this.state.submitting}
                  icon
                  floated='right'
                  labelPosition='right'
                  primary
                  type='submit'
                >
                  Add
                  <Icon name='check' />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <Divider />
        {this.createErrorMessages(
          this.state.stripeErrorMessage,
          this.props.apiErrorMessage
        )}
        <Image src='/powered_by_stripe.png' alt='stripe logo' size='small' />
      </Segment>
    );
  }
}

const mapStateToProps = ({ errorMessage }) => {
  return { apiErrorMessage: errorMessage };
};

const PaymentMethodCardSectionWithStripe = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <PaymentMethodCardSection
        stripe={stripe}
        elements={elements}
        {...props}
      />
    )}
  </ElementsConsumer>
);

export default connect(mapStateToProps, {
  clearErrorMessage,
  setErrorMessage,
  fetchUser,
})(PaymentMethodCardSectionWithStripe);
