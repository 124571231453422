import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PaymentConfirm from './PaymentConfirm';
import {
  Segment,
  Header,
  Message,
  Button,
  Icon,
  Form,
  Modal,
} from 'semantic-ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import TopupForm from './method/TopupForm';
import api from '../../apis/api';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

class PaymentForm extends Component {
  state = {
    submitting: false,
    confirming: false,
    credits: 0,
    amountValue: 0,
    creditsError: false,
    paymentIntent: {},
    topup: false,
  };

  onSubmit = async (value) => {
    this.setState({ submitting: true });
    try {
      const res = await api.post(`/billing/payment`, {
        amount: Math.round(parseFloat(value) * 100),
        currency: 'usd',
      });
      this.setState({
        confirming: true,
        paymentIntent: res.data,
        submitting: false,
      });
    } catch (error) {
      if (error.response) {
        this.setState({ submitting: false });
        // need to display this error to the user somehow
        console.log(error.response.data.errorMessage);
      }
    }
  };

  onCancel() {
    this.setState({
      confirming: false,
    });
  }

  renderTopupModal() {
    return (
      <Modal
        open={this.state.topup}
        onClose={() => this.setState({ topup: false })}
        closeOnDimmerClick
      >
        <Modal.Content>
          <TopupForm
            user={this.props.user}
            primaryPaymentMethod={this.props.primaryPaymentMethod}
            onFinish={() => this.setState({ topup: false })}
          />
        </Modal.Content>{' '}
      </Modal>
    );
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      amountValue: value,
      credits: value,
      pristine: false,
      creditsError: isNaN(value),
    });
  };

  render() {
    return (
      <>
        {this.renderTopupModal()}
        <Segment>
          <Header as='h2'>Purchase Credits</Header>
          <Message
            info
            header='Credits can be purchased for $1.00 per credit'
            list={[
              'There is a minimum transaction amount of 25 credits ($25)',
              `If you have multiple payment methods, the Primary payment method
          will be used.`,
            ]}
          />
          <Form>
            <Form.Input
              name='credits'
              label='Amount of Credits'
              value={this.state.credits}
              required
              onChange={this.handleChange}
              error={
                this.state.creditsError
                  ? {
                      content: 'Please enter a valid amount (>= 25)',
                      pointing: 'below',
                    }
                  : undefined
              }
            />
            {this.state.pristine
              ? ''
              : `Charge ${formatter.format(this.state.credits)}`}
            <br />
            <Button
              primary
              icon
              labelPosition='right'
              type='submit'
              onClick={() => this.onSubmit(this.state.amountValue)}
              disabled={
                this.state.pristine ||
                this.state.creditsError ||
                this.state.credits < 25
              }
              loading={this.state.submitting}
            >
              <Icon name='money bill alternate' />
              Buy
            </Button>
            <Link to='/account/billing/history'>
              <Button secondary labelPosition='right' icon>
                <Icon name='clipboard list' />
                View Payment History
              </Button>
            </Link>
            <Button secondary onClick={() => this.setState({ topup: true })}>
              Set Automatic Top-up
            </Button>
          </Form>
          <Elements
            stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}
          >
            <PaymentConfirm
              amount={this.state.amountValue}
              credits={this.state.credits}
              onCancel={() => this.onCancel()}
              open={this.state.confirming}
              paymentIntent={this.state.paymentIntent}
            />
          </Elements>
        </Segment>
      </>
    );
  }
}

export default PaymentForm;
