import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Box, Typography, Button, Stack, Chip } from '@mui/material';
import EnvDrawer from '../../../../../shared/EnvDrawer';

export default function DeviceConfigEnvField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const [opened, setOpened] = useState(false);

  return (
    <Box>
      <Typography variant='body1'>
        <b>Environment Variables</b>
      </Typography>
      <Stack direction='column' spacing={2}>
        <Box>
          {value.map((value) => {
            return (
              <Chip
                variant='outlined'
                key={value.key}
                label={`${value.key}:${value.value}`}
                sx={{ fontSize: '1rem' }}
              />
            );
          })}
        </Box>
        <Button
          sx={{ maxWidth: '120px' }}
          variant='outlined'
          onClick={() => setOpened(true)}
          disabled={edit}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <AddCircleOutlineIcon />
            <Typography variant='button' display='block' gutterBottom>
              Add/Edit
            </Typography>
          </Stack>
        </Button>
      </Stack>
      <EnvDrawer
        open={opened}
        initialValues={value}
        error={error}
        handleClose={() => setOpened(false)}
        setValue={(values) => {
          setChanged();
          setValue(values);
        }}
        setError={setError}
      />
    </Box>
  );
}
