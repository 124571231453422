// https://github.com/mui-org/material-ui-x/blob/master/packages/grid/x-data-grid-generator/src/renderer/renderProgress.tsx

import * as React from 'react';
import clsx from 'clsx';
import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import round from '../../util/round';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 26,
        borderRadius: 2,
      },
      value: {
        position: 'absolute',
        lineHeight: '24px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      bar: {
        height: '100%',
        '&.low': {
          backgroundColor: '#088208a3',
        },
        '&.medium': {
          backgroundColor: '#efbb5aa3',
        },
        '&.high': {
          backgroundColor: '#f44336',
        },
      },
    }),
  { defaultTheme }
);

const ProgressBar = React.memo(function ProgressBar(props) {
  const {
    value: { total, used },
  } = props;

  const valueInPercent = round(total === 0 ? 100 : (used / total) * 100, 0);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>
        {`${valueInPercent.toLocaleString()} %`} of {total}
      </div>
      <div
        className={clsx(classes.bar, {
          low: valueInPercent <= 50,
          medium: valueInPercent > 50 && valueInPercent <= 75,
          high: valueInPercent > 75,
        })}
        style={{ maxWidth: `${valueInPercent}%` }}
      />
    </div>
  );
});

export default function renderProgress(params) {
  return <ProgressBar value={params.value} />;
}
