import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function ServicePortField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    let port = Number(newValue);
    if (Number.isNaN(port) || port % 1 !== 0) {
      setError('Port must be an integer');
      return;
    }
    if (port < 8000 || port > 8999) {
      setError('Port must be between 8000 and 8999');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Port'
          id='port'
          aria-describedby='port-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='port-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='port-helper-text'>
            Enter a port number between 8000 and 8999 for the service to be
            available on
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
