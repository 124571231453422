import _ from 'lodash';
import {
  FETCH_PROVIDERS,
  FETCH_PROVIDER,
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  SET_PROVIDER,
  CLEAR_PROVIDER,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchProviders = () => async (dispatch) => {
  try {
    const res = await api.get('/provider');
    // await Promise.all(
    //   [].concat(
    //     res.data.map(async (provider) => {
    //       await dispatch(fetchRegions(provider.provider_uuid, provider.type));
    //     })
    //   )
    // );
    // this dispatch will bring down the loading screen on resource dashboard, so it has to be last
    dispatch({
      type: FETCH_PROVIDERS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchProvider = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/provider/${id}`);
    if (_.isEmpty(res.data)) {
      dispatch({ type: DELETE_PROVIDER, payload: id });
    } else {
      dispatch({
        type: FETCH_PROVIDER,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch({ type: DELETE_PROVIDER, payload: id });
    } else {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  }
};

export const fetchActiveProvider = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/provider/${id}`);
    if (_.isEmpty(res.data)) {
      throw new Error('Provider Not Found');
    } else {
      dispatch({
        type: SET_PROVIDER,
        payload: res.data,
      });
    }
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateProvider = (id, values) => async (dispatch) => {
  try {
    const res = await api.patch(`/provider/${id} `, values);
    dispatch({
      type: FETCH_PROVIDER,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteProvider = (id) => async (dispatch) => {
  try {
    await api.delete(`/provider/${id}`);
    dispatch({ type: DELETE_PROVIDER, payload: id });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createProvider = (values) => async (dispatch) => {
  try {
    const res = await api.post('/provider', values);
    dispatch({
      type: CREATE_PROVIDER,
      payload: res.data,
    });
    dispatch({
      type: SET_PROVIDER,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const setActiveProvider = (provider, post_action) => (dispatch) => {
  dispatch({
    type: SET_PROVIDER,
    payload: provider,
  });
  if (post_action) {
    post_action();
  }
};
export const clearActiveProvider = (post_action) => (dispatch) => {
  dispatch({
    type: CLEAR_PROVIDER,
  });
  if (post_action) {
    post_action();
  }
};
