import _ from 'lodash';
import {
  FETCH_PROJECTS,
  FETCH_PROJECT,
  CREATE_PROJECT,
  REMOVE_PROJECT,
} from '../actions/types';

const initialState = [];
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS:
      newState = action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA === 'Personal') {
          return -1;
        }
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case CREATE_PROJECT:
      newState = [...state, action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA === 'Personal') {
          return -1;
        }
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case FETCH_PROJECT:
      newState = [...state];
      _.remove(newState, (project) => {
        return project.id === action.payload.id;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA === 'Personal') {
          return -1;
        }
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case REMOVE_PROJECT:
      newState = [...state];
      _.remove(newState, (project) => {
        return project.id === action.payload;
      });
      newState = newState.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA === 'Personal') {
          return -1;
        }
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
