import { FETCH_SECRETS } from '../actions/types';

const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SECRETS:
      return action.payload || [];
    default:
      return state;
  }
}
export default reducer;
