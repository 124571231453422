import React, { useEffect } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels } from '../../../../../../actions/models';

export default function DeviceConfigModelUuidField({
  value,
  error,
  setValue,
  setError,
  setChanged,
}) {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.models);

  useEffect(() => {
    (async () => {
      if (models === null) {
        dispatch(fetchModels());
        return;
      }
    })();
  }, [dispatch, models]);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Model cannot be empty');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id='model-label'>Model</InputLabel>
        <Select
          labelId='model-label'
          id='model_uuid'
          value={value}
          label='Model'
          onChange={handleChange}
        >
          {models
            ? models.map((model) => {
                return (
                  <MenuItem key={model.model_uuid} value={model.model_uuid}>
                    {model.name}
                  </MenuItem>
                );
              })
            : undefined}
        </Select>
        <FormHelperText id='model-helper-text'>
          The model to deploy for inference
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
