import { FETCH_PROJECT_SERVICES } from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchProjectServices = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/project/${project_uuid}/services`);
    dispatch({
      type: FETCH_PROJECT_SERVICES,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const getProjectServices = async (project_uuid) => {
  try {
    const res = await api.get(`/project/${project_uuid}/services`);
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const enableProjectService = async (project_uuid, service_id) => {
  try {
    const res = await api.patch(
      `/project/${project_uuid}/services/${service_id}/enable`
    );
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const disableProjectService = async (project_uuid, service_id) => {
  try {
    const res = await api.patch(
      `/project/${project_uuid}/services/${service_id}/disable`
    );
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
