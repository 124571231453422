import React, { useState } from 'react';

import {
  FormControl,
  Box,
  TextField,
  Button,
  Stack,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';
import api from '../../../apis/api';
import toNiceTimestamp from '../../../util/toNiceTimestamp';

export default function CouponField({ value, setValue, setChanged }) {
  const [coupon_status, setCouponStatus] = useState('unknown');
  const [submitting, setSubmitting] = useState(false);
  const [credits, setCredits] = useState('');
  const [expires, setExpires] = useState('');

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    setCouponStatus('unknown');
  };

  const checkCouponStatus = async () => {
    setSubmitting(true);
    try {
      const res = await api.get(`/customer/coupon/${value.trim()}`);
      setCredits(res.data.credits);
      setExpires(res.data.expires);
      setCouponStatus('active');
    } catch (error) {
      setCouponStatus(error.response.status === 400 ? 'used' : 'invalid');
    }
    setSubmitting(false);
  };

  return (
    <Box>
      <Stack spacing={2} direction='row' alignItems='flex-start'>
        <FormControl fullWidth required>
          <TextField
            label='Coupon Code'
            id='coupon_code'
            aria-describedby='coupon-code-helper-text'
            variant='outlined'
            onChange={handleChange}
            value={value}
            error={['invalid', 'used'].includes(coupon_status)}
          />
          {coupon_status !== 'unknown' ? (
            <FormHelperText id='coupon-code-helper-text'>
              {coupon_status === 'active'
                ? `credits: ${credits} expires: ${toNiceTimestamp(
                    expires,
                    true
                  )}`
                : coupon_status === 'used'
                ? 'Coupon Code Already Applied'
                : 'Invalid Coupon Code'}
            </FormHelperText>
          ) : undefined}
        </FormControl>
        <Button
          onClick={checkCouponStatus}
          disabled={value === ''}
          variant='outlined'
          sx={{ height: '53px' }}
        >
          {submitting ? (
            <CircularProgress color='inherit' size={16} />
          ) : coupon_status === 'active' ? (
            <CheckCircleOutlineOutlinedIcon color='success' />
          ) : ['invalid', 'used'].includes(coupon_status) ? (
            <DoDisturbOutlinedIcon color='error' />
          ) : (
            <HelpOutlineOutlinedIcon color='info' />
          )}
        </Button>
      </Stack>
    </Box>
  );
}
