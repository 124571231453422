import React from 'react';
import { Button, Icon, Segment, Grid } from 'semantic-ui-react';

const PaymentMethod = ({
  paymentMethod,
  isPrimary,
  removePaymentMethod,
  setPrimary,
}) => {
  return (
    <>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4} floated='left'>
              <Icon size='large' name={`cc ${paymentMethod.card.brand}`} />
              Card x{paymentMethod.card.last4}
              <p>
                Expires: {paymentMethod.card.exp_month}/
                {paymentMethod.card.exp_year}
              </p>
            </Grid.Column>
            <Grid.Column verticalAlign='middle' width={2} floated='left'>
              {isPrimary ? (
                <p>
                  <Icon size='large' name='font awesome flag' /> Primary
                </p>
              ) : undefined}
            </Grid.Column>
            <Grid.Column width={10} verticalAlign='middle' floated='right'>
              <Button
                onClick={removePaymentMethod}
                icon
                floated='right'
                labelPosition='right'
                secondary
              >
                <Icon name='cancel' />
                Remove
              </Button>
              {isPrimary ? (
                <></>
              ) : (
                <Button
                  onClick={setPrimary}
                  icon
                  floated='right'
                  labelPosition='right'
                  secondary
                >
                  <Icon name='star outline' />
                  Set Primary
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default PaymentMethod;
