import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function AWSRoleARNField({
  value,
  error,
  setValue,
  setError,
  setChanged,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Role cannot be blank.');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='IAM Role'
          id='role'
          aria-describedby='role-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={error}
          value={value}
        />
        {error ? (
          <FormHelperText id='role-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='role-helper-text'>
            Enter the role ARN for the proxiML user to assume
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
