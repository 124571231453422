import _ from 'lodash';
import {
  FETCH_DATASETS,
  UPDATE_DATASET,
  DELETE_DATASET,
  CREATE_DATASET,
} from './types';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchDatasets = () => async (dispatch) => {
  const project_uuid = store.getState().user.selected_project;
  try {
    let res = await api.get('/dataset', { params: { project_uuid } });
    dispatch({
      type: FETCH_DATASETS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateDataset = (dataset_uuid, name) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.patch(
      `/dataset/${dataset_uuid}`,
      { name: name },
      { params: { project_uuid } }
    );
    dispatch({
      type: UPDATE_DATASET,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteDataset = (dataset_uuid) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    await api.delete(`/dataset/${dataset_uuid}`, {
      params: { project_uuid },
    });
    dispatch({
      type: DELETE_DATASET,
      payload: dataset_uuid,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createDataset = (values) => async (dispatch) => {
  try {
    if (!values.project_uuid) {
      const project_uuid = store.getState().user.selected_project;
      values.project_uuid = project_uuid;
    }
    const res = await api.post('/dataset', values);
    if (res.data.project_uuid !== store.getState().user.selected_project) {
      return;
    }
    dispatch({
      type: CREATE_DATASET,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchDataset = (dataset_uuid) => async (dispatch) => {
  try {
    const dataset = await getDataset(dataset_uuid);
    if (!dataset || _.isEmpty(dataset)) {
      dispatch({ type: DELETE_DATASET, payload: dataset_uuid });
    } else {
      dispatch({
        type: UPDATE_DATASET,
        payload: dataset,
      });
    }
  } catch (error) {
    dispatch(setErrorMessage(error));
    throw error;
  }
};

export const getDataset = async (dataset_uuid) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/dataset/${dataset_uuid}`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return;
    }
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const getDatasetDetails = async (dataset_uuid) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/dataset/${dataset_uuid}/details`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
export const exportDataset = (dataset_uuid, values) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.post(`/dataset/${dataset_uuid}/export`, values, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_DATASET,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const abortExportDataset = (dataset_uuid) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.delete(`/dataset/${dataset_uuid}/export`, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_DATASET,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
