import { SET_REGION, CLEAR_REGION } from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_REGION:
      newState = action.payload ? { ...action.payload } : undefined;
      return newState;
    case CLEAR_REGION: {
      return null;
    }

    default:
      return state;
  }
}
export default reducer;
