import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  FormControlLabel,
  Switch,
  Box,
  Typography,
} from '@mui/material';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { CheckCircleOutline } from '@mui/icons-material';
import toTitleCase from 'util/toTitleCase';
import UploadFile from '../../shared/UploadFile';
import MultiLineSecretField from './MultiLineSecretField';

export default function GCPCredential({
  type,
  credential,
  putCredential,
  removeCredential,
  icon,
}) {
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(true);
  const [key_id, setKeyId] = useState(credential?.key_id || '');
  const [secret, setSecret] = useState(credential?.secret || '');
  const [secret_error, setSecretError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        type: credential?.type || type,
        key_id,
        secret,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      await putCredential(payload);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setPristine(true);
  };

  const handleRemove = async () => {
    if (!credential) {
      return;
    }
    setLoading(true);

    try {
      await removeCredential(credential?.type || type);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleUpload = (file) => {
    setPristine(false);
    setSecret(file.contents);
    setKeyId(file.filename);
  };

  const submit_disabled =
    pristine || secret_error || !Boolean(key_id) || !Boolean(secret);

  return (
    <Stack
      paddingBottom='10px'
      direction={'row'}
      spacing={1}
      alignItems={'center'}
    >
      <Tooltip title={`${toTitleCase(type)} Credential`}>
        <Icon fontSize='large' sx={{ alignContent: 'center' }}>
          <img
            src={icon}
            width={'25px'}
            height={'25px'}
            alt={`${toTitleCase(type)} Credential`}
          />
        </Icon>
      </Tooltip>
      <Typography>
        File: <b>{credential.key_id}</b>
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={uploadFile}
            onChange={() => setUploadFile(!uploadFile)}
          />
        }
        label='Upload JSON File'
      />
      {uploadFile ? (
        <>
          <UploadFile name={'Credentials JSON'} setValue={handleUpload} />
          <Box sx={{ display: 'flex', flexGrow: 1 }} />
        </>
      ) : (
        <MultiLineSecretField
          label='Credentials JSON'
          value={secret}
          setValue={setSecret}
          setChanged={() => setPristine(false)}
          error={secret_error}
          setError={setSecretError}
        />
      )}

      <IconButton
        onClick={handleSubmit}
        disabled={submit_disabled}
        size='large'
      >
        {loading ? (
          <CircularProgress size={25} />
        ) : (
          <CheckCircleOutline color={submit_disabled ? 'inherit' : 'brand'} />
        )}
      </IconButton>
      <IconButton onClick={handleRemove} size='large'>
        {loading ? <CircularProgress size={25} /> : <DeleteOutlinedIcon />}
      </IconButton>
    </Stack>
  );
}
