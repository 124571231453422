import React, { Component } from 'react';
import { Grid, Header, Segment, Icon, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

import GpuClassDetail from './GpuClassDetail';
import JOB_TYPES from '../types';

class ResourcesReview extends Component {
  state = {
    expanded: true,
  };

  getSelectedGpuClass = (gpu_type_id) => {
    const selected_gpu_class = this.props.gpuClasses.find(
      (gpu_class) => gpu_class.id === gpu_type_id
    );
    return selected_gpu_class;
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (!this.props.data) {
      return;
    }
    const {
      type,
      gpu_count,
      cpu_count,
      worker_count,
      disk_size,
      gpu_types,
      available,
    } = this.props.data;
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon
              name={this.state.expanded ? 'triangle down' : 'triangle right'}
            />
            Resources
          </Message.Header>
        </Message>
        {this.state.expanded ? (
          <>
            <Segment attached>
              <label>
                <b>GPU Type(s)</b>
              </label>
              {gpu_types.map((gpu_type_id) => {
                return (
                  <GpuClassDetail
                    key={gpu_type_id}
                    details={this.getSelectedGpuClass(gpu_type_id)}
                  />
                );
              })}

              {[
                JOB_TYPES.NOTEBOOK.value,
                JOB_TYPES.INFERENCE.value,
                JOB_TYPES.ENDPOINT.value,
              ].includes(type) ? (
                <>
                  <p>
                    <b>
                      <label>GPU Count:</label>
                      <span> {gpu_count}</span>
                    </b>
                  </p>
                  <p>
                    <b>
                      <label>CPU Count:</label>
                      <span> {cpu_count}</span>
                    </b>
                  </p>
                  <p>
                    <b>
                      <label>Disk Space Allocated:</label>
                      <span> {disk_size} GB</span>
                    </b>
                  </p>{' '}
                </>
              ) : (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign='middle'>
                      <p>
                        <label>Number of Workers:</label>
                        <span> {worker_count}</span>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign='middle'>
                      <p>
                        <label>GPUs per Worker:</label>
                        <span> {gpu_count}</span>
                      </p>
                      <p>
                        <label>CPUs per Worker:</label>
                        <span> {cpu_count}</span>
                      </p>
                      <p>
                        <label>Disk Space per Worker:</label>
                        <span> {disk_size} GB</span>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign='middle'>
                      <p>
                        <b>
                          <label>Total GPUs:</label>
                          <span> {worker_count * gpu_count}</span>
                        </b>
                      </p>
                      <p>
                        <b>
                          <label>Total Disk Space Allocated:</label>
                          <span> {worker_count * disk_size} GB</span>
                        </b>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Segment>
            {available !== null && !available ? (
              <Message warning attached>
                <Header as='h3'>
                  The GPUs requested are not currently available at the
                  requested maximum price.
                </Header>
                {worker_count > 1 ? (
                  <p>
                    To start this job immediately, go back and select a
                    different GPU Type, specify a higher Maximum Price per GPU,
                    or reduce the GPU Count or number of workers. Otherwise,
                    some workers may not start immediately. Waiting workers will
                    automatically start when GPUs become available. You will not
                    be billed for time spent waiting. Workers waiting for more
                    than 7 days may be automatically purged.
                  </p>
                ) : (
                  <p>
                    To start this job immediately, go back and select a
                    different GPU Type, specify a higher Maximum Price per GPU,
                    or reduce the GPU Count. Otherwise, this job will wait
                    indefinitely for GPUs become available. You will not be
                    billed for time spent waiting. Jobs waiting for more than 7
                    days may be automatically purged.
                  </p>
                )}
              </Message>
            ) : undefined}
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ user, gpuClasses }) {
  return {
    user,
    gpuClasses,
  };
}

export default connect(mapStateToProps)(ResourcesReview);
