import React, { useState } from 'react';
import { Button, Stack, Typography, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from 'react-router-dom';
import DeviceConfigsModal from './DeviceConfigsModal';

function NodeGridToolbar({ region, service, type, deployConfigToSelected }) {
  const history = useHistory();

  const [deviceConfigsOpen, setDeviceConfigsOpen] = useState(false);
  const disabled = region.storage.instances.length === 0;

  return (
    <GridToolbarContainer>
      <Stack direction='row' spacing={1}>
        <Tooltip
          title={Boolean(disabled) ? 'Storage node required for region' : ''}
        >
          <span>
            {' '}
            <Button
              color='brand'
              variant='outlined'
              disabled={Boolean(disabled)}
              onClick={() =>
                history.push({
                  pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/node/new`,
                  search: `?service=${service}&type=${type}`,
                })
              }
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <AddCircleOutlineIcon />
                <Typography variant='button' display='block' gutterBottom>
                  Add
                </Typography>
              </Stack>
            </Button>
            <LoadingButton variant='outlined' onClick={deployConfigToSelected}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <SystemUpdateAltIcon />
                <Typography variant='button' display='block' gutterBottom>
                  Deploy Config
                </Typography>
              </Stack>
            </LoadingButton>
            <Button
              variant='outlined'
              disabled={Boolean(disabled)}
              onClick={() => setDeviceConfigsOpen(true)}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <SettingsIcon />
                <Typography variant='button' display='block' gutterBottom>
                  Manage Device Configs
                </Typography>
              </Stack>
            </Button>
          </span>
        </Tooltip>
        <GridToolbarColumnsButton variant='outlined' />
        <GridToolbarFilterButton variant='outlined' />
      </Stack>
      <DeviceConfigsModal
        open={deviceConfigsOpen}
        region={region}
        handleClose={() => {
          setDeviceConfigsOpen(false);
        }}
      />
    </GridToolbarContainer>
  );
}
export default NodeGridToolbar;
