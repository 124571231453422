import React, { useState } from 'react';
import {
  Grid,
  Toolbar,
  Stack,
  Button,
  TextField,
  Box,
  FormControl,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';

import DisplayNameField from './DisplayNameField';
import GivenNameField from './GivenNameField';
import LastNameField from './LastNameField';
import { deleteUser, updateUser } from '../../../actions/user';
import CouponField from './CouponField';

export default function ProfileForm({ edit, onSubmit }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [pristine, setPristine] = useState(Boolean(edit));
  const [loading, setLoading] = useState(false);
  const [given_name, setGivenName] = useState(user.given_name);
  const [given_name_error, setGivenNameError] = useState(false);
  const [family_name, setFamilyName] = useState(user.family_name);
  const [family_name_error, setFamilyNameError] = useState(false);
  const [display_name, setDisplayName] = useState(user.display_name);
  const [display_name_error, setDisplayNameError] = useState(false);
  const [coupon_code, setCouponCode] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await dispatch(
        updateUser({
          given_name,
          family_name,
          display_name,
          coupon_code: Boolean(coupon_code) ? coupon_code : undefined,
        })
      );
      setLoading(false);
      setPristine(true);
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    if (edit) {
      setGivenName(user.given_name);
      setFamilyName(user.family_name);
      setDisplayName(user.display_name);
      setPristine(true);
    } else {
      await dispatch(deleteUser());
    }
  };

  if (!user) {
    return <div>User Not Defined</div>;
  }

  return (
    <Grid container spacing={2} sx={{ maxWidth: 600 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box>
          <FormControl fullWidth required>
            <TextField
              label='Email'
              id='email'
              variant='outlined'
              value={user.email}
              disabled
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DisplayNameField
          value={display_name}
          error={display_name_error}
          setValue={setDisplayName}
          setError={setDisplayNameError}
          setChanged={() => setPristine(false)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <GivenNameField
          value={given_name}
          error={given_name_error}
          setValue={setGivenName}
          setError={setGivenNameError}
          setChanged={() => setPristine(false)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <LastNameField
          value={family_name}
          error={family_name_error}
          setValue={setFamilyName}
          setError={setFamilyNameError}
          setChanged={() => setPristine(false)}
        />
      </Grid>
      {!edit ? (
        <Grid item xs={12} md={12} lg={12}>
          <CouponField
            value={coupon_code}
            setValue={setCouponCode}
            setChanged={() => setPristine(false)}
          />
        </Grid>
      ) : undefined}
      <Grid item xs={12} md={12} lg={12}>
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <LoadingButton
              variant='contained'
              color='brand'
              onClick={handleSubmit}
              loading={loading}
              disabled={
                pristine ||
                Boolean(given_name_error) ||
                Boolean(display_name_error) ||
                Boolean(family_name_error) ||
                !Boolean(given_name) ||
                !Boolean(display_name) ||
                !Boolean(family_name)
              }
            >
              {edit ? 'Update' : 'Next'}
            </LoadingButton>

            <Button
              variant='outlined'
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </Grid>
    </Grid>
  );
}
