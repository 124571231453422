import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import ProjectDatastoresGridToolbar from './ProjectDatastoresGridToolbar';
import {
  disableProjectDatastore,
  enableProjectDatastore,
  getProjectDatastores,
} from 'actions/projectDatastores';

export default function ProjectDatastores({ project }) {
  const [project_datastores, setProjectDatastores] = useState(null);
  const [transitioningProjectDatastores, setTransitioningProjectDatastores] =
    useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    connector_id: false,
  });

  useEffect(() => {
    (async () => {
      if (project_datastores === null) {
        const res = await getProjectDatastores(project.id);
        setProjectDatastores(res);
      }
    })();
  }, [setProjectDatastores, project_datastores, project.id]);

  const handleEnableProjectDatastore = useCallback(
    (project_datastore) => async () => {
      setTransitioningProjectDatastores(
        (prevTransitioningProjectDatastores) => [
          ...prevTransitioningProjectDatastores,
          project_datastore.id,
        ]
      );
      const datastore = await enableProjectDatastore(
        project.id,
        project_datastore.id
      );
      setProjectDatastores((prevDatastores) => {
        const index = prevDatastores.findIndex(
          (store) => store.id === datastore.id
        );
        const new_project_datastores = [...prevDatastores];
        new_project_datastores[index] = datastore;
        return new_project_datastores;
      });
      setTransitioningProjectDatastores((prevTransitioningProjectDatastores) =>
        prevTransitioningProjectDatastores.filter(
          (id) => id !== project_datastore.id
        )
      );
    },
    [project]
  );

  const handleDisableProjectDatastore = useCallback(
    (project_datastore) => async () => {
      setTransitioningProjectDatastores(
        (prevTransitioningProjectDatastores) => [
          ...prevTransitioningProjectDatastores,
          project_datastore.id,
        ]
      );
      const datastore = await disableProjectDatastore(
        project.id,
        project_datastore.id
      );
      setProjectDatastores((prevDatastores) => {
        const index = prevDatastores.findIndex(
          (store) => store.id === datastore.id
        );
        const new_project_datastores = [...prevDatastores];
        new_project_datastores[index] = datastore;
        return new_project_datastores;
      });
      setTransitioningProjectDatastores((prevTransitioningProjectDatastores) =>
        prevTransitioningProjectDatastores.filter(
          (id) => id !== project_datastore.id
        )
      );
    },
    [project]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'region_uuid',
        headerName: 'Region',
        type: 'string',
        flex: 1.5,
      },
      {
        field: 'enabled',
        headerName: 'Enabled',
        type: 'boolean',

        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<CheckCircleOutlinedIcon />}
            label='Enable'
            onClick={handleEnableProjectDatastore(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<CancelOutlinedIcon />}
            label='Disable'
            onClick={handleDisableProjectDatastore(params.row)}
            showInMenu
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label='Remove'
          //   onClick={deleteProjectMember(params.row)}
          //   showInMenu
          // />,
        ],
      },
    ],
    [handleEnableProjectDatastore, handleDisableProjectDatastore]
  );
  if (transitioningProjectDatastores) {
    // do something
  }

  const Toolbar = () => <ProjectDatastoresGridToolbar project={project} />;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Project Datastores
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.id}
                columns={columns}
                rows={project_datastores || []}
                components={{
                  Toolbar: Toolbar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
