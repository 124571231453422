import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import ProjectServicesGridToolbar from './ProjectServicesGridToolbar';
import {
  disableProjectService,
  enableProjectService,
  getProjectServices,
} from 'actions/projectServices';

export default function ProjectServices({ project }) {
  const [project_services, setProjectServices] = useState(null);
  const [transitioningProjectServices, setTransitioningProjectServices] =
    useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    connector_id: false,
  });

  useEffect(() => {
    (async () => {
      if (project_services === null) {
        const res = await getProjectServices(project.id);
        setProjectServices(res);
      }
    })();
  }, [setProjectServices, project_services, project.id]);

  const handleEnableProjectService = useCallback(
    (project_service) => async () => {
      setTransitioningProjectServices((prevTransitioningProjectServices) => [
        ...prevTransitioningProjectServices,
        project_service.id,
      ]);
      const service = await enableProjectService(
        project.id,
        project_service.id
      );
      setProjectServices((prevServices) => {
        const index = prevServices.findIndex(
          (service) => service.id === project_service.id
        );
        const new_project_services = [...prevServices];
        new_project_services[index] = service;
        return new_project_services;
      });
      setTransitioningProjectServices((prevTransitioningProjectServices) =>
        prevTransitioningProjectServices.filter(
          (id) => id !== project_service.id
        )
      );
    },
    [project]
  );

  const handleDisableProjectService = useCallback(
    (project_service) => async () => {
      setTransitioningProjectServices((prevTransitioningProjectServices) => [
        ...prevTransitioningProjectServices,
        project_service.id,
      ]);
      const service = await disableProjectService(
        project.id,
        project_service.id
      );
      setProjectServices((prevServices) => {
        const index = prevServices.findIndex(
          (service) => service.id === project_service.id
        );
        const new_project_services = [...prevServices];
        new_project_services[index] = service;
        return new_project_services;
      });
      setTransitioningProjectServices((prevTransitioningProjectServices) =>
        prevTransitioningProjectServices.filter(
          (id) => id !== project_service.id
        )
      );
    },
    [project]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'region_uuid',
        headerName: 'Region',
        type: 'string',
        flex: 1.5,
      },
      {
        field: 'enabled',
        headerName: 'Enabled',
        type: 'boolean',

        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<CheckCircleOutlinedIcon />}
            label='Enable'
            onClick={handleEnableProjectService(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<CancelOutlinedIcon />}
            label='Disable'
            onClick={handleDisableProjectService(params.row)}
            showInMenu
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label='Remove'
          //   onClick={deleteProjectMember(params.row)}
          //   showInMenu
          // />,
        ],
      },
    ],
    [handleEnableProjectService, handleDisableProjectService]
  );

  if (transitioningProjectServices) {
    // do something
  }

  const Toolbar = () => <ProjectServicesGridToolbar project={project} />;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Project Services
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.id}
                columns={columns}
                rows={project_services || []}
                components={{
                  Toolbar: Toolbar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
