import React, { useEffect, useState } from 'react';
import { Button, Stack, Alert, Snackbar, Slide } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearErrorMessage } from '../../actions/errorMessage';

function TransitionUp(props) {
  return <Slide {...props} direction='up' />;
}

export default function ErrorSnackBar({ message, retry }) {
  const [open, setOpen] = useState(Boolean(message));
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(Boolean(message));
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearErrorMessage());
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={TransitionUp}
    >
      <Stack direction='row' spacing={2}>
        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
          {message}
        </Alert>
        {retry ? (
          <Button color='secondary' size='small' onClick={retry}>
            RETRY
          </Button>
        ) : undefined}
      </Stack>
    </Snackbar>
  );
}
