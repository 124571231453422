function toNiceTimestamp(time, include_time) {
  const date = new Date(time);

  return new Intl.DateTimeFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      dateStyle: 'medium',
      timeStyle: include_time ? 'medium' : undefined,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  ).format(date);
}
export default toNiceTimestamp;
