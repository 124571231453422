import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useSelector } from 'react-redux';
import COLLECTION_TYPES from './collectionTypes';

export default function CollectionTypeField({
  entity_type,
  value,
  setValue,
  setChanged,
  edit,
}) {
  const user = useSelector((state) => state.user);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  const disable_regional = !Boolean(user.cloudbender_enabled);
  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id='source-type-label'>Type</InputLabel>
        <Select
          labelId='type-label'
          id='type'
          value={value}
          label='Type'
          aria-describedby='type-helper-text'
          onChange={handleChange}
          disabled={edit}
        >
          <MenuItem
            key={COLLECTION_TYPES.REGIONAL}
            value={COLLECTION_TYPES.REGIONAL}
            disabled={disable_regional}
          >
            Regional
          </MenuItem>

          <MenuItem key={COLLECTION_TYPES.EVEFS} value={COLLECTION_TYPES.EVEFS}>
            EverywhereFS&#8482;
          </MenuItem>
        </Select>

        <FormHelperText id='type-helper-text'>
          Select the type of {entity_type} to create
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
