import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function DatastoreRootField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (!/^\/$|^\/\/$|^(\/[\w-]+)+$/.test(newValue)) {
      setError('Root path must be valid absolute linux path.');
    } else if (newValue === '') {
      setError('Root path cannot be blank (use "/")');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Root Path'
          id='root'
          aria-describedby='root-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='root-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='root-helper-text'>
            Enter the root path for the datastore (use "/" to map to the root of
            the datastore)
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
