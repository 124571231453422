import DATASTORE_TYPES from 'components/shared/datastoreTypes';

const DIRECT_MOUNT_DATASTORES = [
  DATASTORE_TYPES.NFS,
  DATASTORE_TYPES.SMB,
  DATASTORE_TYPES.AWS_S3,
  DATASTORE_TYPES.AWS_EFS,
  DATASTORE_TYPES.GCP_CS,
  DATASTORE_TYPES.GCP_FS,
];
export default DIRECT_MOUNT_DATASTORES;
