import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import RequiredFields from './JobForm/RequiredFields';
import EnvironmentFields from './JobForm/EnvironmentFields';
import DataFields from './JobForm/DataFields';
import ModelFields from './JobForm/ModelFields';
import WorkerFields from './JobForm/WorkerFields';
import { updateJob } from '../../actions/jobs';
import { fetchEnvironments } from '../../actions/environments';
import JOB_TYPES from './types';

class JobEditForm extends Component {
  state = {
    pristine: true,
    submitting: false,
    form_error: true,
    resources: this.props.job.resources,
    resources_error: false,
    data: this.props.job.data,
    data_error: false,
    model: this.props.job.model,
    model_error: false,
    environment: this.props.job.environment,
    environment_error: false,
  };

  componentDidMount() {
    this.props.fetchEnvironments();
  }

  componentDidUpdate(prevProps, prevState) {
    const { resources_error, data_error, model_error, environment_error } =
      this.state;
    const form_error =
      resources_error || data_error || model_error || environment_error;
    if (prevState.form_error !== form_error) {
      this.setState({ form_error });
    }
  }

  handleChange = (type, values, error) => {
    this.setState({
      pristine: false,
      [type]: values,
      [`${type}_error`]: error,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      submitting: true,
    });

    let update = {};
    if (
      this.state.resources.gpu_type_id !== this.props.job.resources.gpu_type_id
    ) {
      update.resources = update.resources || {};
      update.resources.gpu_type_id = this.state.resources.gpu_type_id;
    }
    if (
      !_.isEqual(
        this.state.resources.gpu_types,
        this.props.job.resources.gpu_types
      )
    ) {
      update.resources = update.resources || {};
      update.resources.gpu_types = this.state.resources.gpu_types;
    }
    if (this.state.resources.gpu_count !== this.props.job.resources.gpu_count) {
      update.resources = update.resources || {};
      update.resources.gpu_count = this.state.resources.gpu_count;
    }
    if (this.state.resources.cpu_count !== this.props.job.resources.cpu_count) {
      update.resources = update.resources || {};
      update.resources.cpu_count = this.state.resources.cpu_count;
    }

    if (!_.isEqual(this.state.data, this.props.job.data)) {
      update.data = {};
      if (!_.isEqual(this.state.data.datasets, this.props.job.data.datasets)) {
        update.data.datasets = [...this.state.data.datasets];
      }
      if (this.state.data.output_type !== this.props.job.data.output_type) {
        if (
          !this.state.data.output_type ||
          this.state.data.output_type === ''
        ) {
          update.data.output_type = null;
          update.data.output_uri = null;
          update.data.output_options = null;
        } else {
          update.data.output_type = this.state.data.output_type;
          update.data.output_uri = this.state.data.output_uri;
          update.data.output_options = { ...this.state.data.output_options };
        }
      } else {
        update.data.output_type = this.state.data.output_type;
        if (this.state.data.output_uri !== this.props.job.data.output_uri) {
          update.data.output_uri = this.state.data.output_uri;
        }
        if (
          !_.isEqual(
            this.state.data.output_options,
            this.props.job.data.output_options
          )
        ) {
          update.data.output_options = { ...this.state.data.output_options };
        }
      }
    }
    if (!_.isEqual(this.state.model, this.props.job.model)) {
      update.model = {};
      if (
        !_.isEqual(
          this.state.model.checkpoints,
          this.props.job.model.checkpoints
        )
      ) {
        update.model.checkpoints = [...this.state.model.checkpoints];
      }
    }

    await this.props.updateJob(this.props.job.job_uuid, update);
    this.props.onClose();
  };

  render() {
    const { pristine, form_error, submitting } = this.state;
    const { job } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <RequiredFields
          initialValues={{ ...job.resources, job_name: job.name }}
          type={job.type}
          onChange={this.handleChange}
          edit
        />
        <DataFields
          initialValues={job.data}
          type={job.type}
          onChange={this.handleChange}
          edit
        />
        <ModelFields
          initialValues={job.model}
          type={job.type}
          onChange={this.handleChange}
          edit
        />
        {job.type === JOB_TYPES.TRAINING.value ? (
          <WorkerFields
            initialValues={job.workers}
            onChange={this.handleChange}
            edit
          />
        ) : undefined}
        <EnvironmentFields
          initialValues={job.environment}
          type={job.type}
          onChange={this.handleChange}
          edit
        />
        <br />
        <Button
          floated='left'
          secondary
          onClick={(e) => {
            e.preventDefault();
            this.props.onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          floated='right'
          primary
          disabled={pristine || form_error}
          loading={submitting}
          onClick={this.handleSubmit}
        >
          Confirm
        </Button>
        <br />
        <br />
      </Form>
    );
  }
}

export default connect(null, {
  updateJob,
  fetchEnvironments,
})(JobEditForm);
