import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  CircularProgress,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
// import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import UpdateIcon from '@mui/icons-material/Update';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  deleteRig,
  disableRemoteSupport,
  enableRemoteSupport,
  runAction,
  toggleMaintenance,
  updateRig,
} from '../../../../actions/rigs';
import { fetchGpuClasses } from '../../../../actions/gpuClasses';
import NodeGridToolbar from './NodeGridToolbar';
import STATUSES from '../../../statuses';
import PollNode from './PollNode';
import RIG_SERVICES from './rigServices';
import RIG_TYPES from './rigTypes';
import PROVIDER_TYPES from '../../providerTypes';
import renderProgress from '../../../shared/renderProgress';
import BytesToMinimumGB from '../../../../util/BytesToMinimumGB';
import STORAGE_MODES from '../../RegionForm/Shared/storageModes';
import TelemetryInfo from './TelemetryInfo';
import Devices from './Devices';

export default function Nodes({ region, rigs }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const gpu_types = useSelector((state) => state.gpuClasses);
  const [selectedRigs, setSelectedRigs] = useState([]);
  const [transitioningRigs, setTransitioningRigs] = useState([]);

  const [storageRigs, setStorageRigs] = useState([]);
  const [computeRigs, setComputeRigs] = useState([]);
  const [deviceRigs, setDeviceRigs] = useState([]);
  const [ephemeralRigs, setEphemeralRigs] = useState([]);
  const [telemetryInfoOpen, setTelemetryInfoOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (gpu_types === null) {
        dispatch(fetchGpuClasses());
        return;
      }
    })();
  }, [dispatch, gpu_types]);

  useEffect(() => {
    const storage_rigs = [],
      compute_rigs = [],
      device_rigs = [],
      ephemeral_rigs = [];
    if (rigs !== null) {
      rigs.forEach((rig) => {
        if (rig.service === RIG_SERVICES.STORAGE) {
          storage_rigs.push(rig);
        } else {
          if (rig.type === RIG_TYPES.EPHEMERAL) {
            ephemeral_rigs.push(rig);
          } else if (rig.type === RIG_TYPES.DEVICE) {
            device_rigs.push(rig);
          } else {
            compute_rigs.push(rig);
          }
        }
      });
      setStorageRigs(storage_rigs);
      setComputeRigs(compute_rigs);
      setDeviceRigs(device_rigs);
      setEphemeralRigs(ephemeral_rigs);
    }
  }, [rigs]);

  //   const viewDetails = useCallback(
  //     (rig) => () => {
  //       history.push({
  //         pathname: `/resources/region/${region.region_uuid}/node/details/${rig.rig_uuid}`,
  //         search: `?provider_type=${region.provider_type}`,
  //       });
  //     },
  //     [history, region]
  //   );

  const updateNodeStatistics = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        runAction(region.provider_uuid, rig.region_uuid, rig.rig_uuid, 'report')
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const toggleNodeMaintenace = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        toggleMaintenance(region.provider_uuid, rig.region_uuid, rig.rig_uuid)
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const enableNodeRemoteSupport = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        enableRemoteSupport(region.provider_uuid, rig.region_uuid, rig.rig_uuid)
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const disableNodeRemoteSupport = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        disableRemoteSupport(
          region.provider_uuid,
          rig.region_uuid,
          rig.rig_uuid
        )
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const editNode = useCallback(
    (rig) => () => {
      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/node/${rig.rig_uuid}/edit`,
      });
    },
    [history, region]
  );
  const removeNode = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        deleteRig(region.provider_uuid, rig.region_uuid, rig.rig_uuid)
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const stopNode = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        updateRig(region.provider_uuid, rig.region_uuid, rig.rig_uuid, {
          command: 'stop',
        })
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const startNode = useCallback(
    (rig) => async () => {
      setTransitioningRigs((prevTransitioningRigs) => [
        ...prevTransitioningRigs,
        rig.rig_uuid,
      ]);
      await dispatch(
        updateRig(region.provider_uuid, rig.region_uuid, rig.rig_uuid, {
          command: 'start',
        })
      );
      setTransitioningRigs((prevTransitioningRigs) =>
        prevTransitioningRigs.filter((rig_uuid) => rig_uuid !== rig.rig_uuid)
      );
    },
    [dispatch, region]
  );

  const getRigName = useCallback((params) => params.row.friendly_name, []);

  const renderRigName = useCallback(
    (params) => {
      if (params.row.config?.remote_support_enabled) {
        return (
          <Tooltip title={'Remote Support is Enabled'}>
            <span>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2'>
                  {params.row.friendly_name}
                </Typography>
                <LockOpenOutlinedIcon color='error' />
              </Stack>
            </span>
          </Tooltip>
        );
      } else {
        return (
          <Typography variant='body2'>{params.row.friendly_name}</Typography>
        );
      }
    },

    []
  );

  const getGpuType = useCallback(
    (params) => {
      if (gpu_types) {
        const gpu_type = gpu_types.find(
          (gpu_type) =>
            gpu_type.id ===
            (params.row.resource_type_id || params.row.gpu_type_id)
        );
        if (gpu_type) {
          return gpu_type.name;
        }
      }
    },
    [gpu_types]
  );

  const getHotStorage = useCallback((params) => {
    if (params.row.state && params.row.state.storage) {
      return {
        used:
          BytesToMinimumGB(
            params.row.state.storage.hot_total -
              params.row.state.storage.hot_available
          ) || 0,
        total: BytesToMinimumGB(params.row.state.storage.hot_total) || 0,
      };
    } else {
      return {
        used: 0,
        total: 0,
      };
    }
  }, []);

  const getColdStorage = useCallback((params) => {
    if (params.row.state && params.row.state.storage) {
      return {
        used:
          BytesToMinimumGB(
            params.row.state.storage.cold_total -
              params.row.state.storage.cold_available
          ) || 0,
        total: BytesToMinimumGB(params.row.state.storage.cold_total) || 0,
      };
    } else {
      return {
        used: 0,
        total: 0,
      };
    }
  }, []);

  const renderStatus = useCallback(
    (params) => {
      if (transitioningRigs.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      switch (params.row.status) {
        case STATUSES.ACTIVE:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <CheckCircleIcon color='success' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.OFFLINE:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <StopCircleIcon />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.STOPPED:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <StopCircleIcon />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.ERRORED:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <ErrorOutlineIcon color='error' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.MAINTENANCE:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <WarningIcon color='warning' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        default:
          return (
            <>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CircularProgress size={20} />
                <Typography variant='body2'>{params.row.status}</Typography>
              </Stack>
              <PollNode region={region} rig={params.row} interval={10000} />
            </>
          );
      }
    },
    [region, transitioningRigs]
  );

  const centralStorageComputeColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
        valueGetter: getRigName,
        renderCell: renderRigName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'gpu_type',
        headerName: 'Gpu Type',
        type: 'string',
        valueGetter: getGpuType,
        flex: 0.5,
      },
      {
        field: 'hot_storage',
        headerName: 'Storage',
        valueGetter: getHotStorage,
        renderCell: renderProgress,
        flex: 0.5,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];
          if (params.row.config?.remote_support_enabled) {
            actions.push(
              <GridActionsCellItem
                icon={<EngineeringIcon />}
                label='Disable Support'
                onClick={disableNodeRemoteSupport(params.row)}
                showInMenu
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<EngineeringIcon />}
                label='Enable Support'
                onClick={enableNodeRemoteSupport(params.row)}
                showInMenu
              />
            );
          }
          actions.push(
            //   <GridActionsCellItem
            //     icon={<InfoIcon />}
            //     label='View Details'
            //     onClick={viewDetails(params.row)}
            //     showInMenu
            //   />,
            <GridActionsCellItem
              icon={<EngineeringIcon />}
              label='Toggle Maintenance'
              onClick={toggleNodeMaintenace(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<UpdateIcon />}
              label='Update Stats'
              onClick={updateNodeStatistics(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={editNode(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={removeNode(params.row)}
              showInMenu
            />
          );
          if (region.provider_type !== PROVIDER_TYPES.PHYSICAL.value) {
            actions.unshift(
              <GridActionsCellItem
                icon={<StopCircleOutlinedIcon />}
                label='Stop'
                onClick={stopNode(params.row)}
                showInMenu
                disabled={
                  ![STATUSES.ACTIVE, STATUSES.MAINTENANCE].includes(
                    params.row.status
                  )
                }
              />
            );
            actions.unshift(
              <GridActionsCellItem
                icon={<PlayCircleOutlineIcon />}
                label='Start'
                onClick={startNode(params.row)}
                showInMenu
                disabled={params.row.status !== STATUSES.STOPPED}
              />
            );
          }
          return actions;
        },
      },
    ],
    [
      renderRigName,
      getRigName,
      getGpuType,
      renderStatus,
      //   viewDetails,
      toggleNodeMaintenace,
      updateNodeStatistics,
      editNode,
      removeNode,
      getHotStorage,
      startNode,
      stopNode,
      region.provider_type,
      disableNodeRemoteSupport,
      enableNodeRemoteSupport,
    ]
  );
  const localStorageComputeColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
        valueGetter: getRigName,
        renderCell: renderRigName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'gpu_type',
        headerName: 'Gpu Type',
        type: 'string',
        valueGetter: getGpuType,
        flex: 0.5,
      },
      {
        field: 'hot_storage',
        headerName: 'Hot Storage',
        valueGetter: getHotStorage,
        renderCell: renderProgress,
        flex: 0.5,
      },
      {
        field: 'cold_storage',
        headerName: 'Cold Storage',
        valueGetter: getColdStorage,
        renderCell: renderProgress,
        flex: 0.5,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];
          if (params.row.config?.remote_support_enabled) {
            actions.push(
              <GridActionsCellItem
                icon={<EngineeringIcon />}
                label='Disable Support'
                onClick={disableNodeRemoteSupport(params.row)}
                showInMenu
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<EngineeringIcon />}
                label='Enable Support'
                onClick={enableNodeRemoteSupport(params.row)}
                showInMenu
              />
            );
          }
          actions.push(
            //   <GridActionsCellItem
            //     icon={<InfoIcon />}
            //     label='View Details'
            //     onClick={viewDetails(params.row)}
            //     showInMenu
            //   />,
            <GridActionsCellItem
              icon={<EngineeringIcon />}
              label='Toggle Maintenance'
              onClick={toggleNodeMaintenace(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<UpdateIcon />}
              label='Update Stats'
              onClick={updateNodeStatistics(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={editNode(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={removeNode(params.row)}
              showInMenu
            />
          );
          if (region.provider_type !== PROVIDER_TYPES.PHYSICAL.value) {
            actions.unshift(
              <GridActionsCellItem
                icon={<StopCircleOutlinedIcon />}
                label='Stop'
                onClick={stopNode(params.row)}
                showInMenu
                disabled={
                  ![STATUSES.ACTIVE, STATUSES.MAINTENANCE].includes(
                    params.row.status
                  )
                }
              />
            );
            actions.unshift(
              <GridActionsCellItem
                icon={<PlayCircleOutlineIcon />}
                label='Start'
                onClick={startNode(params.row)}
                showInMenu
                disabled={params.row.status !== STATUSES.STOPPED}
              />
            );
          }
          return actions;
        },
      },
    ],
    [
      renderRigName,
      getRigName,
      getGpuType,
      renderStatus,
      //   viewDetails,
      toggleNodeMaintenace,
      updateNodeStatistics,
      editNode,
      removeNode,
      getHotStorage,
      getColdStorage,
      startNode,
      stopNode,
      region.provider_type,
      disableNodeRemoteSupport,
      enableNodeRemoteSupport,
    ]
  );

  const computeColumns =
    region.storage.mode === STORAGE_MODES.CENTRAL
      ? centralStorageComputeColumns
      : localStorageComputeColumns;

  const ephemeralColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
        valueGetter: getRigName,
        renderCell: renderRigName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'gpu_type',
        headerName: 'Gpu Type',
        type: 'string',
        valueGetter: getGpuType,
        flex: 0.5,
      },
    ],
    [
      renderRigName,
      getRigName,
      getGpuType,
      renderStatus,
      //   viewDetails,
    ]
  );

  const storageColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
        valueGetter: getRigName,
        renderCell: renderRigName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'hot_storage',
        headerName: 'Hot Storage',
        valueGetter: getHotStorage,
        renderCell: renderProgress,
        flex: 0.5,
      },
      {
        field: 'cold_storage',
        headerName: 'Cold Storage',
        valueGetter: getColdStorage,
        renderCell: renderProgress,
        flex: 0.5,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];

          if (params.row.config?.remote_support_enabled) {
            actions.push(
              <GridActionsCellItem
                icon={<EngineeringIcon />}
                label='Disable Support'
                onClick={disableNodeRemoteSupport(params.row)}
                showInMenu
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<EngineeringIcon />}
                label='Enable Support'
                onClick={enableNodeRemoteSupport(params.row)}
                showInMenu
              />
            );
          }
          actions.push(
            //   <GridActionsCellItem
            //     icon={<InfoIcon />}
            //     label='View Details'
            //     onClick={viewDetails(params.row)}
            //     showInMenu
            //   />,
            <GridActionsCellItem
              icon={<QueryStatsIcon />}
              label='Show Telemetry Info'
              onClick={() => setTelemetryInfoOpen(true)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<EngineeringIcon />}
              label='Toggle Maintenance'
              onClick={toggleNodeMaintenace(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<UpdateIcon />}
              label='Update Stats'
              onClick={updateNodeStatistics(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={removeNode(params.row)}
              showInMenu
            />
          );
          if (region.provider_type !== PROVIDER_TYPES.PHYSICAL.value) {
            actions.unshift(
              <GridActionsCellItem
                icon={<StopCircleOutlinedIcon />}
                label='Stop'
                onClick={stopNode(params.row)}
                showInMenu
                disabled={
                  ![STATUSES.ACTIVE, STATUSES.MAINTENANCE].includes(
                    params.row.status
                  )
                }
              />
            );
            actions.unshift(
              <GridActionsCellItem
                icon={<PlayCircleOutlineIcon />}
                label='Start'
                onClick={startNode(params.row)}
                showInMenu
                disabled={params.row.status !== STATUSES.STOPPED}
              />
            );
          }
          return actions;
        },
      },
    ],
    [
      renderRigName,
      getRigName,
      renderStatus,
      toggleNodeMaintenace,
      updateNodeStatistics,
      removeNode,
      getHotStorage,
      getColdStorage,
      stopNode,
      startNode,
      region.provider_type,
      disableNodeRemoteSupport,
      enableNodeRemoteSupport,
      //   viewDetails,
    ]
  );

  const ComputeToolbar = () => (
    <NodeGridToolbar
      region={region}
      selected={selectedRigs}
      editable
      service={RIG_SERVICES.COMPUTE}
      type={RIG_TYPES.PERMANENT}
    />
  );

  const EphemeralToolbar = () => (
    <NodeGridToolbar
      region={region}
      selected={selectedRigs}
      service={RIG_SERVICES.COMPUTE}
      type={RIG_TYPES.EPHEMERAL}
    />
  );

  const StorageToolbar = () => (
    <NodeGridToolbar
      region={region}
      selected={selectedRigs}
      service={RIG_SERVICES.STORAGE}
      type={RIG_TYPES.PERMANENT}
      disabled={
        storageRigs.length
          ? 'Only one storage node allowed per region'
          : undefined
      }
    />
  );

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h6' paragraph gutterBottom>
            {region.storage.mode === STORAGE_MODES.LOCAL
              ? 'Nodes'
              : 'Compute Nodes'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  checkboxSelection
                  getRowId={(row) => row.rig_uuid}
                  columns={computeColumns}
                  rows={computeRigs}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectedRigs(newSelectionModel);
                  }}
                  // onRowSelectionModelChange={(newSelectionModel) => {
                  //   setSelectedRigs(newSelectionModel);
                  // }}
                  // rowSelectionModel={selectedRigs}
                  components={{
                    Toolbar: ComputeToolbar,
                  }}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {region.provider_type === PROVIDER_TYPES.PHYSICAL.value ? (
        <Devices region={region} rigs={deviceRigs} />
      ) : undefined}
      {ephemeralRigs.length ? (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h6' paragraph gutterBottom>
              Ephemeral Nodes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    autoHeight
                    getRowId={(row) => row.rig_uuid}
                    columns={ephemeralColumns}
                    rows={ephemeralRigs}
                    components={{
                      Toolbar: EphemeralToolbar,
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : undefined}
      {region.storage.mode === STORAGE_MODES.CENTRAL ? (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h6' paragraph gutterBottom>
              Storage Nodes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    autoHeight
                    getRowId={(row) => row.rig_uuid}
                    columns={storageColumns}
                    rows={storageRigs}
                    components={{
                      Toolbar: StorageToolbar,
                    }}
                  />
                </div>
              </div>
            </div>
            <TelemetryInfo
              open={telemetryInfoOpen}
              handleClose={() => setTelemetryInfoOpen(false)}
              region={region}
            />
          </AccordionDetails>
        </Accordion>
      ) : undefined}
    </>
  );
}
