import React, { Fragment } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';

const PublicDatasetsField = (props) => {
  const categories = [
    ...new Set(props.datasets.map((dataset) => dataset.category)),
  ];
  const selected_dataset = props.datasets.find(
    (dataset) => dataset.dataset_uuid === props.value
  );
  return (
    <div className={props.error && props.value ? 'error field' : 'field'}>
      <Dropdown
        className='ui fluid selection dropdown'
        text={selected_dataset ? selected_dataset.name : ''}
        value={props.value}
        error={Boolean(props.error && props.value)}
      >
        <Dropdown.Menu>
          {categories.map((category) => {
            return (
              <Fragment key={category}>
                <Dropdown.Header key={category} content={category} />
                {props.datasets
                  .filter((dataset) => dataset.category === category)
                  .map((dataset) => {
                    return (
                      <Dropdown.Item
                        key={dataset.dataset_uuid}
                        name={`datasets[${props.fieldNum}].dataset_uuid`}
                        value={dataset.dataset_uuid}
                        onClick={props.handleChange}
                      >
                        {dataset.name}
                      </Dropdown.Item>
                    );
                  })}
              </Fragment>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      {props.error && props.value ? (
        <Label pointing prompt>
          Duplicate Dataset Name
        </Label>
      ) : undefined}
    </div>
  );
};
export default PublicDatasetsField;
