import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from '@mui/material';

export default function StorageModeField(props) {
  const handleChange = (event) => {
    if (event.target.value !== props.value) {
      props.setChanged();
    }
    props.setValue(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='storage-mode-label'>Storage Mode</InputLabel>
        <Select
          labelId='storage-mode-label'
          id='storage-mode'
          value={props.value}
          label='Storage Mode'
          onChange={handleChange}
          disabled={props.disabled}
        >
          <MenuItem value='local'>Local</MenuItem>
          <MenuItem value='central'>Central</MenuItem>
        </Select>
        <FormHelperText id='storage-mode-helper-text'>
          Select Local for stand-alone compute nodes or Central for a region
          that uses a central storage node
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
