import _ from 'lodash';
import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      newState = action.payload.sort((a, b) => {
        const timeA = a.start_time;
        const timeB = b.start_time;
        if (timeA < timeB) {
          return -1;
        } else if (timeA > timeB) {
          return 1;
        } else return 0;
      });
      return newState;
    case CREATE_SUBSCRIPTION:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const timeA = a.start_time;
        const timeB = b.start_time;
        if (timeA < timeB) {
          return -1;
        } else if (timeA > timeB) {
          return 1;
        } else return 0;
      });
      return newState;
    case FETCH_SUBSCRIPTION:
      newState = [...(state || [])];
      _.remove(newState, (subscription) => {
        return (
          subscription.subscription_uuid === action.payload.subscription_uuid
        );
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const timeA = a.start_time;
        const timeB = b.start_time;
        if (timeA < timeB) {
          return -1;
        } else if (timeA > timeB) {
          return 1;
        } else return 0;
      });
      return newState;

    default:
      return state;
  }
}
export default reducer;
