import React from 'react';

import { useDispatch } from 'react-redux';

import useInterval from '../../util/useInterval';

export default function PollEntity({ poll_function, interval }) {
  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(poll_function());
  }, interval);

  return <div></div>;
}
