import React, { useState, useCallback, useEffect } from 'react';
import {
  IconButton,
  Grid,
  Box,
  Typography,
  Drawer,
  Paper,
  Stack,
  Toolbar,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckpointField from './CheckpointField';
import { fetchCheckpoints } from '../../../actions/checkpoints';
import { fetchPublicCheckpoints } from '../../../actions/publicCheckpoints';

export default function CheckpointDrawer({
  open,
  handleClose,
  initialValues,
  setValue,
}) {
  const dispatch = useDispatch();
  const checkpoints = useSelector((state) => state.checkpoints);
  const publicCheckpoints = useSelector((state) => state.publicCheckpoints);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [selectedCheckpoints, setSelectedCheckpoints] = useState(
    initialValues.map((checkpoints) => {
      return { ...checkpoints };
    })
  );

  const [checkpoint_errors, setCheckpointErrors] = useState(
    new Array(initialValues.length).fill(false)
  );

  useEffect(() => {
    setSelectedCheckpoints(
      initialValues.map((checkpoints) => {
        return { ...checkpoints };
      })
    );
  }, [initialValues]);

  useEffect(() => {
    (async () => {
      if (checkpoints === null) {
        dispatch(fetchCheckpoints());
        return;
      }
      setLoading(false);
    })();
  }, [dispatch, checkpoints]);

  useEffect(() => {
    (async () => {
      if (publicCheckpoints === null) {
        dispatch(fetchPublicCheckpoints());
        return;
      }
      setLoading(false);
    })();
  }, [dispatch, publicCheckpoints]);

  const calculateError = useCallback(
    (checkpoint_errors) => {
      const errors_set = new Set([...checkpoint_errors]);
      if (
        errors_set.size > 1 ||
        ([...errors_set][0] && [...errors_set][0] !== false)
      ) {
        setError(true);
      } else {
        setError(false);
      }
    },
    [setError]
  );

  const setCheckpointError = useCallback(
    (i, error) => {
      const new_errors = [...checkpoint_errors];
      new_errors[i] = error;

      setCheckpointErrors(new_errors);
      calculateError(new_errors);
    },
    [checkpoint_errors, setCheckpointErrors, calculateError]
  );

  const addCheckpoint = useCallback(() => {
    const new_checkpoints = [
      ...selectedCheckpoints,
      { id: '', as: '', public: false },
    ];
    setSelectedCheckpoints(new_checkpoints);
    const new_errors = [...checkpoint_errors, 'Checkpoint cannot be blank'];
    setCheckpointErrors(new_errors);
    calculateError(new_errors);
  }, [
    selectedCheckpoints,
    checkpoint_errors,
    setSelectedCheckpoints,
    calculateError,
    setCheckpointErrors,
  ]);

  const removeCheckpoint = useCallback(
    (i) => {
      const new_checkpoints = [...selectedCheckpoints];
      new_checkpoints.splice(i, 1);

      setSelectedCheckpoints(new_checkpoints);
      const new_errors = [...checkpoint_errors];
      new_errors.splice(i, 1);
      setCheckpointErrors(new_errors);
      calculateError(new_errors);
    },
    [
      selectedCheckpoints,
      checkpoint_errors,
      setSelectedCheckpoints,
      setCheckpointErrors,
      calculateError,
    ]
  );

  const setCheckpointValue = useCallback(
    (i, values) => {
      const new_checkpoints = [...selectedCheckpoints];
      new_checkpoints[i] = values;
      setSelectedCheckpoints(new_checkpoints);
    },
    [selectedCheckpoints, setSelectedCheckpoints]
  );

  const submit = useCallback(() => {
    setValue(selectedCheckpoints);
    handleClose();
  }, [selectedCheckpoints, setValue, handleClose]);

  return (
    <Drawer
      sx={{ height: '100%' }}
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      <Grid
        container
        spacing={2}
        direction='row'
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          margin: '0px',
          padding: '0px 0px 0px 0px !important',
          '.MuiGrid-root': {
            padding: '0px 0px 0px 0px',
          },
          maxWidth: '550px',
        }}
      >
        <Grid
          item
          container
          justify='flex-start'
          sx={{
            maxHeight: '75px',
          }}
        >
          <Paper
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
            elevation={1}
          >
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => handleClose()}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>Manage Checkpoints</Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
          <Box sx={{ padding: '16px' }}>
            <Stack spacing={2} direction='column' alignItems='center'>
              {selectedCheckpoints.map((value, index) => {
                return (
                  <CheckpointField
                    key={`checkpoint-${index}`}
                    index={index}
                    value={value}
                    error={checkpoint_errors[index]}
                    setError={setCheckpointError}
                    setValue={setCheckpointValue}
                    remove={removeCheckpoint}
                  />
                );
              })}
              <Button
                variant='outlined'
                color='brand'
                onClick={() => addCheckpoint()}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AddCircleOutlineIcon />
                  <Typography variant='button' display='block' gutterBottom>
                    Add Checkpoint
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          container
          sx={{
            maxHeight: '50px',
            position: 'absolute',
            bottom: '0px',
            width: '100%',
          }}
        >
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={1}>
            <Toolbar>
              <Stack spacing={2} direction='row'>
                <Button
                  variant='contained'
                  color='brand'
                  onClick={submit}
                  disabled={error}
                >
                  Save
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setSelectedCheckpoints(
                      initialValues.map((checkpoints) => {
                        return { ...checkpoints };
                      })
                    );
                    setCheckpointErrors(
                      new Array(initialValues.length).fill(false)
                    );
                    setError(false);
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
      </Grid>
    </Drawer>
  );
}
