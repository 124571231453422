import { SET_USER, LOGOUT, DELETE_USER } from './types';
import store from '../store';
import { logout } from './session';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const recordLogin = () => async (dispatch) => {
  try {
    const res = await api.patch('/customer', { last_login: Date.now() });
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchUser = () => async (dispatch) => {
  try {
    if (store.getState().session.isLoggedIn) {
      const res = await api.get('/customer');
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
    } else {
      dispatch({
        type: LOGOUT,
      });
    }
  } catch (error) {
    console.log(error);
    const message = getMessageFromApiError(error);
    console.log(message);
  }
};

export const createUser = () => async (dispatch) => {
  // all the user details come from the token
  let res;
  try {
    res = await api.post('/customer', {
      signup_brand: process.env.REACT_APP_ORG_NAME,
    });
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
  }
};

export const updateUser = (values) => async (dispatch) => {
  try {
    const res = await api.patch('/customer', values);
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteUser = () => async (dispatch) => {
  try {
    const res = await api.delete('/customer');
    dispatch({
      type: DELETE_USER,
      payload: res.data,
    });
    dispatch(logout());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createApiKey = () => async (dispatch) => {
  try {
    const res = await api.post('/customer/key', {}, { responseType: 'blob' });
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `credentials.json`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const removeApiKey = (id) => async (dispatch) => {
  try {
    await api.delete(`/customer/key/${id}`);
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createBillingLinkRequest = (email) => async (dispatch) => {
  try {
    await api.post('/customer/link/request', {
      requested_billing_email: email,
    });
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const cancelBillingLinkRequest = () => async (dispatch) => {
  try {
    await api.delete('/customer/link/request');
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const cancelBillingLink = () => async (dispatch) => {
  try {
    await api.delete('/customer/link');
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const approveBillingLink = (customer_uuid) => async (dispatch) => {
  try {
    await api.post(`/customer/link`, { customer_uuid });
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const rejectBillingLinkRequest = (customer_uuid) => async (dispatch) => {
  try {
    await api.delete(`/customer/link/request/${customer_uuid}`);
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
export const removeBillingLink = (customer_uuid) => async (dispatch) => {
  try {
    await api.delete(`/customer/link/${customer_uuid}`);
    dispatch(fetchUser());
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
