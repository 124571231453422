import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const UploadButton = ({ name, onChange, disabled }) => {
  let input;
  return (
    <React.Fragment>
      <Button
        icon
        labelPosition='right'
        onClick={(e) => {
          e.preventDefault();
          input.click();
        }}
        disabled={disabled}
        basic
        color='black'
      >
        <Icon name='upload' />
        {name}
      </Button>

      <input
        ref={(element) => (input = element)}
        hidden
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}
        type='file'
      />
    </React.Fragment>
  );
};
export default UploadButton;
