import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import ProjectDataConnectorsGridToolbar from './ProjectDataConnectorsGridToolbar';
import {
  disableProjectDataConnector,
  enableProjectDataConnector,
  getProjectDataConnectors,
} from 'actions/projectDataConnectors';

export default function ProjectDataConnectors({ project }) {
  const [project_data_connectors, setProjectDataConnectors] = useState(null);
  const [
    transitioningProjectDataConnectors,
    setTransitioningProjectDataConnectors,
  ] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    connector_id: false,
  });

  useEffect(() => {
    (async () => {
      if (project_data_connectors === null) {
        const res = await getProjectDataConnectors(project.id);
        setProjectDataConnectors(res);
      }
    })();
  }, [setProjectDataConnectors, project_data_connectors, project.id]);

  const handleEnableProjectDataConnector = useCallback(
    (project_data_connector) => async () => {
      setTransitioningProjectDataConnectors(
        (prevTransitioningProjectDataConnectors) => [
          ...prevTransitioningProjectDataConnectors,
          project_data_connector.id,
        ]
      );
      const data_connector = await enableProjectDataConnector(
        project.id,
        project_data_connector.id
      );
      setProjectDataConnectors((prevDataConnectors) => {
        const index = prevDataConnectors.findIndex(
          (connector) => connector.id === data_connector.id
        );
        const new_project_data_connectors = [...prevDataConnectors];
        new_project_data_connectors[index] = data_connector;
        return new_project_data_connectors;
      });
      setTransitioningProjectDataConnectors(
        (prevTransitioningProjectDataConnectors) =>
          prevTransitioningProjectDataConnectors.filter(
            (id) => id !== project_data_connector.id
          )
      );
    },
    [project]
  );

  const handleDisableProjectDataConnector = useCallback(
    (project_data_connector) => async () => {
      setTransitioningProjectDataConnectors(
        (prevTransitioningProjectDataConnectors) => [
          ...prevTransitioningProjectDataConnectors,
          project_data_connector.id,
        ]
      );
      const data_connector = await disableProjectDataConnector(
        project.id,
        project_data_connector.id
      );
      setProjectDataConnectors((prevDataConnectors) => {
        const index = prevDataConnectors.findIndex(
          (connector) => connector.id === data_connector.id
        );
        const new_project_data_connectors = [...prevDataConnectors];
        new_project_data_connectors[index] = data_connector;
        return new_project_data_connectors;
      });
      setTransitioningProjectDataConnectors(
        (prevTransitioningProjectDataConnectors) =>
          prevTransitioningProjectDataConnectors.filter(
            (id) => id !== project_data_connector.id
          )
      );
    },
    [project]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'region_uuid',
        headerName: 'Region',
        type: 'string',
        flex: 1.5,
      },
      {
        field: 'enabled',
        headerName: 'Enabled',
        type: 'boolean',

        flex: 0.5,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<CheckCircleOutlinedIcon />}
            label='Enable'
            onClick={handleEnableProjectDataConnector(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<CancelOutlinedIcon />}
            label='Disable'
            onClick={handleDisableProjectDataConnector(params.row)}
            showInMenu
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label='Remove'
          //   onClick={deleteProjectMember(params.row)}
          //   showInMenu
          // />,
        ],
      },
    ],
    [handleEnableProjectDataConnector, handleDisableProjectDataConnector]
  );

  const Toolbar = () => <ProjectDataConnectorsGridToolbar project={project} />;

  if (transitioningProjectDataConnectors) {
    // do something
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Project Data Connectors
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.id}
                columns={columns}
                rows={project_data_connectors || []}
                components={{
                  Toolbar: Toolbar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
