import React, { Component } from 'react';
import { Menu, Divider, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import JOB_TYPES from './jobs/types';

class SideBar extends Component {
  state = {};
  componentDidMount() {
    this.setState({ hideNav: window.innerWidth <= 760 });
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ hideNav: window.innerWidth <= 760 });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  logoLink() {
    if (this.props.session.isLoggedIn && this.props.user) {
      if (this.props.user.status === 'approved') {
        return '/dashboard';
      }
    }
    return '/';
  }

  render() {
    const { status } = this.props.user;
    const { hideNav } = this.state;

    return this.props.session.isLoggedIn &&
      this.props.user &&
      status === 'approved' ? (
      <Menu fixed='left' inverted vertical size='large' borderless compact>
        <Menu.Item
          as={Link}
          to={this.logoLink()}
          name='Logo'
          onClick={this.handleItemClick}
        >
          <img
            className={`ui ${hideNav ? '' : 'small'} image`}
            alt={`${process.env.REACT_APP_ORG_NAME}`}
            src={hideNav ? '/logo-small.png' : '/logo.png'}
            width={'30px'}
            height={'auto'}
          />
        </Menu.Item>
        <Menu.Item as={Link} to='/dashboard' onClick={this.handleItemClick}>
          <Icon name='home' />
          {hideNav ? '' : 'Home'}
        </Menu.Item>
        <Divider />

        {Object.entries(JOB_TYPES).map(([key, type]) => {
          return (
            <Menu.Item
              key={type.value}
              as={Link}
              to={`/jobs/${type.value}`}
              onClick={this.handleItemClick}
            >
              <Icon name={type.icon} />
              {hideNav ? '' : type.label}
            </Menu.Item>
          );
        })}
        <Menu.Item as={Link} to='/checkpoint' onClick={this.handleItemClick}>
          <Icon name='check square outline' />
          {hideNav ? '' : 'Checkpoints'}
        </Menu.Item>
        <Menu.Item as={Link} to='/dataset' onClick={this.handleItemClick}>
          <Icon name='hdd' />
          {hideNav ? '' : 'Datasets'}
        </Menu.Item>
        <Menu.Item as={Link} to='/model' onClick={this.handleItemClick}>
          <Icon name='code branch' />
          {hideNav ? '' : 'Models'}
        </Menu.Item>

        <Menu.Item as={Link} to='/volume' onClick={this.handleItemClick}>
          <Icon name='folder outline' />
          {hideNav ? '' : 'Volumes'}
        </Menu.Item>
        <Divider />
        <Menu.Item as={Link} to='/resources' onClick={this.handleItemClick}>
          <Icon name='microchip' />
          {hideNav ? '' : 'CloudBender™'}
        </Menu.Item>
        <Menu.Item as={Link} to='/projects' onClick={this.handleItemClick}>
          <Icon name='group' />
          {hideNav ? '' : 'Projects'}
        </Menu.Item>

        <Menu.Item as={Link} to='/archive/jobs' onClick={this.handleItemClick}>
          <Icon name='archive' />
          {hideNav ? '' : 'Archive'}
        </Menu.Item>
        <Menu.Item
          as={Link}
          to='/account/billing'
          onClick={this.handleItemClick}
        >
          <Icon name='payment' />
          {hideNav ? '' : 'Billing'}
        </Menu.Item>
        <Menu.Item
          as={Link}
          to='/account/settings'
          onClick={this.handleItemClick}
        >
          <Icon name='setting' />
          {hideNav ? '' : 'Settings'}
        </Menu.Item>
      </Menu>
    ) : null;
  }
}
function mapStateToProps({ user, session }) {
  return {
    user,
    session,
  };
}

export default connect(mapStateToProps)(SideBar);
