import _ from 'lodash';
import {
  FETCH_PAYMENT_METHODS,
  CLEAR_PAYMENT_METHODS,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS:
      newState = action.payload.sort((a, b) => {
        const brandA = a.card.brand;
        const brandB = b.card.brand;
        const yearA = a.card.exp_year;
        const yearB = b.card.exp_year;
        const monthA = a.card.exp_month;
        const monthB = b.card.exp_month;
        const last4A = a.card.last4;
        const last4B = b.card.last4;
        const createdDateA = a.created;
        const createdDateB = b.created;
        if (brandA < brandB) {
          return 1;
        } else if (brandA > brandB) {
          return -1;
        } else {
          if (yearA < yearB) {
            return 1;
          }
          if (yearA > yearB) {
            return -1;
          }
          // names must be equal
          else {
            if (monthA < monthB) {
              return -1;
            }
            if (monthA > monthB) {
              return 1;
            } else {
              if (last4A < last4B) {
                return 1;
              } else if (last4A > last4B) {
                return -1;
              } else {
                return createdDateA - createdDateB;
              }
            }
          }
        }
      });
      return newState;
    case CREATE_PAYMENT_METHOD:
      return [...state, action.payload];
    case UPDATE_PAYMENT_METHOD:
      newState = [...state];
      _.remove(newState, (pm) => {
        return pm.id === action.payload.id;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const brandA = a.card.brand;
        const brandB = b.card.brand;
        const yearA = a.card.exp_year;
        const yearB = b.card.exp_year;
        const monthA = a.card.exp_month;
        const monthB = b.card.exp_month;
        const last4A = a.card.last4;
        const last4B = b.card.last4;
        const createdDateA = a.created;
        const createdDateB = b.created;
        if (brandA < brandB) {
          return 1;
        } else if (brandA > brandB) {
          return -1;
        } else {
          if (yearA < yearB) {
            return 1;
          }
          if (yearA > yearB) {
            return -1;
          }
          // names must be equal
          else {
            if (monthA < monthB) {
              return -1;
            }
            if (monthA > monthB) {
              return 1;
            } else {
              if (last4A < last4B) {
                return 1;
              } else if (last4A > last4B) {
                return -1;
              } else {
                return createdDateA - createdDateB;
              }
            }
          }
        }
      });
      return newState;
    case REMOVE_PAYMENT_METHOD:
      newState = [...state];
      _.remove(newState, (pm) => {
        return pm.id === action.payload;
      });
      return newState;
    case CLEAR_PAYMENT_METHODS:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
