import userReducer from './userReducer';
import jobsReducer from './jobsReducer';
import gpuClassesReducer from './gpuClassesReducer';
import sessionReducer from './sessionReducer';
import paymentMethodsReducer from './paymentMethodsReducer';
import paymentsReducer from './paymentsReducer';
import errorMessageReducer from './errorMessageReducer';
import projectCredentialsReducer from './projectCredentialsReducer';
import projectSecretsReducer from './projectSecretsReducer';
import archivedJobsReducer from './archivedJobsReducer';
import datasetReducer from './datasetReducer';
import modelReducer from './modelReducer';
import publicDatasetReducer from './publicDatasetReducer';
import environmentsReducer from './environmentsReducer';
import projectsReducer from './projectsReducer';
import userCredentialsReducer from './userCredentialsReducer';
import providersReducer from './providersReducer';
import providerReducer from './providerReducer';
import rigsReducer from './rigsReducer';
import datastoresReducer from './datastoresReducer';
import projectDatastoresReducer from './projectDatastoresReducer';
import dataConnectorsReducer from './dataConnectorsReducer';
import projectDataConnectorsReducer from './projectDataConnectorsReducer';
import servicesReducer from './servicesReducer';
import projectServicesReducer from './projectServicesReducer';
import regionsReducer from './regionsReducer';
import regionReducer from './regionReducer';
import deviceConfigsReducer from './deviceConfigsReducer';
import subscriptionPlansReducer from './subscriptionPlansReducer';
import subscriptionsReducer from './subscriptionsReducer';
import checkpointReducer from './checkpointReducer';
import publicCheckpointReducer from './publicCheckpointReducer';
import volumesReducer from './volumesReducer';

const reducers = {
  user: userReducer,
  jobs: jobsReducer,
  gpuClasses: gpuClassesReducer,
  session: sessionReducer,
  paymentMethods: paymentMethodsReducer,
  payments: paymentsReducer,
  errorMessage: errorMessageReducer,
  projectCredentials: projectCredentialsReducer,
  projectSecrets: projectSecretsReducer,
  archivedJobs: archivedJobsReducer,
  datasets: datasetReducer,
  models: modelReducer,
  publicDatasets: publicDatasetReducer,
  environments: environmentsReducer,
  projects: projectsReducer,
  userCredentials: userCredentialsReducer,
  providers: providersReducer,
  provider: providerReducer,
  regions: regionsReducer,
  region: regionReducer,
  rigs: rigsReducer,
  datastores: datastoresReducer,
  projectDatastores: projectDatastoresReducer,
  dataConnectors: dataConnectorsReducer,
  projectConnectors: projectDataConnectorsReducer,
  services: servicesReducer,
  projectServices: projectServicesReducer,
  deviceConfigs: deviceConfigsReducer,
  subscriptionPlans: subscriptionPlansReducer,
  subscriptions: subscriptionsReducer,
  checkpoints: checkpointReducer,
  publicCheckpoints: publicCheckpointReducer,
  volumes: volumesReducer,
};

export default reducers;
