const PROVIDER_TYPES = {
  PHYSICAL: {
    value: 'physical',
    label: 'Physical (On-Prem)',
  },
  GCP: {
    value: 'gcp',
    label: 'Google Cloud',
  },
  AWS: {
    value: 'aws',
    label: 'AWS',
  },
  AZURE: {
    value: 'azure',
    label: 'Azure',
  },
};
export default PROVIDER_TYPES;
