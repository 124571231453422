import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Box, Typography, Button, Stack, Chip } from '@mui/material';
import CheckpointDrawer from '../../../../../shared/CheckpointDrawer';

export default function DeviceConfigCheckpointField({
  value,
  setValue,
  setChanged,
  edit,
}) {
  const [opened, setOpened] = useState(false);

  return (
    <Box>
      <Typography variant='body1'>
        <b>Checkpoints</b>
      </Typography>
      <Stack direction='column' spacing={2}>
        <Stack direction='column' spacing={1}>
          {value.map((value) => {
            return (
              <Chip
                variant='outlined'
                key={value.id}
                label={`${value.as}`}
                sx={{ fontSize: '1rem' }}
              />
            );
          })}
        </Stack>
        <Button
          sx={{ maxWidth: '120px' }}
          variant='outlined'
          onClick={() => setOpened(true)}
          disabled={edit}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <AddCircleOutlineIcon />
            <Typography variant='button' display='block' gutterBottom>
              Add/Edit
            </Typography>
          </Stack>
        </Button>
      </Stack>
      <CheckpointDrawer
        open={opened}
        initialValues={value}
        handleClose={() => setOpened(false)}
        setValue={(values) => {
          setChanged();
          setValue(values);
        }}
      />
    </Box>
  );
}
