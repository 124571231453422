import React from 'react';
import {
  // Button,
  Stack,
  // Typography
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function ProjectDataConnectorsGridToolbar({ project, disabled }) {
  return (
    <GridToolbarContainer>
      <Stack direction='row' spacing={1}>
        {/* <Button
          color='brand'
          variant='outlined'
          disabled={disabled}
          onClick={() =>
            history.push({
              pathname: `/project/details/${project.id}/member/edit`,
            })
          }
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <AddCircleOutlineIcon />
            <Typography variant='button' display='block' gutterBottom>
              Add
            </Typography>
          </Stack>
        </Button> */}

        <GridToolbarColumnsButton variant='outlined' />
        <GridToolbarFilterButton variant='outlined' />
      </Stack>
    </GridToolbarContainer>
  );
}
export default ProjectDataConnectorsGridToolbar;
