import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

const email_re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function MemberEmailField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (!email_re.test(newValue)) {
      setError('Must be a valid email');
    } else if (newValue === '') {
      setError('Email cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Email'
          id='email'
          aria-describedby='email-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='email-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='email-helper-text'>
            Enter the user's proxiML account email
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
