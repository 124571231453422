import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Container,
  Header,
  Segment,
  Form,
  Button,
  Icon,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import RequiredFields from './RequiredFields';
import EnvironmentFields from './EnvironmentFields';
import DataFields from './DataFields';
import ModelFields from './ModelFields';
import WorkerFields from './WorkerFields';
import EndpointFields from './EndpointFields';
import JOB_TYPES from '../types';
import toTitleCase from '../../../util/toTitleCase';

class JobForm extends Component {
  state = {
    pristine: true,
    form_error: true,
    type: this.props.type,
    resources: this.props.jobRequest.resources,
    resources_error: false,
    environment: this.props.jobRequest.environment,
    environment_error: false,
    data: this.props.jobRequest.data,
    data_error: false,
    model: this.props.jobRequest.model,
    model_error: false,
    endpoint: this.props.jobRequest.endpoint,
    endpoint_error: false,
    workers: this.props.jobRequest.workers || [],
    workers_error: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      resources_error,
      environment_error,
      data_error,
      model_error,
      workers_error,
      endpoint_error,
    } = this.state;
    const form_error =
      resources_error ||
      environment_error ||
      data_error ||
      model_error ||
      workers_error ||
      endpoint_error;
    if (prevState.form_error !== form_error) {
      this.setState({ form_error });
    }
  }

  handleChange = (type, values, error) => {
    this.setState({
      pristine: false,
      [type]: values,
      [`${type}_error`]: error,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.onSubmit({
      name: this.state.resources.job_name,
      resources: { ...this.state.resources },
      environment: { ...this.state.environment },
      data: { ...this.state.data },
      model: { ...this.state.model },
      endpoint: { ...this.state.endpoint },
      workers: [...this.state.workers],
      type: this.state.type,
    });
  };

  render() {
    const {
      pristine,
      form_error,
      type,
      resources,
      environment,
      data,
      model,
      endpoint,
      workers,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Container>
              <Grid.Row>
                <Segment>
                  <Header as='h2' textAlign='center'>
                    New {toTitleCase(type)} Job
                  </Header>

                  <Form onSubmit={this.handleSubmit}>
                    <RequiredFields
                      initialValues={resources}
                      type={type}
                      onChange={this.handleChange}
                    />
                    {![JOB_TYPES.ENDPOINT.value].includes(type) ? (
                      <DataFields
                        initialValues={data}
                        type={type}
                        onChange={this.handleChange}
                      />
                    ) : undefined}
                    <ModelFields
                      initialValues={model}
                      type={type}
                      onChange={this.handleChange}
                    />
                    {[
                      JOB_TYPES.TRAINING.value,
                      JOB_TYPES.INFERENCE.value,
                    ].includes(type) ? (
                      <WorkerFields
                        initialValues={workers}
                        type={type}
                        onChange={this.handleChange}
                      />
                    ) : undefined}
                    {[JOB_TYPES.ENDPOINT.value].includes(type) ? (
                      <EndpointFields
                        initialValues={endpoint}
                        type={type}
                        onChange={this.handleChange}
                      />
                    ) : undefined}
                    <EnvironmentFields
                      initialValues={environment}
                      type={type}
                      onChange={this.handleChange}
                    />
                    <br />
                    <Grid verticalAlign='middle'>
                      <Grid.Row>
                        <Grid.Column floated='left' width={3}>
                          <Link to={type ? `/jobs/${type}` : '/dashboard'}>
                            <Button
                              icon
                              floated='left'
                              secondary
                              labelPosition='left'
                            >
                              <Icon name='close' />
                              Cancel
                            </Button>
                          </Link>
                        </Grid.Column>

                        <Grid.Column floated='right' width={3}>
                          <Button
                            icon
                            floated='right'
                            primary
                            disabled={pristine || form_error}
                            labelPosition='right'
                          >
                            <Icon name='right arrow' />
                            Next
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </Grid.Row>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(JobForm);
