import React from 'react';
import { Button, Stack, Typography, Tooltip } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useHistory } from 'react-router-dom';

function DataConnectorGridToolbar({ region, disabled }) {
  const history = useHistory();

  return (
    <GridToolbarContainer>
      <Stack direction='row' spacing={1}>
        <Tooltip
          title={
            disabled
              ? 'Upgrade your feature level to use Regional Data Connectors'
              : ''
          }
        >
          <span>
            <Button
              color='brand'
              variant='outlined'
              disabled={disabled}
              onClick={() =>
                history.push({
                  pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/data_connector/edit`,
                })
              }
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <AddCircleOutlineIcon />
                <Typography variant='button' display='block' gutterBottom>
                  Add
                </Typography>
              </Stack>
            </Button>
          </span>
        </Tooltip>
        <GridToolbarColumnsButton variant='outlined' />
        <GridToolbarFilterButton variant='outlined' />
      </Stack>
    </GridToolbarContainer>
  );
}
export default DataConnectorGridToolbar;
