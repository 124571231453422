import { FETCH_ARCHIVED_JOBS, CLEAR_ARCHIVED_JOBS } from './types';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchArchivedJobs =
  (sort_by, count, ascending, last_key_uuid, last_key_sort, update_last_keys) =>
  async (dispatch) => {
    try {
      const res = await api.get('/job/archived', {
        params: {
          sort_by: sort_by,
          count: count * 3,
          ascending,
          last_key_uuid,
          last_key_sort,
        },
      });
      update_last_keys(res.data.last_key_uuid, res.data.last_key_sort);
      dispatch({
        type: FETCH_ARCHIVED_JOBS,
        payload: res.data.jobs,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const clearArchivedJobs = () => ({
  type: CLEAR_ARCHIVED_JOBS,
});
