import React from 'react';

import {
  FormControl,
  FormControlLabel,
  Box,
  FormHelperText,
  Switch,
} from '@mui/material';

export default function OwnedResourcesOnlyField({
  value,
  setValue,
  setChanged,
}) {
  return (
    <Box>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={() => {
                setChanged();
                setValue(!value);
              }}
            />
          }
          label='Owned Resources Only'
        />
        <FormHelperText id='owned-resources-only-helper-text'>
          Restrict project workloads to only run on CloudBender systems that are
          attached to the project owner's account.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
