import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Backdrop,
  CircularProgress,
  Typography,
  Toolbar,
  Stack,
  Button,
  Paper,
  Grid,
  Link,
  Breadcrumbs,
  Badge,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/ContentCopy';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import theme from '../../theme';
import {
  fetchCollections,
  fetchCollection,
  deleteCollection,
  abortExportCollection,
} from '../../actions/collections';
import STATUSES from '../statuses';
import PollEntity from '../shared/PollEntity';
import toNiceTimestamp from '../../util/toNiceTimestamp';
import { clearErrorMessage } from '../../actions/errorMessage';
import ErrorSnackBar from '../shared/ErrorSnackBar';
import THIRD_PARTY_TYPES from '../jobs/JobForm/thirdPartyTypes';
import ConnectDialog from '../shared/ConnectDialog';
import CollectionCopyModal from './CollectionCopyModal';
import CollectionExportModal from './CollectionExportModal';
import toTitleCase from '../../util/toTitleCase';
import ENTITY_TYPES from 'components/shared/entityTypes';
import readableBytes from '../../util/readableBytes';

export default function CollectionsDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const entity_type = location.pathname.split('/')[1];

  const api_error = useSelector((state) => state.errorMessage);
  const collections = useSelector((state) => state[`${entity_type}s`]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [transitioningCollections, setTransitioningCollections] = useState([]);

  const [loading, setLoading] = useState(true);
  const [connectOpen, setConnectOpen] = useState(false);
  const [connectId, setConnectId] = useState(null);
  const [copyOpen, setCopyOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (collections === null) {
        dispatch(fetchCollections(entity_type));
        return;
      }
      setLoading(false);
    })();
  }, [dispatch, collections, entity_type]);

  const removeCollection = useCallback(
    async (id) => {
      setTransitioningCollections((prevTransitioningCollections) => [
        ...prevTransitioningCollections,
        id,
      ]);
      await dispatch(deleteCollection(entity_type, id));
      setTransitioningCollections((prevTransitioningCollections) =>
        prevTransitioningCollections.filter(
          (transitioning_id) => transitioning_id !== id
        )
      );
    },
    [dispatch, entity_type]
  );

  const deleteSelectedCollections = useCallback(
    async (selectedCollections) => {
      const ids = [...selectedCollections];
      setSelectedCollections([]);
      setTransitioningCollections((prevTransitioningCollections) => [
        ...prevTransitioningCollections,
        ...ids,
      ]);
      for (let i = 0; i < ids.length; i++) {
        // parallel actions put a lot of stress on the API
        await dispatch(deleteCollection(entity_type, ids[i]));
      }
      setTransitioningCollections((prevTransitioningCollections) =>
        prevTransitioningCollections.filter((id) => !ids.includes(id))
      );
    },
    [dispatch, entity_type]
  );

  const renderStatus = useCallback(
    (params) => {
      if (transitioningCollections.includes(params.id)) {
        return <CircularProgress size={20} />;
      }
      switch (params.row.status) {
        case STATUSES.READY:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <CheckCircleIcon color='success' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        case STATUSES.FAILED:
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <ErrorOutlineIcon color='error' />
              <Typography variant='body2'>{params.row.status}</Typography>
            </Stack>
          );
        default:
          return (
            <>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CircularProgress size={20} />
                <Typography variant='body2'>{params.row.status}</Typography>
              </Stack>
              <PollEntity
                poll_function={() =>
                  fetchCollection(entity_type, params.row.id)
                }
                interval={10000}
              />
            </>
          );
      }
    },
    [transitioningCollections, entity_type]
  );

  const renderName = useCallback(
    (params) => {
      return (
        <Link
          component={RouterLink}
          to={`/${entity_type}/details/${params.id}`}
        >
          {params.row.name}
        </Link>
      );
    },
    [entity_type]
  );

  const renderCreatedDate = useCallback((params) => {
    return toNiceTimestamp(params.row.createdAt, true);
  }, []);

  const renderSize = useCallback(
    (params) => {
      return entity_type === ENTITY_TYPES.VOLUME
        ? params.row.capacity
        : readableBytes(params.row.used_size);
    },
    [entity_type]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        renderCell: renderStatus,
        minWidth: 150,
        flex: 0.25,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        type: 'string',
        renderCell: renderCreatedDate,
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: entity_type === ENTITY_TYPES.VOLUME ? 'capacity' : 'size',
        headerName: entity_type === ENTITY_TYPES.VOLUME ? 'Capacity' : 'Size',
        type: 'string',
        renderCell: renderSize,
        minWidth: 50,
        flex: 0.25,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          const actions = [];
          if (
            (params.row.source_type === THIRD_PARTY_TYPES.LOCAL.value &&
              params.row.status === STATUSES.DOWNLOADING &&
              !params.row.size) ||
            (params.row.output_type === THIRD_PARTY_TYPES.LOCAL.value &&
              params.row.status === STATUSES.EXPORTING)
          ) {
            const client_connected =
              params.row.vpn &&
              params.row.vpn.client &&
              params.row.vpn.client.connected;
            actions.push(
              <GridActionsCellItem
                icon={
                  <Badge color='brand' badgeContent={client_connected ? 0 : 1}>
                    <LinkOutlinedIcon />
                  </Badge>
                }
                label='Connect'
                onClick={() => {
                  setConnectId(params.id);
                  setConnectOpen(true);
                }}
              />,
              <GridActionsCellItem
                icon={
                  <Badge color='brand' badgeContent={client_connected ? 0 : 1}>
                    <LinkOutlinedIcon />
                  </Badge>
                }
                label='Connect'
                onClick={() => {
                  setConnectId(params.id);
                  setConnectOpen(true);
                }}
                showInMenu
              />
            );
          }
          if (params.row.status === STATUSES.EXPORTING) {
            actions.push(
              <GridActionsCellItem
                icon={<CancelOutlinedIcon />}
                label='Cancel Export'
                onClick={() => {
                  dispatch(abortExportCollection(entity_type, params.id));
                }}
                showInMenu
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<FeedOutlinedIcon />}
              label='View Logs'
              onClick={() => {
                history.push(`/${entity_type}/logs/${params.id}`);
              }}
              showInMenu
            />,
            // <GridActionsCellItem
            //   icon={<QueryStatsIcon />}
            //   label='Show Telemetry Info'
            //   onClick={() => setTelemetryInfoOpen(true)}
            //   showInMenu
            // />,
            // <GridActionsCellItem
            //   icon={<UpdateIcon />}
            //   label='Update Stats'
            //   onClick={updateNodeStatistics(params.row)}
            //   showInMenu
            // />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Remove'
              onClick={() => removeCollection(entity_type, params.id)}
              showInMenu
            />
          );
          return actions;
        },
      },
    ],
    [
      renderStatus,
      renderCreatedDate,
      renderSize,
      history,
      renderName,
      removeCollection,
      dispatch,
      entity_type,
      //   viewDetails,
    ]
  );

  if (loading) {
    return (
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        </ThemeProvider>
      </ScopedCssBaseline>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={`/dashboard`}
            >
              Home
            </Link>
            <Typography color='text.primary'>
              {toTitleCase(entity_type)}s
            </Typography>
          </Breadcrumbs>
          <br />
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={1}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <Typography variant='h4'>
                        {toTitleCase(entity_type)}s
                      </Typography>
                      <Button
                        color='brand'
                        onClick={() => history.push(`/${entity_type}/edit`)}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <AddCircleOutlineIcon />
                          <Typography variant='button' display='block'>
                            Create
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        onClick={() => setCopyOpen(true)}
                        disabled={Boolean(selectedCollections.length !== 1)}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <CopyIcon />
                          <Typography variant='button' display='block'>
                            Copy
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: `/${entity_type}/edit`,
                            search: `?id=${selectedCollections[0]}`,
                          })
                        }
                        disabled={Boolean(selectedCollections.length !== 1)}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <EditIcon />
                          <Typography variant='button' display='block'>
                            Edit
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        onClick={() => setExportOpen(true)}
                        disabled={Boolean(selectedCollections.length !== 1)}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <FileDownloadOutlinedIcon />
                          <Typography variant='button' display='block'>
                            Export
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        onClick={() => {
                          deleteSelectedCollections(selectedCollections);
                        }}
                        disabled={!Boolean(selectedCollections.length)}
                      >
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <DeleteIcon />
                          <Typography variant='button' display='block'>
                            Delete
                          </Typography>
                        </Stack>
                      </Button>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        autoHeight
                        checkboxSelection
                        getRowId={(row) => row.id}
                        columns={columns}
                        rows={collections}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectedCollections(newSelectionModel);
                        }}
                        // onRowSelectionModelChange={(newSelectionModel) => {
                        //   setSelectedCollections(newSelectionModel);
                        // }}
                        // rowSelectionModel={selectedCollections}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
          <ConnectDialog
            open={connectOpen}
            handleClose={() => setConnectOpen(false)}
            type={entity_type}
            id={connectId}
          />
          <CollectionCopyModal
            entity_type={entity_type}
            open={copyOpen}
            handleClose={() => setCopyOpen(false)}
            collection={collections.find(
              (collection) => collection.id === selectedCollections[0]
            )}
          />
          <CollectionExportModal
            entity_type={entity_type}
            open={exportOpen}
            handleClose={() => setExportOpen(false)}
            collection={collections.find(
              (collection) => collection.id === selectedCollections[0]
            )}
          />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
