import React, { useState } from 'react';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Collapse,
} from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function CollectionTreeLevel({
  name,
  count,
  size,
  contents,
  pl,
}) {
  const [expanded, setExpanded] = useState(name === '/' ? true : false);

  if (contents.length) {
    return (
      <>
        <ListItemButton
          onClick={contents.length ? () => setExpanded(!expanded) : undefined}
          sx={{ pl: pl }}
        >
          <ListItemAvatar>
            <Avatar>{expanded ? <FolderOpenIcon /> : <FolderIcon />}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${name}`}
            secondary={`Item Count ${count} - Size ${size}`}
          />
          {contents.length ? (
            expanded ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : undefined}
        </ListItemButton>
        {contents.length ? (
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {contents.map((item) => {
                const { name, count, size, contents = [] } = item;
                return (
                  <CollectionTreeLevel
                    key={name}
                    name={name}
                    count={count}
                    size={size}
                    contents={contents}
                    pl={pl + 2}
                  />
                );
              })}
            </List>
          </Collapse>
        ) : undefined}
      </>
    );
  }

  return (
    <ListItem sx={{ pl: pl }}>
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${name}`}
        secondary={`Item Count ${count} - Size ${size}`}
      />
    </ListItem>
  );
}
