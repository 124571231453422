import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';
import DATASTORE_TYPES from 'components/shared/datastoreTypes';

export default function DatastoreUriField({
  value,
  type,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    switch (type) {
      case DATASTORE_TYPES.NFS:
        if (
          /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
            newValue
          ) ||
          /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(
            newValue
          )
        ) {
          setError(false);
        } else {
          setError(`Invalid URI for datastore type ${type}`);
        }
        break;
      case DATASTORE_TYPES.SMB:
        if (
          /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
            newValue
          ) ||
          /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(
            newValue
          )
        ) {
          setError(false);
        } else {
          setError(`Invalid URI for datastore type ${type}`);
        }
        break;
      default:
        setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Address/URI'
          id='uri'
          aria-describedby='uri-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
          disabled={edit}
        />
        {error ? (
          <FormHelperText id='uri-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='uri-helper-text'>
            Enter the address/URI of the datastore
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
