import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import history from '../history';
import SideBar from './SideBar';

class ProtectedRoute extends Component {
  renderContent(props, ProtectedComponent) {
    if (!this.props.user) {
      return (
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column textAlign='center'>
              You must login to access this page.
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    if (this.props.user.status === 'approved') {
      return (
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column largeScreen={2} computer={4} tablet={4} mobile={2}>
              <SideBar />
            </Grid.Column>
            <Grid.Column largeScreen={14} computer={12} tablet={12} mobile={14}>
              <ProtectedComponent {...props} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else {
      history.push('/');
      return <div>Redirecting...</div>;
    }
  }

  render() {
    const { component: ProtectedComponent, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => this.renderContent(props, ProtectedComponent)}
      />
    );
  }
}

function mapStateToProps({ user, session }) {
  return {
    user,
    session,
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
