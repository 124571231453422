import _ from 'lodash';
import {
  FETCH_MODELS,
  UPDATE_MODEL,
  DELETE_MODEL,
  CREATE_MODEL,
} from './types';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const fetchModels = () => async (dispatch) => {
  const project_uuid = store.getState().user.selected_project;
  try {
    let res = await api.get('/model', { params: { project_uuid } });
    dispatch({
      type: FETCH_MODELS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateModel = (model_uuid, name) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.patch(
      `/model/${model_uuid}`,
      { name: name },
      { params: { project_uuid } }
    );
    dispatch({
      type: UPDATE_MODEL,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteModel = (model_uuid) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    await api.delete(`/model/${model_uuid}`, { params: { project_uuid } });
    dispatch({
      type: DELETE_MODEL,
      payload: model_uuid,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createModel = (values) => async (dispatch) => {
  try {
    if (!values.project_uuid) {
      const project_uuid = store.getState().user.selected_project;
      values.project_uuid = project_uuid;
    }
    const res = await api.post('/model', values);
    if (res.data.project_uuid !== store.getState().user.selected_project) {
      return;
    }
    dispatch({
      type: CREATE_MODEL,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchModel = (model_uuid) => async (dispatch) => {
  try {
    const model = await getModel(model_uuid);
    if (!model || _.isEmpty(model)) {
      dispatch({ type: DELETE_MODEL, payload: model_uuid });
    } else {
      dispatch({
        type: UPDATE_MODEL,
        payload: model,
      });
    }
  } catch (error) {
    dispatch(setErrorMessage(error));
    throw error;
  }
};

export const getModel = async (model_uuid) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/model/${model_uuid}`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return;
    }
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const getModelDetails = async (model_uuid) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/model/${model_uuid}/details`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const exportModel = (model_uuid, values) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.post(`/model/${model_uuid}/export`, values, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_MODEL,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const abortExportModel = (model_uuid) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.delete(`/model/${model_uuid}/export`, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_MODEL,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
