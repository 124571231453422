import _ from 'lodash';
import {
  FETCH_VOLUMES,
  UPDATE_VOLUME,
  DELETE_VOLUME,
  CREATE_VOLUME,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import store from '../store';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchVolumes = () => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;

    let res = await api.get('/volume', { params: { project_uuid } });
    dispatch({
      type: FETCH_VOLUMES,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const updateVolume = (id, name) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.patch(
      `/volume/${id}`,
      { name: name },
      { params: { project_uuid } }
    );
    dispatch({
      type: UPDATE_VOLUME,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const deleteVolume = (id) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    await api.delete(`/volume/${id}`, {
      params: { project_uuid },
    });
    dispatch({
      type: DELETE_VOLUME,
      payload: id,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const createVolume = (values) => async (dispatch) => {
  try {
    if (!values.project_uuid) {
      const project_uuid = store.getState().user.selected_project;
      values.project_uuid = project_uuid;
    }
    const res = await api.post('/volume', values);
    if (res.data.project_uuid !== store.getState().user.selected_project) {
      return;
    }
    dispatch({
      type: CREATE_VOLUME,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const exportVolume = (id, values) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.post(`/volume/${id}/export`, values, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_VOLUME,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const abortExportVolume = (id) => async (dispatch) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.delete(`/volume/${id}/export`, {
      params: { project_uuid },
    });
    dispatch({
      type: UPDATE_VOLUME,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};

export const fetchVolume = (id) => async (dispatch) => {
  try {
    const volume = await getVolume(id);
    if (!volume || _.isEmpty(volume)) {
      dispatch({ type: DELETE_VOLUME, payload: id });
    } else {
      dispatch({
        type: UPDATE_VOLUME,
        payload: volume,
      });
    }
  } catch (error) {
    dispatch(setErrorMessage(error));
    throw error;
  }
};

export const getVolume = async (id) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/volume/${id}`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return;
    }
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};

export const getVolumeDetails = async (id) => {
  try {
    const project_uuid = store.getState().user.selected_project;
    const res = await api.get(`/volume/${id}/details`, {
      params: { project_uuid },
    });
    return res.data;
  } catch (error) {
    const message = getMessageFromApiError(error);
    throw new Error(`API Error - ${message}`);
  }
};
