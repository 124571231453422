import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
  CircularProgress,
  Alert,
  AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createRig, updateRig } from '../../../../../actions/rigs';
import api from '../../../../../apis/api';
import GCPQuotas from '../../../RegionForm/GcpRegionForm/GCPQuotas';
import NodeNameField from '../Shared/NodeNameField';
import ResourceTypeField from './ResourceTypeField';
import RIG_SERVICES from '../../Nodes/rigServices';
import StorageInstanceClassField from '../../../RegionForm/Shared/StorageInstanceClassField';
import ComputeInstanceClassField from './ComputeInstanceClassField';

export default function GCPNodeForm({ region, rig, service, type }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState(rig?.friendly_name || '');
  const [resource_type_id, setResourceType] = useState(
    rig?.resource_type_id || ''
  );
  const [instance_class, setInstanceClass] = useState(
    rig?.instance_class || ''
  );
  const [providerMetadata, setProviderMetadata] = useState(undefined);
  const [apiError, setApiError] = useState(undefined);
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    (async () => {
      if (region) {
        try {
          const resp = await api.get(
            `/provider/${region.provider_uuid}/metadata`
          );
          setProviderMetadata(resp.data);
        } catch (error) {
          if (error.response.status === 403) {
            setApiError({
              title: `Error Accessing ${region.name} Region Details`,
              message:
                'The service account credentials provided was not able to access the region data for your project.  Ensure that the provided service account is configured as a Compute Admin and try again.',
            });
          } else {
            setApiError({
              title: 'Error',
              message: `An unknown error occurred, please contact support at support@${process.env.REACT_APP_DOMAIN_SUFFIX}`,
            });
          }
        }
      }
    })();
  }, [region]);

  if (!region) {
    history.push('/resources');
    return <div>Redirecting...</div>;
  }

  const handleSubmit = async () => {
    setLoading(true);
    const config = Object.fromEntries(
      Object.entries({
        upnp_enabled: false,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    const payload = Object.fromEntries(
      Object.entries({
        service,
        resource_type_id,
        instance_class,
        friendly_name: name,
        type,
        config,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      if (rig) {
        await dispatch(
          updateRig(
            region.provider_uuid,
            region.region_uuid,
            rig.rig_uuid,
            payload
          )
        );
      } else {
        await dispatch(
          createRig(region.provider_uuid, region.region_uuid, payload)
        );
      }
      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/dashboard`,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ display: 'flex', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>
                  {rig
                    ? `Edit ${rig.friendly_name}`
                    : `Create New ${region.name} ${_.camelCase(service)} Node`}
                </Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
        {apiError ? (
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Alert severity='error'>
                <AlertTitle>{apiError.title}</AlertTitle>
                {apiError.message}
              </Alert>
            </Box>
          </Grid>
        ) : (
          <>
            {providerMetadata ? (
              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Box
                    padding='15px'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant='h6'>General</Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <NodeNameField
                          value={name}
                          error={nameError}
                          setValue={setName}
                          setError={setNameError}
                          provider_type={region.provider_type}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>

                      {service === RIG_SERVICES.STORAGE ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <StorageInstanceClassField
                            value={instance_class}
                            setValue={setInstanceClass}
                            setChanged={() => setPristine(false)}
                          />
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12} md={12} lg={12}>
                            <ResourceTypeField
                              value={resource_type_id}
                              setValue={setResourceType}
                              setChanged={() => setPristine(false)}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <ComputeInstanceClassField
                              value={instance_class}
                              resource_type_id={resource_type_id}
                              setValue={setInstanceClass}
                              setChanged={() => setPristine(false)}
                            />
                          </Grid>
                        </>
                      )}

                      {/* <Grid container spacing={2} item xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant='h6'>Configuration</Typography>
                        </Grid>
                        
                      </Grid> */}
                    </Grid>
                  </Box>
                  <GCPQuotas
                    providerMetadata={providerMetadata}
                    selected_region={name}
                  />
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  minHeight='300px'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack spacing={2} direction='column'>
                    <CircularProgress />
                    <Typography variant='body'>
                      Loading {region.name} quotas
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
              <Toolbar>
                <Stack spacing={2} direction='row'>
                  <LoadingButton
                    variant='contained'
                    color='brand'
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={
                      pristine ||
                      !Boolean(name) ||
                      (service !== RIG_SERVICES.STORAGE &&
                        !Boolean(resource_type_id)) ||
                      !Boolean(instance_class)
                    }
                  >
                    Submit
                  </LoadingButton>

                  <Button
                    variant='outlined'
                    onClick={() => history.goBack()}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Toolbar>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}
