import _ from 'lodash';
import {
  FETCH_JOBS,
  CREATE_JOB,
  FETCH_JOB,
  REMOVE_JOB,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_JOBS:
      newState = action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case CREATE_JOB:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case FETCH_JOB:
      newState = [...(state || [])];
      _.remove(newState, (job) => {
        return job.job_uuid === action.payload.job_uuid;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    case REMOVE_JOB:
      newState = [...(state || [])];
      _.remove(newState, (job) => {
        return job.job_uuid === action.payload;
      });
      newState = newState.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else return 0;
        }
      });
      return newState;
    default:
      return state;
  }
}
export default reducer;
