import {
  FETCH_DATASTORE,
  FETCH_DATASTORES,
  DELETE_DATASTORE,
  CREATE_DATASTORE,
  CLEAR_DATASTORES,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchDatastores =
  (provider_uuid, region_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/datastore`
      );
      dispatch({
        type: FETCH_DATASTORES,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const fetchDatastore =
  (provider_uuid, region_uuid, store_id) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/datastore/${store_id}`
      );
      dispatch({
        type: FETCH_DATASTORE,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const updateDatastore =
  (provider_uuid, region_uuid, store_id, data) => async (dispatch) => {
    try {
      const res = await api.patch(
        `/provider/${provider_uuid}/region/${region_uuid}/datastore/${store_id}`,
        data
      );
      dispatch({
        type: FETCH_DATASTORE,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const deleteDatastore =
  (provider_uuid, region_uuid, store_id) => async (dispatch) => {
    try {
      await api.delete(
        `/provider/${provider_uuid}/region/${region_uuid}/datastore/${store_id}`
      );
      dispatch({
        type: DELETE_DATASTORE,
        payload: store_id,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const createDatastore =
  (provider_uuid, region_uuid, values) => async (dispatch) => {
    try {
      const res = await api.post(
        `/provider/${provider_uuid}/region/${region_uuid}/datastore`,
        values
      );
      dispatch({
        type: CREATE_DATASTORE,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const clearDatastores = () => ({
  type: CLEAR_DATASTORES,
});
