import React, { Component } from 'react';
import { Form, Button, Radio, Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { submitJob } from '../../actions/jobs';
import { createModel } from '../../actions/models';
import { createDataset } from '../../actions/datasets';
import { createCheckpoint } from '../../actions/checkpoints';
import WorkerFields from './JobForm/WorkerFields';
import RequiredFields from './JobForm/RequiredFields';
import DataFields from './JobForm/DataFields';
import JOB_TYPES from './types';
import history from '../../history';
import EnvironmentFields from './JobForm/EnvironmentFields';
import { fetchEnvironments } from '../../actions/environments';
import { fetchCredentials } from '../../actions/projectCredentials';
import ModelFields from './JobForm/ModelFields';
import toTitleCase from '../../util/toTitleCase';

class JobCopyForm extends Component {
  state = {
    pristine: true,
    submitting: false,
    name_error: false,
    copy_type: 'data',
    name: `${this.props.job.name} copy`,
    resources: {},
    resources_error: false,
    data: this.props.job.data,
    data_error: false,
    model: this.props.job.model,
    model_error: false,
    workers: [],
    workers_error: false,
    environment: this.props.job.environment,
    environment_error: false,
    form_error: true,
    trainml_type: 'model',
    save_model: 'model',
  };

  componentDidMount() {
    this.props.fetchEnvironments();
    this.props.fetchCredentials();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      resources_error,
      workers_error,
      data_error,
      model_error,
      environment_error,
    } = this.state;
    const form_error =
      resources_error ||
      workers_error ||
      data_error ||
      model_error ||
      environment_error;
    if (prevState.form_error !== form_error) {
      this.setState({ form_error });
    }
  }

  handleChange = (e, { name, value }) => {
    switch (name) {
      case 'name':
        this.setState({
          [name]: value,
          name_error: value === '' || /[#$<>+%!`&*'"|{}?/\\:=@]/.test(value),
          pristine: false,
        });
        break;
      default:
        this.setState({
          [name]: value,
          pristine: false,
        });
    }
  };

  handleFormChange = (type, values, error) => {
    if (type === 'resources') {
    }
    this.setState({
      pristine: false,
      [type]: values,
      [`${type}_error`]: error,
      name: type === 'resources' ? values.job_name : this.state.name,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      submitting: true,
    });

    switch (this.state.copy_type) {
      case 'config': {
        let new_job = { ...this.props.job };
        new_job.name = this.state.name;
        await this.props.submitJob(new_job);
        break;
      }
      case 'data': {
        await this.props.submitJob({
          name: this.state.name,
          source_job_uuid: this.props.job.job_uuid,
          resources: { ...this.state.resources },
          data: { ...this.state.data },
          environment: { ...this.state.environment },
          model: { ...this.state.model },
        });
        break;
      }
      case 'convert': {
        await this.props.submitJob({
          name: this.state.name,
          type: JOB_TYPES.TRAINING.value,
          source_job_uuid: this.props.job.job_uuid,
          resources: { ...this.state.resources },
          data: { ...this.state.data },
          workers: [...this.state.workers],
          environment: { ...this.state.environment },
          model: { ...this.state.model },
        });
        return;
      }
      case 'trainml': {
        const payload = {
          name: this.state.name,
          source_type: 'trainml',
          source_uri: this.props.job.job_uuid,
          source_options: {
            type: 'job',
            save_model: this.state.save_model === 'model',
          },
        };
        switch (this.state.trainml_type) {
          case 'dataset':
            await this.props.createDataset(payload);
            history.push('/dataset');
            break;
          case 'checkpoint':
            await this.props.createCheckpoint(payload);
            history.push('/checkpoint');
            break;
          case 'model':
            await this.props.createModel(payload);
            history.push('/model');
            break;
          default:
            throw Error('Unknown trainml type');
        }

        return;
      }
      default:
        break;
    }

    this.props.onClose();
  };

  render() {
    const {
      pristine,
      submitting,
      name,
      name_error,
      copy_type,
      form_error,
      trainml_type,
      save_model,
    } = this.state;
    const { job } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className='field'>
          <label>Copy Type</label>
          <Form.Field>
            <Radio
              label='Full (Data and Configuration)'
              name='copy_type'
              value='data'
              checked={copy_type === 'data'}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Configuration Only'
              name='copy_type'
              value='config'
              checked={copy_type === 'config'}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Convert To Training Job'
              name='copy_type'
              value='convert'
              checked={copy_type === 'convert'}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={`Save to ${process.env.REACT_APP_ORG_NAME}`}
              name='copy_type'
              value='trainml'
              checked={copy_type === 'trainml'}
              onChange={this.handleChange}
            />
          </Form.Field>
        </div>

        {copy_type !== 'trainml' ? (
          <>
            <RequiredFields
              onChange={this.handleFormChange}
              disk_min={job.resources.disk_size}
              initialValues={{ ...job.resources, job_name: name }}
              small
            />
            <DataFields
              type={
                copy_type === 'convert'
                  ? JOB_TYPES.TRAINING.value
                  : JOB_TYPES.NOTEBOOK.value
              }
              initialValues={job.data}
              onChange={this.handleFormChange}
            />
            <ModelFields
              type={
                copy_type === 'convert'
                  ? JOB_TYPES.TRAINING.value
                  : JOB_TYPES.NOTEBOOK.value
              }
              copy={copy_type !== 'config'}
              initialValues={job.model}
              onChange={this.handleFormChange}
            />
            {copy_type === 'convert' ? (
              <WorkerFields
                type={JOB_TYPES.TRAINING.value}
                onChange={this.handleFormChange}
              />
            ) : undefined}
            <EnvironmentFields
              type={
                copy_type === 'convert'
                  ? JOB_TYPES.TRAINING.value
                  : JOB_TYPES.NOTEBOOK.value
              }
              initialValues={job.environment}
              onChange={this.handleFormChange}
            />
          </>
        ) : (
          <Segment attached>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    name='name'
                    label={`New ${toTitleCase(trainml_type)} Name`}
                    value={name}
                    required
                    onChange={this.handleChange}
                    error={
                      name_error
                        ? {
                            content: `${
                              copy_type === 'config' ? 'Job' : 'Model'
                            } names cannot be blank and cannot include the following characters: #$<>+%!\`&*'"|{}?/\\:=@`,
                            pointing: 'above',
                          }
                        : undefined
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Dropdown
                    name='trainml_type'
                    label='Type'
                    onChange={this.handleChange}
                    selection
                    fluid
                    clearable
                    options={[
                      {
                        key: 'checkpoint',
                        value: 'checkpoint',
                        text: 'Checkpoint',
                      },
                      { key: 'dataset', value: 'dataset', text: 'Dataset' },
                      { key: 'model', value: 'model', text: 'Model' },
                    ]}
                    value={trainml_type}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Dropdown
                    name='save_model'
                    label='Save Directory'
                    onChange={this.handleChange}
                    selection
                    fluid
                    clearable
                    options={[
                      { key: 'model', value: 'model', text: 'Models' },
                      { key: 'output', value: 'output', text: 'Output' },
                    ]}
                    value={save_model}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
        <br />
        <Button
          floated='left'
          secondary
          onClick={(e) => {
            e.preventDefault();
            this.props.onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          floated='right'
          primary
          disabled={pristine || name_error || !name || form_error}
          loading={submitting}
          onClick={this.handleSubmit}
        >
          Copy
        </Button>
        <br />
        <br />
      </Form>
    );
  }
}

export default connect(null, {
  submitJob,
  createModel,
  fetchEnvironments,
  fetchCredentials,
  createDataset,
  createCheckpoint,
})(JobCopyForm);
