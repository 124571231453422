import React, { useState } from 'react';

import {
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createRig, updateRig } from '../../../../../actions/rigs';
import NodeNameField from '../Shared/NodeNameField';
import RIG_SERVICES from '../../Nodes/rigServices';
import MinionIdField from './MinionIdField';
import RIG_TYPES from '../../Nodes/rigTypes';
import NodeRegionField from './RegionField';

export default function PhysicalNodeForm({
  region,
  rig,
  service,
  type,
  minion_id,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState(rig?.friendly_name || '');
  const [region_uuid, setRegionUuid] = useState(region?.region_uuid || '');
  const [provider_uuid, setProviderUuid] = useState(
    region?.provider_uuid || ''
  );
  const [minionId, setMinionId] = useState(minion_id || rig?.minion_id || '');
  const [miningEnabled, setMiningEnabled] = useState(
    rig?.mining?.mining_enabled || false
  );
  const [upnpEnabled, setUpnpEnabled] = useState(
    rig ? rig.config.upnp_enabled : type === RIG_TYPES.PERMANENT
  );
  const [nameError, setNameError] = useState(false);
  const [minionIdError, setMinionIdError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (rig) {
        await dispatch(
          updateRig(provider_uuid, region_uuid, rig.rig_uuid, {
            friendly_name: name,
            config: {
              upnp_enabled: upnpEnabled,
            },
            mining: {
              mining_enabled: miningEnabled,
            },
          })
        );
      } else {
        await dispatch(
          createRig(provider_uuid, region_uuid, {
            service,
            minion_id: minionId,
            friendly_name: name,
            type,
            config: {
              upnp_enabled: upnpEnabled,
            },
            mining: {
              mining_enabled: miningEnabled,
            },
          })
        );
      }
      history.push({
        pathname: `/resources/provider/${provider_uuid}/region/${region_uuid}/dashboard`,
      });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Paper sx={{ display: 'flex', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => history.goBack()} disabled={loading}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>
                  {rig
                    ? `Edit ${rig.friendly_name}`
                    : service === RIG_SERVICES.STORAGE
                    ? `Create new ${(region?.name || '') + ' '}Storage Node`
                    : type === RIG_TYPES.DEVICE
                    ? `Create New ${(region?.name || '') + ' '}Device`
                    : `Create New ${(region?.name || '') + ' '}Compute Node`}
                </Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Box
            padding='15px'
            sx={{
              maxWidth: 400,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>General</Typography>
              </Grid>
              {minion_id ? (
                <Grid item xs={12} md={12} lg={12}>
                  <NodeRegionField
                    value={region_uuid}
                    setValue={setRegionUuid}
                    setProvider={setProviderUuid}
                    setChanged={() => setPristine(false)}
                  />
                </Grid>
              ) : undefined}

              <Grid item xs={12} md={12} lg={12}>
                <NodeNameField
                  value={name}
                  error={nameError}
                  provider_type={region.provider_type}
                  setValue={setName}
                  setError={setNameError}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
              {!Boolean(rig) ? (
                <Grid item xs={12} md={12} lg={12}>
                  <MinionIdField
                    value={minionId}
                    error={minionIdError}
                    setValue={setMinionId}
                    setError={setMinionIdError}
                    setChanged={() => setPristine(false)}
                    edit={Boolean(rig)}
                  />
                </Grid>
              ) : undefined}
            </Grid>
            {type !== RIG_TYPES.DEVICE ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant='h6'>Configuration</Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={upnpEnabled}
                          onChange={() => setUpnpEnabled(!upnpEnabled)}
                        />
                      }
                      label='UPnP Enabled'
                    />
                  </Grid>
                </Grid>
                {(rig && rig.service === RIG_SERVICES.COMPUTE) ||
                (service === RIG_SERVICES.COMPUTE &&
                  type === RIG_TYPES.PERMANENT) ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant='h6'>Mining</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={miningEnabled}
                            onChange={() => setMiningEnabled(!miningEnabled)}
                            disabled={!user.wallets || !user.wallets.eth}
                          />
                        }
                        label='Mining Enabled'
                      />
                    </Grid>
                  </Grid>
                ) : undefined}
              </>
            ) : undefined}
          </Box>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Divider />
          <Toolbar>
            <Stack spacing={2} direction='row'>
              <LoadingButton
                variant='contained'
                color='brand'
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  pristine ||
                  Boolean(nameError) ||
                  !Boolean(minionId) ||
                  Boolean(minionIdError)
                }
              >
                Submit
              </LoadingButton>

              <Button
                variant='outlined'
                onClick={() => history.goBack()}
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
      </Grid>
    </Paper>
  );
}
