import React, { Component } from 'react';
import { Grid, Card, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { fetchJobs } from '../actions/jobs';
import { fetchDatasets } from '../actions/datasets';
import { fetchModels } from '../actions/models';
import { fetchCheckpoints } from '../actions/checkpoints';
import { connect } from 'react-redux';
import STATUSES from './statuses';
import JOB_TYPES from './jobs/types';

// maybe use the card component
class Dashboard extends Component {
  state = {
    notebookCount: this.props.jobs.reduce(
      (val, job) => (job.type === JOB_TYPES.NOTEBOOK.value ? val + 1 : val + 0),
      0
    ),
    notebookRunningCount: this.props.jobs.reduce(
      (val, job) =>
        job.type === JOB_TYPES.NOTEBOOK.value && job.status === STATUSES.RUNNING
          ? val + 1
          : val + 0,
      0
    ),
    trainingCount: this.props.jobs.reduce(
      (val, job) => (job.type === JOB_TYPES.TRAINING.value ? val + 1 : val + 0),
      0
    ),
    trainingRunningCount: this.props.jobs.reduce(
      (val, job) =>
        job.type === JOB_TYPES.TRAINING.value && job.status === STATUSES.RUNNING
          ? val + 1
          : val + 0,
      0
    ),
    inferenceCount: this.props.jobs.reduce(
      (val, job) =>
        job.type === JOB_TYPES.INFERENCE.value ? val + 1 : val + 0,
      0
    ),
    inferenceRunningCount: this.props.jobs.reduce(
      (val, job) =>
        job.type === JOB_TYPES.INFERENCE.value &&
        job.status === STATUSES.RUNNING
          ? val + 1
          : val + 0,
      0
    ),
    endpointCount: this.props.jobs.reduce(
      (val, job) => (job.type === JOB_TYPES.ENDPOINT.value ? val + 1 : val + 0),
      0
    ),
    endpointRunningCount: this.props.jobs.reduce(
      (val, job) =>
        job.type === JOB_TYPES.ENDPOINT.value && job.status === STATUSES.RUNNING
          ? val + 1
          : val + 0,
      0
    ),
    datasetCount: this.props.datasets.reduce(
      (val, dataset) => (dataset.status === STATUSES.READY ? val + 1 : val + 0),
      0
    ),
    modelCount: this.props.models.reduce(
      (val, model) => (model.status === STATUSES.READY ? val + 1 : val + 0),
      0
    ),
    checkpointCount: this.props.checkpoints.reduce(
      (val, checkpoint) =>
        checkpoint.status === STATUSES.READY ? val + 1 : val + 0,
      0
    ),
  };
  componentDidMount() {
    this.props.fetchJobs();
    this.props.fetchDatasets();
    this.props.fetchModels();
    this.props.fetchCheckpoints();
  }
  componentDidUpdate(prevProps) {
    if (this.props.jobs) {
      if (!prevProps.jobs || this.props.jobs.length !== prevProps.jobs.length) {
        let notebookCount = 0;
        let notebookRunningCount = 0;
        let trainingCount = 0;
        let trainingRunningCount = 0;
        let inferenceCount = 0;
        let inferenceRunningCount = 0;
        let endpointCount = 0;
        let endpointRunningCount = 0;
        for (let job of this.props.jobs) {
          switch (job.type) {
            case JOB_TYPES.NOTEBOOK.value:
              notebookCount++;
              if (job.status === STATUSES.RUNNING) {
                notebookRunningCount++;
              }
              break;
            case JOB_TYPES.TRAINING.value:
              trainingCount++;
              if (job.status === STATUSES.RUNNING) {
                trainingRunningCount++;
              }
              break;
            case JOB_TYPES.INFERENCE.value:
              inferenceCount++;
              if (job.status === STATUSES.RUNNING) {
                inferenceRunningCount++;
              }
              break;
            case JOB_TYPES.ENDPOINT.value:
              endpointCount++;
              if (job.status === STATUSES.RUNNING) {
                endpointRunningCount++;
              }
              break;
            default:
              return;
          }
        }
        this.setState({
          notebookCount,
          notebookRunningCount,
          trainingCount,
          trainingRunningCount,
          inferenceCount,
          inferenceRunningCount,
          endpointCount,
          endpointRunningCount,
        });
      }
    }
    if (this.props.datasets) {
      if (
        !prevProps.datasets ||
        this.props.datasets.length !== prevProps.datasets.length
      ) {
        let datasetCount = this.props.datasets.reduce(
          (val, dataset) =>
            dataset.status === STATUSES.READY ? val + 1 : val + 0,
          0
        );
        this.setState({
          datasetCount,
        });
      }
    }
    if (this.props.models) {
      if (
        !prevProps.models ||
        this.props.models.length !== prevProps.models.length
      ) {
        let modelCount = this.props.models.reduce(
          (val, model) => (model.status === STATUSES.READY ? val + 1 : val + 0),
          0
        );
        this.setState({
          modelCount,
        });
      }
    }
    if (this.props.checkpoints) {
      if (
        !prevProps.checkpoints ||
        this.props.checkpoints.length !== prevProps.checkpoints.length
      ) {
        let checkpointCount = this.props.checkpoints.reduce(
          (val, checkpoint) =>
            checkpoint.status === STATUSES.READY ? val + 1 : val + 0,
          0
        );
        this.setState({
          checkpointCount,
        });
      }
    }
  }

  render() {
    const {
      notebookCount,
      notebookRunningCount,
      trainingCount,
      trainingRunningCount,
      inferenceCount,
      inferenceRunningCount,
      endpointCount,
      endpointRunningCount,
      datasetCount,
      modelCount,
      checkpointCount,
    } = this.state;
    return (
      <>
        <Grid
          stackable
          relaxed
          style={{
            transform: 'translate(0, 20px)',
          }}
          centered
          columns='equal'
        >
          <Grid.Column>
            <Card raised fluid>
              <Card.Content header='Resources' />
              <Card.Content as={Link} to={'/jobs/notebook'}>
                <Header as='h4'>Notebooks</Header>
                <p>Total Count: {notebookCount}</p>
                <p>Currently Running: {notebookRunningCount}</p>
              </Card.Content>
              <Card.Content as={Link} to={'/jobs/training'}>
                <Header as='h4'>Training Jobs</Header>
                <p>Total Count: {trainingCount}</p>
                <p>Currently Running: {trainingRunningCount}</p>
              </Card.Content>
              <Card.Content as={Link} to={'/jobs/inference'}>
                <Header as='h4'>Inference Jobs</Header>
                <p>Total Count: {inferenceCount}</p>
                <p>Currently Running: {inferenceRunningCount}</p>
              </Card.Content>
              <Card.Content as={Link} to={'/jobs/endpoint'}>
                <Header as='h4'>Endpoints</Header>
                <p>Total Count: {endpointCount}</p>
                <p>Currently Running: {endpointRunningCount}</p>
              </Card.Content>
              <Card.Content as={Link} to={'/dataset'}>
                <Header as='h4'>Datasets</Header>
                <p>Total Count: {datasetCount}</p>
              </Card.Content>
              <Card.Content as={Link} to={'/model'}>
                <Header as='h4'>Models</Header>
                <p>Total Count: {modelCount}</p>
              </Card.Content>
              <Card.Content as={Link} to={'/checkpoint'}>
                <Header as='h4'>Checkpoints</Header>
                <p>Total Count: {checkpointCount}</p>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card raised fluid>
              <Card.Content header='Getting Started' />
              <Card.Content>
                <Link to='/account/billing'>
                  <p>Add Credits</p>
                </Link>
              </Card.Content>
              <Card.Content>
                <Link
                  to={{
                    pathname: '/jobs/notebook/new',
                  }}
                >
                  <p>Start a Notebook</p>
                </Link>
              </Card.Content>
              <Card.Content>
                <Link
                  to={{
                    pathname: '/jobs/training/new',
                  }}
                >
                  <p>Create a Training Job</p>
                </Link>
              </Card.Content>
              <Card.Content>
                <Link
                  to={{
                    pathname: '/jobs/inference/new',
                  }}
                >
                  <p>Run an Inference Job</p>
                </Link>
              </Card.Content>
              <Card.Content>
                <Link
                  to={{
                    pathname: '/jobs/endpoint/new',
                  }}
                >
                  <p>Deploy an Inference Endpoint</p>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card raised fluid>
              <Card.Content header='Documentation' />
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/getting-started/creating-account/`}
                >
                  Getting Started Guide
                </a>
              </Card.Content>
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/tutorials/basic/jupyter-notebook-pytorch-imagenet/`}
                >
                  Notebooks Tutorial
                </a>
              </Card.Content>
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/tutorials/basic/parallel-experiments-with-notebook-copying/`}
                >
                  Parallel Training Experiments with Notebooks
                </a>
              </Card.Content>
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/tutorials/basic/tensorflow-cifar10-image-classification/`}
                >
                  Simple Image Classifier Training Job
                </a>
              </Card.Content>
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/tutorials/ml-pipeline/pytorch-pneumonia-detection-pipeline/`}
                >
                  Full Training and Inference Pipeline
                </a>
              </Card.Content>
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/tutorials/hyperparameter-tuning/hyperopt-mxnet-voc-object-detection/`}
                >
                  Parallel Hyperparameter Tuning
                </a>
              </Card.Content>
              <Card.Content>
                <a
                  href={`${process.env.REACT_APP_DOC_URL}/tutorials/gan/stable-diffusion-2-custom-model-training`}
                >
                  Stable Diffusion 2 Dreambooth Training
                </a>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}
function mapStateToProps({
  user,
  jobs,
  datasets,
  models,
  checkpoints,
  errorMessage,
}) {
  return {
    user,
    jobs: jobs || [],
    datasets: datasets || [],
    models: models || [],
    checkpoints: checkpoints || [],
    errorMessage,
  };
}

export default connect(mapStateToProps, {
  fetchJobs,
  fetchDatasets,
  fetchModels,
  fetchCheckpoints,
})(Dashboard);
