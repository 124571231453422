import { CLEAR_SESSION, SET_SESSION, LOGOUT } from './types';
import { Auth } from 'aws-amplify';
import { extractSession } from '../services/auth';
import store from '../store';

export const clearSession = () => ({
  type: CLEAR_SESSION,
});

export const logout = () => async (dispatch) => {
  Auth.signOut();
  dispatch(clearSession());
  dispatch({
    type: LOGOUT,
  });
};

export const setSession = (session) => (dispatch) => {
  dispatch({
    type: SET_SESSION,
    session,
  });
};

export const getSession = () => async (dispatch) => {
  let session;
  try {
    const user = await Auth.currentAuthenticatedUser();
    session = extractSession(user);
    dispatch({ type: SET_SESSION, session });
  } catch (err) {
    if (
      err === 'The user is not authenticated' &&
      store.getState().session.isLoggedIn &&
      store.getState().session.expires > Date.now() / 1000
    ) {
      return;
    }
    dispatch(clearSession());
  }
};
