import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material';

export default function GCPRegionField({
  value,
  setValue,
  setChanged,
  all_regions,
  used_regions,
  edit,
}) {
  const [optionValue, setOptionValue] = React.useState(value ? value : null);
  const [inputValue, setInputValue] = React.useState(value ? value : '');

  const handleChange = (event, newValue) => {
    if (!newValue) {
      setOptionValue(null);
      setValue(null);
      return;
    }
    if (newValue.label !== value) {
      setChanged();
    }
    setValue(newValue.label);
    setOptionValue(newValue.label);
  };

  const regions = all_regions.map((region) => {
    if (used_regions.includes(region.name)) {
      return {
        label: region.name,
        disabled: true,
      };
    } else {
      return { label: region.name };
    }
  });

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          id='gcp-region-name'
          options={regions}
          // sx={{ width: 300 }}
          onChange={handleChange}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disabled={edit}
          value={optionValue}
          inputValue={inputValue}
          getOptionDisabled={(option) => option.disabled}
          isOptionEqualToValue={(option, value) => option.label === value}
          renderInput={(params) => (
            <TextField {...params} label='Region Name' />
          )}
        />

        <FormHelperText id='gcp-region-name-helper-text'>
          Select a Google Cloud region to deploy CloudBender resources.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
