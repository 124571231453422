import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Toolbar,
  Stack,
  Box,
  Tooltip,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';

import { exportCollection } from '../../../actions/collections';
import CollectionOutputTypeField from './CollectionOutputTypeField';
import CollectionOutputUriField from './CollectionOutputUriField';
import CollectionEndpointUrlField from './CollectionEndpointUrlField';
import THIRD_PARTY_TYPES from '../../jobs/JobForm/thirdPartyTypes';
import toTitleCase from '../../../util/toTitleCase';

export default function CollectionExportModal({
  entity_type,
  open,
  handleClose,
  collection,
}) {
  const dispatch = useDispatch();

  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [output_type, setOutputType] = useState('');
  const [output_uri, setOutputUri] = useState('');
  const [output_uri_error, setOutputUriError] = useState(false);
  const [archive, setArchive] = useState(true);
  const [endpoint_url, setEndpointUrl] = useState('');
  const [endpoint_url_error, setEndpointUrlError] = useState(false);
  const user = useSelector((state) => state.user);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        output_type,
        output_uri,
        output_options: {
          archive,
          endpoint_url: Boolean(endpoint_url) ? endpoint_url : undefined,
        },
      };
      await dispatch(exportCollection(entity_type, collection.id, payload));
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  if (!collection) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle></DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  const not_enough_credits = Boolean(
    !user.cloudbender_enabled &&
      user.credits <= 0 &&
      !user.billing_account_id &&
      user.id === user.selected_project
  );

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            Export {toTitleCase(entity_type)} {collection.name}
          </Grid>
          <Grid item xs={1} sx={{ maxWidth: '25px' }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          padding='10px'
          sx={{
            maxWidth: 400,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <CollectionOutputTypeField
                entity_type={entity_type}
                value={output_type}
                setValue={(value) => {
                  setOutputUri('');
                  setOutputUriError(false);

                  if (Boolean(endpoint_url)) {
                    setEndpointUrl('');
                    setEndpointUrlError(false);
                  }
                  setOutputType(value);
                }}
                setChanged={() => setPristine(false)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CollectionOutputUriField
                value={output_uri}
                error={output_uri_error}
                setValue={setOutputUri}
                setError={setOutputUriError}
                setChanged={() => setPristine(false)}
                outputType={output_type}
              />
            </Grid>
            {output_type === THIRD_PARTY_TYPES.WASABI.value ? (
              <Grid item xs={12} md={12} lg={12}>
                <CollectionEndpointUrlField
                  value={endpoint_url}
                  error={endpoint_url_error}
                  edit={Boolean(collection)}
                  setValue={setEndpointUrl}
                  setError={setEndpointUrlError}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
            ) : undefined}
            <Grid item xs={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={archive}
                    onChange={() => {
                      setPristine(false);
                      setArchive(!archive);
                    }}
                  />
                }
                label='Archive'
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Toolbar>
          <Stack spacing={2} direction='row'>
            <Tooltip title={not_enough_credits ? 'Not Enough Credits' : ''}>
              <span>
                <LoadingButton
                  variant='contained'
                  color='brand'
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={
                    pristine ||
                    not_enough_credits ||
                    Boolean(output_uri_error) ||
                    !Boolean(output_uri) ||
                    Boolean(endpoint_url_error) ||
                    (output_type === THIRD_PARTY_TYPES.WASABI.value &&
                      !endpoint_url)
                  }
                >
                  Submit
                </LoadingButton>
              </span>
            </Tooltip>

            <Button variant='outlined' onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
          </Stack>
        </Toolbar>
      </DialogActions>
    </Dialog>
  );
}
