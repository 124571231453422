import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

// const email_re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

class ProfileForm extends Component {
  state = {
    email: this.props.user.email,
    given_name: this.props.user.given_name,
    family_name: this.props.user.family_name,
    display_name: this.props.user.display_name,
    // emailError: false,
    pristine: true,
    error: false,
  };

  handleChange = (e, { name, value }) => {
    switch (name) {
      // case 'email':
      //   const valid = email_re.test(value);
      //   emailError = !valid;
      //   break;
      case 'display_name':
        this.setState({
          [name]: value,
          nameError: value === '',
        });
        this.props.handleChange({
          error: value === '' || this.state.firstError || this.state.lastError,
          pristine: false,
        });
        break;
      case 'given_name':
        this.setState({
          [name]: value,
          firstError: value === '',
        });
        this.props.handleChange({
          error: this.state.nameError || value === '' || this.state.lastError,
          pristine: false,
        });
        break;
      case 'family_name':
        this.setState({
          [name]: value,
          lastError: value === '',
        });
        this.props.handleChange({
          error: this.state.nameError || this.state.firstError || value === '',
          pristine: false,
        });
        break;
      default:
        this.setState({
          [name]: value,
        });
        this.props.handleChange({
          pristine: false,
        });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };

  render() {
    const { email, given_name, family_name, display_name } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Input
          name='email'
          label='Email'
          required
          value={email}
          onChange={this.handleChange}
          disabled
        />
        <Form.Input
          name='display_name'
          label='Full Name'
          value={display_name}
          required
          onChange={this.handleChange}
          error={
            this.state.nameError
              ? {
                  content: 'Please enter a value',
                  pointing: 'below',
                }
              : undefined
          }
        />
        <Form.Input
          name='given_name'
          label='First Name'
          value={given_name}
          onChange={this.handleChange}
          error={
            this.state.firstError
              ? {
                  content: 'Please enter a value',
                  pointing: 'below',
                }
              : undefined
          }
        />
        <Form.Input
          name='family_name'
          label='Last Name'
          value={family_name}
          onChange={this.handleChange}
          error={
            this.state.lastError
              ? {
                  content: 'Please enter a value',
                  pointing: 'below',
                }
              : undefined
          }
        />

        {this.props.buttons}
      </Form>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps)(ProfileForm);
