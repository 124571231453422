import React, { Component } from 'react';
import {
  Grid,
  Container,
  Header,
  Button,
  Icon,
  Message,
  Placeholder,
  PlaceholderLine,
  Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DataReview from './DataReview';
import EnvironmentReview from './EnvironmentReview';
import ModelReview from './ModelReview';
import ResourcesReview from './ResourcesReview';
import WorkerReview from './WorkerReview';
import BytesToMinimumGB from '../../../util/BytesToMinimumGB';
import JOB_TYPES from '../types';
import EndpointReview from './EndpointReview';
import api from '../../../apis/api';
import NoGpusModal from '../NoGpusModal';
import { clearErrorMessage } from '../../../actions/errorMessage';

class JobFormReview extends Component {
  state = {
    available: null,
    loading: true,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const {
      gpu_types,
      gpu_count,
      cpu_count,
      max_price,
      disk_size,
      preemptible,
    } = this.props.jobRequest.resources;
    try {
      const res = await api.get(
        `/project/${this.props.user.selected_project}/gputypes/available`,
        {
          params: {
            gpu_types: JSON.stringify(gpu_types),
            gpu_count,
            cpu_count,
            max_price,
            disk_size,
            preemptible,
          },
        }
      );
      this.setState({ available: res.data, loading: false });
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  }

  handleSubmit = async (type) => {
    this.props.onSubmit();
  };

  handleCancel = async (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  renderCreditsMessage() {
    const not_enough_credits = Boolean(
      !this.props.user.cloudbender_enabled &&
        this.props.user.credits <= this.props.credits_per_hour_min &&
        !this.props.user.billing_account_id &&
        this.props.user.id === this.props.user.selected_project
    );
    if (this.props.max_price_too_low) {
      <Message attached='bottom' error>
        <Header as='h3'>Maximum Price Too Low</Header>
        <p>
          The maximum price per GPU requested is lower than the minimum
          available price per GPU.
        </p>
      </Message>;
    } else if (not_enough_credits && !this.props.submitting) {
      return (
        <Message attached='bottom' error>
          <Header as='h3'>Not Enough Credits</Header>
          <p>
            Please{' '}
            <Link to='/account/billing' target='_blank'>
              purchase credits
            </Link>{' '}
            in order to create a job.
          </p>
        </Message>
      );
    } else {
      return (
        <Message attached='bottom' info>
          <Header as='h3'>
            You will be billed{' '}
            {this.props.credits_per_hour_min === this.props.credits_per_hour_max
              ? this.props.credits_per_hour_max
              : `between ${this.props.credits_per_hour_min} and ${this.props.credits_per_hour_max}`}{' '}
            credits each hour this job runs.
          </Header>
        </Message>
      );
    }
  }

  render() {
    if (!this.props.jobRequest) {
      return <div>No data</div>;
    }
    const not_enough_credits = Boolean(
      !this.props.user.cloudbender_enabled &&
        this.props.user.credits <= this.props.credits_per_hour_min &&
        !this.props.user.billing_account_id &&
        this.props.user.id === this.props.user.selected_project
    );
    const {
      jobRequest: {
        name,
        type,
        data,
        environment,
        model,
        endpoint,
        workers,
        resources,
      },
      submitting,
    } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Container>
              <Header as='h2' textAlign='center'>
                Review: {name}
              </Header>
              {this.state.loading ? (
                <Segment>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <PlaceholderLine />
                      <PlaceholderLine />
                      <PlaceholderLine />
                      <PlaceholderLine />
                    </Placeholder.Paragraph>
                    <Placeholder.Paragraph>
                      <PlaceholderLine />
                      <PlaceholderLine />
                      <PlaceholderLine />
                      <PlaceholderLine />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Segment>
              ) : (
                <>
                  <ResourcesReview
                    data={{
                      ...resources,
                      available: this.state.available,
                      type,
                      worker_count: workers.length,
                      datasets_size: data.datasets
                        ? data.datasets.length
                          ? data.datasets
                              .map((dataset) =>
                                BytesToMinimumGB(
                                  dataset.size || dataset.used_size
                                )
                              )
                              .reduce((a, b) => a + b)
                          : 0
                        : 0,
                    }}
                  />
                  {(data.datasets && data.datasets.length) ||
                  data.output_type ||
                  data.input_type ? (
                    <DataReview data={data} />
                  ) : undefined}
                  {model.source_uri ||
                  (model.checkpoints && model.checkpoints.length) ? (
                    <ModelReview data={model} />
                  ) : undefined}
                  {[
                    JOB_TYPES.TRAINING.value,
                    JOB_TYPES.INFERENCE.value,
                  ].includes(type) ? (
                    <WorkerReview type={type} data={workers} />
                  ) : type === JOB_TYPES.ENDPOINT.value ? (
                    <EndpointReview type={type} data={endpoint} />
                  ) : undefined}
                  <EnvironmentReview data={environment} />
                  {this.renderCreditsMessage()}
                  <Grid verticalAlign='middle'>
                    <Grid.Row>
                      <Grid.Column floated='left' width={3}>
                        <Button
                          icon
                          floated='left'
                          secondary
                          labelPosition='left'
                          onClick={this.handleCancel}
                        >
                          <Icon name='close' />
                          Cancel
                        </Button>
                      </Grid.Column>
                      <Grid.Column floated='right' width={3}>
                        <Button
                          icon
                          floated='right'
                          primary
                          disabled={
                            not_enough_credits ||
                            this.state.available === null ||
                            this.props.max_price_too_low
                          }
                          labelPosition='right'
                          loading={submitting}
                          onClick={() => this.handleSubmit(type)}
                        >
                          <Icon name='check' />
                          Create
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                    {/* <Grid.Row>
                  <Grid.Column>
                    <DisplayCode code={JSON.stringify(this.props.jobRequest)} />
                  </Grid.Column>
                </Grid.Row> */}
                  </Grid>
                </>
              )}
              <NoGpusModal
                errorMessage={this.props.errorMessage}
                clearError={() => this.props.clearErrorMessage()}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps({ user, errorMessage }) {
  return { user, errorMessage };
}

export default connect(mapStateToProps, { clearErrorMessage })(JobFormReview);
