import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Segment, Header } from 'semantic-ui-react';
import ConsolidatedBillingRequestForm from './consolidated/ConsolidatedBillingRequestForm';
import ConsolidatedBillingRequestPending from './consolidated/ConsolidatedBillingRequestPending';
import ConsolidatedBillingAdmin from './consolidated/ConsolidatedBillingAdmin';

class ConsolidatedBillingForm extends Component {
  render() {
    return (
      <>
        <Segment>
          <Header textAlign='center' as='h2'>
            Consolidated Billing
          </Header>
          <p>
            Consolidated billing allows multiple{' '}
            {process.env.REACT_APP_ORG_NAME} users to shared the same credit
            pool that is managed by and only visible to a central account.
          </p>
          {(this.props.user.billing_link_requests &&
            this.props.user.billing_link_requests.length) ||
          (this.props.user.billing_linked_accounts &&
            this.props.user.billing_linked_accounts.length) ? (
            <ConsolidatedBillingAdmin />
          ) : this.props.user.requested_billing_email ? (
            <ConsolidatedBillingRequestPending />
          ) : (
            <ConsolidatedBillingRequestForm />
          )}
        </Segment>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps)(ConsolidatedBillingForm);
