import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { getCognitoSignInUrl } from '../services/auth';
import history from '../history';

class Landing extends Component {
  renderButton() {
    if (this.props.session.isLoggedIn && this.props.user) {
      return (
        <Link
          to={
            this.props.user.status === 'approved'
              ? '/dashboard'
              : '/account/login'
          }
        >
          <Button primary labelPosition='right' icon>
            <Icon name='sign-in' />
            Get Started
          </Button>
        </Link>
      );
    }

    return (
      <a href={getCognitoSignInUrl()}>
        <Button primary labelPosition='right' icon>
          <Icon name='sign-in' />
          Get Started
        </Button>
      </a>
    );
  }
  render() {
    if (this.props.session.isLoggedIn && this.props.user) {
      if (this.props.user.status === 'approved') {
        history.push('/dashboard');
        return <div>Redirecting...</div>;
      }
    }
    return (
      <div id='content' className='ui pusher' style={{ textAlign: 'center' }}>
        <img
          className='ui large centered image'
          alt={`${process.env.REACT_APP_ORG_NAME}`}
          src='/logo.png'
        />
        <br />
        <h3>{process.env.REACT_APP_TAG_LINE}</h3>
        <br />
        {this.renderButton()}
      </div>
    );
  }
}
function mapStateToProps({ user, session }) {
  return {
    user,
    session,
  };
}

export default connect(mapStateToProps)(Landing);
