import React from 'react';

import { FormControl, FormHelperText, TextField } from '@mui/material';

export default function KeyIdField({
  label,
  placeholder,
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);
    if (newValue === '') {
      setError('Key ID cannot be blank');
    } else {
      setError(false);
    }
  };

  return (
    <FormControl fullWidth required>
      <TextField
        label={label || 'Key ID'}
        id='key-id'
        aria-describedby='key-id-helper-text'
        variant='outlined'
        onChange={handleChange}
        error={Boolean(error)}
        value={value}
        disabled={edit}
        placeholder={placeholder}
      />
      {error ? (
        <FormHelperText id='key-id-helper-text'>{error}</FormHelperText>
      ) : undefined}
    </FormControl>
  );
}
