import React, { useState, useCallback } from 'react';
import {
  IconButton,
  Grid,
  Box,
  Typography,
  Drawer,
  Paper,
  Stack,
  Toolbar,
  Button,
} from '@mui/material';

import { ArrowBack } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EnvField from './EnvField';

export default function EnvDrawer({
  open,
  handleClose,
  initialValues,
  setValue,
  error,
  setError,
}) {
  const [env, setEnv] = useState(
    initialValues.map((env) => {
      return { ...env };
    })
  );

  const [env_errors, setEnvErrors] = useState(
    new Array(initialValues.length).fill(false)
  );

  const calculateError = useCallback(
    (env_errors) => {
      const errors_set = new Set([...env_errors]);
      if (
        errors_set.size > 1 ||
        ([...errors_set][0] && [...errors_set][0] !== false)
      ) {
        setError(true);
      } else {
        setError(false);
      }
    },
    [setError]
  );

  const setEnvError = useCallback(
    (i, error) => {
      const new_errors = [...env_errors];
      new_errors[i] = error;
      setEnvErrors(new_errors);
      calculateError(new_errors);
    },
    [env_errors, setEnvErrors, calculateError]
  );

  const addEnv = useCallback(() => {
    const new_env = [...env, { key: '', value: '' }];
    setEnv(new_env);
    const new_errors = [...env_errors, 'Key cannot be blank'];
    setEnvErrors(new_errors);
    calculateError(new_errors);
  }, [env, env_errors, setEnv, calculateError, setEnvErrors]);

  const removeEnv = useCallback(
    (i) => {
      const new_env = [...env];
      new_env.splice(i, 1);
      setEnv(new_env);
      const new_errors = [...env_errors];
      new_errors.splice(i, 1);
      setEnvErrors(new_errors);
      calculateError(new_errors);
    },
    [env, env_errors, setEnv, setEnvErrors, calculateError]
  );

  const setEnvValue = useCallback(
    (i, values) => {
      const new_env = [...env];
      new_env[i] = values;
      setEnv(new_env);
    },
    [env, setEnv]
  );

  const submit = useCallback(() => {
    setValue(env);
    handleClose();
  }, [env, setValue, handleClose]);

  return (
    <Drawer
      sx={{ height: '100%' }}
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      <Grid
        container
        spacing={2}
        direction='row'
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          margin: '0px',
          padding: '0px 0px 0px 0px !important',
          '.MuiGrid-root': {
            padding: '0px 0px 0px 0px',
          },
          maxWidth: '550px',
        }}
      >
        <Grid
          item
          container
          justify='flex-start'
          sx={{
            maxHeight: '75px',
          }}
        >
          <Paper
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
            elevation={1}
          >
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => handleClose()}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>
                  Manage Environment Variables
                </Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <Stack spacing={2} direction='column' alignItems='center'>
              {env.map((value, index) => {
                return (
                  <EnvField
                    key={`env-${index}`}
                    index={index}
                    value={value}
                    error={env_errors[index]}
                    setError={setEnvError}
                    setValue={setEnvValue}
                    remove={removeEnv}
                  />
                );
              })}
              <Button variant='outlined' color='brand' onClick={() => addEnv()}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AddCircleOutlineIcon />
                  <Typography variant='button' display='block' gutterBottom>
                    Add Variable
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          container
          sx={{
            maxHeight: '50px',
            position: 'absolute',
            bottom: '0px',
            width: '100%',
          }}
        >
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={1}>
            <Toolbar>
              <Stack spacing={2} direction='row'>
                <Button
                  variant='contained'
                  color='brand'
                  onClick={submit}
                  disabled={error}
                >
                  Save
                </Button>
                <Button variant='outlined' onClick={handleClose}>
                  Cancel
                </Button>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
      </Grid>
    </Drawer>
  );
}
