import THIRD_PARTY_TYPES from '../jobs/JobForm/thirdPartyTypes';

const KEY_TYPES = [
  THIRD_PARTY_TYPES.AWS,
  THIRD_PARTY_TYPES.AZURE,
  THIRD_PARTY_TYPES.DOCKER,
  THIRD_PARTY_TYPES.HUGGINGFACE,
  THIRD_PARTY_TYPES.GCP,
  THIRD_PARTY_TYPES.KAGGLE,
  THIRD_PARTY_TYPES.NVIDIA,
  THIRD_PARTY_TYPES.WASABI,
];

export default KEY_TYPES;
