import {
  FETCH_DEVICE_CONFIG,
  FETCH_DEVICE_CONFIGS,
  DELETE_DEVICE_CONFIG,
  CREATE_DEVICE_CONFIG,
  CLEAR_DEVICE_CONFIGS,
} from './types';
import { setErrorMessage } from './errorMessage';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';

export const fetchDeviceConfigs =
  (provider_uuid, region_uuid) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/device/config`
      );
      dispatch({
        type: FETCH_DEVICE_CONFIGS,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const fetchDeviceConfig =
  (provider_uuid, region_uuid, config_id) => async (dispatch) => {
    try {
      const res = await api.get(
        `/provider/${provider_uuid}/region/${region_uuid}/device/config/${config_id}`
      );
      dispatch({
        type: FETCH_DEVICE_CONFIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const updateDeviceConfig =
  (provider_uuid, region_uuid, config_id, data) => async (dispatch) => {
    try {
      const res = await api.put(
        `/provider/${provider_uuid}/region/${region_uuid}/device/config/${config_id}`,
        data
      );
      dispatch({
        type: FETCH_DEVICE_CONFIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const deleteDeviceConfig =
  (provider_uuid, region_uuid, config_id) => async (dispatch) => {
    try {
      await api.delete(
        `/provider/${provider_uuid}/region/${region_uuid}/device/config/${config_id}`
      );
      dispatch({
        type: DELETE_DEVICE_CONFIG,
        payload: config_id,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const createDeviceConfig =
  (provider_uuid, region_uuid, values) => async (dispatch) => {
    try {
      const res = await api.post(
        `/provider/${provider_uuid}/region/${region_uuid}/device/config`,
        values
      );
      dispatch({
        type: CREATE_DEVICE_CONFIG,
        payload: res.data,
      });
    } catch (error) {
      const message = getMessageFromApiError(error);
      dispatch(setErrorMessage(message));
      throw new Error(`API Error - ${message}`);
    }
  };

export const clearDeviceConfigs = () => ({
  type: CLEAR_DEVICE_CONFIGS,
});
