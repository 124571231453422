import { FETCH_ARCHIVED_JOBS, CLEAR_ARCHIVED_JOBS } from '../actions/types';

const initialState = [];

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARCHIVED_JOBS:
      return [...state, ...action.payload];
    case CLEAR_ARCHIVED_JOBS:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
