import _ from 'lodash';
import {
  FETCH_DATASETS,
  UPDATE_DATASET,
  CREATE_DATASET,
  DELETE_DATASET,
} from '../actions/types';

const initialState = null;
let newState;

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATASETS:
      newState = action.payload.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case UPDATE_DATASET:
      newState = [...(state || [])];
      _.remove(newState, (dataset) => {
        return dataset.dataset_uuid === action.payload.dataset_uuid;
      });
      newState = [...newState, action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case CREATE_DATASET:
      newState = [...(state || []), action.payload].sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    case DELETE_DATASET:
      newState = [...(state || [])];
      _.remove(newState, (dataset) => {
        return dataset.dataset_uuid === action.payload;
      });
      newState = newState.sort((a, b) => {
        const timeA = a.createdAt;
        const timeB = b.createdAt;
        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        } else return 0;
      });
      return newState;
    default:
      return state;
  }
}

export default reducer;
