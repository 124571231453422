import { CLEAR_PAYMENTS, FETCH_PAYMENTS } from './types';
import api from '../apis/api';
import getMessageFromApiError from '../util/getMessageFromApiError';
import { setErrorMessage } from './errorMessage';

export const clearPayments = () => ({
  type: CLEAR_PAYMENTS,
});

export const fetchPayments = () => async (dispatch) => {
  try {
    const res = await api.get('/billing/payment');
    dispatch({
      type: FETCH_PAYMENTS,
      payload: res.data,
    });
  } catch (error) {
    const message = getMessageFromApiError(error);
    dispatch(setErrorMessage(message));
    throw new Error(`API Error - ${message}`);
  }
};
