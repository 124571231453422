import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Button, Icon, Form } from 'semantic-ui-react';
import { cancelBillingLinkRequest } from '../../../actions/user';

class ConsolidatedBillingRequestPending extends Component {
  state = {
    email: '',
    error: false,
    pristine: true,
    submitting: false,
  };

  cancelRequest = async () => {
    this.setState({ submitting: true });
    await this.props.cancelBillingLinkRequest();
    this.setState({ submitting: false });
  };

  render() {
    return (
      <>
        <Header as='h4'>Your request is pending</Header>
        <Form>
          <Form.Input
            value={this.props.user.requested_billing_email}
            label='Consolidated Billing Account Email'
            disabled
          />
        </Form>
        <br />
        <Button
          primary
          icon
          labelPosition='right'
          onClick={this.cancelRequest}
          loading={this.state.submitting}
        >
          <Icon name='envelope' />
          Cancel Request
        </Button>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  cancelBillingLinkRequest,
})(ConsolidatedBillingRequestPending);
