import { CLEAR_SESSION, SET_SESSION } from '../actions/types';

const initialState = {
  isLoggedIn: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION:
      return Object.assign({}, action.session, { isLoggedIn: true });

    case CLEAR_SESSION:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
