import React, { Component } from 'react';
import { Button, Table, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import round from '../../../util/round';

import { removeApiKey } from '../../../actions/user';

class ApiKey extends Component {
  state = {
    removing: false,
  };

  handleDelete = async (e) => {
    e.preventDefault();
    this.setState({ removing: true });
    await this.props.removeApiKey(this.props.api_key.id);
  };

  render() {
    const { id, created, last_rotated } = this.props.api_key;
    return (
      <Table.Row>
        <Table.Cell>{id}</Table.Cell>
        <Table.Cell textAlign='center'>
          {moment(created).format('ll')}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {round(moment.duration(moment().diff(last_rotated)).asDays(), 0)} Days
        </Table.Cell>
        <Table.Cell>
          <Button
            secondary
            icon
            labelPosition='right'
            floated='right'
            onClick={this.handleDelete}
            loading={this.state.removing}
          >
            Remove
            <Icon name='trash alternate' />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default connect(null, { removeApiKey })(ApiKey);
