import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

const ipv4CidrRegex = /^(?:\d{1,3}\.){3}\d{1,3}(?:\/\d{1,2})?$/;
const ipv6CidrRegex =
  /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}(?:\/\d{1,3})?$/;

export default function DataConnectorCidrField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  edit,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setValue(newValue);

    if (ipv4CidrRegex.test(newValue) || ipv6CidrRegex.test(newValue)) {
      setError(false);
    } else {
      setError(`Invalid address.  Must be in CIDR notation.`);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label='Address'
          id='cidr'
          aria-describedby='cidr-helper-text'
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
        />
        {error ? (
          <FormHelperText id='cidr-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='cidr-helper-text'>
            Enter the IP address or address range the target service runs on in
            CIDR notation.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
