import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Message,
  Segment,
  Dimmer,
  Loader,
  Grid,
  Header,
  Placeholder,
  Container,
} from 'semantic-ui-react';

import PaymentMethodList from './method/PaymentMethodList';
import PaymentForm from './PaymentForm';
import ConsolidatedBillingForm from './ConsolidatedBillingForm';

class Payments extends Component {
  renderPaymentForm() {
    if (!this.props.paymentMethods) {
      return (
        <Dimmer.Dimmable as={Segment} dimmed>
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Dimmer.Dimmable>
      );
    }
    if (this.props.paymentMethods.length > 0) {
      return (
        <PaymentForm
          user={this.props.user}
          primaryPaymentMethod={this.props.primaryPaymentMethod}
        />
      );
    } else {
      return (
        <Message negative>
          <Message.Header>
            Please add a payment method to purchase credits.
          </Message.Header>
        </Message>
      );
    }
  }

  render() {
    return (
      <Grid rows='equal'>
        <Grid.Row>
          <Grid.Column>
            <Container>
              <Grid.Row>{this.renderPaymentForm()}</Grid.Row>
              <br />
              <Grid.Row>
                <Segment textAlign='center'>
                  <Header as='h2'>Payment Methods</Header>
                  <PaymentMethodList primary={this.props.user.primary_pm} />
                </Segment>
              </Grid.Row>
              <br />
              <ConsolidatedBillingForm />
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = ({ user, paymentMethods }) => {
  const paymentMethod = _.filter(paymentMethods, (method) => {
    return method.id === user.primary_pm;
  });
  return {
    user: user,
    paymentMethods: paymentMethods,
    primaryPaymentMethod: paymentMethod[0],
  };
};

export default connect(mapStateToProps)(Payments);
