import React from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useSelector } from 'react-redux';
import PROVIDER_TYPES from '../../../providerTypes';

export default function NodeRegionField({
  value,
  error,
  setValue,
  setError,
  setChanged,
  setProvider,
}) {
  const regions = useSelector((state) => state.regions);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setChanged();
    setProvider(
      regions[PROVIDER_TYPES.PHYSICAL.value]?.find(
        (region) => region.region_uuid === newValue
      )?.provider_uuid
    );
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='region-id-label'>Region</InputLabel>
        <Select
          labelId='region-id-label'
          id='region_uuid'
          value={value}
          label='Region'
          onChange={handleChange}
          required
        >
          {regions[PROVIDER_TYPES.PHYSICAL.value]?.map((region) => {
            return (
              <MenuItem key={region.region_uuid} value={region.region_uuid}>
                {region.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText id='region-id-helper-text'>
          Select the region to add the new node to.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
